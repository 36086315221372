import React, { useState } from "react";
import { Notyf } from "notyf";

import upload from "~/assets/svg/upload-lg.svg";

// Components
import Loader from "~/components/Loader";
// Utils
import { updateTerminal } from "~/api/terminal";
//import { uploadFile } from '~/api/upload';
import { useLoadMedia } from "~hooks/loadMedia";
import { getRealFileName } from "~/_helpers/helper";
//let _URL = window.URL;
//let f = (file) => _URL.createObjectURL(file);

const Imgs = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const { terminal, store } = props;
  const { imageLogoUrl, fond, bannerUrl } = terminal;
  const [loadLogo, setLoadLogo] = useState(false);
  const [loadFond, setloadFond] = useState(false);
  const [loadBannerUrl, setloadBannerUrl] = useState(false);
  const [loadBanner, setLoadBanner] = useState(false);
  const [input, setInput] = useState({
    logo: "",
    fond: "",
    bannerUrl: "",
    banner: "",
  });
  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    /* uppdateInfo,
    setUpdateInfo, */
    setLoadMedia,
    Media,
  } = useLoadMedia({
    logo: {
      boolean: loadLogo,
      action: setLoadLogo,
      name: "logo",
      updateValue: setInput,
    },
    fond: {
      boolean: loadFond,
      action: setloadFond,
      name: "fond",
      updateValue: setInput,
    },
    bannerUrl: {
      boolean: loadBannerUrl,
      action: setloadBannerUrl,
      name: "bannerUrl",
      updateValue: setInput,
    },
    banner: {
      boolean: loadBanner,
      action: setLoadBanner,
      name: "banner",
      updateValue: setInput,
    },
  });

  const updateConfig = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      const data = {};
      if (input.logo) {
        data.imageLogoUrl = input.logo;
      }
      // if (input.banner) {
      //   data.imageBannerUrl = input.banner;
      // }
      if (input.fond) {
        if (getRealFileName(input.fond).includes(".mp4")) {
        }

        data.fond = {
          url: input.fond,
          type: getRealFileName(input.fond).includes(".mp4") ? "video" : "img",
        };
      }

      if (input.bannerUrl) {
        if (getRealFileName(input.bannerUrl).includes(".mp4")) {
        }

        data.bannerUrl = {
          url: input.bannerUrl,
          type: getRealFileName(input.bannerUrl).includes(".mp4")
            ? "video"
            : "img",
        };
      }
      await updateTerminal(store._id, data);
      notyf.success("success");
      setLoad(false);
      props.setTerminal({ ...terminal, ...data });
    } catch (error) {
      notyf.error("Une erreur s'est produite: " + error.message);
      setLoad(false);
    }
  };

  /* const handleUploadFond = (e) => {
    let file = e.target.files[0];
    let type = file.name.includes('.mp4') ? 'video' : 'img';
    if (!file) {
      notyf.error('Veuillez sélectionner une image.');
    } else if (!file.name.match(/\.(jpg|jpeg|png|gif|mp4)$/)) {
      notyf.error('Veuillez sélectionner une image ou video valide. ');
    } else {
      setFondType(type);
      setInput({ ...input, fond: file });
    }
  }; */
  /*  const handleUploadLogo = (e) => {
    let file = e.target.files[0];
    if (!file) {
      notyf.error('Veuillez sélectionner une image.');
    } else if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      notyf.error('Veuillez sélectionner une image ou video valide. ');
    } else if (e.target.files[0].size > 2097152) {
      notyf.error("La taille de l'image doit être inférieure à 2 Mo !");
    } else {
      setInput({ ...input, logo: file });
    }
  } */ return (
    <>
      {loadMedia ? (
        <Media
          setLoadMedia={setLoadMedia}
          handleSelectedMedia={handleSelectedMedia}
        />
      ) : (
        <div className="styles--banner styles--bornes">
          <form>
            {load ? (
              <Loader />
            ) : (
              <div className="styles--body">
                <div>
                  <p>Images ou Videos</p>
                  <div className="styles--body-upload">
                    {/*   <input
                      type='file'
                      id='upload--baner'
                      onChange={handleUploadFond}
                    /> */}
                    <label htmlFor="upload--baner">
                      <img
                        alt="upload-file"
                        name="fond"
                        onClick={handleMedia}
                        src={upload}
                      />
                    </label>
                    {(fond?.url || input.fond) &&
                      (!getRealFileName(input.fond || fond?.url).includes(
                        ".mp4"
                      ) ? (
                        <img
                          className="styles--body-banner"
                          alt="current--banner"
                          src={input.fond ? input.fond : fond.url}
                        />
                      ) : (
                        <video
                          src={input.fond ? input.fond : fond.url}
                          controls
                          type="video/mp4"
                          autoPlay={true}
                          width="300"
                        />
                      ))}

                    {fond?.url && (
                      <p>
                        Vous pouvez modifier l'image en chargant une nouvelle
                      </p>
                    )}
                    {/* {input.picture !== "" && (<span>{ getRealFileName(input.picture.name) }</span>)} */}
                  </div>
                </div>
                <div>
                  <p>Logos sur bornes</p>
                  <div className="styles--body-upload">
                    {/*  <input
                      type='file'
                      id='upload--logo'
                      onChange={handleUploadLogo}
                    /> */}
                    <label htmlFor="upload--logo">
                      <img
                        alt="upload-file"
                        name="logo"
                        onClick={handleMedia}
                        src={upload}
                      />
                    </label>

                    {(imageLogoUrl || input.logo) && (
                      <img
                        className="styles--body-banner"
                        alt="current--banner"
                        src={input.logo ? input.logo : imageLogoUrl}
                      />
                    )}

                    {imageLogoUrl && (
                      <p>
                        Vous pouvez modifier l'image en chargant une nouvelle
                      </p>
                    )}

                    {/* {input.picture !== "" && (<span>{ getRealFileName(input.picture.name) }</span>)} */}
                  </div>
                </div>

                <div>
                  <p>Bannière sur bornes: Image ou Video</p>
                  <div className="styles--body-upload">
                    <label htmlFor="upload--banner">
                      <img
                        alt="upload-file"
                        name="bannerUrl"
                        onClick={handleMedia}
                        src={upload}
                      />
                    </label>

                    {/* {(imageBannerUrl || input.banner) && (
                      <img
                        className="styles--body-banner"
                        alt="current--banner"
                        src={input.banner ? input.banner : imageBannerUrl}
                      />
                    )} */}

                    {(bannerUrl?.url || input.bannerUrl) &&
                      (!getRealFileName(
                        input.bannerUrl || bannerUrl?.url
                      ).includes(".mp4") ? (
                        <img
                          className="styles--body-banner"
                          alt="current--banner"
                          src={
                            input.bannerUrl ? input.bannerUrl : bannerUrl.url
                          }
                        />
                      ) : (
                        <video
                          src={
                            input.bannerUrl ? input.bannerUrl : bannerUrl.url
                          }
                          controls
                          type="video/mp4"
                          autoPlay={true}
                          width="300"
                        />
                      ))}

                    {fond?.bannerUrl && (
                      <p>
                        Vous pouvez modifier l'image en chargant une nouvelle
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="styles--top styles--bornes--btn">
              <button onClick={updateConfig}>Valider</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Imgs;
