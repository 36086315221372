import React, { Fragment, useState } from "react";
import { Col } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import EditEnseigne from "../Inputs/EditEnseigne";
//svgs
import More from "~/assets/svg/more.svg";
//import editIcon from "~/assets/images/fi-rs-pencil.png";
import editIcon from "~/assets/images/fi-rs-pencil.png";

//Pagination
import TablePagination from '~/assets/table-pagination/'

const List = (props) => {
  const [showMore, setShowMore] = useState(false);
  const [selected, setSelected] = useState("");
  const [editEnseigne, setEditEnseigne] = useState(false);


  // Pagination  
  const tableColumns = [{
      Header: 'Status',
      accessor: 'status',
      className: 'tdswitch',
      Cell: ({ value, row })  => {
        let enseigne = row.original
        return(
          <Switch
            className="switchButton"
            onChange={() => props.handleEditEnseigneStatus(enseigne._id)}
            checked={enseigne.status === "on" ? true : false}
            color="primary"
            name="changeStatus"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        )
      }
  },{
      Header: 'Id',
      accessor: d => d._id.substring(0, 8)
  },{
      Header: 'Intitulé',
      accessor: d => d.intitule.toLowerCase()
  },{
    Header: 'Website', 
    accessor: 'urlWebSite',
    className: 'lowercase'
  },{
    Header: 'Nom', 
    accessor: d => d.identity.lastname.toLowerCase()
  },{
    Header: 'Formule', 
    accessor: 'offer'
  },{
    Header: 'Action', 
    accessor: '_id',
    Cell: ({ value, row })  => {
      let enseigne = row.original
      return(
        <>
          <button className="btn" onClick={() => { setShowMore(!showMore); setSelected(enseigne._id); }}>
            <img alt="more" src={More} className="pointer"/>
          </button>

          {
            showMore && selected === enseigne._id &&
            <div className="supremeDash--btnsWrapper supremeDash--btnsWrapper-open">
              <div className="overley" onClick={()=>{setShowMore(false)}} ></div>
              <div style={{display: 'block'}}>
                <Col  onClick={() => props.handleRedirectToEnseigne(enseigne._id)}>
                  <p>Entrer</p>
                  <i className="fas fa-door-open"></i>
                </Col>
                <Col onClick={() => { setEditEnseigne(true); setSelected(enseigne); }}>
                  <div>
                    <p>Modifier l'enseigne</p>
                    <img 
                      alt="edit-icon"
                      src={editIcon}
                      className="supremeDash--editIcon"
                    />
                  </div>
                </Col>
              </div>
            </div>
          }
        </> 
      )
    }
  }];

  if (props.filteredEnseigne !== []) {
    return (
      <Fragment>

        <div className="supremeDash--list-wrapper"> 
          <TablePagination columns={tableColumns} data={props.filteredEnseignes} searchLabel={'Rechercher par id, email ou nom'} /> 
        </div>
        
        {editEnseigne && (
          <EditEnseigne show={editEnseigne} setShow={setEditEnseigne} enseigne={selected} />
        )}
      </Fragment>
    );
  } else {
    return <p>Loading ...</p>;
  }
};

export default List;
