import React from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "~/assets/svg/close.svg";

const Confirm = (props) => {
  return (
    <Modal className="modal" show={props.show} onHide={ () => props.handleClose(false) } >

      <Modal.Header>
        <h1 className="modal--title">Attention</h1>
        <img onClick={() => props.handleClose(false)} className="modal--close" alt="close-svg" src={closeIcon} />
      </Modal.Header>

      <Modal.Body>
        {props.message}
      </Modal.Body>

      <Modal.Footer>
        <button className="modal--btn-close" onClick={() => props.handleClose(false)} > Annuler </button>
        <button className="modal--btn-validate" onClick={props.handleConfirm} >
            {props.confirmeLoad ? (
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            ) : ( "Oui" )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;