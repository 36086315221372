import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Notyf } from "notyf";
import EditStore from "./EditStore";
import AddStore from "./AddStore";
import DeleteStore from "./DeleteStore";
import Loader from "~/components/Loader";
import {
  getEnseigneById,
  getEnseigneByUserId,
  updateEnseigne,
} from "~/api/enseigne";
import {
  getStoresOfEnseigne,
  createStore,
  updateStore,
  updateStoreStatus,
  resilierStore,
} from "~/api/stores";
import { useSelector, useDispatch } from "react-redux";
import { changeUserRole } from "~/redux/actions/userAction";
import { Switch } from "@material-ui/core";

//Pagination
import TablePagination from "~/assets/table-pagination/";

//svgs
import Plus from "~/assets/svg/plus.svg";
//import Loop from "~/assets/svg/loop.svg";
import More from "~/assets/svg/more.svg";
import Edit from "~/assets/images/fi-rs-pencil.png";
import Trash from "~/assets/svg/trash-black.svg";
import Terminals from "./Terminals";
import Caisses from "./Caisses";

const Dashboard = (props) => {
  //  messages
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "bottom",
    },
    duration: 6000,
    dismissible: true,
  });

  //state globaux
  const user = useSelector((state) => state.user);
  const [load, setLoad] = useState(true);
  let history = useHistory();
  //récupération du enseigne du super
  const [enseigne, setEnseigne] = useState({});
  const [stores, setStores] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let enseigneId = queryString.parse(props.location.search).enseigneid;
    if (enseigneId) {
      localStorage.setItem("enseigneId", enseigneId);
    } else if (localStorage.getItem("enseigneId") !== null) {
      enseigneId = localStorage.getItem("enseigneId");
    }

    // enseigne => get enseigne by USER ID
    (async function fetch() {
      if (!enseigneId) {
        await getEnseigneByUserId(user.data.user.id).then((res) => {
          if (res.data.exist === false) {
            history.push("/");
            return;
          } else {
            enseigneId = res.data.enseigne._id;
            localStorage.setItem("enseigneId", enseigneId);
          }
        });
      }

      // get Enseigne
      getEnseigneById(enseigneId)
        .then((res) => {
          setEnseigne(res.data.enseigne);

          // get stores of Enseigne
          getStoresOfEnseigne(enseigneId)
            .then((res) => {
              setStores(res.data.stores);
              setLoad(false);
            })
            .catch((err) => {
              setLoad(false);
              notyf.error(err.message);
            });
        })
        .catch((err) => {
          setLoad(false);
          notyf.error(err.message);
        });
    })();

    // eslint-disable-next-line
  }, []);

  const handleRedirectToBoutque = (storeId) => {
    dispatch(changeUserRole("ROLE_STORE"));
    props.history.push(`/store/commandes/statistics?storeid=${storeId}`);
  };

  /*  ---Ajout d'une boutique--- */
  const addStoreInfos = {
    intitule: "",
    street: "",
    zip: "",
    city: "",
    lat: null,
    lng: null,
    email: "",
    password: "",
    phone: "",
    managerFirstname: "",
    managerLastname: "",
    sendMail: true,
    vad: true,
  };
  const [showAddStore, setShowAddStore] = useState(false);
  const [addStoreInput, setAddStoreInput] = useState(addStoreInfos);
  const handleShowAddStore = () => {
    setShowAddStore(true);
  };
  const handleCloseAddStore = () => {
    setShowAddStore(false);
  };

  const handleChangeAddStore = (e) => {
    setAddStoreInput({
      ...addStoreInput,
      [e.target.name]: e.target.value.toLowerCase(),
    });
  };

  const handleSendMail = (e) => {
    let send = false;
    if (e.target.checked) {
      send = true;
    }
    setAddStoreInput({ ...addStoreInput, sendMail: send });
  };
  const handleChangeTimeZone = (tz) => {
    setAddStoreInput({ ...addStoreInput, timeZone: tz });
  };
  const handleEditTimeZone = (tz) => {
    setAddStoreInput({ ...selectedInputEditStore, timeZone: tz });
  };

  const handleAddStore = (e) => {
    e.preventDefault();
    setLoad(true);

    createStore(addStoreInput, enseigne._id)
      .then((res) => {
        setLoad(false);
        setShowAddStore(false);
        notyf.success(res.data.message);
        setAddStoreInput(addStoreInfos);
        setStores([...stores, res.data.store]);
        updateCountStores("add");
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  /* Maj count stores */
  const updateCountStores = (action) => {
    let count = enseigne.countStores;
    if (action === "add") {
      count = count + 1;
    } else {
      count = count - 1;
    }
    const upEnseigne = {
      ...enseigne,
      countStores: count,
    };

    updateEnseigne(upEnseigne)
      .then((res) => {
        return null;
      })
      .catch((err) => {
        notyf.error(err.message);
      });
  };

  /* Modification d'une boutique */
  const [selectedInputEditStore, setSelectedInputEditStore] = useState({});
  const [showEditStore, setShowEditStore] = useState(false);
  const [editLoad, setEditLoad] = useState(false);

  const handleShowEditStore = (store) => {
    setShowEditStore(true);
    setSelectedInputEditStore({
      _id: store._id,
      intitule: store.intitule,
      street: store.identity.street,
      zip: store.identity.zip,
      city: store.identity.city,
      lat: store.identity.lat,
      lng: store.identity.lng,
      phone: store.identity.phone,
      managerFirstname: store.manager.firstname,
      managerLastname: store.manager.lastname,
      vad: store.vad,
    });
  };
  const handleCloseEditStore = () => {
    setShowEditStore(false);
  };

  const handleChangeSelectedEditStore = (e) => {
    setSelectedInputEditStore({
      ...selectedInputEditStore,
      [e.target.name]:
        e.target.name === "vad"
          ? Boolean(e.target.value)
          : e.target.value.toLowerCase(),
    });
  };

  const handleEditStore = (e) => {
    if (e) e.preventDefault();
    setEditLoad(true);
    updateStore(selectedInputEditStore)
      .then((res) => {
        notyf.success(res.data.message);
        setStores(res.data.stores);
        setEditLoad(false);
        handleCloseEditStore();
      })
      .catch((err) => {
        setEditLoad(false);
        notyf.error(err.message);
      });
  };

  //changer le status d'une boutique
  /*
  const handleEditStatusOfStore = (storeId) => {
      updateStoreStatus(storeId)
      .then((res) => {
        notyf.success(res.data.message);
        setStores(res.data.stores);
      })
      .catch((err) => {
        notyf.error(err.message);
      });
  };*/

  //résiliation d'une boutique
  const [selectedDeleteStoreId, setSelectedDeleteStoreId] = useState("");
  const [deleteStoreModal, setDeleteStoreModal] = useState(false);
  const [deleteStoreLoad, setDeleteStoreLoad] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [terminalStoreModal, setTerminalStoreModal] = useState(false);
  const [caisseStoreModal, setCaisseStoreModal] = useState(false);

  const handleOpenTerminalStoreModal = () => {
    setTerminalStoreModal(true);
  };
  const handleOpenCaisseStoreModal = () => {
    setCaisseStoreModal(true);
  };
  const handleCloseTerminalStoreModal = () => {
    setTerminalStoreModal(false);
  };
  const handleCloseCaisseStoreModal = () => {
    setCaisseStoreModal(false);
  };

  const changeStatus = (store, type) => {
    if (type === "paid" && user.data.scope !== "ROLE_SUPREMEADMIN") {
      notyf.error(
        "Désolé, c'est que l'admin qui a l'autorisation de faire cette action !"
      );
      return false;
    }

    updateStoreStatus(store._id, type)
      .then((res) => {
        notyf.success(res.data.message);
        setStores(res.data.stores);
      })
      .catch((err) => {
        setEditLoad(false);
        notyf.error(err.message);
      });
  };
  const handleOpenDeleteStoreModal = () => {
    setDeleteStoreModal(true);
  };
  const handleCloseDeleteStoreModal = () => {
    setDeleteStoreModal(false);
  };
  const handleDeleteStore = () => {
    setDeleteStoreLoad(true);
    resilierStore(selectedDeleteStoreId)
      .then((res) => {
        setDeleteStoreLoad(false);
        notyf.success(res.data.message);
        //setDeleteStoreModal(false);
        //setEnseigne(res.data.enseigne);
        setTimeout(function () {
          window.location = window.location.href.split("?")[0];
        }, 600);
        //updateCountStores("delete");
      })
      .catch((err) => {
        setDeleteStoreLoad(false);
        notyf.error(err.message);
      });
  };

  //SEARCHBAR
  /*
  const [input, setInput] = useState("");
  const handleChange = (e) => {
    setInput(e.target.value);
  }; */

  // Pagination
  const getTrProps = (row) => {
    if (row.original) {
      return {
        style: {
          background: row.original.paid ? "#FFFFFF" : "#FFB454",
          color: "#393838",
        },
      };
    }
    return {};
  };
  const tableColumns = [
    {
      Header: "Payé ?",
      className: "tdswitch",
      accessor: (d) => d._id.substring(0, 8),
      Cell: ({ value, row }) => {
        let store = row.original;
        return (
          <Switch
            onChange={() => changeStatus(store, "paid")}
            checked={store.paid}
            color={store.paid ? "primary" : "warning"}
            name="changePaid"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        );
      },
    },
    {
      Header: "Activé ?",
      className: "tdswitch",
      accessor: (d) => d._id.substring(0, 8),
      Cell: ({ value, row }) => {
        let store = row.original;
        return (
          <Switch
            onChange={() => changeStatus(store, "status")}
            checked={store.status === "on" ? true : false}
            color={store.status === "on" ? "primary" : "warning"}
            name="changeStatus"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        );
      },
    },
    {
      Header: "Nom de la boutique",
      accessor: "intitule",
    },
    {
      Header: "Ville",
      accessor: (d) => d.identity.city,
    },
    {
      Header: "Action",
      cssWidth: 166,
      accessor: (d) => d._id,
      Cell: ({ value, row }) => {
        let store = row.original;
        return (
          <div className="btnTitleSuper">
            <button
              className="btn"
              onClick={() => {
                store.showMore = !store.showMore;
                setStores([...stores]);
              }}
            >
              <img alt="more" src={More} className="ml-3 pointer" />
            </button>
            {store.showMore && (
              <div className="superdash--list-btns superdash--list-btns-open">
                <div
                  className="overley"
                  onClick={() => {
                    store.showMore = false;
                    setStores([...stores]);
                  }}
                ></div>
                <div>
                  {store.accessible && (
                    <>
                      <div
                        className="d-flex"
                        onClick={() => handleRedirectToBoutque(store._id)}
                      >
                        <p>Entrer</p>
                        <i
                          className="fas fa-door-open"
                          style={{ fontSize: 18 }}
                        ></i>
                      </div>
                      <hr />
                    </>
                  )}

                  <div
                    className="d-flex"
                    onClick={() => {
                      handleShowEditStore(store);
                      store.showMore = false;
                    }}
                  >
                    <p>Modifier</p>
                    <img alt="edit" src={Edit} />
                  </div>
                  <hr />
                  {user.data.scope === "ROLE_SUPREMEADMIN" && (
                    <>
                      <div
                        className="d-flex"
                        onClick={() => {
                          setSelectedStoreId(store._id);
                          store.showMore = false;
                          handleOpenTerminalStoreModal();
                        }}
                      >
                        <p>Borne</p>
                        <img alt="edit" src={Edit} />
                      </div>
                      <hr />

                      <div
                        className="d-flex"
                        onClick={() => {
                          setSelectedStoreId(store._id);
                          handleOpenCaisseStoreModal();
                          store.showMore = false;
                        }}
                      >
                        <p>Caisse</p>
                        <img alt="edit" src={Edit} />
                      </div>
                      <hr />
                    </>
                  )}
                  <div
                    className="d-flex"
                    onClick={() => {
                      setSelectedDeleteStoreId(store._id);
                      store.showMore = false;
                      handleOpenDeleteStoreModal();
                    }}
                  >
                    <p>Résilier</p>
                    <img alt="Trash" src={Trash} />
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  if (enseigne !== [] && stores !== []) {
    return (
      <div className="supremeDash">
        <div className="block-styled container">
          <div className="block-styled-body">
            <div className="superdash--inputsWrapper">
              {!load && (
                <button
                  className="superdash--addStore-btn"
                  onClick={handleShowAddStore}
                >
                  <img alt="plus" src={Plus} />
                  <p>Ajouter une boutique</p>{" "}
                </button>
              )}
            </div>
            <div className="superdash--list-wraper">
              {load ? (
                <Loader />
              ) : stores.length ? (
                <TablePagination
                  columns={tableColumns}
                  data={stores}
                  searchLabel={"Rechercher par id, email ou nom"}
                  getTrProps={getTrProps}
                />
              ) : (
                <div>
                  <p className="txt_align_center">
                    Aucune boutique pour le moment...
                  </p>
                </div>
              )}

              <AddStore
                showAddStore={showAddStore}
                addStoreInput={addStoreInput}
                handleCloseAddStore={handleCloseAddStore}
                handleChangeAddStore={handleChangeAddStore}
                handleSendMail={handleSendMail}
                handleAddStore={handleAddStore}
                handleChangeTimeZone={handleChangeTimeZone}
                load={load}
              />
              <EditStore
                editLoad={editLoad}
                selectedInputEditStore={selectedInputEditStore}
                showEditStore={showEditStore}
                handleCloseEditStore={handleCloseEditStore}
                handleChangeSelectedEditStore={handleChangeSelectedEditStore}
                handleEditStore={handleEditStore}
                handleChangeTimeZone={handleEditTimeZone}
              />
              <DeleteStore
                deleteStoreModal={deleteStoreModal}
                deleteStoreLoad={deleteStoreLoad}
                handleCloseDeleteStoreModal={handleCloseDeleteStoreModal}
                handleDeleteStore={handleDeleteStore}
              />
              <Terminals
                terminalStoreModal={terminalStoreModal}
                handleCloseTerminalStoreModal={handleCloseTerminalStoreModal}
                selectedStoreId={selectedStoreId}
              />
              <Caisses
                caisseStoreModal={caisseStoreModal}
                handleCloseCaisseStoreModal={handleCloseCaisseStoreModal}
                selectedStoreId={selectedStoreId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default Dashboard;
