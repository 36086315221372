import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";

// Components
import Loader from "~/components/Loader";
import closeIcon from "~/assets/svg/close.svg";
// Utils
import { getCategoriesOfStore } from "~/api/menu";
import { getProduitsOfStore } from "~/api/produits";
import { showPrice } from "~/_helpers/helper";

const ChoixProduits = (props) => {
  const defaultValues = { produits: props.default || [] };
  const store = props.store;
  const { control, handleSubmit, setValue, watch } = useForm({ produits: [] });
  const [load, setLoad] = useState(true);
  const [categories, setCategories] = useState([]);
  const [produits, setProduits] = useState([]);
  const [currentCategorie, setCurrentCategorie] = useState(0);

  const selectedProduits = watch("produits");

  const setDefaultValues = () => {
    let result = [];
    categories.map((cat, index) => {
      result[index] = produits
        .filter((p) => p.idCategorie === cat._id)
        .map((p) => {
          let isSelected = defaultValues.produits.find(
            (__p) => __p.id === p._id
          );

          if (!p.multiChoice || p.variantes.length === 0) {
            return {
              _id: isSelected ? isSelected.id : false,
            };
          } else {
            return {
              _id: isSelected ? isSelected.id : false,
              variant:
                isSelected && isSelected.variant
                  ? isSelected.variant.id
                  : false,
            };
          }
        });
      return true;
    });
    setValue(`produits`, result);
  };

  useEffect(() => {
    let storeId = store._id;
    let menuActive = localStorage.getItem("menuActive");
    getCategoriesOfStore(storeId, menuActive)
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((err) => {
        setLoad(false);
      });

    getProduitsOfStore(storeId)
      .then((res) => {
        setProduits(res.data.produits);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });

    // eslint-disable-next-line
  }, []);

  const onCatChanged = (e, catId, index) => {
    let products = getProduitsOfCategorie(catId);
    if (e.target.checked) {
      setValue(
        `produits[${index}]`,
        products.map((p, k) => {
          if (!p.multiChoice || p.variantes.length === 0) {
            return {
              _id: p._id,
            };
          } else {
            return {
              _id: p._id,
              variant: selectedProduits[index][k].variant || p.variantes[0]._id,
            };
          }
        })
      );
    } else {
      setValue(
        `produits[${index}]`,
        products.map((p) => {
          if (!p.multiChoice || p.variantes.length === 0) {
            return {
              _id: false,
            };
          } else {
            return {
              _id: false,
              variant: false,
            };
          }
        })
      );
    }
  };

  const getProduitsOfCategorie = (idCategorie) => {
    return produits.filter((prod) => prod.idCategorie === idCategorie);
  };

  const onSubmit = (data) => {
    let products = [];
    selectedProduits.map((groupProducts) => {
      groupProducts.map((product) => {
        if (product._id) {
          let productInfo = produits.find((ps) => ps._id === product._id);
          /* let variantInfo = productInfo.variantes.find(
            (v) => v._id === product.variant
          );
 */
          products.push({ ...productInfo });
        }
        return true;
      });
      return true;
    });

    props.submit(products);
  };

  if (produits.length > 0 && categories.length > 0 && !selectedProduits) {
    setDefaultValues();
  }
  return (
    <Modal
      className='modal promotions-select-products'
      show={props.show}
      onHide={() => props.handleClose(false)}
    >
      <Modal.Header>
        <h1 className='modal--title'>Choix des produits</h1>
        <img
          onClick={() => props.handleClose(false)}
          className='modal--close'
          alt='close-svg'
          src={closeIcon}
        />
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {load && <Loader />}
          {!load && (
            <div className='popup_produits'>
              {categories.length < 1 ? (
                <p>Merci d'ajouter des catégories/produits</p>
              ) : (
                <div id='accordion'>
                  {categories.map((categorie, index) => (
                    <div
                      key={index}
                      className={`card  ${
                        currentCategorie === index ? "opened" : ""
                      }`}
                    >
                      <div className='card-header' id='headingOne'>
                        <div className='custom-control custom-checkbox'>
                          <input
                            type='checkbox'
                            className='custom-control-input'
                            id={`cat_key_${index}`}
                            name='produits'
                            value={categorie._id}
                            onChange={(e) => {
                              onCatChanged(e, categorie._id, index);
                            }}
                          />
                          <label
                            className='custom-control-label'
                            htmlFor={`cat_key_${index}`}
                          ></label>
                        </div>
                        <h5
                          className='mb-0'
                          onClick={() => setCurrentCategorie(index)}
                        >
                          {categorie.name}
                          {currentCategorie === index ? (
                            <i className='fa fa-chevron-down'></i>
                          ) : (
                            <i className='fa fa-chevron-right'></i>
                          )}
                        </h5>
                      </div>
                      <div
                        key={`collapse${index}`}
                        className={`collapse  ${
                          currentCategorie === index ? "show" : ""
                        }`}
                      >
                        <div className='card-body'>
                          {getProduitsOfCategorie(categorie._id).length > 0 ? (
                            <div className='products-list'>
                              {getProduitsOfCategorie(categorie._id).map(
                                (produit, k) => (
                                  <div key={k}>
                                    <div className='products-item'>
                                      <div className='custom-control custom-checkbox'>
                                        <Controller
                                          control={control}
                                          name={`produits`}
                                          render={({ field }) => (
                                            <input
                                              type='checkbox'
                                              className='custom-control-input'
                                              id={`cat_key_${index}_product_key_${k}`}
                                              value={produit._id}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  let _products = selectedProduits;
                                                  _products[index][k]._id =
                                                    produit._id;
                                                  setValue(
                                                    `produits`,
                                                    _products
                                                  );
                                                  if (
                                                    produit.multiChoice &&
                                                    produit.variantes.length > 0
                                                  ) {
                                                    _products[index][
                                                      k
                                                    ].variant =
                                                      produit.variantes[0]._id;
                                                    setValue(
                                                      `produits`,
                                                      _products
                                                    );
                                                  }
                                                } else {
                                                  let _products = selectedProduits;
                                                  _products[index][
                                                    k
                                                  ]._id = null;
                                                  setValue(
                                                    `produits`,
                                                    _products
                                                  );
                                                  if (
                                                    produit.multiChoice &&
                                                    produit.variantes.length > 0
                                                  ) {
                                                    _products[index][
                                                      k
                                                    ].variant = null;
                                                    setValue(
                                                      `produits`,
                                                      _products
                                                    );
                                                  }
                                                }
                                              }}
                                              checked={
                                                field.value &&
                                                field.value[index][k]?._id ===
                                                  produit._id
                                              }
                                            />
                                          )}
                                        />

                                        <label
                                          className='custom-control-label'
                                          htmlFor={`cat_key_${index}_product_key_${k}`}
                                        >
                                          <div className='d-flex align-items-center'>
                                            <div className='img-product'>
                                              <img
                                                src={produit.imageUrl}
                                                alt=''
                                                className='mr-2'
                                                style={{ width: 35 }}
                                              />
                                            </div>
                                            <div className='product-name'>
                                              {produit.name}
                                              <br />
                                              {!produit.multiChoice && (
                                                <span>
                                                  {showPrice(
                                                    produit.priceGlobal
                                                  )}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    {produit.multiChoice && (
                                      <div className='variations'>
                                        {produit.variantes.map((item, j) => (
                                          <div key={j} className='product-name'>
                                            <div className='custom-control custom-radio'>
                                              <Controller
                                                control={control}
                                                name={`produits`}
                                                render={({ field }) => {
                                                  return (
                                                    <input
                                                      type='radio'
                                                      className='custom-control-input'
                                                      id={`cat_key_${index}_product_key_${k}_variant_${j}`}
                                                      value={item._id}
                                                      checked={
                                                        field.value &&
                                                        field.value[index][k]
                                                          ?.variant === item._id
                                                      }
                                                      onChange={(e) => {
                                                        if (e.target.checked) {
                                                          let _products = selectedProduits;
                                                          _products[index][
                                                            k
                                                          ]._id = produit._id;
                                                          _products[index][
                                                            k
                                                          ].variant = item._id;
                                                          setValue(
                                                            `produits`,
                                                            _products
                                                          );
                                                        } else {
                                                          let _products = selectedProduits;
                                                          _products[index][
                                                            k
                                                          ]._id = null;
                                                          _products[index][
                                                            k
                                                          ].variant = null;
                                                          setValue(
                                                            `produits`,
                                                            _products
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  );
                                                }}
                                              />
                                              <label
                                                className='custom-control-label'
                                                htmlFor={`cat_key_${index}_product_key_${k}_variant_${j}`}
                                              >
                                                <div>
                                                  {item.name}
                                                  <br></br>
                                                  {showPrice(item.price)}
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <center style={{ padding: 15 }}>
                              Cette catégorie ne contients aucun produit pour le
                              moments...
                            </center>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <button
            className='modal--btn-close'
            onClick={() => props.handleClose(false)}
          >
            {" "}
            Annuler{" "}
          </button>
          <button className='modal--btn-validate' type='submit'>
            {" "}
            Valider le choix{" "}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChoixProduits;
