import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
//
import { BASE_URI } from "~/assets/base";
// Utils
import { archiveFiscalType } from "~/constants/archiveFiscaleType";

//Component
import Loader from "~/components/Loader";
import Downlaord from "~/components/Svgs/Downlaord";
// CSS
import "./_archive.scss";

//Pagination
import TablePagination from "~/assets/table-pagination";
//api
import { getCaisseWithStoreId } from "~/api/caisse";

import { getArchivesWithStoreId } from "~/api/archives";

const tableColumns = [
  {
    Header: "Appareil",
    accessor: "appareil",
  },
  {
    Header: "Periode",
    accessor: "periode",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Telechargement",
    accessor: "telechargement",
  },
];

const ArchiveFiscale = (props) => {
  const store = props.store;

  const [cashRegisters, setCashRegisters] = useState([]);
  const [archives, setArchives] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAppareil, setSelectAppareil] = useState({
    label: "Caisse1",
    value: "caisse1",
  });

  //Stats
  // const store = props.store;
  const [selectArchiveType, setSelectArchiveType] = useState(
    archiveFiscalType[0]
  );

  const formatArchive = (arch) => ({
    appareil: selectAppareil.value,
    periode:
      arch.type[0].toUpperCase() +
      "-" +
      moment(arch.createdDate).format("DD-MM-YYYY"),
    date: moment(arch.createdDate).format("DD-MM-YYYY"),
    telechargement: (
      <Downlaord
        link={`${BASE_URI}store/archived/download/${arch.fileName.replace(
          "/",
          "_"
        )}`}
      />
    ),
  });
  /// Pagination

  const handleFindArchives = async () => {
    setLoading(true);
    const res = await getArchivesWithStoreId(store._id, {
      cashRegister: selectAppareil.value,
      type: selectArchiveType.value,
    });
    setArchives(res.archives);
    setLoading(false);
  };

  useEffect(() => {
    const getCaisses = async () => {
      try {
        setLoading(true);
        const res = await getCaisseWithStoreId(store._id);
        setCashRegisters(res.cashs);
        if (res.cashs[0]) {
          const resA = await getArchivesWithStoreId(store._id, {
            cashRegister: res.cashs[0].idConnexion,
            type: "day",
          });
          setArchives(resA.archives);
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    if (store) getCaisses();
  }, [store]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className='page-commandes page-archive-fiscale'>
        <div className='block-styled'>
          <div className='block-styled-body'>
            <h1 className='block-styled-heading'>Archives Fiscales</h1>
            <div className='filters'>
              <div className='p20'>
                <div className='row'>
                  <div className='col-sm-4 mb-4'>
                    <label>Appareil</label>
                    <Select
                      options={cashRegisters.map((cash) => ({
                        value: cash.idConnexion,
                        label: cash.name,
                      }))}
                      value={selectAppareil}
                      onChange={(value) => setSelectAppareil(value)}
                      className='basic-single'
                      classNamePrefix='select'
                    />
                  </div>
                  <div className='col-sm-4'>
                    <label>Type</label>
                    <Select
                      options={[...archiveFiscalType]}
                      value={selectArchiveType}
                      onChange={(value) => setSelectArchiveType(value)}
                      className='basic-single'
                      classNamePrefix='select'
                    />
                  </div>
                  <div className='col-sm-4 mt29'>
                    <button
                      type='submit'
                      className='modal--btn-validate validateBtn'
                      onClick={handleFindArchives}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='commandes-list'>
              <TablePagination
                columns={tableColumns}
                data={archives.map(formatArchive)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchiveFiscale;
