import React  from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "~/assets/svg/close.svg";
import CountrySelect from "~/components/Reusable/CountrySelect";
import TimezoneSelect from "react-timezone-select";

const AddEnseigne = (props) => {
  return (
    <Modal className="modal addenseigne" show={props.show} onHide={props.handleClose}>
      <form onSubmit={(e) => props.handleAddEnseigne(e)}>
        <Modal.Header closeButton>
          <h1 className="modal--title">Ajouter une enseigne</h1>
          <img
            onClick={props.handleClose}
            className="modal--close"
            alt="close-svg"
            src={closeIcon}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="inputs">
            <label>Nom de l’enseigne</label>
            <input
              required
              name="intitule"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
              value={props.addEnseigneInput.intitule}
            /> 

            <label>Email enseigne</label>
            <input
              required
              name="email"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
              value={props.addEnseigneInput.email}
              className="input-email"
            />

            <label>Adresse</label>
            <input
              required
              name="address"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
              value={props.addEnseigneInput.address}
            />

            <div className="row">
              <div className="col-sm-6">
                <label>Code postale</label>
                <input
                  required
                  name="zipCode"
                  onChange={(e) => props.handleChangeAddEnseigne(e)}
                  value={props.addEnseigneInput.zipCode}
                />
              </div>
              <div className="col-sm-6">
                <label>Ville</label>
                <input
                  required
                  name="city"
                  onChange={(e) => props.handleChangeAddEnseigne(e)}
                  value={props.addEnseigneInput.city}
                />
              </div>
            </div>

            <CountrySelect 
              onChange={(e)=>{props.handleChangeAddEnseigne(e)}}
              name="country"
              value={props.addEnseigneInput.country}
            /> 

            <label>Fuseau horaire</label>
            <TimezoneSelect
              required
              name="timeZone"
              className="information--timezone"
              value={props.addEnseigneInput.timeZone}
              onChange={(tz) => props.handleChangeAddEnseigne(tz)}
            />

            <label>Url website</label>
            <input
              required
              name="urlWebSite"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
              value={props.addEnseigneInput.urlWebSite}
            /> 
            <label>Nom du responsable</label>
            <input
              required
              name="lastname"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
              value={props.addEnseigneInput.lastname}
            />

            <label>Prénom du responsable</label>
            <input
              required
              name="firstname"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
              value={props.addEnseigneInput.firstname}
            />

            <label>Sexe</label>
            <select
              value={props.addEnseigneInput.gender}
              name="gender"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
            >
              <option value={"homme"}>Homme</option>
              <option value={"femme"}>Femme</option>
            </select>
            
            <label>Téléphone</label>
            <input
              required
              name="phone"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
              value={props.addEnseigneInput.phone}
              type="number"
            />
            
            <label>Formules</label>
            <select
              required
              value={props.addEnseigneInput.offer}
              name="offer"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
            >
              <option value={"abonnement"}>Abonnement mensuel de 59€</option>
              <option value={"commission"}>Commission de 5% par panier</option>
            </select>
            
            <label>Mot de passe</label>
            <input
              required
              name="password"
              onChange={(e) => props.handleChangeAddEnseigne(e)}
              value={props.addEnseigneInput.password}
              type="password"
              className="input-libre"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={props.handleClose}
            type="button"
            className="modal--btn-close"
          >
            Fermer
          </button>
          <button className="modal--btn-validate" type="submit">
            {props.load ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Chargement...</span>
              </div>
            ) : (
              "Ajouter"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEnseigne;
