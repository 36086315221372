import React, { useState, useEffect } from "react";
import { List, arrayMove } from "react-movable";
import { Notyf } from "notyf";
// Components
import AddCategorie from "./AddCategorie";
import EditCategorie from "./EditCategorie";
import Loader from "~/components/Loader";
import Confirm from "~/components/Commun/Confirm";
// Utils
import {
  getCategoriesOfStore,
  deleteCategorie,
  initOrderCategories,
} from "~/api/menu";
//svg
import dragable from "~/assets/svg/dragable.svg";
import Plus from "~/assets/svg/plus.svg";
import EditOrangeIcon from "~/components/Svgs/Shop/EditOrange";
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";

const CategorieStore = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const store = props.store;
  const [load, setLoad] = useState(true);
  const [categories, setCategories] = useState([]);
  const [toAdd, setToAdd] = useState(false);
  const [toEdit, setToEdit] = useState(false);
  const [toDelete, setToDelete] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    let storeId = store._id;
    let menuActive = localStorage.getItem("menuActive");
    getCategoriesOfStore(storeId, menuActive)
      .then((res) => {
        setCategories(res.data.categories);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
    // eslint-disable-next-line
  }, []);

  const handleDelete = () => {
    setLoad(true);
    setToDelete(false);
    deleteCategorie(selected._id)
      .then((res) => {
        let categories = res.data.categories;
        setCategories(categories);
        notyf.success(res.data.message);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const updateOrder = (oldIndex, newIndex) => {
    setLoad(true);
    const move = newIndex > oldIndex ? "down" : "up";

    oldIndex = categories[oldIndex].position;
    newIndex = categories[newIndex].position;

    initOrderCategories(store._id, move, oldIndex, newIndex)
      .then((res) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          <div className="layout-head">
            <div className="row">
              <div className="col-6">
                <h1>Catégories</h1>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="modal--btn-validate"
                  onClick={() => setToAdd(true)}
                >
                  <img alt="plus" src={Plus} /> Ajouter une catégorie
                </button>
              </div>
            </div>
          </div>

          {categories.length ? (
            <div className="layout-content">
              <List
                lockVertically
                values={categories}
                onChange={({ oldIndex, newIndex }) => {
                  setCategories(arrayMove(categories, oldIndex, newIndex));
                  updateOrder(oldIndex, newIndex);
                }}
                renderList={({ children, props }) => (
                  <div className="block-styled container">
                    <div className="block-styled-body">
                      <div className="react-table">
                        <div className="table-container">
                          <table className="table">
                            <thead className="table-dark">
                              <tr>
                                <th scope="col">Position</th>
                                <th scope="col">Nom</th>
                                <th scope="col" className="actions">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody {...props}>{children}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                renderItem={({ value, props, isDragged, isSelected }) => {
                  const row = (
                    <tr
                      {...props}
                      style={{
                        backgroundColor:
                          isDragged || isSelected ? "#ececec" : "#ffffff",
                      }}
                    >
                      <td className="tdswitch">
                        <button
                          data-movable-handle
                          style={{
                            cursor: isDragged ? "grabbing" : "grab",
                          }}
                        >
                          <img alt="" src={dragable} />
                        </button>
                      </td>

                      <td>
                        <img
                          alt=""
                          style={{ width: "31px", marginRight: "10px" }}
                          src={value.iconUrl}
                        />
                        {value.name}
                      </td>
                      <td className="actions multiple-btns">
                        <button
                          type="button"
                          className="btn btn-light btn-edit"
                          onClick={() => {
                            setSelected(value);
                            setToEdit(true);
                          }}
                        >
                          <EditOrangeIcon />
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-delete"
                          onClick={() => {
                            setSelected(value);
                            setToDelete(true);
                          }}
                        >
                          <TrashOutlineIcon />
                        </button>
                      </td>
                    </tr>
                  );
                  return isDragged ? (
                    <table
                      className="table dragged-table"
                      style={{ ...props.style, borderSpacing: 0 }}
                    >
                      <tbody>{row}</tbody>
                    </table>
                  ) : (
                    row
                  );
                }}
              />
            </div>
          ) : (
            <p className="mt-5 mb-5 text-center">
              Aucune données pour le moments...
            </p>
          )}

          {toAdd && (
            <AddCategorie
              categories={categories}
              storeId={store._id}
              showAdd={toAdd}
              handleCloseAdd={setToAdd}
              init={setCategories}
            />
          )}
          {toEdit && (
            <EditCategorie
              categorie={selected}
              showEdit={toEdit}
              handleCloseEdit={setToEdit}
              init={setCategories}
            />
          )}
          {toDelete && (
            <Confirm
              show={toDelete}
              handleClose={setToDelete}
              message="Êtes-vous sûr de vouloir supprimer la catégorie ?"
              handleConfirm={handleDelete}
              confirmeLoad={load}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CategorieStore;
