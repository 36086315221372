import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

import "./main.scss";

import { BrowserRouter } from "react-router-dom";

// Import Redux / actions
import { Provider } from 'react-redux';
import mainStore from './redux/store';


ReactDOM.render(
  <Provider store={mainStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

