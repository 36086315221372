import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Notyf } from "notyf";
//components
import ChoixProduits from "./ChoixProduits";
import { addAditionnalMenu } from "../../../../../api/stores";
const Select = ({ store }) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const { register, setValue, watch } = useForm({
    defaultValues: [...store.additinalMenus],
  });
  const [showProduits, setShowProduits] = useState(false);
  const [data, setData] = useState([]);
  const selectedProduits = watch("produitsIdentiqueOffert.produits");
  const onSubmit = async () => {
    if (selectedProduits.length === 0) {
      notyf.error("Veuillez sélectionner au moins un produit");
      return;
    }
    await addAditionnalMenu(store._id, data);
    notyf.success("Produits additionnels ajouté");
  };

  const getSellectedProduits = (datas) => {
    setValue("produitsIdentiqueOffert.produits", [
      ...data,
      ...datas.filter((x) => !data.map((dx) => dx._id).includes(x._id)),
    ]);
    setShowProduits(false);
    setData((x) => [
      ...x,
      ...datas.filter((dax) => !data.map((dx) => dx._id).includes(dax._id)),
    ]);
  };

  useEffect(() => {
    const { additinalMenus } = store;
    setValue("produitsIdentiqueOffert.produits", additinalMenus);
    setData((x) => [...x, ...additinalMenus]);
  }, [store, setValue]);

  return (
    <div className="type5_etape1">
      <form>
        <div>
          <button
            className="btn btn-light select-products"
            type="button"
            onClick={() => setShowProduits(!showProduits)}
          >
            selectionnez les produits additionnels
          </button>

          {data && data.length > 0 && (
            <div className="selected_produits mb-4">
              <input
                type="hidden"
                name="data"
                {...register("produitsIdentiqueOffert.produits")}
              />
              <ul className="list-group">
                {data.map((product, index) => (
                  <li className="d-flex" key={index}>
                    <div>
                      <img
                        src={product.imageUrl}
                        alt=""
                        style={{ width: 35 }}
                      />
                    </div>
                    <div>
                      {product.name}
                      {product.variant && (
                        <span> [{product.variant.name}]</span>
                      )}
                    </div>
                    <div className="remove-item">
                      <button
                        onClick={() => {
                          data.splice(index, 1);
                          selectedProduits.splice(index, 1);
                          getSellectedProduits(selectedProduits);
                        }}
                        type="button"
                        className="btn btn-light"
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {data.length > 0 && (
          <div className="form-group steps-controlers">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="btn"
                onClick={() => onSubmit()}
                style={{
                  width: "250px",
                  backgroundColor: "#48DF84",
                  color: "#fff",
                }}
                type="button"
              >
                Valider ce choix
              </button>
            </div>
          </div>
        )}
      </form>

      {showProduits && (
        <ChoixProduits
          store={store}
          handleClose={setShowProduits}
          show={showProduits}
          submit={getSellectedProduits}
          default={selectedProduits}
        />
      )}
    </div>
  );
};

export default Select;
