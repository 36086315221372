import React, { useState } from "react";
import { Notyf } from "notyf";
import { Switch } from "@material-ui/core";

// Components
import Loader from "~/components/Loader";
// Utils
import { updateTerminal } from "~/api/terminal";

const PlaceCommande = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const store = props.store;
  const terminal = props.terminal;
  const { scanQrCode } = props.terminal.config;
  const [canScan, setCanScan] = useState(scanQrCode);

  const updateConfig = async (e) => {
    e.preventDefault();
    setLoad(true);

    const config = {
      ...terminal.config,
      scanQrCode: canScan,
    };

    try {
      const res = await updateTerminal(store._id, {
        config,
      });
      if (res.status === 200) {
        notyf.success("Success");
        setLoad(false);
        props.setTerminal({ ...terminal, config });
      }
    } catch (error) {
      setLoad(false);
      notyf.error("Erreur");
    }
  };

  return (
    <div className="container">
      {load ? (
        <Loader />
      ) : (
        <div className="row gx-5 justify-content-between page-type-commande">
          <div className="col">
            <div className="block-styled">
              <h1 className="text-center block-styled-head">Scannage</h1>
              <div className="borne">
                <div className="row gx-5 btns-toggle justify-content-between">
                  <div className="col">
                    <div className="input mt-3">
                      <Switch
                        onChange={(e) => setCanScan(!canScan)}
                        checked={canScan}
                        color="primary"
                        name="canCB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Scanner Qr code client
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center styles--top">
                <button type="button" onClick={updateConfig}>
                  Validez
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaceCommande;
