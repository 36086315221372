import React, { useState } from 'react';
import { Notyf } from "notyf";

// Components
import Loader from "~/components/Loader";
// Utils
import { updateStoreConfig } from '~/api/stores' ;


const TableCommande = (props) => {

    const notyf = new Notyf({
        position: {
            x: "right",
            y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const [load, setLoad] = useState(false);
    const store = props.store ;
    const [table, setTable] = useState(store.config.commande.table);


    const updateConfig = (e) => {
        e.preventDefault();
        setLoad(true);

        let commandeConfig = {
            ...store.config.commande,
            table
        }

        updateStoreConfig(store._id, "commande", commandeConfig)
            .then((res) => {
                setLoad(false);
                notyf.success(res.data.message);
                setTimeout(function(){ window.location.reload(); }, 600);
            })
            .catch((err) => {
                setLoad(false);
                notyf.error(err.message);
            });

    }


    return (
        <div className="container">
            { load 
            ? ( <Loader /> ) 
            : (
                <div className="row gx-5 justify-content-between page-type-commande restable">
                    <div className="col">
                        <div className="block-styled">
                            <h1 className="block-styled-head">Reservation de table</h1>
                            <div className="block-styled-body carde">
                            
                                <div className="cmd-choix d-flex">
                                    <div>
                                        <strong>Offrez-vous la réservation de table ?</strong>
                                    </div>
                                    <div>
                                        <span>
                                            <span 
                                                className={table.enable ? "option active" : "option"}
                                                onClick={() => setTable({...table, enable: true})}
                                            >Oui</span>
                                            <span 
                                                className={!table.enable ? "option active" : "option"}
                                                onClick={() => setTable({...table, enable: false})}
                                            >Non</span>
                                        </span>
                                    </div>
                                </div>

                                { table.enable && (
                                    <>
                                        <div className="cmd-choix">
                                            <div className="mb-3 d-flex">
                                                <div>
                                                    <span className="mr-3">Nombre de couvert disponible ?</span>
                                                </div>
                                                <div className="input-group-number">
                                                    <div className="input-group">
                                                        <input 
                                                            type="number" className="form-control oneNumber" 
                                                            value={table.nbrCouvert}
                                                            min="0"
                                                            max="100"
                                                            onChange={ (e) => setTable({...table, nbrCouvert: e.target.value}) } />
                                                        <span className="input-group-text">Couverts</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-5 mb-4">
                                            <strong>Je permet au clients de reserver une table, mais ...</strong>
                                        </div>

                                        <div className="cmd-choix">
                                            <div className="mb-3 d-flex">
                                                <div>
                                                    <span className="mr-3">Minimum :</span>
                                                </div>
                                                <div className="input-group-number">
                                                    <div className="input-group"> 
                                                        <input 
                                                            type="number" className="form-control oneNumber" 
                                                            value={table.min}
                                                            min="0"
                                                            max="100"
                                                            onChange={ (e) => setTable({...table, min: e.target.value}) } />
                                                        <span className="input-group-text">Personnes</span>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>

                                        <div className="cmd-choix">
                                            <div className="mb-3 d-flex">
                                                <div>
                                                    <span className="mr-3">Maximum :</span>
                                                </div>
                                                <div className="input-group-number">
                                                    <div className="input-group"> 
                                                        <input 
                                                            type="number" className="form-control oneNumber" 
                                                            value={table.max}
                                                            min="0"
                                                            max="100"
                                                            onChange={ (e) => setTable({...table, max: e.target.value}) } />
                                                        <span className="input-group-text">Personnes</span>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>

                                        <div className="cmd-choix">
                                            <div className="mb-3 d-flex">
                                                <div>
                                                    <span className="mr-3">Les commandes doivent être faites :</span>
                                                </div>
                                                <div className="input-group-number">
                                                    <div className="input-group"> 
                                                        <input 
                                                            type="number" className="form-control oneNumber" 
                                                            value={table.preCommandeMin}
                                                            step="15"
                                                            min="15"
                                                            max="1380"
                                                            onChange={ (e) => setTable({...table, preCommandeMin: e.target.value}) } />
                                                        <span className="input-group-text">min en avance</span>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>

                                        <div className="cmd-choix">
                                            <div className="mb-3 d-flex">
                                                <div>
                                                    <span className="mr-3">Les commandes ne peuvent etre faites&nbsp;:</span>
                                                </div>
                                                <div className="input-group-number">
                                                    <div className="input-group"> 
                                                        <input 
                                                            type="number" className="form-control oneNumber" 
                                                            min="0"
                                                            max="7"
                                                            value={table.preCommandeDay} 
                                                            onChange={ (e) => setTable({...table, preCommandeDay: e.target.value}) } />
                                                        <span className="input-group-text">jours en avance</span>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </>
                                )} 
                                
                                <div className="text-center">
                                    <button type="button" className="modal--btn-validate" onClick={ updateConfig }>Validez</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TableCommande