import instanceAxios from "./axios.instance" ;
import { BASE_URI } from "~/assets/base";


export const getOrdersOfStore = async (storeId) => {
    const response = await instanceAxios.get(`${BASE_URI}store/orders/${storeId}`);
    return response;
}
export const createOrder = async (datas) => {
    const response = await instanceAxios.post(`${BASE_URI}store/order/create`,
        {
            newOrder : datas
        }
    );
    return response;
}
export const updateOrder = async (order) => {
    const response = await instanceAxios.put(`${BASE_URI}store/order/update/${order._id}`,
        { order }
    )
    return response;
}
export const deleteOrder = async (id) => {
    const response = await instanceAxios.delete(`${BASE_URI}store/order/delete/${id}`);
    return response;
}

export const createAdress = async (datas) => {
    const response = await instanceAxios.post(`${BASE_URI}store/address/create`,
        {
            newAddress : datas
        }
    );
    return response;
}

export const getStats = async (data) => {
    const response = await instanceAxios.post(`${BASE_URI}store/orders/${data.storeId}/stats`,{
            start : data.start,
            end : data.end
        }
    );
    return response;
}