import React, { useState, useRef, useEffect } from 'react';
import { List } from 'react-movable';
import { useFieldArray } from 'react-hook-form';
import Select from 'react-select';

//SVG
import dragable from '~/assets/svg/dragable.svg';
import TrashOutlineIcon from '~/components/Svgs/Shop/TrashOutline';
import Confirm from '~/components/Commun/Confirm';
import EditOrangeIcon from '~/components/Svgs/Shop/EditOrange';
import ValidateIcon from '~/components/Svgs/Shop/Validate';

export default ({ nestIndex, control, register, groupes, multiChoice }) => {
  let { fields, remove, append, move } = useFieldArray({
    control,
    name: `variantes.${nestIndex}.groupes`,
  });
  const [groupesOptions, setGroupesOptions] = useState([]);
  const [selectedOption, setSelect] = useState(null);
  const groupeChoix = useRef(null);

  const [toDeleteGroupe, setToDeleteGroupe] = useState(null);
  const [toUpdateVariant, setToUpdateVariant] = useState([]);
  const variantDatas = useRef({});

  useEffect(() => {
    setGroupesOptions(groupes);
    // eslint-disable-next-line
  }, [groupes]);

  const appendGroup = () => {
    let check = groupesOptions.filter(
      (groupe) => groupe?.value === selectedOption?.value
    );
    if (check.length !== 1 || !groupeChoix.current.value) {
      return false;
    }

    append({
      idGroupe: selectedOption.value,
      nbr: groupeChoix.current.value,
    });
    setGroupesOptions(
      groupesOptions.filter((groupe) => groupe.value !== selectedOption.value)
    );
    // setSelect(groupesOptions[0]);
    groupeChoix.current.value = null;

    setSelect(null);
  };

  const removeGroupe = (groupe, index) => {
    setGroupesOptions([
      ...groupesOptions,
      groupes.find((item) => item.value === groupe.idGroupe),
    ]);
    remove(index);
  };

  const handleChange = (selectedOption) => {
    setSelect(selectedOption);
  };

  const getGroupeName = (id) => {
    let groupe = groupes.find((row) => {
      return row.value === id;
    });
    return groupe?.label;
  };

  const updateVariant = (index, id) => {
    if (toUpdateVariant.includes(index)) {
      setToUpdateVariant((currentState) =>
        currentState.filter((state) => state !== index)
      );
    } else {
      setToUpdateVariant((currentState) => [index]);
    }

    variantDatas.current =
      variantDatas.current.id !== id
        ? fields.find((field) => field.id === id)
        : {};
  };

  const handleChangeField = (e, id) => {
    console.log('e', e.currentTarget.name);
    let value = e.currentTarget.name.split('.')[
      e.currentTarget.name.split('.').length - 1
    ];
    variantDatas.current[value] = e.currentTarget.value;
  };

  const validateField = (index, id, fields) => {
    updateVariant(index, id, fields);
  };

  return (
    <>
      {fields.length > 0 && (
        <tr>
          {multiChoice && <td style={{ width: '100px' }}>&nbsp;</td>}
          <td colSpan='4' className='p-0 border-0'>
            <List
              lockVertically
              values={fields}
              onChange={({ oldIndex, newIndex }) => {
                move(oldIndex, newIndex);
              }}
              renderList={({ children, props }) => (
                <table className='table mb-0 first-tr-border-0'>
                  {!multiChoice && (
                    <thead className='table-dark'>
                      <tr>
                        <th style={{ width: '100px' }}></th>
                        <th>Groupe</th>
                        <th>
                          <span>Nombre de choix</span>
                        </th>
                        <th style={{ width: 100 }} align='center'></th>
                      </tr>
                    </thead>
                  )}
                  <tbody {...props}>{children}</tbody>
                </table>
              )}
              renderItem={({ value, index, props, isDragged, isSelected }) => {
                const row = (
                  <tr
                    {...props}
                    style={{
                      backgroundColor:
                        isDragged || isSelected ? '#ececec' : '#fafafa',
                    }}
                  >
                    <td style={{ width: '100px' }}>
                      <button
                        data-movable-handle
                        style={{
                          cursor: isDragged ? 'grabbing' : 'grab',
                        }}
                      >
                        <img alt='' src={dragable} />
                      </button>
                    </td>
                    <td>
                      {getGroupeName(value.idGroupe)}
                      <input
                        style={{
                          border: ' 0.5px solid #DEDEDE',
                          outline: 'none',
                          borderRadius: '8px',
                        }}
                        type='hidden'
                        {...register(
                          `variantes[${nestIndex}].groupes.${index}.idGroupe`
                        )}
                        defaultValue={value.idGroupe}
                      />
                    </td>
                    <td>
                      {!toUpdateVariant.includes(index) && value.nbr}
                      <input
                        style={{
                          border: ' 0.5px solid #DEDEDE',
                          outline: 'none',
                          borderRadius: '8px',
                        }}
                        type={toUpdateVariant.includes(index) ? '' : 'hidden'}
                        {...register(
                          `variantes[${nestIndex}].groupes.${index}.nbr`
                        )}
                        defaultValue={value.nbr}
                        onChange={(e) => handleChangeField(e, value.id)}
                      />
                    </td>
                    <td style={{ width: '100px' }} align='center'>
                      {!toUpdateVariant.includes(index) && (
                        <button
                          className='btn btn-danger'
                          type='button'
                          onClick={() => updateVariant(index, value.id)}
                        >
                          <EditOrangeIcon />
                        </button>
                      )}
                    </td>
                    <td style={{ width: '100px' }} align='center'>
                      {!toUpdateVariant.includes(index) ? (
                        <button
                          className='btn btn-danger'
                          type='button'
                          onClick={() => {
                            setToDeleteGroupe({ value, index });
                          }}
                        >
                          <TrashOutlineIcon />
                        </button>
                      ) : (
                        <button
                          className='btn btn-light'
                          type='button'
                          onClick={() => {
                            validateField(index, value.id, fields);
                          }}
                        >
                          <ValidateIcon />
                        </button>
                      )}
                    </td>
                  </tr>
                );
                return isDragged ? (
                  <table
                    className='table dragged-table dragged-top'
                    style={{ ...props.style, borderSpacing: 0 }}
                  >
                    <tbody>{row}</tbody>
                  </table>
                ) : (
                  row
                );
              }}
            />
          </td>
        </tr>
      )}
      <tr className=''>
        {multiChoice && <td>&nbsp;</td>}
        <td colSpan='3' style={{ padding: 0 }} className='border-0'>
          <div className='add-groupe'>
            <table className='table border-0'>
              <tbody>
                <tr>
                  <td style={{ width: 100 }}></td>
                  <td>
                    <Select
                      onChange={handleChange}
                      className='basic-single'
                      classNamePrefix='select'
                      value={selectedOption}
                      defaultValue={selectedOption}
                      options={groupesOptions}
                    />
                  </td>
                  <td>
                    <input
                      className='form-control'
                      type='number'
                      placeholder='nombre de choix'
                      ref={groupeChoix}
                    />
                  </td>
                  <td style={{ width: 100 }} align='center'>
                    <button
                      className='btn btn-warning btn-add'
                      type='button'
                      onClick={appendGroup}
                    >
                      {' '}
                      +{' '}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>

      {toDeleteGroupe && (
        <Confirm
          show={toDeleteGroupe}
          handleClose={setToDeleteGroupe}
          message='Êtes-vous sûr de vouloir supprimer ce groupe ?'
          handleConfirm={() => {
            removeGroupe(toDeleteGroupe.value, toDeleteGroupe.index);
            setToDeleteGroupe(false);
          }}
          confirmeLoad={false}
        />
      )}
    </>
  );
};
