import React from "react";

const Dash = (props) => {
  return (
    <span className="sidebar--list-svg mr">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.875 9.08463C1.70931 9.08463 1.575 8.95031 1.575 8.78463V1.20129C1.575 1.03561 1.70931 0.901294 1.875 0.901294H7.54167C7.70735 0.901294 7.84167 1.03561 7.84167 1.20129V8.78463C7.84167 8.95031 7.70735 9.08463 7.54167 9.08463H1.875ZM1.875 16.7513C1.70931 16.7513 1.575 16.617 1.575 16.4513V12.7013C1.575 12.5356 1.70931 12.4013 1.875 12.4013H7.54167C7.70735 12.4013 7.84167 12.5356 7.84167 12.7013V16.4513C7.84167 16.617 7.70735 16.7513 7.54167 16.7513H1.875ZM11.4583 16.7513C11.2926 16.7513 11.1583 16.617 11.1583 16.4513V8.86796C11.1583 8.70227 11.2926 8.56796 11.4583 8.56796H17.125C17.2907 8.56796 17.425 8.70228 17.425 8.86796V16.4513C17.425 16.617 17.2907 16.7513 17.125 16.7513H11.4583ZM11.1583 1.20129C11.1583 1.03561 11.2926 0.901294 11.4583 0.901294H17.125C17.2907 0.901294 17.425 1.03561 17.425 1.20129V4.95129C17.425 5.11698 17.2907 5.25129 17.125 5.25129H11.4583C11.2926 5.25129 11.1583 5.11698 11.1583 4.95129V1.20129Z"
          stroke={props.color}
          strokeWidth="1.4"
        />
      </svg>
    </span>
  );
};

export default Dash;
