import React from "react";

const LogoutIcon = (props) => {
  return (
    <span className="sidebar--list-svg">
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.625 0H12.375V13.75H9.625V0ZM15.2762 5.34875L17.27 3.355C20.13 5.335 22 8.635 22 12.375C22 18.4525 17.0775 23.375 11 23.375C4.9225 23.375 0 18.4525 0 12.375C0 8.635 1.87 5.335 4.73 3.355L6.71 5.335C4.345 6.7925 2.75 9.39125 2.75 12.375C2.75 16.9262 6.44875 20.625 11 20.625C15.5512 20.625 19.25 16.9262 19.25 12.375C19.25 9.39125 17.655 6.7925 15.2762 5.34875Z" fill="#E08005"/>
        </svg>  
    </span>
  );
};

export default LogoutIcon;
