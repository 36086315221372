import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";

// Components
// import MesCommandes from './mes-commandes'
import ArchiveFiscale from './archive-fiscale'
import Reservations from './reservations'
import Statistics from './statistics'

const CommandeStore = (props) => {

    return ( 
        <Switch> 
            <Route exact path="/store/commandes">
                <Redirect to="/store/commandes/statistics" />
            </Route>
      
            <Route exact path="/store/commandes/statistics" component={() => <Statistics store={props.store} />} />
            <Route exact path="/store/commandes/archives-fiscales" component={() => <ArchiveFiscale store={props.store} />} />
            <Route exact path="/store/commandes/reservations" component={() => <Reservations store={props.store} />} /> 
        </Switch>
    )
}

export default CommandeStore
