import React, { useState } from 'react';
import { Notyf } from 'notyf';
//import { uploadFile } from '~/api/upload';
import { updateEnseigneStyles } from '~/api/enseigne';
import { getRealFileName } from '~/_helpers/helper';
//svg
import upload from '~/assets/svg/upload-lg.svg';
import Loader from '~/components/Loader';
import { useLoadMedia } from '~/hooks/loadMedia';

const Logo = ({ enseigne }) => {
  const notyf = new Notyf({
    position: {
      x: 'center',
      y: 'top',
    },
    duration: 3000,
    dismissible: true,
  });

  let infos = {
    logo: '',
    favicon: '',
  };
  const [files, setFiles] = useState(infos);
  const [load, setLoad] = useState('');
  const [loadLogo, setLoadLogo] = useState(false);
  const [loadFav, setLoadFav] = useState(false);
  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    uppdateInfo,
    setUpdateInfo,
    Media,
    setLoadMedia,
  } = useLoadMedia({
    logo: {
      boolean: loadLogo,
      action: setLoadLogo,
      name: 'logo',
      updateValue: setFiles,
    },
    favicon: {
      boolean: loadFav,
      action: setLoadFav,
      name: 'favicon',
      updateValue: setFiles,
    },
  });
  /* const handleUpload = (e) => 
    let target = e.target;
    let imageFile = e.target.files[0] ;
    let file = new FormData();
    file.append("file", imageFile);

    var dim = { width: 250, height: 250 };

    if (target.name !== "logo") {
      dim = { width: 70, height: 70 };
    }

    if (!imageFile) {
      notyf.error("Veuillez sélectionner une image.");
    }else if ( !imageFile.name.match(/\.(jpg|jpeg|png|svg|gif)$/) ) {
      notyf.error("Veuillez sélectionner une image valide. ");
    }else if ( e.target.files[0].size > 2097152 ) {
      notyf.error("La taille de l'image doit être inférieure à 2 Mo !");
    }else {
      let _URL = window.URL;
      let img = new Image();
      img.onload = function () {
        if (target.name === "logo") {
          setLoadLogo(true);
    
          uploadFile('logo', file)
          .then((res) => {
              setLoadLogo(false);
              setLoad(false);
              setFiles({ ...files, "logo": res.data.filename });
            })
            .catch((err) => {
              notyf.error(err.message);
              setLoadLogo(false);
            });
        } else {
          setLoadFav(true);
          uploadFile('favicon', file)
            .then((res) => {
              setLoadFav(false);
              setFiles({ ...files, "favicon": res.data.filename });
            })
            .catch((err) => {
              notyf.error(err.message);
              setLoadFav(false);
            });
        }
      };
      img.src = _URL.createObjectURL(imageFile);
    }
    
  }; */
  const handleValidate = (e) => {
    e.preventDefault();
    setLoad(true);
    setUpdateInfo(false);
    let styles = enseigne.styles;

    if (files.logo !== '') {
      styles.logo.pathLogo = files.logo;
    }
    if (files.favicon !== '') {
      styles.logo.pathFavicon = files.favicon;
    }
    if (files.logo === '' && files.favicon === '') {
      notyf.error('Vous devez choisir un logo et un favicon');
      setLoad(false);
    } else {
      updateEnseigneStyles(enseigne._id, styles)
        .then((res) => {
          setLoad(false);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          setLoad(false);
          notyf.error(err.message);
        });
    }
  };
  return (
    <>
      {loadMedia ? (
        <Media
          handleSelectedMedia={handleSelectedMedia}
          setLoadMedia={setLoadMedia}
        />
      ) : (
        <div className='styles--logo'>
          <form onSubmit={handleValidate}>
            <div className='styles--top'>
              <h1>Logo</h1>
              <hr />
            </div>
            {load ? (
              <Loader />
            ) : (
              <>
                <div className='container mb-5'>
                  <div className='row'>
                    <div className='col text-center'>
                      <p className='mb-2'>Votre logo actuel</p>
                      <img
                        className='styles--body-logo'
                        alt='logo'
                        src={
                          files.logo === ''
                            ? enseigne.styles.logo.pathLogo
                            : files.logo
                        }
                      />
                    </div>
                    <div className='col text-center'>
                      <p className='mb-2'>Votre favicon actuel</p>
                      <img
                        className='styles--body-favicon'
                        alt='logo'
                        src={
                          files.favicon === ''
                            ? enseigne.styles.logo.pathFavicon
                            : files.pathFavicon
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='styles--body'>
                  <div>
                    <p className='mb-2'>Selectionnez un logo</p>
                    <span>(taille 250ix250 en PNG)</span>
                    {loadLogo && (
                      <div className='spinner-border text-light' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    )}
                    {enseigne.styles.logo.pathLogo && (
                      <span>
                        {/*  (ou ignorez cette étape si vous ne voulez pas le
                        changer) */}
                      </span>
                    )}
                    <div className='styles--body-upload'>
                      {/*  <input
                    type='file'
                    id='upload--logo'
                    onChange={handleUpload}
                    name='logo'
                  /> */}
                      <label htmlFor='upload--logo'>
                        <img
                          alt='upload-file'
                          src={upload}
                          name='logo'
                          onClick={handleMedia}
                        />
                      </label>
                      {files.logo !== '' && (
                        <span>{getRealFileName(files.logo)}</span>
                      )}
                    </div>
                  </div>

                  <div>
                    <p className='mb-2'>Selectionnez un favicon</p>{' '}
                    <span>(taille 70x70 en PNG)</span>{' '}
                    {loadFav && (
                      <div className='spinner-border text-light' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    )}
                    {enseigne.styles.logo.pathFavicon && (
                      <span>
                        {/*  (ou ignorez cette étape si vous ne voulez pas le
                        changer) */}
                      </span>
                    )}
                    <div className='styles--body-upload'>
                      {/*  <input
                    type='file'
                    id='upload--favicon'
                    onChange={handleUpload}
                    name='favicon'
                  /> */}
                      <label htmlFor='upload--favicon'>
                        <img
                          alt='upload-file'
                          src={upload}
                          name='favicon'
                          onClick={handleMedia}
                        />
                      </label>
                      {files.favicon !== '' && (
                        <span>{getRealFileName(files.favicon)}</span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className='styles--top'>
              {uppdateInfo && (
                <div className='update-msg-container'>
                  <span>
                    Il ya une nouvelle mise à jour <br /> appuyez sur valider
                    pour appliquer
                  </span>
                </div>
              )}
              <button>Valider</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Logo;
