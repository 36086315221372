import instanceAxios from "./axios.instance" ;
import { BASE_URI } from "~/assets/base";

export const getProduitsOfStore = async (storeId) => {
    const response = await instanceAxios.get(`${BASE_URI}store/produits/${storeId}`);
    return response;
}
export const createProduit = async (datas) => {
    const response = await instanceAxios.post(`${BASE_URI}store/produit/create`,
        {
            newProduit : datas
        }
    );
    return response;
}
export const updateProduit = async (produit) => {
    const response = await instanceAxios.put(`${BASE_URI}store/produit/update/${produit._id}`,
        { produit }
    )
    return response;
}
export const deleteProduit = async (id) => {
    const response = await instanceAxios.delete(`${BASE_URI}store/produit/delete/${id}`);
    return response;
}
export const initOrderProduits = async ( storeId, move, oldIndex, newIndex ) => {
    const response = await instanceAxios.put(`${BASE_URI}store/produits/position/${storeId}`,
        { move, oldIndex, newIndex }
    )
    return response;
}
