import React from "react";
import "./checkout.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Sepa from "./Sepa";

const stripePromise = loadStripe(
  "pk_test_51IPPQlCUX4Iq5f7HBxNKmbzUTtOBDvFCX34JiFIGRRvmouvaFBkr5isFTxueqLgS8B7OprqBk0smKEHQ424wLpK500nh9SJ8yz"
);

function PaymentStripe() {
  return (
    <div className="bodyStripe">
      <Elements stripe={stripePromise}>
        <Sepa />
      </Elements>
    </div>
  );
}

export default PaymentStripe;
