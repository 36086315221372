import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Components
import EmporterCommande from "./emporter";
import LivraisonCommande from "./livraison";
import PlaceCommande from "./place";
import TableCommande from "./table";
import AcceptOnlineOrder from "./commandeLigne";

const TypeCommandeStore = (props) => {
  return (
    <Switch>
      <Route exact path="/store/type-commande">
        <Redirect to="/store/type-commande/emporter" />
      </Route>
      <Route
        exact
        path="/store/type-commande/emporter"
        component={() => <EmporterCommande store={props.store} />}
      />
      <Route
        exact
        path="/store/type-commande/livraison"
        component={() => <LivraisonCommande store={props.store} />}
      />
      <Route
        exact
        path="/store/type-commande/place"
        component={() => <PlaceCommande store={props.store} />}
      />
      <Route
        exact
        path="/store/type-commande/table"
        component={() => <TableCommande store={props.store} />}
      />
      <Route
        exact
        path="/store/type-commande/online-order"
        component={() => <AcceptOnlineOrder store={props.store} />}
      />
    </Switch>
  );
};

export default TypeCommandeStore;
