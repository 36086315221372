import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Notyf } from "notyf";

// Components
import Loader from "~/components/Loader";

// utils
import { createCustomer } from '~/api/customer' ;


const AddCustomer = ({ storeId, setAdd, addCustomer }) => {

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const { register, handleSubmit } = useForm();
  const [load, setLoad] = useState(false);

  const onSubmit = (data) => {

    setLoad(true);
    let newCustomer = {
      ...data,
      storeId,
    };

    createCustomer(newCustomer)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        addCustomer(res.data.customer);
        setAdd(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="customer--add">
        <div className="block-styled"> 
          <h1 className="block-styled-head">
            <i className="fas fa-arrow-left" onClick={() => setAdd(false)}></i>
            Nouveau client
          </h1>
          <div className="block-styled-body">
            
            <form className="inputs" onSubmit={handleSubmit(onSubmit)}> 

              <div className="container">
                <div className="row">
                    <div className="col">
                        <label>Sexe</label>
                        <select name="gender" {...register("gender", { required: true })} >
                            <option value={"homme"}>Homme</option>
                            <option value={"femme"}>Femme</option>
                        </select>
                    </div>
                </div>

                <div className="row"> 
                    <div className="col">
                      <label>Prénom</label>
                      <input type="text" name="firstName" defaultValue="" {...register("firstName", { required: true })} required />
                    </div>
                </div> 

                <div className="row">
                    <div className="col">
                      <label>Nom</label>
                      <input type="text" name="lastName" defaultValue="" {...register("lastName", { required: true })} required />
                    </div> 
                </div>
                
                <div className="row">
                    <div className="col">
                        <label>Téléphone</label>
                        <input name="phone" type="number" defaultValue="" {...register("phone", { required: true })} />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <label>Points</label>
                        <input name="points" type="number" min="0" step="1" defaultValue="0" {...register("points", { required: true })} />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col">
                        <label>Email</label>
                        <input required name="email" defaultValue="" {...register("email", { required: true })} className="input-email" />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <label>Mot de passe</label>
                        <input required name="password" type="password" defaultValue="" {...register("password", { required: true })} className="input-libre" />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <button className="modal--btn-validate" type="submit">Ajouter</button>
                    </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default AddCustomer;