import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// Component
import Select from "react-select";
// Utils
import { getTime, getDate } from "~/_helpers/helper";
import days from "~/constants/days";

const PromoMoment = ({ promoDatas, update, retour, last }) => {
  const types = [
    { value: "place", label: "Sur place" },
    { value: "emporter", label: "A emporter" },
    { value: "livraison", label: "En livraison" },
  ];
  const moments = [
    { value: false, label: "Toujours" },
    { value: true, label: "Personnalisé" },
  ];
  const { register, control, handleSubmit, setValue, getValues, watch } =
    useForm({ defaultValues: promoDatas });
  const [selectedTime, setSelectedTime] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const isCustom = watch("moments.custom");
  const enablePlageDate = watch("moments.plageDate.enable");

  useEffect(() => {
    if (promoDatas._id !== null) {
      // init champs date when update
      if (
        promoDatas.moments.plageDate.start ||
        promoDatas.moments.plageDate.end
      ) {
        let Istart = promoDatas.moments.plageDate.start;
        let Iend = promoDatas.moments.plageDate.end || null;

        let start = new Date(
          Istart.substr(6, 4),
          Istart.substr(3, 2) - 1,
          Istart.substr(0, 2),
          0,
          0,
          0
        );
        let end = Iend
          ? new Date(
              Iend.substr(6, 4),
              Iend.substr(3, 2) - 1,
              Iend.substr(0, 2),
              0,
              0,
              0
            )
          : null;

        setValue("moments.plageDate", {
          ...promoDatas.moments.plageDate,
          start: getDate(start),
          end: end ? getDate(end) : null,
        });
        setStartDate(start);
        setEndDate(end);
      }

      if (promoDatas.moments.time) {
        var today = new Date();
        let Istart = promoDatas.moments.time.start.toString();
        let Iend = promoDatas.moments.time.end.toString();

        let start = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDay(),
          Istart.split(":")[0],
          Istart.split(":")[1],
          0
        );
        let end = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDay(),
          Iend.split(":")[0],
          Iend.split(":")[1],
          0
        );

        setValue("moments.time", {
          ...promoDatas.moments.time,
          start: getTime(start),
          end: getTime(end),
        });
        setSelectedTime({ ...selectedTime, start, end });
      }
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    update(data);
  };

  const checkChecked = (array, val) => {
    if (!array) return;
    let checked = false;
    array.map((i) => {
      if (i.toString() === val.toString()) {
        checked = true;
      }
      return true;
    });

    return checked;
  };

  const handleTime = (ref, e) => {
    let horaire = getValues("moments.time");
    if (ref === "start") {
      setSelectedTime({ ...selectedTime, start: e });
      setValue("moments.time", { ...horaire, start: getTime(e) });
    } else {
      setSelectedTime({ ...selectedTime, end: e });
      setValue("moments.time", { ...horaire, end: getTime(e) });
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    setValue("moments.plageDate.start", start ? getDate(start) : null);
    setValue("moments.plageDate.end", end ? getDate(end) : null);
  };

  const getDefaultChoix = () => {
    if (promoDatas._id !== null) {
      return moments.find((m) => m.value === promoDatas.moments.custom);
    }
    return moments[0];
  };

  return (
    <div className="commun_moments">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="startAmount">
            Quel type de commande est concerné ?
          </label>
          <Controller
            name="typeCommande"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  value={types.filter((type) =>
                    field.value.includes(type.value)
                  )}
                  onChange={(e) => field.onChange(e.map((elem) => elem.value))}
                  isMulti
                  options={types}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{ opacity: 0, height: 0 }}
                  value={field.value}
                  required={true}
                />
              </>
            )}
          />
        </div>

        <div className="form-group">
          <label htmlFor="moments.custom">Quel moment est elle proposé ?</label>
          <Controller
            name="moments.custom"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                defaultValue={getDefaultChoix()}
                onChange={(e) => onChange(e.value)}
                options={moments}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            )}
          />
        </div>

        {isCustom && (
          <>
            <div className="row mb-4 days-container">
              {days.map((day, index) => {
                return (
                  <div
                    key={index}
                    className="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={index}
                      name="moments.days"
                      value={day.value}
                      checked={checkChecked(moments.days, day.value)}
                      {...register("moments.days", { valueAsNumber: true })}
                    />
                    <label className="custom-control-label" htmlFor={index}>
                      {day.label}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="form-group d-flex align-items-center TimePickerContainer">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label className="mr-4">Entre : </label>
                <TimePicker
                  value={selectedTime.start}
                  onChange={(e) => handleTime("start", e)}
                  okLabel="OK"
                  clearLabel="Effacer"
                  cancelLabel="Annuler"
                  clearable
                  ampm={false}
                  label="24 hours"
                />
                <label className="mx-4">ET </label>
                <TimePicker
                  value={selectedTime.end}
                  onChange={(e) => handleTime("end", e)}
                  okLabel="OK"
                  clearLabel="Effacer"
                  cancelLabel="Annuler"
                  clearable
                  ampm={false}
                  label="24 hours"
                />
              </MuiPickersUtilsProvider>
            </div>
          </>
        )}

        <div className="custom-control custom-checkbox mb-3">
          <input
            type="checkbox"
            className="custom-control-input"
            id="moments.plageDate.enable"
            {...register("moments.plageDate.enable", { valueAsNumber: true })}
          />
          <label
            className="custom-control-label"
            htmlFor="moments.plageDate.enable"
          >
            Définir une date de debut et fin
          </label>
        </div>

        {enablePlageDate && (
          <div className="mb-3">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/dd/yyyy"
              minDate={new Date()}
              selectsRange
              inline
            />
          </div>
        )}
        <div className="form-group steps-controlers">
          <div>
            <button className="btn btn-dark" type="button" onClick={retour}>
              Retour
            </button>
          </div>
          <div>
            <button className="btn btn-warning" type="submit">
              {last ? "Terminé" : "Suivant"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PromoMoment;
