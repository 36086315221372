import React, { useState } from "react";
import { Notyf } from "notyf";
import Loader from "~/components/Loader";
import { updateEnseigneStyles } from "~/api/enseigne";

const Perso = ({ enseigne }) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 5000,
    dismissible: true,
  });

  const [input, setInput] = useState(enseigne.styles.perso || "");
  const [load, setLoad] = useState(false);

  const handleValidate = (e) => {
    e.preventDefault();
    setLoad(true);
    let styles = enseigne.styles;
    styles.perso = input;
    updateEnseigneStyles(enseigne._id, styles)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
    return false;
  };

  return (
    <div className='styles--banner'>
      <form onSubmit={handleValidate}>
        <div className='styles--top'>
          <h1>Style personnalisé</h1>

          <hr />
        </div>

        {load ? (
          <Loader />
        ) : (
          <div className='styles--body'>
            <div>
              <p>Personnalisé le style</p>
              <div className='perso'>
                <textarea
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        <div className='styles--top'>
          <button>Valider</button>
        </div>
      </form>
    </div>
  );
};

export default Perso;
