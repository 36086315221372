import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Offline from "../Offline";
import Online from "../Online";
import Loader from "~/components/Loader";

// Import Redux / actions
import { useDispatch, useSelector } from "react-redux";
import { getUserInSession } from "~/redux/actions/userAction";
import PaymentStripe from "../PaymentStripe";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUserInSession());
  }, [dispatch]);

  const mainContent = (
    <div className="app">
      <Switch>
        <Route
          exact
          path={[
            "/",
            "/password-forget",
            "/validate-store-subscription/:storeId/enseigneId/:enseigneId",
          ]}
          component={Offline}
        />
        <Route
          path={["/dashboard", "/enseigne", "/store"]}
          component={Online}
        />
        <Route path="/checkout-payment" component={PaymentStripe} />;
      </Switch>
    </div>
  );

  return user.inLoad ? <Loader /> : mainContent;
};

export default App;
