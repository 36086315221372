import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
import closeIcon from "~/assets/svg/close.svg";
import { updateEnseigne } from '~/api/enseigne';
import CountrySelect from "~/components/Reusable/CountrySelect";
import TimezoneSelect from "react-timezone-select";

const EditEnseigne = ({ show, setShow, enseigne }) => {

  const notyf = new Notyf({
    position: {
      x: "center",
      y: "bottom",
    },
    duration: 3000,
    dismissible: true,
  });
  
  const [load, setLoad] = useState(false);
  const { handleSubmit, register, control } = useForm();

  const onSubmit = (data) => {
      const upEnseigne = {
        ...enseigne,
        identity : data.identity,
        intitule : data.intitule,
        urlWebSite : data.urlWebSite,
        offer : data.offer,
        newOffer : data.offer === enseigne.newOffer ? null :  enseigne.newOffer
      }
  
      updateEnseigne(upEnseigne)
      .then((res) => {
        setLoad(false);
        setShow(false);
        notyf.success(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, [1500]);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <Modal className="modal addenseigne" show={show} onHide={setShow}>
      <form className="inputs" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <h1 className="modal--title">Modifier l'enseigne</h1>
          <img
            onClick={() => setShow(false)}
            className="modal--close"
            alt="close-svg"
            src={closeIcon}
          />
        </Modal.Header>
        <Modal.Body>
          <label>Nom de l’enseigne</label>
          <input
            required
            type="text"
            defaultValue={enseigne.intitule}
            name="intitule"
            {...register("intitule", { required: true })}
          />
          <label>Adresse</label>
            <input 
              required
              type="text"
              defaultValue={enseigne.identity.address}
              name="identity.address"
              {...register("identity.address", { required: false })} 
            />

            <div className="row">
              <div className="col-sm-6">
                <label>Code postale</label>
                <input  
                  required
                  type="number"
                  defaultValue={enseigne.identity.zipCode}
                  name="identity.zipCode"
                  {...register("identity.zipCode", { required: false })} 
                />
              </div>
              <div className="col-sm-6">
                <label>Ville</label>
                <input
                  required
                  type="text"
                  defaultValue={enseigne.identity.city}
                  name="identity.city"
                  {...register("identity.city", { required: false })}  
                />
              </div>
            </div>

            <Controller  
              name="identity.country" 
              control={control}   
              rules={{ required: true }} 
              defaultValue={enseigne.identity.country}
              render={({ field }) => { 
                return (
                  <CountrySelect   
                  type="text"
                  value={field.value}
                  name="identity.country" 
                  onChange={field.onChange}
                /> 
                )
              }}
            /> 
            

            <label>Fuseau horaire</label>
            <Controller  
              name="identity.timeZone" 
              control={control}   
              rules={{ required: false }} 
              defaultValue={enseigne.identity.timeZone}
              render={({ field }) => { 
                return (
                  <TimezoneSelect
                    required
                    name="identity.timeZone"
                    className="information--timezone"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )
              }}
            /> 

          <label>Url website</label>
          <input
            required
            type="text"
            defaultValue={enseigne.urlWebSite}
            name="urlWebSite"
            {...register("urlWebSite", { required: true })}
          />

          <label>Nom</label>
          <input
            required
            type="text"
            defaultValue={enseigne.identity.lastname}
            name="identity.lastname"
            {...register("identity.lastname", { required: true })}
          />

          <label>Prénom</label>
          <input
            required
            type="text"
            defaultValue={enseigne.identity.firstname}
            name="identity.firstname"
            {...register("identity.firstname", { required: true })}
          />

          <label>Genre</label>
          <select
            required
            defaultValue={enseigne.identity.gender}
            {...register("identity.gender", { required: true })}
            name="identity.gender"
          >
            <option>Homme</option>
            <option>Femme</option>
          </select>
          
          <label>Téléphone</label>
          <input
            required
            type="tel"
            defaultValue={enseigne.identity.phone}
            name="identity.phone"
            {...register("identity.phone", { required: true })}
          />

          <label>Formules</label>
          <select
            defaultValue={enseigne.offer}
            name="offer"
            {...register("offer", { required: true })}
          >
            <option value={"abonnement"}>Abonnement mensuel de 59€</option>
            <option value={"commission"}>Commission de 5% par panier</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="modal--btn-close"
            onClick={() => setShow(false)}
          >
            Retour
          </button>
          <button className="modal--btn-validate" type="submit">
            {load ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Modifier"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditEnseigne;
