import React, { useState } from "react";
import { useTable, usePagination } from "react-table";

// Pagination
import Pagination from "./pagination";
import "./_pagination.scss";

// searching
const simpleTextSearch = require("simple-text-search");

const TablePagination = ({
  columns,
  data,
  withPaginate = true,
  searchLabel,
  getTrProps,
}) => {
  const [searchInput, setSearchInput] = useState();
  const customPageSize = withPaginate ? 10 : 300;
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: customPageSize },
    },
    usePagination
  );

  const handelSetSearchInput = (val) => {
    setSearchInput(val);
    setPageSize(val.trim() ? 9999999999 : customPageSize);
  };

  const filterdPage = page.filter((row) => {
    let item = { ...row };
    delete item.values["_id"];
    let get = simpleTextSearch(Object.values(item.values));
    return get(searchInput).length > 0;
  });

  // Render the UI for your table
  return (
    <div className="react-table">
      <Pagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        withPaginate={withPaginate}
        searchForm={true}
        searchLabel={searchLabel}
        searchInput={searchInput}
        handelSearchInput={handelSetSearchInput}
        className="pagination-top"
      />

      <div className="table-container">
        <table {...getTableProps()} className="table">
          <thead className="table-dark">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      className: column.headerClassName,
                    })}
                    style={{ width: column.cssWidth }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {filterdPage.map((row, i) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps(getTrProps ? getTrProps(row) : {})}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.className,
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {withPaginate && (
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
};
export default TablePagination;
