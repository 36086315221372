import React, { useRef, useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { List } from "react-movable";
import GroupesArray from "./GroupesArray";
//import { Notyf } from 'notyf';
//import { uploadFile } from '~/api/upload';
import { updateProduit } from "~/api/produits";
//SVG
import dragable from "~/assets/svg/dragable.svg";
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";
import Confirm from "~/components/Commun/Confirm";
import upload from "~/assets/svg/add_image.svg";
import noImage from "~/assets/images/noimg.jpg";
import EditOrangeIcon from "~/components/Svgs/Shop/EditOrange";
import ValidateIcon from "~/components/Svgs/Shop/Validate";
import { useLoadMedia } from "~/hooks/loadMedia";

export default function VariantsArray({
  action,
  control,
  register,
  setValue,
  getValues,
  groupes,
  multiChoice,
  multiPrice,
  multiPriceType,
  product,
  setProduct,
}) {
  let { fields, append, remove, move, update } = useFieldArray({
    control,
    name: "variantes",
  });
  const variantName = useRef(null);
  const variantCout = useRef(null);
  const [image, setImage] = useState("");
  /* const notyf = new Notyf({
    position: {
      x: 'center',
      y: 'top',
    },
    duration: 3000,
    dismissible: true,
  }); */
  const [toDeleteVariant, setToDeleteVariant] = useState(null);
  const [toUpdateVariant, setToUpdateVariant] = useState([]);
  const [loadImage, setLoadImage] = useState(false);

  const [variantSelectedId, setVariantSelectedId] = useState(null);

  /*   const [imageUrl, setImageUrl] = useState('');
  const [loadImageUrl, setLoadImageUrl] = useState(false); */
  const variantDatas = useRef({});
  const variantImageRef = useRef(null);
  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    //uppdateInfo,
    //setUpdateInfo,
    setLoadMedia,
    Media,
  } = useLoadMedia({
    variant: {
      boolean: loadImage,
      action: setLoadImage,
      name: "variant",
      updateImage: (image) => {
        if (variantSelectedId !== null) {
          const field = fields.find((f) => f.id === variantSelectedId);
          const fieldIndex = fields.findIndex(
            (f) => f.id === variantSelectedId
          );

          if (fieldIndex !== -1) {
            update(fieldIndex, { ...field, imageUrl: image });
          }
        } else {
          setImage(image);
        }

        setVariantSelectedId(null);
      },
    },
    /* variant :{

    } */
  });

  const variantPriceType =
    (multiPrice && multiPriceType === "emporter/place") || !multiPrice
      ? "price"
      : "secondPrice";

  useEffect(() => {
    if (action === "add") {
      let variants = getValues("variantes");
      setValue("variantes", []);
      if (variants.length < 1 || !multiChoice) {
        append({
          name: "Variante 1",
          price: 0,
          secondPrice: 0,
        });
      }
    }

    // eslint-disable-next-line
  }, [multiChoice]);

  const appendVariant = () => {
    if (!variantName.current.value || !variantCout.current.value) {
      return false;
    }

    const data = {
      name: variantName.current.value,
      [variantPriceType]: variantCout.current.value,
      imageUrl: image,
      multiPrice,
    };

    if (!data.price) {
      data.price = 0;
    } else if (!data.secondPrice) {
      data.secondPrice = 0;
    }

    append(data);
    variantName.current.value = variantCout.current.value = null;
    setImage("");
  };
  const removeVariant = (index) => {
    remove(index);
    setToDeleteVariant(false);
  };
  const updateVariant = (index, id) => {
    if (toUpdateVariant.includes(index)) {
      setToUpdateVariant((currentState) =>
        currentState.filter((state) => state !== index)
      );
    } else {
      setToUpdateVariant((currentState) => [index]);
    }
    variantDatas.current =
      variantDatas.current.id !== id
        ? fields.find((field) => field.id === id)
        : {};
  };

  const handleChangeField = (e, id) => {
    let value = e.currentTarget.name.split(".")[
      e.currentTarget.name.split(".").length - 1
    ];
    variantDatas.current[value] = e.currentTarget.value;
  };

  const validateField = (index, id) => {
    updateVariant(index, id);
  };

  const updateVariantImage = async (e, id) => {
    product.variantes = handleFields(image);
    const res = await updateProduit(product);

    updateVariant();
    handleFields(image);
    setProduct([...res.data.produits]);

    function handleFields(input) {
      let field = fields.find((f) => f.id === id);
      field.imageUrl = input;
      fields.splice(fields.indexOf(field), 1, field);
      return fields;
    }
  };
  return (
    <>
      {loadMedia ? (
        <Media
          setLoadMedia={setLoadMedia}
          handleSelectedMedia={handleSelectedMedia}
        />
      ) : (
        <>
          {fields.length > 0 && !toDeleteVariant && (
            <table className="table mb-0">
              {multiChoice && (
                <thead className="table-dark">
                  <tr>
                    <th style={{ width: "100px" }}>Image</th>
                    <th>Nom</th>
                    <th>Coût</th>
                    <th style={{ width: "100px" }}></th>
                  </tr>
                </thead>
              )}

              <List
                lockVertically
                values={fields}
                onChange={({ oldIndex, newIndex }) => {
                  move(oldIndex, newIndex);
                }}
                renderList={({ children, props }) => (
                  <tbody {...props}>{children}</tbody>
                )}
                renderItem={({
                  value,
                  index,
                  props,
                  isDragged,
                  isSelected,
                }) => {
                  const row = (
                    <tr
                      {...props}
                      style={{
                        backgroundColor:
                          isDragged || isSelected ? "#ececec" : "#fafafa",
                      }}
                    >
                      <td
                        colSpan="5"
                        style={{ padding: 0 }}
                        className="border-0"
                      >
                        <table className="table mb-0">
                          <tbody>
                            <tr
                              style={{
                                display: multiChoice ? "table-row" : "none",
                              }}
                            >
                              <td style={{ width: "100px" }}>
                                <button
                                  data-movable-handle
                                  style={{
                                    cursor: isDragged ? "grabbing" : "grab",
                                  }}
                                >
                                  <img alt="" src={dragable} />
                                </button>

                                <img
                                  src={
                                    value.imageUrl ? value.imageUrl : noImage
                                  }
                                  alt=""
                                  style={{ width: 40 }}
                                  onClick={() => {
                                    setVariantSelectedId(() => value.id);
                                    handleMedia({
                                      target: { name: "variant" },
                                    });
                                  }}
                                />

                                <input
                                  ref={variantImageRef}
                                  type="file"
                                  onChange={(e) => {
                                    updateVariantImage(e, value.id);
                                  }}
                                  hidden
                                />
                                <input
                                  type="hidden"
                                  {...register(`variantes.${index}.imageUrl`)}
                                  defaultValue={value.imageUrl}
                                />
                              </td>
                              <td>
                                {!toUpdateVariant.includes(index) && value.name}
                                <input
                                  style={{
                                    border: " 0.5px solid #DEDEDE",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type={
                                    toUpdateVariant.includes(index)
                                      ? ""
                                      : "hidden"
                                  }
                                  {...register(`variantes.${index}.name`)}
                                  defaultValue={value.name}
                                  onChange={(e) =>
                                    handleChangeField(e, value.id)
                                  }
                                />
                              </td>
                              <td>
                                {!toUpdateVariant.includes(index) &&
                                  value[variantPriceType] + "€"}
                                <input
                                  style={{
                                    border: " 0.5px solid #DEDEDE",
                                    outline: "none",
                                    borderRadius: "8px",
                                  }}
                                  type={
                                    toUpdateVariant.includes(index)
                                      ? ""
                                      : "hidden"
                                  }
                                  {...register(
                                    `variantes.${index}.${variantPriceType}`
                                  )}
                                  defaultValue={value[variantPriceType]}
                                  onChange={(e) =>
                                    handleChangeField(e, value.id)
                                  }
                                />
                              </td>
                              <td style={{ width: 100 }} align="center">
                                {!toUpdateVariant.includes(index) && (
                                  <button
                                    className="btn btn-warning"
                                    type="button"
                                    onClick={() => {
                                      updateVariant(index, value.id);
                                    }}
                                  >
                                    <EditOrangeIcon />
                                  </button>
                                )}
                              </td>
                              <td style={{ width: 100 }} align="center">
                                {!toUpdateVariant.includes(index) ? (
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() => {
                                      setToDeleteVariant({ index });
                                    }}
                                  >
                                    <TrashOutlineIcon />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-light"
                                    type="button"
                                    onClick={() => {
                                      validateField(index, value.id);
                                    }}
                                  >
                                    <ValidateIcon />
                                  </button>
                                )}
                              </td>
                            </tr>
                            <GroupesArray
                              nestIndex={index}
                              groupes={groupes}
                              multiChoice={multiChoice}
                              toUpdateVariant={toUpdateVariant}
                              handleChangeField={handleChangeField}
                              validateField={validateField}
                              updateVariant={updateVariant}
                              {...{ control, register }}
                            />
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  );
                  return isDragged ? (
                    <table
                      className="table dragged-table dragged-top"
                      style={{ ...props.style, borderSpacing: 0 }}
                    >
                      <tbody>{row}</tbody>
                    </table>
                  ) : (
                    row
                  );
                }}
              />
            </table>
          )}

          {multiChoice && (
            <div className="add-variant">
              <table className="table">
                <tr>
                  <td style={{ width: 100 }}>
                    <div className="styles--body-upload center">
                      {image === "" && (
                        <>
                          {/* <input
                        type='file'
                        id='upload_variante_image'
                        onChange={handleUpload}
                      /> */}
                          <label htmlFor="upload_variante_image">
                            <img
                              name="variant"
                              alt="upload-file"
                              src={upload}
                              style={{ width: 40 }}
                              onClick={handleMedia}
                            />
                          </label>
                        </>
                      )}
                      {image !== "" && (
                        <img src={image} alt="" style={{ width: 40 }} />
                      )}
                    </div>
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Nom de la variante"
                      ref={variantName}
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Côut"
                      ref={variantCout}
                    />
                  </td>
                  <td style={{ width: 100 }} align="center">
                    <button
                      className="btn btn-warning btn-add"
                      type="button"
                      onClick={appendVariant}
                    >
                      {" "}
                      +{" "}
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          )}

          {toDeleteVariant && (
            <Confirm
              show={toDeleteVariant}
              handleClose={setToDeleteVariant}
              message="Êtes-vous sûr de vouloir supprimer cette Variante ?"
              handleConfirm={() => {
                removeVariant(toDeleteVariant.index);
              }}
              confirmeLoad={false}
            />
          )}
        </>
      )}
    </>
  );
}
