import React, { useState } from 'react';
// Components
import Promotion from './Promotion';
import Confirm from '~/components/Commun/Confirm';



const ListesPromotions = ({ listes, setEdit, setSelected, handleChangeStatus, handleDelete, store }) => {

    const [toDelete, setToDelete] = useState(false)
    const [promoId, setPromoId] = useState(null)
    const [load, setLoad] = useState(false)

    const confirmDelete =(id)=>{
        setToDelete(true)
        setPromoId(id)
    }

    return (
        <>
            {listes.map((promo, id) => (
                <Promotion
                    key={id}
                    promo={promo}
                    setEdit={setEdit}
                    setSelected={setSelected}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={confirmDelete}
                    store={store}
              />
            ))}
            { toDelete && <Confirm show={toDelete} handleClose={setToDelete} 
                                            message="Êtes-vous sûr de vouloir supprimer cette promotion ?" 
                                            handleConfirm={()=>{handleDelete(promoId); setLoad(true)}} confirmeLoad={load} /> }
        </>
    )
}

export default ListesPromotions
