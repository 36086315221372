import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";
import mailIcon from "~/assets/svg/mail.svg";
import lockIcon from "~/assets/svg/lock.svg";
import eyeOpen from "~/assets/svg/eye-open.svg";
import eyeClose from "~/assets/svg/eye-close.svg";
import fondOrange from "~/assets/svg/fond-orange.svg";
import Loader from "~/components/Loader";
// Import Redux / actions
import { useDispatch, useSelector } from 'react-redux';
import { authUser } from '~/redux/actions/userAction';

const Login = (props) => {

  const notyf = new Notyf({
    position: {
      x: "center",
      y: "bottom",
    },
    duration: 3000,
    dismissible: true,
  });

  let infos = {
    email: "",
    password: "",
  };
  const [input, setInput] = useState(infos);
  const [showPassword, setShowPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    dispatch( authUser( input.email, input.password ) );
  };

  useEffect(() => {
    if ( user.data !== null ) {
      setLoad(false);
      if( user.data.scope === "ROLE_SUPREMEADMIN" ){
        props.history.push("/dashboard");
      }else if( user.data.scope === "ROLE_SUPERADMIN" ){
        props.history.push("/enseigne/dashboard");
      }else if( user.data.scope === "ROLE_STORE" ){
        props.history.push("/store");
      }
    }else if( user.error ){
      setLoad(false);
      notyf.error( user.errorMessage );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])


  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="connexion">
        <div className="connexion--top">
          <h1 className="connexion--title">M'IDENTIFIER</h1>

          <form onSubmit={handleSubmit} className="connexion--form yop">
            <label className="connexion--label">Adresse Email</label>
            <div className="connexion--inputWrapper">
              <img alt="mail-icon" src={mailIcon} />
              <input
                className="connexion--input"
                onChange={handleChange}
                value={input.email}
                name="email"
                type="text"
                required
              />
            </div>
            <label className="connexion--label">Mot de passe</label>
            <div className="connexion--inputWrapper">
              <img alt="mail-icon" src={lockIcon} />
              <input
                className="connexion--input input-libre"
                onChange={handleChange}
                value={input.password}
                name="password"
                type={showPassword ? "text" : "password"}
                required
              />
              <img
                className="connexion--eyeIcon"
                onClick={handleShowPassword}
                alt="mail-icon"
                src={showPassword ? eyeOpen : eyeClose}
              />
            </div>
            <p
              onClick={() => props.history.push("/password-forget")}
              className="connexion--passwordForget"
            >
              Mot de passe oublié ?
            </p>
            <button className="connexion--btnWrapper">CONNEXION</button>
          </form>
        </div>
        <img
          className="connexion--separator"
          alt="mail-icon"
          src={fondOrange}
        />
        <div className="connexion--bottom">
          <p>By eatlines</p>
        </div>
      </div>
    );
  }
};

export default Login;
