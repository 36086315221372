import instanceAxios from "./axios.instance" ;
import { BASE_URI } from "~/assets/base";

export const forgetPassword = async (userEmail) => {
    const response = await instanceAxios.post(`${BASE_URI}users/forgetpassword`, {
        email: userEmail
    });

    return response.data;
}

export const resetPassword = async (data) => {
    const response = await instanceAxios.post(`${BASE_URI}users/resetpassword`, data);

    return response.data;
}