import React, { useState, useEffect } from 'react';
import { Switch } from "@material-ui/core";
import { Notyf } from "notyf";
// Components
import AddIngredient from './AddIngredient';
import EditIngredient from './EditIngredient';
import Loader from "~/components/Loader";
import Confirm from '~/components/Commun/Confirm';
// Utils
import { getIngredientOfStore, updateIngredient, deleteIngredient } from '~/api/menu';

//svg 
import Plus from "~/assets/svg/plus.svg";
import EditOrangeIcon from '~/components/Svgs/Shop/EditOrange';
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";

//Pagination
import TablePagination from '~/assets/table-pagination/'

const IngredientsStore = (props) => {

    const notyf = new Notyf({
        position: {
          x: "center",
          y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const store = props.store ;
    const [load, setLoad] = useState(true);
    const [toAdd, setToAdd] = useState(false);
    const [toEdit, setToEdit] = useState(false);
    const [ingredients, setIngredients] = useState([]);
    const [selected, setSelected] = useState({});
    const [toDelete, setToDelete] = useState(false);
    
    useEffect(() => {
        let storeId = store._id;
        let menuActive = localStorage.getItem("menuActive");
        getIngredientOfStore(storeId, menuActive)
            .then((res) => {
                setIngredients(res.data.ingredients);
                setLoad(false);
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
            });
        // eslint-disable-next-line
    }, []);

    const changeStatus = (ingredient) => {

        let newIngredients = {...ingredient, enable: !ingredient.enable};
        updateIngredient(newIngredients)
            .then((res) => {
                let ingredients = res.data.ingredients;
                setIngredients(ingredients);
                notyf.success(res.data.message);
            }).catch((err) => {
                notyf.error(err.message);
            });
    }

    const handleDelete = () => {
        setLoad(true);
        setToDelete(false);
        deleteIngredient(selected._id)
            .then((res) => {
                let ingredients = res.data.ingredients;
                setIngredients(ingredients);
                notyf.success(res.data.message);
                setLoad(false);
            }).catch((err) => {
                notyf.error(err.message);
            });
    }

    /// Pagination 
    const tableColumns = [{
        Header: 'Activé',
        accessor: 'enable',
        className: "tdswitch",
        Cell: ({ row })  => { 
            let ingredient = row.original
            return (
                <Switch
                    onChange={() => { changeStatus(ingredient) } }
                    checked={ ingredient.enable }
                    color="primary"
                    name="changeStatus"
                    inputProps={{ "aria-label": "primary checkbox" }}
                />
            )
        }
    },{
        Header: 'Nom',
        accessor: 'name',
        Cell: ({ row })  => { 
            let ingredient = row.original
            return (
                <>
                    { ingredient.imageUrl && <img alt="" src={ ingredient.imageUrl } style={{width:31,marginRight:10}}/> }
                    { ingredient.name }
                </>
            )
        }
    },{
        Header: 'Action',
        accessor: '_id', 
        className: "actions multiple-btns",
        Cell: ({ row })  => { 
            let ingredient = row.original
            return (
                <>
                    <button type="button" className="btn btn-light btn-edit"
                            onClick={ () => {setSelected(ingredient);setToEdit(true) } }
                    >
                        <EditOrangeIcon />
                    </button>
                    <button type="button" className="btn btn-danger btn-delete"
                            onClick={ () => { setSelected(ingredient);setToDelete(true) } }
                    ><TrashOutlineIcon /></button>
                </>
            )
        }
    }];

    return (
        <>
            {load ? (
                <Loader />
            ) : (
                <div> 
                    <div className="layout-head">
                        <div className="row">
                            <div className="col-6">
                            <h1>Ingrédient</h1>
                            </div>
                            <div className="col">
                                <button type="button" className="modal--btn-validate" onClick={ () => setToAdd(true)  }>
                                    <img alt="plus" src={Plus} /> Ajouter un ingrédient
                                </button>
                            </div>
                        </div>
                    </div>

                    { ingredients.length ? (
                        <div className="layout-content">
                            <div className="block-styled container">
                                <div className="block-styled-body">
                                    <TablePagination columns={tableColumns} data={ingredients} />
                                </div>
                            </div> 
                        </div>
                    ): (
                        <p className="mt-5 mb-5 text-center">Aucune données pour le moments...</p>
                    ) }
                    

                    { toAdd && <AddIngredient storeId={store._id} ingredients={ingredients} showAdd={toAdd} handleCloseAdd={setToAdd} init={setIngredients} /> }
                    { toEdit && <EditIngredient ingredient={selected} showEdit={toEdit} handleCloseEdit={setToEdit} init={setIngredients} /> }
                    { toDelete && <Confirm show={toDelete} handleClose={setToDelete} 
                                            message="Êtes-vous sûr de vouloir supprimer l'ingédient ?" 
                                            handleConfirm={handleDelete} confirmeLoad={load} /> }
                </div>
            )}
        </>
    )
}

export default IngredientsStore