import React, { useState, useEffect } from 'react';
import { Notyf } from 'notyf';
// components
import AddCustomer from './AddCustomer';
import Loader from '~/components/Loader';
import { Switch } from '@material-ui/core';
// Utils
import {
  getCustomerOfStore,
  switchStatutCustomer,
  updatePointsCustomer,
} from '~/api/customer';
// css
import './_clients.scss';

//icons
//import Loop from "~/assets/svg/loop.svg";
//import Close from "~/assets/svg/close-x.svg";
import ValidateIcon from 'src/components/Svgs/Shop/Validate';
import CancelIcon from 'src/components/Svgs/Shop/Cancel';
import EditIcon from 'src/components/Svgs/Shop/Edit';
import Plus from '~/assets/svg/plus.svg';
import TablePagination from '~/assets/table-pagination';

const ClientStore = (props) => {
  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top',
    },
    duration: 3000,
    dismissible: true,
  });

  const store = props.store;
  const [customers, setCustomers] = useState([]);
  const [load, setLoad] = useState(true);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(0);
  const [points, setPoints] = useState(0);
  //const [input, setInput] = useState("");
  const input = '';

  useEffect(() => {
    let storeId = store._id;
    getCustomerOfStore(storeId)
      .then((res) => {
        console.log('customers', res.data.customers);
        setCustomers(res.data.customers);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });

    // eslint-disable-next-line
  }, []);

  const submitEdit = (e) => {
    e.preventDefault();
    setLoad(true);
    updatePointsCustomer(edit, points)
      .then((res) => {
        setCustomers(res.data.customers);
        setLoad(false);
        notyf.success(res.data.message);
        setEdit(0);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };
  const handleAfterAddCustomer = (newCustomer) => {
    setCustomers([...customers, newCustomer]);
  };

  const handleSwitchStatut = (id) => {
    setLoad(true);
    switchStatutCustomer(id)
      .then((res) => {
        setCustomers(res.data.customers);
        setLoad(false);
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const filtered = customers.filter((customer) => {
    return (
      customer._id.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1 ||
      customer.firstName.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
      customer.lastName.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
      customer.user.email.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
  });

  // Pagination
  const tableColumns = [
    {
      Header: 'Statut',
      accessor: 'enable',
      className: 'tdswitch',
      Cell: ({ value, row }) => {
        let customer = row.original;
        return (
          <Switch
            onChange={() => handleSwitchStatut(customer._id)}
            checked={customer.enable}
            color='primary'
            name='enable'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        );
      },
    },
    {
      Header: 'Nom complet',
      accessor: 'fullName',
    },
    /* {
      Header: 'Prénom',
      accessor: 'firstName',
    }, */
    {
      Header: 'Email',
      accessor: (d) => d.user.email,
    },
    {
      Header: 'Tél',
      accessor: 'phone',
    },
    {
      Header: 'Points',
      accessor: 'points',
      Cell: ({ value, row }) => {
        let customer = row.original;
        return (
          <form onSubmit={submitEdit}>
            {edit !== customer._id ? (
              <span>{customer.points}</span>
            ) : (
              <input
                min='0'
                step='1'
                style={{ width: '50px', display: 'inline-block' }}
                type='number'
                value={edit === customer._id ? points : customer.points}
                onChange={(e) => setPoints(e.target.value)}
              />
            )}

            {edit !== customer._id && (
              <button
                type='button'
                className='edit'
                onClick={() => {
                  setEdit(customer._id);
                  setPoints(customer.points);
                }}
              >
                <EditIcon />
              </button>
            )}
            {edit === customer._id && (
              <button type='submit'>
                <ValidateIcon />
              </button>
            )}
            {edit === customer._id && (
              <button type='button' onClick={() => setEdit(0)}>
                <CancelIcon />
              </button>
            )}
          </form>
        );
      },
    },
  ];

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className='text-center page-clients'>
        {add ? (
          <AddCustomer
            storeId={store._id}
            setAdd={setAdd}
            addCustomer={handleAfterAddCustomer}
          />
        ) : (
          <>
            <div className='block-styled container'>
              <div className='block-styled-body'>
                <div className='customers-list'>
                  <div className='row mb-3 d-none'>
                    <div className='col-sm-4'>
                      <button
                        className='supremeDash--addSuper-btn '
                        onClick={() => setAdd(!add)}
                      >
                        <img alt='plus' src={Plus} />{' '}
                        <p>Ajouter un nouveau client</p>
                      </button>
                    </div>
                    <div className='col-sm-4'></div>
                  </div>

                  {filtered.length > 0 ? (
                    <TablePagination columns={tableColumns} data={filtered} />
                  ) : (
                    <p> Aucuns clients</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default ClientStore;
