import React from "react";

const ArrowDownIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr arrow-down">
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.69545 0.750001L9.5 1.54627L5 6L0.5 1.54627L1.30455 0.750002L5 4.40746L8.69545 0.750001Z" fill="#393838"/>
        </svg> 
    </span>
  );
};

export default ArrowDownIcon;
