import React from "react";

const Promotion = (props) => {
  return (
    <span className="sidebar--list-svg">
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M20 6V2C20 0.889999 19.1 0 18 0H2C0.900024 0 0.0100098 0.889999 0.0100098 2V6C1.10999 6 2 6.9 2 8C2 9.1 1.10999 10 0 10V14C0 15.1 0.900024 16 2 16H18C19.1 16 20 15.1 20 14V10C18.9 10 18 9.1 18 8C18 6.9 18.9 6 20 6ZM18 4.54C16.8101 5.23 16 6.53 16 8C16 9.47 16.8101 10.77 18 11.46V14H2V11.46C3.18994 10.77 4 9.47 4 8C4 6.52 3.19995 5.23 2.01001 4.54L2 2H18V4.54ZM11 11H9V13H11V11ZM9 7H11V9H9V7ZM11 3H9V5H11V3Z" 
 fill={props.color}/>
</svg>

    </span>
  );
};

export default Promotion;
