import React, { useState, useRef, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { arrayMove, List } from "react-movable";

//SVG
import dragable from "~/assets/svg/dragable.svg";
import Confirm from "~/components/Commun/Confirm";
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";
import EditOrangeIcon from "~/components/Svgs/Shop/EditOrange";
import ValidateIcon from "~/components/Svgs/Shop/Validate";
import CloseXIcon from "~/components/Svgs/Shop/CloseX";

export default ({
  handleAddTemp,
  temp,
  setTemp,
  handleDeleteTemp,
  getIngredientName,
  dataList,
  setSelectType,
  groupTypes,
  control,
  register,
  groupes,
  setSelections,
}) => {
  let { remove } = useFieldArray({
    control,
    name: `ingredients.groupes`,
  });
  const [groupesOptions, setGroupesOptions] = useState([]);
  const [toDeleteGroupe, setToDeleteGroupe] = useState(null);
  const [toUpdateVariant, setToUpdateVariant] = useState([]);
  const variantDatas = useRef({});

  useEffect(() => {
    setGroupesOptions(groupes);
  }, [groupes]);

  const removeGroupe = (groupe, index) => {
    setGroupesOptions([
      ...groupesOptions,
      groupes.find(
        (item) =>
          item.value === groupe.idIngredient || item.value === groupe.idProduct
      ),
    ]);
    remove(index);
  };

  const updateVariant = (index, id) => {
    if (toUpdateVariant.includes(index)) {
      setToUpdateVariant((currentState) =>
        currentState.filter((state) => state !== index)
      );
    } else {
      setToUpdateVariant((currentState) => [index]);
    }

    variantDatas.current =
      variantDatas.current.id !== id
        ? groupes.find((field) => field.id === id)
        : {};
  };

  const handleChangeField = (e, id) => {
    variantDatas.current.id = e.target.value;
  };

  const validateField = (index, id) => {
    if (variantDatas.current.id) {
      groupes[index].price = variantDatas.current.id;
    }
    setToUpdateVariant([]);
  };

  const handleClose = () => {
    setToUpdateVariant([]);
  };

  const handleTemp = (e) => {
    setTemp(
      temp.typeIngredient === "ingredient"
        ? { ...temp, idIngredient: e.target.value }
        : { ...temp, idProduct: e.target.value }
    );
  };

  return (
    <>
      <tr>
        <td colSpan="5" className="border-0 pl-0 pr-0 pt-0 pb-0">
          <List
            lockVertically
            values={groupes}
            onChange={({ oldIndex, newIndex }) => {
              setSelections(arrayMove(groupes, oldIndex, newIndex));
            }}
            renderList={({ children, props }) => (
              <div className="">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">Nom</th>
                      <th scope="col">Côut</th>
                      <th scope="col" className="actions"></th>
                    </tr>
                  </thead>
                  <tbody {...props}>{children}</tbody>
                </table>
              </div>
            )}
            renderItem={({ value, index, props, isDragged, isSelected }) => {
              const row = (
                <tr
                  {...props}
                  style={{
                    backgroundColor:
                      isDragged || isSelected ? "#ececec" : "#fafafa",
                  }}
                >
                  <td>
                    <button
                      data-movable-handle
                      style={{
                        cursor: isDragged ? "grabbing" : "grab",
                      }}
                    >
                      <img alt="" src={dragable} />
                    </button>
                    <span>
                      {getIngredientName(
                        value.idProduct < 0
                          ? value.idIngredient
                          : value.idProduct || value.idIngredient
                      )}
                      <input
                        style={{
                          border: " 0.5px solid #DEDEDE",
                          outline: "none",
                          borderRadius: "8px",
                        }}
                        type="hidden"
                        {...register(
                          `ingredients.groupes.${index}.idIngredient`
                        )}
                        defaultValue={
                          value.idProduct < 0
                            ? value.idIngredient
                            : value.idProduct || value.idIngredient
                        }
                      />
                    </span>
                  </td>
                  <td>
                    {!toUpdateVariant.includes(index) && `${value.price} €`}
                    <input
                      style={{
                        border: " 0.5px solid #DEDEDE",
                        outline: "none",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                      type={toUpdateVariant.includes(index) ? "" : "hidden"}
                      {...register(`supplement.groupes.${index}.price`)}
                      defaultValue={value.price}
                      onChange={(e) =>
                        handleChangeField(
                          e,
                          value.idProduct < 0
                            ? value.idIngredient
                            : value.idProduct || value.idIngredient
                        )
                      }
                    />
                  </td>
                  <td
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="actions"
                    align="center"
                  >
                    <span>
                      {!toUpdateVariant.includes(index) && (
                        <button
                          style={{
                            boxShadow: "0 3px 8px #e4e4e4",
                            marginRight: "10px",
                          }}
                          className="btn btn-light btn-edit"
                          type="button"
                          onClick={() => updateVariant(index, value.id)}
                        >
                          <EditOrangeIcon />
                        </button>
                      )}
                    </span>
                    {!toUpdateVariant.includes(index) ? (
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() =>
                          handleDeleteTemp(
                            value.idProduct < 0
                              ? value.idIngredient
                              : value.idProduct || value.idIngredient
                          )
                        }
                      >
                        <TrashOutlineIcon />
                      </button>
                    ) : (
                      <span>
                        <button
                          style={{ marginRight: "10px" }}
                          className="btn btn-dark"
                          type="button"
                          onClick={handleClose}
                        >
                          <CloseXIcon color={"#ff0000"} />
                        </button>
                        <button
                          className="btn btn-dark"
                          type="button"
                          onClick={() => {
                            validateField(
                              index,
                              value.idProduct < 0
                                ? value.idIngredient
                                : value.idProduct || value.idIngredient
                            );
                          }}
                        >
                          <ValidateIcon />
                        </button>
                      </span>
                    )}
                  </td>
                </tr>
              );
              return isDragged ? (
                <table
                  className="table dragged-table dragged-top"
                  style={{ ...props.style, borderSpacing: 0 }}
                >
                  <tbody>{row}</tbody>
                </table>
              ) : (
                row
              );
            }}
          />
        </td>
      </tr>

      <div className="add-item">
        <table className="table ingredient-table">
          <tr>
            <td>
              <select
                onChange={(e) => {
                  setSelectType(e.target.value);
                  setTemp({ ...temp, typeIngredient: e.target.value });
                }}
                name="typeIngredient"
              >
                <option value="-1">Selectionner un type</option>
                {groupTypes.map((type, index) => {
                  return (
                    <option key={index} value={type.value}>
                      {type.label}
                    </option>
                  );
                })}
              </select>
            </td>
            <td className="">
              <select onChange={(e) => handleTemp(e)} name="ingredient">
                <option value="-1">Selectionner un ingredient</option>
                {dataList.map((ingredient, index) => {
                  if (
                    groupes.length &&
                    groupes.some(
                      (ingred) =>
                        ingred.idIngredient === ingredient._id ||
                        ingred.idProduct === ingredient._id
                    )
                  ) {
                    return "";
                  }
                  return (
                    <option key={index} value={ingredient._id}>
                      {ingredient.name}
                    </option>
                  );
                })}
              </select>
            </td>
            <td className="">
              <input
                type="number"
                step="0.5"
                min="0"
                name="price"
                value={temp.price}
                onChange={(e) => setTemp({ ...temp, price: e.target.value })}
              />
            </td>
            <td className="actions">
              <button
                type="button"
                className="btn btn-warning btn-add"
                onClick={handleAddTemp}
              >
                +
              </button>
            </td>
          </tr>
        </table>
      </div>
      {toDeleteGroupe && (
        <Confirm
          show={toDeleteGroupe}
          handleClose={setToDeleteGroupe}
          message="Êtes-vous sûr de vouloir supprimer ce groupe ?"
          handleConfirm={() => {
            removeGroupe(toDeleteGroupe.value, toDeleteGroupe.index);
            setToDeleteGroupe(false);
          }}
          confirmeLoad={false}
        />
      )}
    </>
  );
};
