import React, { useState } from "react";
import { Notyf } from "notyf";
import { Switch } from "@material-ui/core";

// Components
import Loader from "~/components/Loader";
import LivraisonGmap from "./map";

// Utils
import { updateStoreConfig } from "~/api/stores";
import { useEffect } from "react";
import { verifiedStuartAddress } from "~/api/stuart";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
  duration: 3000,
  dismissible: true,
});

const LivraisonCommande = (props) => {
  const [load, setLoad] = useState(false);
  const store = props.store;

  const [livraison, setLivraison] = useState({
    ...store.config.commande.livraison,
    deliveryLinks: store.config.commande.livraison.deliveryLinks || {
      uber: "",
      deliveroo: "",
      justEat: "",
    },
  });

  const [showMessage, setShowMessage] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const saveZones = (datas) => {
    setLoad(true);
    let commandeConfig = {
      ...store.config.commande,
      livraison: datas,
    };

    setLivraison(datas);

    updateStoreConfig(store._id, "commande", commandeConfig)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const updateConfig = (e) => {
    let reg = /^(http|https):\/\/[^ "]+$/;
    e.preventDefault();
    if (!livraison.enable) {
      if (
        !livraison.deliveryLinks.uber &&
        !livraison.deliveryLinks.deliveroo &&
        !livraison.deliveryLinks.justEat
      ) {
        notyf.error("Veuillez preciser le lien de livraison");
        return;
      } else if (
        !reg.test(livraison.deliveryLinks.uber) &&
        !reg.test(livraison.deliveryLinks.deliveroo) &&
        !reg.test(livraison.deliveryLinks.justEat)
      ) {
        notyf.error("Le lien n'est pas valide");
        return;
      }
    }

    setLoad(true);

    let commandeConfig = {
      ...store.config.commande,
      livraison,
    };

    updateStoreConfig(store._id, "commande", commandeConfig)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setTimeout(function () {
          window.location.reload();
        }, 600);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const { enable } = livraison;

  useEffect(() => {
    const verifiedAddress = async () => {
      try {
        setLoad(true);
        const ok = await verifiedStuartAddress(
          store.identity,
          store.config.services.stuart
        );

        if (!ok) {
          setShowMessage(true);
        } else {
          setShowMessage(false);
        }
        setLoad(false);
      } catch (error) {
        notyf.error("La verification de votre addresse sur stuart a échouée");
        setLoad(false);
      }
    };

    if (enable) {
      verifiedAddress();
    }
  }, [enable, store]);

  if (showMap)
    return (
      <LivraisonGmap
        store={store}
        handleShowMap={setShowMap}
        livraison={livraison}
        setLivraison={setLivraison}
        submit={saveZones}
      />
    );
  else
    return (
      <div className="container">
        {load ? (
          <Loader />
        ) : (
          <div className="row gx-5 justify-content-between  page-type-commande">
            <div className="col">
              <div className="block-styled">
                <h1 className="text-center block-styled-head">
                  Vente en livraison
                </h1>
                <div className="block-styled-body carde">
                  {showMessage && (
                    <p style={{ color: "red" }}>
                      Votre restaurant n'est pas dans la zone de ramassage de
                      stuart
                    </p>
                  )}
                  <div className="cmd-choix d-flex">
                    <div>
                      <strong>Offrez-vous la vente en livraison ?</strong>
                    </div>
                    <div>
                      <span>
                        <span
                          className={
                            livraison.enable ? "option active" : "option"
                          }
                          onClick={() =>
                            setLivraison({ ...livraison, enable: true })
                          }
                        >
                          Oui
                        </span>
                        <span
                          className={
                            !livraison.enable ? "option active" : "option"
                          }
                          onClick={() =>
                            setLivraison({ ...livraison, enable: false })
                          }
                        >
                          Non
                        </span>
                      </span>
                    </div>
                  </div>

                  {livraison.enable && (
                    <>
                      <div className="cmd-choix d-flex">
                        <div>
                          <strong>
                            Permettez vous les pré commande en dehors des
                            horaires ?
                          </strong>
                        </div>
                        <div>
                          <span>
                            <span
                              className={
                                livraison.enablePreCommande
                                  ? "option active"
                                  : "option"
                              }
                              onClick={() =>
                                setLivraison({
                                  ...livraison,
                                  enablePreCommande: true,
                                })
                              }
                            >
                              Oui
                            </span>
                            <span
                              className={
                                !livraison.enablePreCommande
                                  ? "option active"
                                  : "option"
                              }
                              onClick={() =>
                                setLivraison({
                                  ...livraison,
                                  enablePreCommande: false,
                                })
                              }
                            >
                              Non
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className="cmd-choix">
                        <div className="input-group mb-3 d-flex align-items-center">
                          <span className="mr-3">
                            Les commandes doivent être faites :
                          </span>
                          <input
                            type="number"
                            className="form-control oneNumber"
                            value={livraison.preCommandeMin}
                            step="15"
                            min="15"
                            max="1380"
                            onChange={(e) =>
                              setLivraison({
                                ...livraison,
                                preCommandeMin: e.target.value,
                              })
                            }
                          />
                          <span className="input-group-text">min</span>
                          <span className="ml-3">en avance</span>
                        </div>
                      </div>

                      <div className="cmd-choix mb-5">
                        <div className="input-group d-flex align-items-center">
                          <span className="mr-3">
                            Les commandes ne peuvent etre faites&nbsp;:
                          </span>
                          <input
                            type="number"
                            className="form-control oneNumber"
                            min="0"
                            max="7"
                            value={livraison.preCommandeDay}
                            onChange={(e) =>
                              setLivraison({
                                ...livraison,
                                preCommandeDay: e.target.value,
                              })
                            }
                          />
                          <span className="input-group-text">jours</span>
                          <span className="ml-3">en avance</span>
                        </div>
                      </div>

                      <div className="row gx-5 btns-toggle justify-content-between">
                        <div className="col">
                          <strong>
                            Quels moyens de paiement pour la vente à livraison ?
                          </strong>
                          <p>
                            Activez seulement les paiements en CB si vous
                            souhaité faire appel au prestataire de livraison
                            Stuart. Il ne récupérera pas l’espèce de vos
                            clients.
                          </p>
                          <div className="input">
                            <Switch
                              onChange={(e) =>
                                setLivraison({
                                  ...livraison,
                                  paiement: {
                                    ...livraison.paiement,
                                    canCB: !livraison.paiement.canCB,
                                  },
                                })
                              }
                              checked={livraison.paiement.canCB}
                              color="primary"
                              name="canCB"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            Paiement en ligne par CB
                          </div>
                          <div className="input">
                            <Switch
                              onChange={(e) =>
                                setLivraison({
                                  ...livraison,
                                  paiement: {
                                    ...livraison.paiement,
                                    canES: !livraison.paiement.canES,
                                  },
                                })
                              }
                              checked={livraison.paiement.canES}
                              color="primary"
                              name="canES"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            Paiement en espece
                          </div>
                          <div className="input">
                            <Switch
                              onChange={(e) =>
                                setLivraison({
                                  ...livraison,
                                  paiement: {
                                    ...livraison.paiement,
                                    canCR: !livraison.paiement.canCR,
                                  },
                                })
                              }
                              checked={livraison.paiement.canCR}
                              color="primary"
                              name="canCR"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            Titres restaurant dématérialisés
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {!livraison.enable && (
                    <div className="delivery--links">
                      <input
                        type="text"
                        value={livraison.deliveryLinks?.uber}
                        onChange={(e) => {
                          setLivraison({
                            ...livraison,
                            deliveryLinks: {
                              ...livraison.deliveryLinks,
                              uber: e.target.value,
                            },
                          });
                        }}
                        placeholder="Uber"
                      />
                      <input
                        type="text"
                        value={livraison.deliveryLinks?.deliveroo}
                        onChange={(e) => {
                          setLivraison({
                            ...livraison,
                            deliveryLinks: {
                              ...livraison.deliveryLinks,
                              deliveroo: e.target.value,
                            },
                          });
                        }}
                        placeholder="Deliveroo"
                      />
                      <input
                        type="text"
                        value={livraison.deliveryLinks?.justEat}
                        onChange={(e) => {
                          setLivraison({
                            ...livraison,
                            deliveryLinks: {
                              ...livraison.deliveryLinks,
                              justEat: e.target.value,
                            },
                          });
                        }}
                        placeholder="JustEat"
                      />
                    </div>
                  )}

                  <div className="text-center mb-5">
                    <button
                      type="button"
                      className="modal--btn-validate mt-3"
                      onClick={updateConfig}
                    >
                      Validez
                    </button>
                  </div>

                  {livraison.enable && (
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => {
                          setShowMap(true);
                        }}
                      >
                        Configurer les zones de livraison
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default LivraisonCommande;
