import instanceAxios from './axios.instance';
import { BASE_URI } from '~/assets/base';

export const getMedias = async (enseigneId) => {
  const response = await instanceAxios.get(`${BASE_URI}medias/${enseigneId}`);
  return response;
};

export const saveMedia = async (enseignId, filePath) => {
  const response = await instanceAxios.post(
    `${BASE_URI}medias/save/${enseignId}`,
    { filePath: filePath }
  );
  return response;
};

export const deleteMedia = async (enseigneId, fileName, filePath) => {
  const response = await instanceAxios.post(
    `${BASE_URI}medias/delete/${enseigneId}`,
    { folder: 'medias', filename: fileName, filepath: filePath }
  );
  return response;
};
