import React, { useState } from 'react';

// Components
import ChoixType from './ChoixType';
import BaseData from './BaseData';
import AdvancesData from './types';
// Utils
import { typePromotion } from '~/constants/global';
import initialPromotion from '~/constants/initialPromotion';

const SinglePromotion = (props) => {
  const defaultPromotion = props.current ? props.current : initialPromotion;

  const [step, setStep] = useState(props.step);
  const [promotion, setPromotion] = useState(defaultPromotion);

  const handleChangeType = (value) => {
    setPromotion({ ...promotion, type: value });
  };

  const handleBaseData = (datas) => {
    //console.log('dsz', datas.pathImage);
    let Image = datas.pathImage;
    setPromotion({
      ...promotion,
      name: datas.name,
      description: datas.description,
      pathImage: Image,
    });
    setStep(step + 1);
  };

  const handleAdvancesData = (datas) => {
    //console.log('d', datas);
    setPromotion(initialPromotion);
    props.submit(datas);
  };

  const goBack = () => {
    if (props.action === 'edit' && step === 2) {
      props.show();
    } else {
      setStep(step - 1);
    }
  };

  return (
    <div className='block-styled single-promotion'>
      <div className='block-styled-body carde'>
        <div className='promotion-box'>
          {step === 1 && (
            <ChoixType
              handleType={handleChangeType}
              defaultType={promotion.type}
            />
          )}

          {step === 2 && (
            <BaseData
              baseDatas={handleBaseData}
              title={typePromotion[promotion.type]}
              back={goBack}
              promotion={promotion}
            />
          )}

          {step === 3 && (
            <AdvancesData
              advanceDatas={handleAdvancesData}
              title={typePromotion[promotion.type]}
              back={() => setStep(step - 1)}
              promotion={promotion}
              store={props.store}
            />
          )}

          {step === 1 && (
            <div className='form-group steps-controlers'>
              <div>
                <button
                  className='btn btn-dark'
                  type='button'
                  onClick={() => props.show()}
                >
                  Retour
                </button>
              </div>
              <div>
                <button
                  className='btn btn-warning'
                  type='button'
                  onClick={() => setStep(step + 1)}
                >
                  Suivant   
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SinglePromotion;
