import React from "react";

const EditOrangeIcon = (props) => {
  return (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.1751 1.38916L17.7669 5.38576L5.82817 15.7769H1.23633V11.7803L13.1751 1.38916Z" stroke="#E08005" strokeLinecap="round" strokeLinejoin="round"/>
    </svg> 
  );
};

export default EditOrangeIcon;
