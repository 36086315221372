import React from "react";

const CouponCodeIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr">
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M17.8911 1.24023L16.856 0.205078C16.8192 0.16873 16.7697 0.14834 16.718 0.14834C16.6664 0.14834 16.6168 0.16873 16.5801 0.205078L1.10889 15.6714C1.07254 15.7081 1.05215 15.7577 1.05215 15.8093C1.05215 15.861 1.07254 15.9106 1.10889 15.9473L2.14404 16.9824C2.21973 17.0581 2.34424 17.0581 2.41992 16.9824L17.8887 1.51367C17.9668 1.44043 17.9668 1.31592 17.8911 1.24023ZM4.42188 7.03125C6.36035 7.03125 7.9375 5.4541 7.9375 3.51562C7.9375 1.57715 6.36035 0 4.42188 0C2.4834 0 0.90625 1.57715 0.90625 3.51562C0.90625 5.4541 2.4834 7.03125 4.42188 7.03125ZM4.42188 1.75781C5.39111 1.75781 6.17969 2.54639 6.17969 3.51562C6.17969 4.48486 5.39111 5.27344 4.42188 5.27344C3.45264 5.27344 2.66406 4.48486 2.66406 3.51562C2.66406 2.54639 3.45264 1.75781 4.42188 1.75781ZM14.5781 10.1562C12.6396 10.1562 11.0625 11.7334 11.0625 13.6719C11.0625 15.6104 12.6396 17.1875 14.5781 17.1875C16.5166 17.1875 18.0938 15.6104 18.0938 13.6719C18.0938 11.7334 16.5166 10.1562 14.5781 10.1562ZM14.5781 15.4297C13.6089 15.4297 12.8203 14.6411 12.8203 13.6719C12.8203 12.7026 13.6089 11.9141 14.5781 11.9141C15.5474 11.9141 16.3359 12.7026 16.3359 13.6719C16.3359 14.6411 15.5474 15.4297 14.5781 15.4297Z" fill="black"/>
        </svg> 
    </span>
  );
};

export default CouponCodeIcon;
