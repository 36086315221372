export const getPathInternePages = () => {
  return [
    "styles/banner",
    "styles/logo",
    "styles/variants",
    "styles/colors",
    "styles/products",
    "styles/mobile",
  ];
};

export const isPathInternePages = (path) => {
  let exist = false;
  getPathInternePages().forEach((item) => {
    if (path.includes(item)) {
      exist = true;
    }
  });

  return exist;
};

export const isEnseignepage = (path) => {
  return path.includes("enseigne");
};

export const isBoutiquepage = (path) => {
  return path.includes("store");
};

export const serialiseStore = (datas) => {
  return {
    intitule: datas.intitule,
    status: "off",
    sendMail: datas.sendMail,
    identity: {
      phone: datas.phone,
      street: datas.street,
      zip: datas.zip,
      city: datas.city,
      lat: datas.lat,
      lng: datas.lng,
    },
    manager: {
      firstname: datas.managerFirstname,
      lastname: datas.managerLastname,
    },
    vad: datas.vad,
  };
};

export const dateToTimes = (date) => {
  return new Date(date).getTime();
};

export const GetFormattedDate = (date) => {
  var todayTime = new Date(date);
  var month = ("0" + (todayTime.getMonth() + 1)).slice(-2);
  var day = ("0" + todayTime.getDate()).slice(-2);
  var year = todayTime.getFullYear();
  return year + "-" + month + "-" + day;
};
/**
 *
 * get name of file/image without part added to name
 */
export const getRealFileName = (fileName) => {
  let nameParts = fileName.split("-");

  return fileName.replace(nameParts[0] + "-", "").substring(0, 20);
};
/**
 * Check if array of object has an element
 */
export const hasValueInArrayObject = (obj, attrib, val) => {
  let pos = -1;
  pos = obj.findIndex(function (row, index) {
    if (row[attrib] === val) {
      return true;
    }
    return false;
  });

  return pos > -1 ? true : false;
};

export const generateCode = (length) => {
  var result = [];
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
};

export const getTime = (date) => {
  return date.getHours() + ":" + date.getMinutes();
};

export const getDate = (date) => {
  return date.toLocaleDateString();
};

export const showPrice = (price, devis = "€") => {
  return formatNumber(parseFloat(price)) + devis;
};

const formatNumber = (number) => {
  number = number.toFixed(2) + "";
  let x = number.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "," + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    //x1 = x1.replace(rgx, '$1' + ',' + '$2');
    x1 = x1.replace(rgx, `$1 $2`);
  }
  return x1 + x2;
};

export const getShippingLabel = (value) => {
  switch (value) {
    case "livraison":
      return "En livraison";
    case "emporter":
      return "Emporter";
    case "place":
      return "Sur place";
    default:
      break;
  }
};
export const getBillingLabel = (value) => {
  switch (value) {
    case "carte":
      return "Carte bancaire";
    case "cheque":
      return "Cheque bancaire";
    case "espece":
      return "En espece";
    default:
      break;
  }
};
