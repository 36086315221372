import React from "react";
import { Modal } from "react-bootstrap";

import closeIcon from "~/assets/svg/close.svg";

const DeleteModal = (props) => {
  return (
    <Modal className="modal" show={props.show} onHide={() => props.setShow()}>
      <Modal.Header>
        <h1 class="modal--title">Confirmation</h1>
        <img
          onClick={() => props.setShow()}
          className="modal--close cursor"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>
      <Modal.Body>
        <p className="modal--warning-p">
          Êtes-vous sûr de vouloir supprimé cette enseigne ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
            className="modal--btn-close"
            onClick={() => props.setShow()}
          >
            Annuler
          </button>
          <button
            className="modal--btn-validate"
            onClick={() => props.handleTerminate()}
          >
            {props.load ? (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Résilier"
            )}
          </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
