import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Components
import Imgs from "./imgs";
import Paiement from "./paiement";
import Scannage from "./scannage";
import Reception from "./reception";
import CommandMode from "./commandMode";
import Printer from "./printer";

const Bornes = (props) => {
  return (
    <Switch>
      <Route exact path='/store/bornes'>
        <Redirect to='/store/bornes/img' />
      </Route>
      <Route
        exact
        path='/store/bornes/img'
        component={() => (
          <Imgs
            store={props.store}
            terminal={props.terminal}
            setTerminal={props.setTerminal}
          />
        )}
      />
      <Route
        exact
        path='/store/bornes/paiement'
        component={() => (
          <Paiement
            store={props.store}
            terminal={props.terminal}
            setTerminal={props.setTerminal}
          />
        )}
      />
      <Route
        exact
        path='/store/bornes/scannage'
        component={() => (
          <Scannage
            store={props.store}
            terminal={props.terminal}
            setTerminal={props.setTerminal}
          />
        )}
      />
      <Route
        exact
        path='/store/bornes/recovery'
        component={() => (
          <Reception
            store={props.store}
            terminal={props.terminal}
            setTerminal={props.setTerminal}
          />
        )}
      />
      <Route
        exact
        path='/store/bornes/commandMode'
        component={() => (
          <CommandMode
            store={props.store}
            terminal={props.terminal}
            setTerminal={props.setTerminal}
          />
        )}
      />
      <Route
        exact
        path='/store/bornes/printer'
        component={() => (
          <Printer
            store={props.store}
            terminal={props.terminal}
            setTerminal={props.setTerminal}
          />
        )}
      />
    </Switch>
  );
};

export default Bornes;
