import React from "react";

const ValidateIcon = (props) => {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.83367 9.1245L13.5 0L14.7962 1.17833L5.83367 11.4812L0 6.17783L1.29617 4.99949L5.83367 9.1245Z" fill="#48AB0B"/>
    </svg> 
  );
};

export default ValidateIcon;
