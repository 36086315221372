import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Notyf } from "notyf";
// Component
import Select from "react-select";
import Switch from "@material-ui/core/Switch";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import VariantsArray from "./variants/VariantsArray";
import Supplements from "./supplements/Supplement";
// Utils
import initProduit from "~/constants/initProduct";
import days from "~/constants/days";
import { createProduit, updateProduit } from "~/api/produits";
//import { uploadFile } from '~/api/upload';
//svg
import upload from "~/assets/svg/upload-lg.svg";
import { useLoadMedia } from "~/hooks/loadMedia";

const Produit = (props) => {
  const notyf = new Notyf({
    position: { x: "center", y: "top" },
    duration: 3000,
    dismissible: true,
  });

  let defaultValues = props.produit ? props.produit : initProduit;
  const action = props.action;
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ defaultValues });
  const [selectedTime, setSelectedTime] = useState({
    start: new Date(new Date().setHours(9, 0, 0)),
    end: new Date(new Date().setHours(23, 59, 59)),
  });
  const [load, setLoad] = useState(false);
  const [image, setImage] = useState("");
  const [loadImage, setLoadImage] = useState(false);
  //const [icon, setIcon] = useState({});
  const [categories, setCategories] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [envies, setEnvies] = useState([]);
  const [allergenes, setAllergenes] = useState([]);
  const [sections, setSections] = useState([]);
  const [groupes, setGroupes] = useState([]);
  const [multiPriceType, setMultiPricetype] = useState("emporter/place");

  const horairePrecise = watch("horairePrecise");
  const removeIngredient = watch("removeIngredient.enable");
  const multiChoice = watch("multiChoice");
  const multiPrice = watch("multiPrice");
  const sendToKitcken = watch("sendToKitcken");

  const productPriceType =
    (multiPrice && multiPriceType === "emporter/place") || !multiPrice
      ? "priceGlobal"
      : "secondPrice";

  const TVA = [
    { value: 0, label: "Taux de tva" },
    { value: 5.5, label: "5,5%" },
    { value: 10, label: "10%" },
    { value: 20, label: "20%" },
  ];

  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    setLoadMedia,
    Media,
  } = useLoadMedia({
    produit: {
      boolean: loadImage,
      action: setLoadImage,
      name: "produit",
      updateValue: setImage,
    },
  });

  useEffect(() => {
    let categorieOptions = props.categories.map((categorie) => {
      if (categorie.enable) {
        return { value: categorie._id, label: categorie.name };
      }
      return null;
    });
    setCategories(categorieOptions);

    let enviesOptions = props.envies.map((envie) => {
      if (envie.enable) {
        return { value: envie._id, label: envie.name };
      }
      return null;
    });
    setEnvies(enviesOptions);

    let allergenesOptions = props.allergenes.map((allergene) => {
      return { value: allergene._id, label: allergene.name };
    });
    setAllergenes(allergenesOptions);

    let ingredientsOptions = props.ingredients.map((ingredient) => {
      return { value: ingredient._id, label: ingredient.name };
    });
    setIngredients(ingredientsOptions);

    let groupesOptions = props.groupes.map((groupe) => {
      return { value: groupe._id, label: groupe.name };
    });
    setGroupes(groupesOptions);

    let sectionsOptions = props?.sections?.map((section) => {
      if (section.enable) {
        return { value: section._id, label: section.name };
      }
      return null;
    });
    setSections(sectionsOptions);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (action === "update") {
      // init champs time cas update
      if (defaultValues.horairePrecise.time) {
        var today = new Date();
        let Istart =
          defaultValues.horairePrecise.time.start?.toString() || "09:00";
        let Iend = defaultValues.horairePrecise.time.end?.toString() || "23:59";

        let start = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDay(),
          Istart.split(":")[0],
          Istart.split(":")[1],
          0
        );
        let end = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDay(),
          Iend.split(":")[0],
          Iend.split(":")[1],
          0
        );

        setValue("horairePrecise.time", {
          ...defaultValues.horairePrecise.time,
          start: getTime(start),
          end: getTime(end),
        });
        setSelectedTime({ ...selectedTime, start, end });
      }
    }
    // eslint-disable-next-line
  }, []);

  /*  const handleUpload = (e) => {
    let target = e.target;
    let imageFile = target.files[0];
    let file = new FormData();
    file.append('file', imageFile);

    //var dim = { width: 1024, height: 1024 };

    if (!imageFile) {
      notyf.error('Veuillez sélectionner une image.');
    } else if (!imageFile.name.match(/\.(jpg|jpeg|png|svg|gif)$/)) {
      notyf.error('Veuillez sélectionner une image valide. ');
    } else if (target.files[0].size > 2097152) {
      notyf.error("La taille de l'image doit être inférieure à 2 Mo !");
    } else {
      let _URL = window.URL;
      let img = new Image();
      img.onload = function () {
        setIcon(file);
        setImage(URL.createObjectURL(file.get('file')));
      };
      img.src = _URL.createObjectURL(imageFile);
    }
  }; */

  const getTime = (date) => {
    return date.toLocaleTimeString().slice(0, 5);
  };

  const checkChecked = (array, val) => {
    if (!array) return;
    const newArray = Array.isArray(array) ? array : [array];
    let checked = false;
    newArray.map((i) => {
      if (i.toString() === val.toString()) {
        checked = true;
      }
      return true;
    });

    return checked;
  };

  const handleTime = (ref, e) => {
    if (!e) return;
    let horaire = getValues("horairePrecise.time");
    if (ref === "start") {
      setSelectedTime({ ...selectedTime, start: e });
      setValue("horairePrecise.time", { ...horaire, start: getTime(e) });
    } else {
      setSelectedTime({ ...selectedTime, end: e });
      setValue("horairePrecise.time", { ...horaire, end: getTime(e) });
    }
  };

  const handleUpdatePoduct = (data) => {
    defaultValues = data;
  };

  const onSubmit = (data) => {
    if (props.action === "add") {
      let exists = props.produits.find((item) => {
        return item.name.toLowerCase() === data.name.toLowerCase();
      });
      if (exists) {
        notyf.error("Ce produit est déjà ajouté");
        return;
      }
    }

    let error = "";
    if (data.multiChoice) data.priceGlobal = 0;
    let { multiChoice, variantes, idCategorie, priceGlobal } = data;

    if (!idCategorie) {
      error = "Merci de choisir la catégorie.";
    } else if (multiChoice && variantes.length < 2) {
      error =
        "Un choix multiple doit avoir au moins deux groupes de composition.";
    } else if (!multiChoice && !priceGlobal) {
      error = "Merci de spécifier le prix de produit.";
    }

    if (error !== "") {
      notyf.error(error);
      return false;
    }

    setLoad(true);

    (async function todo() {
      let imageProduit = image.produit;
      /*  if (icon.constructor.name === 'FormData') {
        await uploadFile('products', icon)
          .then((res) => {
            imageProduit = res.data.filename;
          })
          .catch((err) => {
            notyf.error(err.message);
          });
      } */
      let menuActive = localStorage.getItem("menuActive");
      let datas = {
        ...data,
        imageUrl: imageProduit,
        storeId: props.storeId,
        menuId: menuActive,
      };
      if (action === "add") {
        await createProduit(datas)
          .then((res) => {
            let produits = res.data.produits;
            props.init(produits);
            notyf.success(res.data.message);
          })
          .catch((err) => {
            notyf.error(err.message);
          });
      } else {
        await updateProduit(datas)
          .then((res) => {
            props.init(res.data.produits);
            notyf.success(res.data.message);
          })
          .catch((err) => {
            notyf.error(err.message);
          });
      }

      setLoad(false);
      props.handleCloseAdd(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    })().catch((err) => {
      setLoad(false);
      if (err.message) notyf.error(err.message);
    });
  };

  return (
    <>
      {loadMedia ? (
        <Media
          setLoadMedia={setLoadMedia}
          handleSelectedMedia={handleSelectedMedia}
        />
      ) : (
        <div className="form-add-produit">
          <div className="block-styled">
            <h1 className="block-styled-head align-left">
              <i
                className="fas fa-arrow-left"
                onClick={() => props.handleCloseAdd(false)}
              ></i>
              Produits
            </h1>
            <div className="block-styled-body carde">
              <form className="add-produit" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group align-right">
                  <label htmlFor="enable">Activé</label>
                  <Controller
                    name="enable"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        color="primary"
                      />
                    )}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">Nom du produit *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p className="text-danger">{errors.name.message}</p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="name">Description</label>
                  <textarea
                    rows="5"
                    className="form-control"
                    id="description"
                    name="description"
                    {...register("description", { required: false })}
                    placeholder="ex :  salade tomate oignon"
                  ></textarea>
                </div>

                <div className="form-group ">
                  <p>Sélectionnez une photo carré (max 600x600)</p>
                  {defaultValues.imageUrl && (
                    <img
                      alt="produit"
                      src={defaultValues.imageUrl}
                      className="mt-3"
                      style={{ width: "100px" }}
                    />
                  )}
                  <div className="styles--body-upload">
                    {/*  <input
                      type='file'
                      id='upload--logo'
                      onChange={handleUpload}
                      name='logo'
                    /> */}
                    <label htmlFor="upload--logo">
                      <img
                        alt="upload-file"
                        name="produit"
                        src={upload}
                        onClick={handleMedia}
                      />
                    </label>
                    {image !== "" && (
                      <img src={image.produit} alt="" style={{ width: 100 }} />
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="categorie">Catégorie du produit *</label>
                  <Controller
                    name="idCategorie"
                    control={control}
                    options={categories}
                    defaultValue={
                      props.category
                        ? {
                            value: props.category._id,
                            label: props.category.name,
                          }
                        : categories[0]
                    }
                    render={({ field }) => {
                      if (props.category && field.value === "") {
                        field.onChange(props.category._id);
                      }
                      return (
                        <Select
                          onChange={(e) => field.onChange(e.value)}
                          className="basic-single"
                          classNamePrefix="select"
                          value={categories.find(
                            (cat) => cat.value === field.value
                          )}
                          defaultValue={
                            props.category
                              ? {
                                  value: props.category._id,
                                  label: props.category.name,
                                }
                              : {}
                          }
                          options={categories}
                        />
                      );
                    }}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="tva">TVA</label>
                  <Controller
                    name="tva"
                    control={control}
                    defaultValue={TVA[0].value}
                    render={({ field }) => {
                      return (
                        <Select
                          onChange={(e) => field.onChange(e.value)}
                          className="basic-single"
                          classNamePrefix="select"
                          value={TVA.find((tva) => tva.value === field.value)}
                          defaultValue={TVA[0].value}
                          options={TVA}
                        />
                      );
                    }}
                  />
                </div>
                <div className="alert alert-secondary">
                  <span>Visibilité</span>
                </div>
                <div className="form-group">
                  <Controller
                    name="visibilite.canEmporter"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        color="primary"
                      />
                    )}
                  />
                  <label htmlFor="canEmporter">A emporter</label>
                </div>
                <div className="form-group">
                  <Controller
                    name="visibilite.canLivraison"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        color="primary"
                      />
                    )}
                  />
                  <label htmlFor="canLivraison">Livraison</label>
                </div>
                <div className="form-group">
                  <Controller
                    name="visibilite.canPlace"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        color="primary"
                      />
                    )}
                  />
                  <label htmlFor="canPlace">Sur place</label>
                </div>

                <div className="row mb-4  align-items-center choice-toggle">
                  <div className="col-6 text-left">
                    <p>le produit est il visible a une horaire precise ?</p>
                  </div>
                  <div className="col-3 text-right">
                    <div className="cmd-choix">
                      <input
                        type="hidden"
                        {...register(`horairePrecise.enable`)}
                      />
                      <span>
                        <span
                          className={
                            horairePrecise.enable ? "option active" : "option"
                          }
                          onClick={() =>
                            setValue("horairePrecise.enable", true)
                          }
                        >
                          Oui
                        </span>
                        <span
                          className={
                            !horairePrecise.enable ? "option active" : "option"
                          }
                          onClick={() =>
                            setValue("horairePrecise.enable", false)
                          }
                        >
                          Non
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                {horairePrecise.enable && (
                  <>
                    <div className="row mb-4 days">
                      {days.map((day, index) => {
                        return (
                          <div
                            key={index}
                            className="custom-control custom-checkbox custom-control-inline"
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={index}
                              name="horairePrecise.days"
                              value={day.value}
                              checked={checkChecked(
                                horairePrecise.days,
                                day.value
                              )}
                              {...register("horairePrecise.days", {
                                valueAsNumber: true,
                              })}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={index}
                            >
                              {day.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="form-group TimePickerContainer">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <label className="mr-4">Entre : </label>
                        <TimePicker
                          value={selectedTime.start}
                          onChange={(e) => handleTime("start", e)}
                          okLabel="OK"
                          clearLabel="Effacer"
                          cancelLabel="Annuler"
                          clearable
                          ampm={false}
                          label="24 hours"
                        />
                        <label className="mx-4">ET </label>
                        <TimePicker
                          value={selectedTime.end}
                          onChange={(e) => handleTime("end", e)}
                          okLabel="OK"
                          clearLabel="Effacer"
                          cancelLabel="Annuler"
                          clearable
                          ampm={false}
                          label="24 hours"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </>
                )}

                <div className="alert alert-secondary">
                  <span>Option</span>
                </div>

                <div className="row mb-4 align-items-center choice-toggle">
                  <div className="col-6 text-left">
                    <p>
                      Souhaitez vous donner la possibilité au client d’enlever
                      un ingrédient
                    </p>
                  </div>
                  <div className="col-3 text-right">
                    <div className="cmd-choix">
                      <input
                        type="hidden"
                        {...register(`removeIngredient.enable`)}
                      />
                      <span>
                        <span
                          className={
                            removeIngredient ? "option active" : "option"
                          }
                          onClick={() =>
                            setValue("removeIngredient.enable", true)
                          }
                        >
                          Oui
                        </span>
                        <span
                          className={
                            !removeIngredient ? "option active" : "option"
                          }
                          onClick={() =>
                            setValue("removeIngredient.enable", false)
                          }
                        >
                          Non
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                {removeIngredient && (
                  <>
                    <div className="form-group options">
                      <Controller
                        name="removeIngredient.blacklist"
                        control={control}
                        render={({ field }) => (
                          <Select
                            value={ingredients.filter((ing) =>
                              field.value.includes(ing.value)
                            )}
                            onChange={(e) =>
                              field.onChange(e.map((elem) => elem.value))
                            }
                            isMulti
                            options={ingredients}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        )}
                      />
                    </div>
                  </>
                )}

                <div className="alert alert-secondary">
                  <span>Groupe de composition</span>
                </div>

                <div className="row mb-4  align-items-center choice-toggle">
                  <div className="col-6 text-left">
                    <p>Le prix du produit en livraison est il different ?</p>
                  </div>
                  <div className="col-3 text-right">
                    <div className="cmd-choix">
                      <input type="hidden" {...register(`multiPrice`)} />
                      <span>
                        <span
                          className={multiPrice ? "option active" : "option"}
                          onClick={() => setValue("multiPrice", true)}
                        >
                          Oui
                        </span>
                        <span
                          className={!multiPrice ? "option active" : "option"}
                          onClick={() => setValue("multiPrice", false)}
                        >
                          Non
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-4  align-items-center choice-toggle">
                  <div className="col-6 text-left">
                    <p>Le produit a t’il un choix multiple</p>
                  </div>
                  <div className="col-3 text-right">
                    <div className="cmd-choix">
                      <input type="hidden" {...register(`multiChoice`)} />
                      <span>
                        <span
                          className={multiChoice ? "option active" : "option"}
                          onClick={() => setValue("multiChoice", true)}
                        >
                          Oui
                        </span>
                        <span
                          className={!multiChoice ? "option active" : "option"}
                          onClick={() => setValue("multiChoice", false)}
                        >
                          Non
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                {multiPrice && (
                  <div className="row alert alert-secondary multiPrice">
                    <div
                      className={`col-6 ${
                        multiPriceType === "emporter/place" ? "active" : ""
                      }`}
                      onClick={() => setMultiPricetype("emporter/place")}
                    >
                      <p>Prix sur place / a emporter</p>
                    </div>
                    <div
                      className={`col-6 ${
                        multiPriceType === "livraison" ? "active" : ""
                      }`}
                      onClick={() => setMultiPricetype("livraison")}
                    >
                      <p>Prix en livraison</p>
                    </div>
                  </div>
                )}

                {!multiChoice && (
                  <>
                    <div className="form-group mb-4">
                      <label htmlFor={productPriceType}>Prix *</label>
                      <input
                        className="form-control"
                        type="number"
                        name={productPriceType}
                        id={productPriceType}
                        value={watch(productPriceType)}
                        min="0"
                        step="0.01"
                        {...register(productPriceType)}
                      />
                    </div>
                  </>
                )}

                <div className="row xmb-2">
                  <div className="col">
                    <p className="colored_orange inlabel">
                      Ajoutez les groupes de composition obligatoire{" "}
                    </p>
                  </div>
                </div>

                <div
                  className={`mb-4 variants ${
                    multiChoice ? "multiChoice" : ""
                  }`}
                >
                  <VariantsArray
                    handleUpdatePoduct={handleUpdatePoduct}
                    action={action}
                    {...{ control, register, getValues, setValue, errors }}
                    groupes={groupes}
                    multiChoice={multiChoice}
                    product={defaultValues}
                    setProduct={props.init}
                    multiPrice={multiPrice}
                    multiPriceType={multiPriceType}
                  />
                </div>

                <div className="alert alert-secondary">
                  <span>Groupe en supplement</span>
                </div>
                <div className="row xmb-2">
                  <div className="col">
                    <p className="colored_orange inlabel">
                      Selectionnez les groupe à proposer en supplément
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <Supplements groupes={groupes} {...{ control, register }} />
                </div>

                <div className="alert alert-secondary">
                  <span>Sections</span>
                </div>
                <div className="row mb-4  align-items-center choice-toggle">
                  <div className="col-6 text-left">
                    <p>Produit envoyé en cuisine</p>
                  </div>
                  <div className="col-3 text-right">
                    <div className="cmd-choix">
                      <input type="hidden" {...register(`sendToKitcken`)} />
                      <span>
                        <span
                          className={sendToKitcken ? "option active" : "option"}
                          onClick={() => setValue("sendToKitcken", true)}
                        >
                          Oui
                        </span>
                        <span
                          className={
                            !sendToKitcken ? "option active" : "option"
                          }
                          onClick={() => setValue("sendToKitcken", false)}
                        >
                          Non
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group mb-4">
                  <p className="colored_orange inlabel">
                    Selectionnez les sections liées au produit
                  </p>
                  <Controller
                    name="sections"
                    control={control}
                    render={({ field }) => (
                      <Select
                        onChange={(e) =>
                          field.onChange(e.map((elem) => elem.value))
                        }
                        isMulti
                        options={sections}
                        value={sections?.filter((ev) =>
                          field?.value?.includes(ev.value)
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    )}
                  />
                </div>

                <div className="alert alert-secondary">
                  <span>Envie</span>
                </div>

                <div className="form-group mb-4">
                  <p className="colored_orange inlabel">
                    Selectionnez les ingrédients pour retrouver le produit via
                    le filtre des envies
                  </p>
                  <Controller
                    name="envies"
                    control={control}
                    render={({ field }) => (
                      <Select
                        onChange={(e) =>
                          field.onChange(e.map((elem) => elem.value))
                        }
                        isMulti
                        options={envies}
                        value={envies.filter((ev) =>
                          field.value.includes(ev.value)
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    )}
                  />
                </div>

                <div className="alert alert-secondary">
                  <span>Allergène</span>
                </div>

                <div className="form-group mb-5">
                  <p className="colored_orange inlabel">
                    Selectionnez les allergènes lié au produit
                  </p>
                  <Controller
                    name="allergenes"
                    control={control}
                    render={({ field }) => (
                      <Select
                        defaultValue={[]}
                        onChange={(e) =>
                          field.onChange(e.map((elem) => elem.value))
                        }
                        isMulti
                        options={allergenes}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={allergenes.filter((al) =>
                          field.value.includes(al.value)
                        )}
                      />
                    )}
                  />
                </div>

                <div className="form-group mb-3 text-right">
                  <button
                    type="button"
                    className="btn btn-light btn-back"
                    onClick={() => props.handleCloseAdd(false)}
                  >
                    Retour
                  </button>

                  <button
                    type="submit"
                    className="modal--btn-validate"
                    disabled={load}
                  >
                    {load ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : action === "add" ? (
                      "Ajouter"
                    ) : (
                      "Modifier"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Produit;
