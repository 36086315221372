import React, { useState, useEffect } from "react";
import Loader from "~/components/Loader";
import { Notyf } from "notyf";
import { getEnseigneById } from "~/api/enseigne";
import { updateLoyalty } from "~/api/loyalty";

//svg
import Radio from "../../../Svgs/Super/addProduct/radio";

const Loyalty = () => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "bottom",
    },
    duration: 3000,
    dismissible: true,
  });

  let infos = {
    enable: false,
    moneyPoints: 0,
    pointsMoney: 0,
  };

  const [load, setLoad] = useState(false);
  const [input, setInput] = useState(infos);
  const [enseigne, setEnseigne] = useState();

  useEffect(() => {
    setLoad(true);
    let enseigneId = localStorage.getItem("enseigneId");
    getEnseigneById(enseigneId)
      .then((res) => {
        setInput({
          ...res.data.enseigne.loyalty,
        });
        setEnseigne(res.data.enseigne);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: +e.target.value.toLowerCase() });
  };

  const handleValidate = (e) => {
    e.preventDefault();
    let { moneyPoints, pointsMoney } = input;

    if (input.enable && moneyPoints === 0 && pointsMoney === 0) {
      notyf.error("Vous devez choisir un nombre différent de 0");
    } else {
      setLoad(true);
      updateLoyalty(enseigne._id, input)
        .then((res) => {
          setLoad(false);
          notyf.success(res.data.message);
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
          notyf.error(err.message);
          setLoad(false);
        });
    }
  };

  return (
    <div className="loyalty">
      <div className="block-styled">
        <div className="block-styled-body">
          <h1>FIDELITE</h1> <hr />
          {load ? (
            <Loader />
          ) : (
            <>
              {" "}
              <p>Souhaitez-vous proposer des points fidélité aux clients?</p>
              <div className="loyalty--checks">
                <div>
                  <p>Oui</p>
                  <span onClick={() => setInput({ ...input, enable: true })}>
                    <Radio color={input.enable ? "#E08005" : "white"} />
                  </span>
                </div>
                <div>
                  <p>Non</p>
                  <span onClick={() => setInput({ ...input, enable: false })}>
                    <Radio color={!input.enable ? "#E08005" : "white"} />
                  </span>
                </div>
              </div>
              <form className="inputs" onSubmit={handleValidate}>
                {input.enable && (
                  <>
                    <div className="loyalty--div ">
                      <span>10€ d'achat donne : </span>
                      <input
                        type="number"
                        step="1"
                        min="0"
                        value={input.moneyPoints}
                        name="moneyPoints"
                        onChange={handleChange}
                        required
                      />
                      <p>Points</p>
                    </div>
                    <div className="loyalty--div">
                      <span>1€ équivale à :</span>
                      <input
                        type="number"
                        step="1"
                        min="0"
                        value={input.pointsMoney}
                        name="pointsMoney"
                        onChange={handleChange}
                        required
                      />
                      <p>Points</p>
                    </div>
                  </>
                )}
                <button type="submit">Valider</button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Loyalty;
