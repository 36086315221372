export const groupTypes = [
  {
    label: "Ingredient(I)",
    value: "ingredient",
  },
  {
    label: "Produit(P)",
    value: "produit",
  },
];
