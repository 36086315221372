import React, { useState } from 'react';
import { useForm } from "react-hook-form"; 
import { Notyf } from 'notyf';

//components
import ChoixProduits from '../commun/ChoixProduits';

const Etape1 = ({ promoDatas, update, retour, store }) => {

    const notyf = new Notyf({
        position: {
          x: "right",
          y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const { register, handleSubmit, setValue, watch } = useForm({defaultValues: promoDatas});
    const [showProduits, setShowProduits] = useState(false);
    const selectedProduits = watch("produitsOffert.produits");

    const onSubmit = (data) => {
        if( selectedProduits.length === 0 ){
            notyf.error("Veuillez sélectionner au moins un produit");
            return
        }
        update({
            ...promoDatas, 
            startAmount: data.startAmount,
            produitsOffert: data.produitsOffert,
        });
    };

    const getSellectedProduits = (data) => {
        setValue('produitsOffert.produits', data);
        setShowProduits(false);
    }

    return (
        <div className="type1_etape1">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="startAmount">Sommes d’achat pour declencher la reduction</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" id="startAmount" name="startAmount" {...register("startAmount", { required: true })} />
                        <div className="input-group-append">
                            <span className="input-group-text">€</span>
                        </div>
                    </div>
                </div>
                
                <div className="form-group">
                    <label htmlFor="reduction.valeur">Nombre de produit offert</label>
                    <div className="input-group">
                        <input type="number" min="1" className="form-control" id="nbProduitOffert" name="nbProduitOffert" {...register("produitsOffert.nombre", { required: true })} />
                    </div>
                    <center className="colored mt-3">
                        <p>( le client pourra choisir le nombre de produit offert )</p>
                    </center>
                </div>

                <center className="mb-4">
                    Selectionner les produits offert ou selectionner <br></br>plusieurs produits pour donner le choix
                </center>
                <div className="custom-control custom-checkbox mb-3">
                    <button className="btn btn-light select-products" type="button" onClick={() => setShowProduits(!showProduits) }>
                        Sélectionner les produits
                    </button>
                    
                    {selectedProduits && selectedProduits.length > 0 && (
                    <div className="selected_produits mb-4">
                        <input type="hidden" name="data" {...register("produitsOffert.produits")} />
                        <ul className="list-group">
                            { selectedProduits.map( (product, index) => 
                                <li className="d-flex" key={index}> 
                                    <div>
                                        <img src={product.imageUrl} alt="" style={{width: 35}} />
                                    </div>
                                    <div>
                                        { product.name }
                                        {
                                            product.variant && 
                                            <span> [{product.variant.name}]</span>
                                        }
                                    </div>
                                    <div  className="remove-item">
                                        <button onClick={()=>{selectedProduits.splice(index, 1); getSellectedProduits(selectedProduits)}} type="button" className="btn btn-light">
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                </li>
                            ) }
                        </ul>
                    </div>
                )}
                </div> 

                <div className="form-group steps-controlers">
                    <div>
                        <button className="btn btn-dark" type="button" onClick={ retour }>Retour</button>
                    </div>
                    <div>
                        <button className="btn btn-warning" type="submit">Suivant</button>
                    </div>
                </div>
            </form>

            { showProduits && (
                <ChoixProduits 
                    store={ store } 
                    handleClose={ setShowProduits } 
                    show={ showProduits } 
                    submit={ getSellectedProduits }
                    default={ selectedProduits }
                />
            ) }

        </div>
    )
}

export default Etape1
