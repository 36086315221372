import React from "react";

const BurgerIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr">
        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="22" height="3" fill="#282828"/>
            <rect x="5" y="7" width="17" height="3" fill="#282828"/>
            <rect y="14.5361" width="22" height="3" fill="#282828"/>
        </svg> 
    </span>
  );
};

export default BurgerIcon;
