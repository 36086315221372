import React from 'react';
import { useForm } from "react-hook-form";
import { generateCode } from '~/_helpers/helper';

const Etape1 = ({ promoDatas, update, retour }) => {

    const { register, handleSubmit, setValue, watch } = useForm({defaultValues: promoDatas});
    const codePromo = watch("codePromo");

    const onSubmit = (data) => {
        update({
            ...promoDatas, 
            startAmount: data.startAmount,
            codePromo: data.codePromo,
        });
    };

    const getCode = (e) => {
        let checked = e.target.checked;
        if( checked ){
            let code = "LO"+ generateCode(7) ;
            setValue('codePromo', code);
        }else{
            setValue('codePromo', '');
        }
    }

    return (
        <div className="type1_etape1">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="startAmount">Livraison offerte à partir de</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" id="startAmount" name="startAmount" {...register("startAmount", { required: true })} />
                        <div className="input-group-append">
                            <span className="input-group-text">€</span>
                        </div>
                    </div>
                </div>

                <div className="custom-control custom-checkbox mb-3">
                    <input type="checkbox" checked={codePromo} className="custom-control-input" id="generate_code" onChange={ getCode } />
                    <label className="custom-control-label" htmlFor="generate_code">Générer un code promo</label>
                </div>

                { codePromo && (
                    <div className="form-group">
                        <label htmlFor="codePromo">Code promo</label>
                        <input type="text" className="form-control" id="codePromo" name="codePromo" { ...register("codePromo") } />
                    </div>
                ) } 
                <div className="form-group steps-controlers">
                    <div>
                        <button className="btn btn-dark" type="button" onClick={ retour }>Retour</button>
                    </div>
                    <div>
                        <button className="btn btn-warning" type="submit">Suivant</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Etape1
