import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Notyf } from "notyf";
import Logo from "~/assets/svg/logo.svg";
import LogoMin from "~/assets/svg/logo-min.svg";
import Arrowclose from "~/assets/svg/arrowclose.svg";

//SUPREME ICONS
import DashIcon from "../../Svgs/Supreme/Dash";
import InfoIcon from "../../Svgs/Supreme/Infos";
import TrashIcon from "../../Svgs/Supreme/Trash";
//SUPER ICONS
import GestionIcon from "../../Svgs/Super/Gestion";
import StyleIcon from "../../Svgs/Super/Style";
import PromotionIcon from "../../Svgs/Super/Promotion";
import BackIcon from "../../Svgs/Super/Back";
import Fidelite from "../../Svgs/Super/Fidelite";
import Media from "../../Svgs/Super/Media";

//SHOP ICONS
import MenuIcon from "../../Svgs/Shop/Menu";
import OrdersIcon from "../../Svgs/Shop/Orders";
import InfosIcon from "../../Svgs/Shop/Infos";
import OrderTypeIcon from "../../Svgs/Shop/OrderType";
import AccessIcon from "../../Svgs/Shop/Access";
import ClientsIcon from "../../Svgs/Shop/Clients";
import ServicesIcon from "../../Svgs/Shop/Services";
import PromotionsIcon from "../../Svgs/Shop/Promotions";
import CouponCodeIcon from "../../Svgs/Shop/CouponCode";
import TimeTableIcon from "../../Svgs/Shop/TimeTable";
import ArrowDownIcon from "../../Svgs/Shop/ArrowDown";
import LogoutIcon from "../../Svgs/Shop/Logout";
// Import Redux / actions
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, changeUserRole } from "~/redux/actions/userAction";

import Logout from "~/assets/svg/logout.svg";
import TerminalIcon from "~/components/Svgs/Shop/Terminal";
import { getCaisseWithStoreId } from "~/api/caisse";
import { getTerminalWithStoreId } from "~/api/terminal";

const Sidebar = (props) => {
  let storeId = localStorage.getItem("storeId");

  const notyf = new Notyf({
    position: {
      x: "center",
      y: "bottom",
    },
    duration: 3000,
    dismissible: true,
    types: [
      {
        type: "warning",
        background: "orange",
        color: "white",
      },
    ],
  });

  // Variable global
  const dispatch = useDispatch();
  let history = useHistory();
  const user = useSelector((state) => state.user);
  const [sub, setSub] = useState("");
  const [caisses, setCaisses] = useState([]);
  const [terminals, setTerminals] = useState([]);

  useEffect(() => {
    let submenu = localStorage.getItem("submenu");
    setSub(submenu);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const cRes = await getCaisseWithStoreId(storeId);
        const tRes = await getTerminalWithStoreId(storeId);

        setCaisses(cRes.cashs);
        setTerminals(tRes.terminals);
      } catch (err) {
        console.log("err ", err);
      }
    })();
  }, [storeId]);

  // Functions
  const handleLogout = () => {
    //localStorage.clear();
    dispatch(logoutUser());
    history.push("/");
    notyf.open({ type: "warning", message: "Vous avez été déconnecté !" });
  };

  const handleRedirectToSupreme = () => {
    if (user.data.scope === "ROLE_SUPREMEADMIN") {
      dispatch(changeUserRole("ROLE_SUPREMEADMIN"));
      localStorage.removeItem("enseigneId");
      history.push("/dashboard");
    }
  };

  const handleRedirectToEnseigne = (enseigneId) => {
    if (
      user.data.scope === "ROLE_SUPREMEADMIN" ||
      user.data.scope === "ROLE_SUPERADMIN"
    ) {
      enseigneId = localStorage.getItem("enseigneId");
      if (enseigneId) {
        dispatch(changeUserRole("ROLE_SUPERADMIN"));
        history.push(`/enseigne/dashboard/?enseigneid=${enseigneId}`);
      } else {
        history.push("/");
      }
    }
  };

  // Find ClassName of topL
  var findClass =
    props.hide === "closeL"
      ? "topL--iconWrapper-close"
      : "topL--iconWrapper-open";

  const setSubMenu = (ref) => {
    setSub(ref);
    localStorage.setItem("submenu", ref);
    if (props.isMobile) props.handleOpen();
  };

  // liste selon le Role

  let mainContent = "";
  if (user.currentRole === "ROLE_STORE") {
    mainContent = (
      <div className="sidebarL--listWrapper">
        <NavLink
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/store/menu"
          onClick={() => setSubMenu("menu")}
        >
          <li className="sidebarL--list_item">
            <MenuIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Menu"}{" "}
            </span>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink  has-submenu"
          activeClassName="selected"
          to="/store/commandes"
          onClick={() => setSubMenu("commandes")}
        >
          <li className="sidebarL--list_item">
            <OrdersIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Commandes"}{" "}
            </span>
            <ArrowDownIcon />
          </li>
        </NavLink>
        {sub === "commandes" && props.hide === "openL" && (
          <div className="submenu">
            <ul>
              {/* <li>
                <NavLink
                  exact
                  className='sidebarL--list-navLink'
                  activeClassName='selected'
                  to='/store/commandes/mes-commandes'
                  onClick={() => setSubMenu("commandes")}
                >
                  <span className='sidebarL--list-dash'>
                    {" "}
                    {props.hide === "openL" && "Mes commandes"}{" "}
                  </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/commandes/statistics"
                  onClick={() => setSubMenu("commandes")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Statistiques"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/commandes/archives-fiscales"
                  onClick={() => setSubMenu("commandes")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Archives Fiscales"}{" "}
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/commandes/reservations"
                  onClick={() => setSubMenu("commandes")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Réservations"}{" "}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <NavLink
          className="sidebarL--list-navLink has-submenu"
          activeClassName="selected"
          to="/store/informations"
          onClick={() => setSubMenu("informations")}
        >
          <span className="sidebarL--list_item">
            <InfosIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Informations"}{" "}
            </span>
            <ArrowDownIcon />
          </span>
        </NavLink>
        {sub === "informations" && props.hide === "openL" && (
          <div className="submenu">
            <ul>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/informations"
                  onClick={() => setSubMenu("informations")}
                >
                  <span className="sidebarL--list-dash">
                    {props.hide === "openL" && "Informations"}
                  </span>
                </NavLink>
              </li>
              <li>
                {/* <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/informations/paiement"
                  onClick={() => setSubMenu("informations")}
                >
                  <span className="sidebarL--list-dash">
                    {props.hide === "openL" && "Système de paiement"}
                  </span>
                </NavLink> */}
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/informations/informations-legales"
                  onClick={() => setSubMenu("informations")}
                >
                  <span className="sidebarL--list-dash">
                    {props.hide === "openL" && "Informations légales"}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/store/acces"
          onClick={() => setSubMenu("acces")}
        >
          <li className="sidebarL--list_item">
            <AccessIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Acces"}{" "}
            </span>
            {caisses.length > 0 && <ArrowDownIcon />}
          </li>
        </NavLink>
        {sub === "acces" && caisses.length > 0 && props.hide === "openL" && (
          <div className="submenu">
            <ul>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/acces/store"
                  onClick={() => setSubMenu("store")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Boutique"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/acces/caisse"
                  onClick={() => setSubMenu("caisse")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Caisse"}{" "}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        )}
        <NavLink
          className="sidebarL--list-navLink has-submenu"
          activeClassName="selected"
          to="/store/type-commande/"
          onClick={() => setSubMenu("commande")}
        >
          <li className="sidebarL--list_item">
            <OrderTypeIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Type de commande"}{" "}
            </span>
            <ArrowDownIcon />
          </li>
        </NavLink>
        {sub === "commande" && props.hide === "openL" && (
          <div className="submenu">
            <ul>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/type-commande/emporter"
                  onClick={() => setSubMenu("commande")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "A emporter"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/type-commande/livraison"
                  onClick={() => setSubMenu("commande")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Livraison"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/type-commande/place"
                  onClick={() => setSubMenu("commande")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Sur place"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/type-commande/table"
                  onClick={() => setSubMenu("commande")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Réservation table"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/type-commande/online-order"
                  onClick={() => setSubMenu("commande")}
                >
                  <span className="sidebarL--list-dash">
                    {props.hide === "openL" && "Commande en ligne"}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/store/services"
          onClick={() => setSubMenu("services")}
        >
          <li className="sidebarL--list_item">
            <ServicesIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Services"}{" "}
            </span>
          </li>
        </NavLink>
        {sub === "services" && props.hide === "openL" && (
          <div className="submenu">
            <ul>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/services/paygreen"
                  onClick={() => setSubMenu("services")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "PayGreen"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/services/sms"
                  onClick={() => setSubMenu("services")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Sms"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/services/nepting"
                  onClick={() => setSubMenu("services")}
                >
                  <span className="sidebarL--list-dash">
                    {props.hide === "openL" && "Nepting"}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/store/promotions"
          onClick={() => setSubMenu("promotions")}
        >
          <li className="sidebarL--list_item">
            <PromotionsIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Promotions"}{" "}
            </span>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/store/codes-promo"
          onClick={() => setSubMenu("codes")}
        >
          <li className="sidebarL--list_item">
            <CouponCodeIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Codes promo"}{" "}
            </span>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/store/horaires"
          onClick={() => setSubMenu("horaires")}
        >
          <li className="sidebarL--list_item">
            <TimeTableIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Horaires"}{" "}
            </span>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/store/clients"
          onClick={() => setSubMenu("clients")}
        >
          <li className="sidebarL--list_item">
            <ClientsIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Clients"}{" "}
            </span>
          </li>
        </NavLink>
        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/store/manager"
          onClick={() => setSubMenu("manager")}
        >
          <li className="sidebarL--list_item">
            <ClientsIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Gerant"}{" "}
            </span>
          </li>
        </NavLink>
        {terminals.length > 0 && (
          <NavLink
            className="sidebarL--list-navLink has-submenu"
            activeClassName="selected"
            to="/store/bornes/"
            onClick={() => setSubMenu("bornes")}
          >
            <li className="sidebarL--list_item">
              <TerminalIcon />
              <span className="sidebarL--list-dash">
                {" "}
                {props.hide === "openL" && "Bornes"}{" "}
              </span>
              <ArrowDownIcon />
            </li>
          </NavLink>
        )}
        {sub === "bornes" && props.hide === "openL" && (
          <div className="submenu">
            <ul>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/bornes/img"
                  onClick={() => setSubMenu("bornes")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Images"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/bornes/paiement"
                  onClick={() => setSubMenu("bornes")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Paiement"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/bornes/scannage"
                  onClick={() => setSubMenu("bornes")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Scannage"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/bornes/recovery"
                  onClick={() => setSubMenu("bornes")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Reception"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/bornes/commandMode"
                  onClick={() => setSubMenu("bornes")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Commande mode"}{" "}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  className="sidebarL--list-navLink"
                  activeClassName="selected"
                  to="/store/bornes/printer"
                  onClick={() => setSubMenu("printer")}
                >
                  <span className="sidebarL--list-dash">
                    {" "}
                    {props.hide === "openL" && "Imprimante"}{" "}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <NavLink
          exact
          className="d-block d-md-none sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/code-promo"
          onClick={handleLogout}
        >
          <li className="sidebarL--list_item">
            <LogoutIcon />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Déconnexion"}{" "}
            </p>
          </li>
        </NavLink>

        {user.data.scope !== "ROLE_STORE" && user.currentRole === "ROLE_STORE" && (
          <>
            <hr />
            <li
              onClick={handleRedirectToEnseigne}
              className="sidebarL--list_item retour"
            >
              <BackIcon />
              <p className="sidebarL--list-superLink">
                {" "}
                {props.hide === "openL" && "Retour à l'enseigne"}{" "}
              </p>
            </li>
          </>
        )}
      </div>
    );
  } else if (user.currentRole === "ROLE_SUPREMEADMIN") {
    mainContent = (
      <div className="sidebarL--listWrapper">
        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/dashboard"
          onClick={() => setSubMenu("dashboard")}
        >
          <li className="sidebarL--list_item">
            <DashIcon />
            <span className="sidebarL--list-dash">
              {" "}
              {props.hide === "openL" && "Dashboard"}{" "}
            </span>
          </li>
        </NavLink>

        <NavLink
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/dashboard/access"
          onClick={() => setSubMenu("informations")}
        >
          <li className="sidebarL--list_item">
            <InfoIcon />
            <span className="sidebarL--list-info">
              {" "}
              {props.hide === "openL" && "Informations"}{" "}
            </span>
          </li>
        </NavLink>

        <NavLink
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/dashboard/terminate"
          onClick={() => setSubMenu("resiliation")}
        >
          <li className="sidebarL--list_item">
            <TrashIcon />
            <span className="sidebarL--list-resi">
              {" "}
              {props.hide === "openL" && "Résiliation"}{" "}
            </span>
          </li>
        </NavLink>

        <NavLink
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/dashboard/partnerSms"
          onClick={() => setSubMenu("resiliation")}
        >
          <li className="sidebarL--list_item">
            <TrashIcon />
            <span className="sidebarL--list-resi">
              {" "}
              {props.hide === "openL" && "Sms"}{" "}
            </span>
          </li>
        </NavLink>

        <NavLink
          exact
          className="d-block d-md-none sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/code-promo"
          onClick={handleLogout}
        >
          <li className="sidebarL--list_item">
            <LogoutIcon />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Déconnexion"}{" "}
            </p>
          </li>
        </NavLink>
      </div>
    );
  } else if (user.currentRole === "ROLE_SUPERADMIN") {
    mainContent = (
      <div className="sidebarL--listWrapper">
        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/dashboard"
          onClick={() => setSubMenu("ajout-gestion")}
        >
          <li className="sidebarL--list_item">
            <GestionIcon />
            <p className="sidebarL--list-superLink">
              {props.hide === "openL" && "Ajout/Gestion"}
            </p>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/informations"
          onClick={() => setSubMenu("informations")}
        >
          <li className="sidebarL--list_item">
            <InfosIcon />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Informations"}{" "}
            </p>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/access"
          onClick={() => setSubMenu("access")}
        >
          <li className="sidebarL--list_item">
            <AccessIcon />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Accès"}
            </p>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/styles/banner"
          onClick={() => setSubMenu("styles")}
        >
          <li className="sidebarL--list_item">
            <StyleIcon />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Styles"}{" "}
            </p>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/loyalty"
          onClick={() => setSubMenu("loyalty")}
        >
          <li className="sidebarL--list_item">
            <Fidelite />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Fidélité"}{" "}
            </p>
          </li>
        </NavLink>

        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/code-promo"
          onClick={() => setSubMenu("code-promo")}
        >
          <li className="sidebarL--list_item">
            <PromotionIcon />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Code-promo"}{" "}
            </p>
          </li>
        </NavLink>
        <NavLink
          exact
          className="sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/media"
          onClick={() => setSubMenu("code-promo")}
        >
          <li className="sidebarL--list_item">
            <Media />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Medias"}{" "}
            </p>
          </li>
        </NavLink>

        <NavLink
          exact
          className="d-block d-md-none sidebarL--list-navLink"
          activeClassName="selected"
          to="/enseigne/code-promo"
          onClick={handleLogout}
        >
          <li className="sidebarL--list_item">
            <LogoutIcon />
            <p className="sidebarL--list-superLink">
              {" "}
              {props.hide === "openL" && "Déconnexion"}{" "}
            </p>
          </li>
        </NavLink>

        {user.data.scope === "ROLE_SUPREMEADMIN" &&
          user.currentRole === "ROLE_SUPERADMIN" && (
            <>
              <hr />
              <li
                onClick={handleRedirectToSupreme}
                className="sidebarL--list_item retour"
              >
                <BackIcon />
                <p className="sidebarL--list-superLink">
                  {" "}
                  {props.hide === "openL" && "Retour au supreme"}{" "}
                </p>
              </li>
            </>
          )}
      </div>
    );
  }

  // Render

  return (
    <div>
      <div className="topL sticky ">
        <img
          alt="logo"
          src={props.hide === "closeL" && !props.isMobile ? LogoMin : Logo}
          className="topL--logo"
        />

        <div
          onClick={handleLogout}
          className="sidebarL--list_item d-flex align-items-center logout "
        >
          Déconnexion{" "}
          <img alt="logout-icon" src={Logout} className="ml-2 mt-1" />
        </div>
      </div>

      <div
        className={`sidebarL ${props.hide}`}
        onClick={props.isMobile ? props.handleOpen : null}
      >
        <span
          className={`topL--iconWrapper ${findClass}`}
          onClick={props.handleOpen}
        >
          <img alt="" src={Arrowclose} />
        </span>

        <ul className="sidebarL--list">
          <div className="sidebarL--listFlex">
            {mainContent}

            {props.hide === "openL" && (
              <div className="footerFixed">
                <div className="d-none d-lg-block sidebar--credits"></div>
              </div>
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
