import React, { useState, useRef } from 'react';
import { Notyf } from "notyf";
import moment from 'moment';


// Components
import Loader from "~/components/Loader";
import ConfirmModal from "~/components/Reusable/confirm";
// Utils
import { updateStoreConfig } from '~/api/stores' ; 

//Css
import './_horaires.scss'

//Icons
import Plus from "~/assets/svg/plus.svg";
import Trash from "~/assets/svg/trash-outline.svg";


const HoraireStore = (props) => {


    const hasMaxHoraireByDay = (days, attrib, val) => { 
        let nbrDays = days.reduce(function (n, day) {
            return n + (day[attrib] === val);
        }, 0);     
        return nbrDays === 2 ;
    }

    const isInValideHoraire = (_horaire1, _horaire2) => {

        let horaire1 = {
            start: moment(_horaire1.opening, 'hh:mm'),
            end: moment(_horaire1.closing, 'hh:mm')
        }
        let horaire2 = {
            start: moment(_horaire2.opening, 'hh:mm'),
            end: moment(_horaire2.closing, 'hh:mm')
        } 
        return horaire2.start.isBetween(horaire1.start, horaire1.end) || horaire2.end.isBetween(horaire1.start, horaire1.end)
    }

    const notyf = new Notyf({
        position: {
            x: "right",
            y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    // Variables
    const days = [
        { num: 0, label: "Dimanche"},
        { num: 1, label: "Lundi"},
        { num: 2, label: "Mardi"},
        { num: 3, label: "Mercredi"},
        { num: 4, label: "Jeudi"},
        { num: 5, label: "Vendredi"},
        { num: 6, label: "Samedi"},
    ];
    const select1 = useRef(null) ;
    const select2 = useRef(null) ;
    const select3 = useRef(null) ;
    
    // Stats
    const [store, setStore] = useState(props.store);
    const [horaires, setHoraires] = useState(store.config.horaires);
    const [load, setLoad] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedDay, setSelectedDay] = useState()
    const [tab, setTab] = useState("restaurant");
    const [inputResto, setInputResto] = useState({});
    const [inputLivraison, setInputLivraison] = useState({identique:horaires.livraison.identique});
    const [inputTable, setInputTable] = useState({identique:horaires.reservation.identique});

    


    // Functions 
    const handleChange = (ref, e) => {
        switch (ref) {
            case "resto":
                let newInputResto = {
                    ...inputResto,
                    [e.target.name]: e.target.value.toLowerCase()
                };
                if( !newInputResto.dayNumber ) {
                    newInputResto = {...newInputResto, dayNumber:select1.current.value} ;
                }
                setInputResto(newInputResto);
                break;
            case "livraison":
                let newInputLivraison = {
                    ...inputLivraison,
                    [e.target.name]: e.target.value.toLowerCase()
                };
                if( !newInputLivraison.dayNumber ) {
                    newInputLivraison = {...newInputLivraison, dayNumber:select2.current.value} ;
                }
                setInputLivraison(newInputLivraison);
                break;
            case "table":
                let newInputTable = {
                    ...inputTable,
                    [e.target.name]: e.target.value.toLowerCase()
                };
                if( !newInputTable.dayNumber ) {
                    newInputTable = {...newInputTable, dayNumber:select3.current.value} ;
                }
                setInputTable(newInputTable);
                break;
            default:
                break;
        }
    }

    const handleStatut = (ref)  => {
        setLoad(true);
        let horairesConfig = horaires;

        switch (ref) {
            case "livraison":
                horairesConfig = {
                    ...horairesConfig,
                    livraison : {
                        ...horairesConfig.livraison,
                        identique: !horairesConfig.livraison.identique
                    }
                }
                break;
            case "table":
                horairesConfig = {
                    ...horairesConfig,
                    reservation : {
                        ...horairesConfig.reservation,
                        identique: !horairesConfig.reservation.identique
                    }
                }
                break;
            default:
                break;
        }

        updateHorraire(horairesConfig);
    }

    const handleSubmit = (ref) => {
        
        let horairesConfig = horaires;  

        switch (ref) {
            case "resto":
                if( !inputResto.dayNumber || !inputResto.opening || !inputResto.closing ) {
                    notyf.error("Merci de remplir tous les champs !"); 
                    return false;
                }
                let dayExists_restaurant = horaires.restaurant.days.find(d => d.dayNumber === inputResto.dayNumber) 
                if( dayExists_restaurant ) {
                    if( isInValideHoraire(dayExists_restaurant, inputResto) ){
                        notyf.error("Vous ne pouvez pas ajouter des horaires croisés"); 
                        return false;
                    }
                } 
                let restaurantHoraires = {
                    ...horaires.restaurant,
                    days: [...horaires.restaurant.days, inputResto]
                }
                horairesConfig = {
                    ...horairesConfig,
                    restaurant: restaurantHoraires
                }
                break;
            case "livraison":
                if( !inputLivraison.dayNumber || !inputLivraison.opening || !inputLivraison.closing ) {
                    notyf.error("Merci de remplir tous les champs !"); 
                    return false;
                }
                let dayExists_livraison = horaires.livraison.days.find(d => d.dayNumber === inputLivraison.dayNumber) 
                if( dayExists_livraison ) {
                    if( isInValideHoraire(dayExists_livraison, inputLivraison) ){
                        notyf.error("Vous ne pouvez pas ajouter des horaires croisés"); 
                        return false;
                    }
                } 
                let livraisonHoraires = {
                    ...horaires.livraison,
                    days: [...horaires.livraison.days, inputLivraison]
                }
                horairesConfig = {
                    ...horairesConfig,
                    livraison: livraisonHoraires
                }
                break;
            case "table":
                if( !inputTable.dayNumber || !inputTable.opening || !inputTable.closing ) {
                    notyf.error("Merci de remplir tous les champs !"); 
                    return false;
                }
                let dayExists_reservation = horaires.reservation.days.find(d => d.dayNumber === inputTable.dayNumber) 
                if( dayExists_reservation ) {
                    if( isInValideHoraire(dayExists_reservation, inputTable) ){
                        notyf.error("Vous ne pouvez pas ajouter des horaires croisés"); 
                        return false;
                    }
                } 
                let tableHoraires = {
                    ...horaires.reservation,
                    days: [...horaires.reservation.days, inputTable]
                }
                horairesConfig = {
                    ...horairesConfig,
                    reservation: tableHoraires
                }
                break;
            default:
                break;
        }

        setLoad(true);
        updateHorraire(horairesConfig, ref);
    }

    const confirmDelete = (id) => {
        setSelectedDay(id);
        setShowModal(true);
    }

    const handleDelete = () => {
        setShowModal(false);
        setLoad(true);
        let horairesConfig = {...horaires};

        switch (tab) {
            case "restaurant":
                let restaurantHoraires = {
                    ...horaires.restaurant,
                    days: horaires.restaurant.days.filter( (day) => day._id !== selectedDay  )
                }
                horairesConfig = {
                    ...horairesConfig,
                    restaurant: restaurantHoraires
                }
                break;
            case "livraison":
                let livraisonHoraires = {
                    ...horaires.livraison,
                    days: horaires.livraison.days.filter( (day) => day._id !== selectedDay  )
                }
                horairesConfig = {
                    ...horairesConfig,
                    livraison: livraisonHoraires
                }
                break;
            case "table":
                let tableHoraires = {
                    ...horaires.reservation,
                    days: horaires.reservation.days.filter( (day) => day._id !== selectedDay  )
                }
                horairesConfig = {
                    ...horairesConfig,
                    reservation: tableHoraires
                }
                break;
            default:
                break;
        }

        updateHorraire(horairesConfig);
    }

    const resetDaysSelect = (ref)=>{
        switch(ref){
            case 'resto': 
                handleChange('resto',{target:{name:'dayNumber', value:select1.current.value}})
                break
            case 'livraison': 
                handleChange('livraison',{target:{name:'dayNumber', value:select2.current.value}})
                break
            case 'table': 
                handleChange('table',{target:{name:'dayNumber', value:select2.current.value}})
                break;
            default:
                break;
        }
        
    }

    const updateHorraire = (horaires, ref) => {
        updateStoreConfig(store._id, "horaires", horaires)
            .then((res) => {
                setStore(res.data.store);
                setHoraires(res.data.store.config.horaires);
                setLoad(false);
                resetDaysSelect(ref)
                notyf.success(res.data.message);
                setTimeout(function(){ window.location.reload(); }, 600);
            })
            .catch((err) => {
                setLoad(false);
                notyf.error(err.message);
            });
    }

    return (
        <div className="time-table">
            { load 
            ? ( <Loader /> ) 
            : (
                <div className="block-styled container">
                    <div className="block-styled-body">
                        <nav>
                            <div className="nav nav-tabs justify-content-center">
                                <button type="button" 
                                        className={ tab === "restaurant" ? "nav-link active" : "nav-link" }
                                        onClick={ () => setTab("restaurant") }
                                        >Horaire restaurant</button>
                                <button type="button" 
                                        className={ tab === "livraison" ? "nav-link active" : "nav-link" }
                                        onClick={ () => setTab("livraison") }
                                        >Horaire Livraison</button>
                                <button type="button" 
                                        className={ tab === "table" ? "nav-link active" : "nav-link" }
                                        onClick={ () => setTab("table") }
                                        >Horaire de reservation de table</button>
                            </div>
                        </nav>

                        <div className="tab-content p-3">
                            {/* Tab horaires restaurant */}
                            <div className={ tab === "restaurant" ? "tab-pane fade show active" : "tab-pane fade" }>
                                <div className="row g-3 form-add">
                                    <div className="col-sm-3">
                                        <select className="form-select" ref={select1} name="dayNumber" onChange={ (e) => handleChange("resto", e) }>
                                            {
                                                days.map( (day, index) => (
                                                    hasMaxHoraireByDay(horaires.restaurant.days, "dayNumber", day.num) 
                                                    ? ""
                                                    : <option key={index} value={ day.num }>{ day.label }</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-sm-3">
                                        <input type="time" className="form-control" name="opening" onChange={ (e) => handleChange("resto", e) } />
                                    </div>
                                    <div className="col-sm-3">
                                        <input type="time" className="form-control" name="closing" onChange={ (e) => handleChange("resto", e) } />
                                    </div>
                                    <div className="col-sm-3">
                                        <button type="button" className="modal--btn-validate btn-add" onClick={ () => handleSubmit("resto") }><img alt="plus" src={Plus} /></button>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    {
                                        horaires.restaurant.days.length > 0
                                        ? (
                                            <table className="table">
                                                <thead className="table-dark">
                                                    <tr>
                                                        <th>Jours</th>
                                                        <th>Ouverture</th>
                                                        <th>Fermeture</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        horaires.restaurant.days.map( (day, index) => (
                                                            <tr key={index}>
                                                                <td>{ days[day.dayNumber].label }</td>

                                                                <td>{ day.opening }</td>
                                                                <td>{ day.closing }</td>
                                                                <td><img alt="Trash" className="delete-btn" src={Trash} onClick={ () => confirmDelete(day._id) } /></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        )
                                        : <p>Aucune horaires pour le moment...</p>
                                    }
                                </div>
                            </div>

                            {/* Tab horaires livraison */}
                            <div className={ tab === "livraison" ? "tab-pane fade show active" : "tab-pane fade" }>
                                <div className="row g-3">
                                    <div className="col">
                                        <div className="cmd-choix mb-4">
                                            <strong>Les horaires sont il different du restaurant ?</strong>
                                            <span className="ml-3">
                                                <span
                                                    className={inputLivraison.identique ? "option" : "option active"}
                                                    onClick={() => {
                                                            setInputLivraison({...inputLivraison, identique: false});
                                                            handleStatut("livraison");
                                                        }
                                                    }
                                                >Oui</span>
                                                <span 
                                                    className={!inputLivraison.identique ? "option" : "option active"}
                                                    onClick={() => {
                                                            setInputLivraison({...inputLivraison, identique: true});
                                                            handleStatut("livraison");
                                                        }
                                                    }
                                                >Non</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    inputLivraison.identique
                                    ? ""
                                    : (
                                        <>
                                            <div className="row g-3 form-add">
                                                {/* <div className="col-sm-1"></div> */}
                                                <div className="col-sm-3">
                                                    <select className="form-select" ref={select2} name="dayNumber" onChange={ (e) => handleChange("livraison", e) }>
                                                        {
                                                            days.map( (day, index) => (
                                                                hasMaxHoraireByDay(horaires.livraison.days, "dayNumber", day.num) 
                                                                ? ""
                                                                : <option key={index} value={ day.num }>{ day.label }</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-sm-3">
                                                    <input type="time" className="form-control" name="opening" onChange={ (e) => handleChange("livraison", e) } />
                                                </div>
                                                <div className="col-sm-3">
                                                    <input type="time" className="form-control" name="closing" onChange={ (e) => handleChange("livraison", e) } />
                                                </div>
                                                <div className="col-sm-3">
                                                    <button type="button" className="modal--btn-validate btn-add" onClick={ () => handleSubmit("livraison") }><img alt="plus" src={Plus} /></button>
                                                </div>
                                            </div>

                                            <div className="row mt-5">
                                                {
                                                    horaires.livraison.days.length > 0
                                                    ? (
                                                        <table className="table">
                                                            <thead className="table-dark">
                                                                <tr>
                                                                    <th>Jours</th>
                                                                    <th>Ouverture</th>
                                                                    <th>Fermeture</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    horaires.livraison.days.map( (day, index) => (
                                                                        <tr key={index}>
                                                                            <td>{ days[day.dayNumber].label }</td>
                                                                            <td>{ day.opening }</td>
                                                                            <td>{ day.closing }</td>
                                                                            <td><img alt="Trash" className="delete-btn" src={Trash} onClick={ () => confirmDelete(day._id) } /></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                    : <p>Aucune horaires pour le moment...</p>
                                                }
                                            </div>
                                        </>
                                    )
                                }     
                            </div>

                            {/* Tab horaires reservation table */}
                            <div className={ tab === "table" ? "tab-pane fade show active" : "tab-pane fade" }>
                                <div className="row g-3">
                                    <div className="col">
                                        <div className="cmd-choix mb-4">
                                            <strong>Les horaires sont il different du restaurant ?</strong>
                                            <span className="ml-3">
                                                <span 
                                                    className={inputTable.identique ? "option" : "option active"}
                                                    onClick={() => {
                                                            setInputTable({...inputTable, identique: false});
                                                            handleStatut("table");
                                                        }
                                                    }
                                                >Oui</span>
                                                <span 
                                                    className={!inputTable.identique ? "option" : "option active"}
                                                    onClick={() => {
                                                            setInputTable({...inputTable, identique: true});
                                                            handleStatut("table");
                                                        }
                                                    }
                                                >Non</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    inputTable.identique
                                    ? ""
                                    : (
                                        <>
                                            <div className="row g-3 form-add">
                                                <div className="col-sm-3">
                                                    <select className="form-select" ref={select3} name="dayNumber" onChange={ (e) => handleChange("table", e) }>
                                                        {
                                                            days.map( (day, index) => (
                                                                hasMaxHoraireByDay(horaires.reservation.days, "dayNumber", day.num) 
                                                                ? ""
                                                                : <option key={index} value={ day.num }>{ day.label }</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-sm-3">
                                                    <input type="time" className="form-control" name="opening" onChange={ (e) => handleChange("table", e) } />
                                                </div>
                                                <div className="col-sm-3">
                                                    <input type="time" className="form-control" name="closing" onChange={ (e) => handleChange("table", e) } />
                                                </div>
                                                <div className="col-sm-3">
                                                    <button type="button" className="modal--btn-validate btn-add" onClick={ () => handleSubmit("table") }><img alt="plus" src={Plus} /></button>
                                                </div>
                                            </div>

                                            <div className="row mt-5">
                                                {
                                                    horaires.reservation.days.length > 0
                                                    ? (
                                                        <table className="table">
                                                            <thead className="table-dark">
                                                                <tr>
                                                                    <th>Jours</th>
                                                                    <th>Ouverture</th>
                                                                    <th>Fermeture</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    horaires.reservation.days.map( (day, index) => (
                                                                        <tr key={index}>
                                                                            <td>{ days[day.dayNumber].label }</td>
                                                                            <td>{ day.opening }</td>
                                                                            <td>{ day.closing }</td>
                                                                            <td><img alt="Trash" className="delete-btn" src={Trash} onClick={ () => confirmDelete(day._id) } /></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                    : <p>Aucune horaires pour le moment...</p>
                                                }
                                            </div>
                                        </>
                                    )
                                }

                                
                            </div>
                        </div>

                        {showModal && (
                            <ConfirmModal
                                show={showModal}
                                setShow={setShowModal}
                                message="Êtes-vous sûr de vouloir supprimer ce jour ?"
                                btnValide="Validez"
                                handleValide={handleDelete}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}


export default HoraireStore