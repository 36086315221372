import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { Notyf } from "notyf";
import { updateEnseigneStyles } from "~/api/enseigne";
import Select from "react-select";

//svg et images
import RadioColor from "../../../../Svgs/Super/styles/radioColor";
import burger from "~/assets/images/styles/burger.png";
import Bag from "../../../../Svgs/Super/styles/Bag";
import Loader from "~/components/Loader";
import { APP_URI } from "~/assets/base";

const Colors = ({ enseigne }) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const arrayFonts = [
    {
      value:
        "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&display=swap",
      name: "'Montserrat', sans-serif",
      label: "Montserrat",
    },
    {
      value: `${APP_URI}/assets/app-fonts/BlendaScript/stylesheet.css`,
      name: "'Blenda Script'",
      label: "Blenda Script",
    },
    {
      value: `${APP_URI}/assets/app-fonts/Gobold/stylesheet.css`,
      name: "'Gobold'",
      label: "Go Bold",
    },
    {
      value: `${APP_URI}/assets/app-fonts/Impact/stylesheet.css`,
      name: "'Impact'",
      label: "Impact",
    },
    {
      value: `${APP_URI}/assets/app-fonts/Insaniburger/stylesheet.css`,
      name: "'Insaniburger'",
      label: "Insani Burger",
    },
    {
      value: `${APP_URI}/assets/app-fonts/Pacifico/stylesheet.css`,
      name: "'Pacifico'",
      label: "Pacifico",
    },
    {
      value: `${APP_URI}/assets/app-fonts/PlayfairDisplay/stylesheet.css`,
      name: "'Playfair Display'",
      label: "Play Fair Display",
    },
    {
      value: `${APP_URI}/assets/app-fonts/Tiza/stylesheet.css`,
      name: "'tiza'",
      label: "tiza",
    },
    {
      value: `${APP_URI}/assets/app-fonts/Prequel/stylesheet.css`,
      name: "'Prequel'",
      label: "Prequel",
    },
  ];

  let obj = {
    police: arrayFonts[0].value,
    btnFondColor: { hex: "" },
    fondColor: { hex: "" },
    btnTextColor: { hex: "" },
    textColor: { hex: "" },
    titreColor: { hex: "" },
  };
  const [design, setDesign] = useState(obj);
  const [mulled, setMulled] = useState("");

  const [load, setLoad] = useState(true);

  useEffect(() => {
    const obj = enseigne.styles.design;
    setDesign({
      ...design,
      btnFondColor: { hex: obj.btnFondColor },
      fondColor: { hex: obj.fondColor },
      btnTextColor: { hex: obj.btnTextColor },
      textColor: { hex: obj.textColor },
      titreColor: { hex: obj.titreColor },
      police: obj.police,
    });
    setLoad(false);
    // eslint-disable-next-line
  }, []);

  const handleChangeComplete = (color) => {
    setDesign({
      ...design,
      [mulled]: color,
    });
  };

  const handleValidate = () => {
    if (
      design.police &&
      design.fondColor &&
      design.btnFondColor &&
      design.btnTextColor &&
      design.textColor &&
      design.titreColor
    ) {
      setLoad(true);
      let styles = enseigne.styles;
      styles.design = {
        ...design,
        btnFondColor: design.btnFondColor.hex,
        fondColor: design.fondColor.hex,
        btnTextColor: design.btnTextColor.hex,
        textColor: design.textColor.hex,
        titreColor: design.titreColor.hex,
      };

      updateEnseigneStyles(enseigne._id, styles)
        .then((res) => {
          setLoad(false);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          setLoad(false);
          notyf.error(err.message);
        });
    } else {
      notyf.error("Merci de sélectionner tous les choix obligatoires !");
    }
  };

  const appendGoogleFonts = (url) => {
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  /// append google app-fonts
  arrayFonts.map((font) => appendGoogleFonts(font.value));

  return (
    <div className="styles--colors">
      <div className="styles--top">
        <h1>Affichage et couleurs</h1>
        <hr />
      </div>
      {load ? (
        <Loader />
      ) : (
        <div className="styles--body">
          <div className="styles--colorBlock">
            <p>Choissiez les couleurs du site</p>

            <div className="styles--block">
              <div className="styles--body-colors">
                <div
                  className="styles--body-colors-left"
                  onClick={() => setMulled("btnFondColor")}
                >
                  <span>
                    {" "}
                    <RadioColor color={design.btnFondColor.hex} />{" "}
                  </span>
                  <div className="styles--body-colors-btn">
                    <p>Bouton</p>
                    <p className="styles--body-colors-btn-principal">
                      ( votre couleur principale )
                    </p>
                  </div>
                </div>
                {mulled === "btnFondColor" && (
                  <>
                    <div className="picker-bg" onClick={() => setMulled("")}>
                      &nbsp;
                    </div>
                    <div className="styles--body-colors-picker">
                      <SketchPicker
                        color={design.btnFondColor}
                        onChangeComplete={handleChangeComplete}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="styles--body-colors">
                <div
                  className="styles--body-colors-left"
                  onClick={() => setMulled("btnTextColor")}
                >
                  <span>
                    {" "}
                    <RadioColor color={design.btnTextColor.hex} />{" "}
                  </span>
                  <p>Texte bouton</p>
                </div>
                {mulled === "btnTextColor" && (
                  <>
                    <div className="picker-bg" onClick={() => setMulled("")}>
                      &nbsp;
                    </div>
                    <div className="styles--body-colors-picker">
                      <SketchPicker
                        color={design.btnTextColor}
                        onChangeComplete={handleChangeComplete}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="styles--block">
              <div className="styles--body-colors">
                <div
                  className="styles--body-colors-left"
                  onClick={() => setMulled("fondColor")}
                >
                  <span>
                    {" "}
                    <RadioColor color={design.fondColor.hex} />{" "}
                  </span>
                  <p>Fond</p>
                </div>
                {mulled === "fondColor" && (
                  <>
                    <div className="picker-bg" onClick={() => setMulled("")}>
                      &nbsp;
                    </div>
                    <div className="styles--body-colors-picker">
                      <SketchPicker
                        color={design.fondColor}
                        onChangeComplete={handleChangeComplete}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="styles--body-colors">
                <div
                  className="styles--body-colors-left"
                  onClick={() => setMulled("textColor")}
                >
                  <span>
                    <RadioColor color={design.textColor.hex} />
                  </span>
                  <p>Texte</p>
                </div>
                {mulled === "textColor" && (
                  <>
                    <div className="picker-bg" onClick={() => setMulled("")}>
                      &nbsp;
                    </div>
                    <div className="styles--body-colors-picker">
                      <SketchPicker
                        color={design.textColor}
                        onChangeComplete={handleChangeComplete}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="styles--block">
              <div className="styles--body-colors">
                <div
                  className="styles--body-colors-left"
                  onClick={() => setMulled("titreColor")}
                >
                  <span>
                    {" "}
                    <RadioColor color={design.titreColor.hex} />{" "}
                  </span>
                  <p>Couleur titre produit</p>
                </div>
                {mulled === "titreColor" && (
                  <>
                    <div className="picker-bg" onClick={() => setMulled("")}>
                      &nbsp;
                    </div>
                    <div className="styles--body-colors-picker">
                      <SketchPicker
                        color={design.titreColor}
                        onChangeComplete={handleChangeComplete}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="styles--body-colors">
                <div className="styles--body-colors-left polices">
                  <p>Police d'ecriture</p>
                  <span className="select-container">
                    <Select
                      required
                      options={arrayFonts}
                      value={design.police}
                      onChange={(value) =>
                        setDesign({ ...design, police: value })
                      }
                      defaultValue={design.police}
                      className="basic-single"
                      classNamePrefix="select"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="styles--body-colors-demo"
            style={{ backgroundColor: design.fondColor.hex }}
          >
            <div className="styles--body-colors-demo-top">
              <p
                style={{
                  backgroundColor: design.btnFondColor.hex,
                  color: design.btnTextColor.hex,
                  fontFamily: design.police.name,
                }}
              >
                TACOS
              </p>
              <p
                style={{
                  color: design.textColor.hex,
                  fontFamily: design.police.name,
                }}
              >
                BURGER
              </p>
              <p
                style={{
                  color: design.textColor.hex,
                  fontFamily: design.police.name,
                }}
              >
                PIZZA
              </p>
              <p
                style={{
                  color: design.textColor.hex,
                  fontFamily: design.police.name,
                }}
              >
                BOISSON
              </p>
            </div>

            <div className="styles--body-colors-demo-middle">
              <p
                style={{
                  color: design.textColor.hex,
                  fontFamily: design.police.name,
                }}
              >
                TACOS
              </p>
              <i className="fas fa-angle-up"></i>
            </div>

            <div className="styles--body-colors-demo-content">
              <div>
                <p
                  style={{
                    color: design.titreColor.hex,
                    fontFamily: design.police.name,
                  }}
                >
                  CHEESEBURGER
                </p>
                <span style={{ color: design.textColor.hex }}>
                  Salades, tomates, oignons , cheddar, 2 steacks ,cornichons,
                  margarine
                </span>
                <p style={{ color: design.textColor.hex }}>5.99€</p>
              </div>
              <img alt="burger" src={burger} />
            </div>
            <button
              style={{
                backgroundColor: design.btnFondColor.hex,
                color: design.btnTextColor.hex,
              }}
            >
              <span>
                <Bag color={design.btnTextColor.hex} />
                <p style={{ color: design.btnTextColor.hex }}>3</p>
              </span>
              <p
                style={{
                  color: design.btnTextColor.hex,
                  fontFamily: design.police.name,
                }}
              >
                PANIER
              </p>
              <p style={{ color: design.btnTextColor.hex }}>0€</p>
            </button>
          </div>
        </div>
      )}

      <div className="styles--top">
        <button onClick={handleValidate}>Valider</button>
      </div>
    </div>
  );
};

export default Colors;
