import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "~/assets/svg/close.svg";

const AddCreditSmsModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [sms, setSms] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await props.handleAddCredit({ sms });
      setSms("");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      props.notyf.error(
        "Une erreur s'est produite: " +
          (err.response?.data.message || err.message)
      );
    }
  };

  return (
    <Modal
      className='modal addCredit'
      show={props.show}
      onHide={props.handleClose}
    >
      <form onSubmit={onSubmit}>
        <Modal.Body>
          <div className='header'>
            <h1 className='modal--title'>{props.storeSelected?.intitule}</h1>
            <img
              onClick={() => {
                props.handleClose();
                setSms("");
              }}
              className='modal--close'
              alt='close-svg'
              src={closeIcon}
            />
          </div>
          <div className='inputs'>
            <div>
              <input
                required
                value={sms}
                onChange={(e) => setSms(e.target.value)}
                name='intitule'
                placeholder='Nombre de sms'
              />
              <span>SMS</span>
            </div>
          </div>
          <div>
            <button className='modal--btn-validate' type='submit'>
              {loading
                ? "Chargement..."
                : `Ajouter ${sms > 0 ? sms + " SMS" : ""}`}
            </button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default AddCreditSmsModal;
