import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
import { updateGroupe } from "~/api/menu";
//svg
import closeIcon from "~/assets/svg/close.svg";
//CSS
import "../_edit_item.scss";
import { groupTypes } from "~/constants/groupType";
import Ingredients from "./ingredients/Ingredients";
import { useForm } from "react-hook-form";

const EditGroupe = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const initGroupe = {
    name: "",
    titre: "",
    selections: [],
  };
  const initTymp = {
    idIngredient: -1,
    idProduct: -1,
    price: 0,
    typeIngredient: "ingredient",
  };

  const { register, control } = useForm({ initTymp });

  const [load, setLoad] = useState(false);
  const [groupe, setGroupe] = useState(initGroupe);
  const [temp, setTemp] = useState(initTymp);
  const [selections, setSelections] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [dataList, setDataList] = useState([]);
  const [sendToKitcken, setSendToKitcken] = useState(
    props.groupe.sendToKitcken
  );

  useEffect(() => {
    setGroupe(props.groupe);
    setSelections(props.groupe.selections);
  }, [props.groupe]);

  useEffect(() => {
    if (selectType === "ingredient") {
      setDataList(props.ingredients.filter((ingredient) => ingredient?.enable));
    } else {
      setDataList(props.products.filter((product) => product?.enable));
    }
  }, [props.ingredients, props.products, selectType]);

  const handleEdit = (e) => {
    e.preventDefault();

    if (selections.length < 1) {
      notyf.error("Merci d'ajouter les ingredients !");
      return false;
    }

    setLoad(true);

    (async function todo() {
      selections.map((item) => {
        if (item.idProduct === -1) {
          return delete item.idProduct;
        }
        if (item.idIngredient === -1) {
          return delete item.idIngredient;
        }
        return item;
      });

      let newGroupe = {
        ...groupe,
        selections,
        sendToKitcken,
      };

      await updateGroupe(newGroupe)
        .then((res) => {
          let groupes = res.data.groupes;
          props.init(groupes);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          notyf.error(err.message);
        });

      setLoad(false);
      props.handleCloseEdit(false);
    })();
  };

  const handleAddTemp = (e) => {
    e.preventDefault();
    if (
      !temp || temp.typeIngredient === "ingredient"
        ? temp.idIngredient < 0
        : temp.idProduct < 0
    ) {
      notyf.error("Merci de selectionner un ingredient avec leur prix !");
      return false;
    }
    setSelections([...selections, temp]);
    setTemp({ ...initTymp, typeIngredient: selectType });
  };

  const handleDeleteTemp = (id) => {
    let itemExist = selections.find(
      (item) => item.idIngredient === id || item.idProduct === id
    );

    if (itemExist.typeIngredient === "produit") {
      setSelections(
        selections.filter((item) => item.idProduct !== itemExist.idProduct)
      );
    } else {
      setSelections(
        selections.filter(
          (item) => item.idIngredient !== itemExist.idIngredient
        )
      );
    }
  };

  // Get ingredient name
  const getIngredientName = (id) => {
    let sls = selections.find(
      (row) => row.idIngredient === id || row.idProduct === id
    );

    const ing = [...props.ingredients, ...props.products].find(
      (row) => row._id === id
    );

    return `${ing?.name} ${sls.typeIngredient === "produit" ? "(P)" : "(I)"}`;
  };

  return (
    <Modal
      className="modal modal-groupe"
      show={props.showEdit}
      onHide={() => props.handleCloseEdit(false)}
    >
      <Modal.Header closeButton>
        <h1 className="modal--title">Modifier {groupe.name} </h1>
        <img
          onClick={() => props.handleCloseEdit(false)}
          className="modal--close"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>

      <form onSubmit={handleEdit} className="layout-content-switcher">
        <Modal.Body className="fade-in">
          <div className="inputs">
            <label>Nom du groupe </label>
            <input
              type="text"
              required
              name="name"
              value={groupe.name}
              {...register("name", { required: true })}
              onChange={(e) => setGroupe({ ...groupe, name: e.target.value })}
            />
          </div>
          <div className="inputs">
            <label>Titre sur le site </label>
            <input
              type="text"
              required
              name="titre"
              {...register("titre", { required: true })}
              value={groupe.titre}
              onChange={(e) => setGroupe({ ...groupe, titre: e.target.value })}
            />
          </div>
          <div className="inputs d-flex align-items-center mt-3">
            <label>Groupe envoyé en cuisine</label>
            <div className="cmd-choix ml-4">
              <span>
                <span
                  className={sendToKitcken ? "option active" : "option"}
                  onClick={() => setSendToKitcken(true)}
                >
                  Oui
                </span>
                <span
                  className={!sendToKitcken ? "option active" : "option"}
                  onClick={() => setSendToKitcken(false)}
                >
                  Non
                </span>
              </span>
            </div>
          </div>
          <div className="mt-4 mb-4">
            <h2 className="text-center mb-3">Selection d'ingredient</h2>
          </div>

          <div className="mb-4">
            <Ingredients
              handleAddTemp={handleAddTemp}
              temp={temp}
              setTemp={setTemp}
              handleDeleteTemp={handleDeleteTemp}
              dataList={dataList}
              selectType={selectType}
              setSelectType={setSelectType}
              groupTypes={groupTypes}
              selections={selections}
              getIngredientName={getIngredientName}
              setSelections={setSelections}
              {...{ control, register }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="one-button">
          <button type="submit" className="modal--btn-validate">
            {load ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Modifier"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditGroupe;
