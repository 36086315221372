import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
//import { uploadFile } from '~/api/upload';
import { createAllergene } from "~/api/menu";
//svg
import closeIcon from "~/assets/svg/close.svg";
import upload from "~/assets/svg/upload-lg.svg";
import { useLoadMedia } from "~/hooks/loadMedia";
//CSS
import "../_add_item.scss";
const AddAllergene = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const [allerg, setAllerg] = useState("");
  const [image, setImage] = useState("");
  //const [icon, setIcon] = useState({});
  const [loadImage, setLoadImage] = useState();
  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    //uppdateInfo,
    //setUpdateInfo,
    setLoadMedia,
    Media,
  } = useLoadMedia({
    allergenes: {
      boolean: loadImage,
      action: setLoadImage,
      name: "allergenes",
      updateValue: setImage,
    },
  });

  /* const handleUpload = (e) => {
    let target = e.target;
    let imageFile = target.files[0];
    let file = new FormData();
    file.append('file', imageFile);

    //var dim = { width: 100, height: 100 };

    if (!imageFile) {
      notyf.error('Veuillez sélectionner une image.');
    } else if (!imageFile.name.match(/\.(jpg|jpeg|png|svg|gif)$/)) {
      notyf.error('Veuillez sélectionner une image valide. ');
    } else if (target.files[0].size > 2097152) {
      notyf.error("La taille de l'image doit être inférieure à 2 Mo !");
    } else {
      let _URL = window.URL;
      let img = new Image();
      img.onload = function () {
        setIcon(file);
        setImage(URL.createObjectURL(file.get('file')));
      };
      img.src = _URL.createObjectURL(imageFile);
    }
  }; */

  const handleAdd = (e) => {
    e.preventDefault();

    let exists = props.allergenes.find((item) => {
      return item.name.toLowerCase() === allerg.toLowerCase();
    });
    if (exists) {
      notyf.error("Cet allergène est déjà ajouté");
      return;
    }

    setLoad(true);

    (async function todo() {
      let iconName = "";
      /* if (icon.constructor.name === 'FormData') {
        await uploadFile('icons', icon).then((res) => {
          iconName = res.data.filename;
        });
      } */

      iconName = image?.allergenes;
      let newAllergene = {
        name: allerg,
        imageUrl: iconName,
        storeId: props.storeId,
      };
      await createAllergene(newAllergene).then((res) => {
        let allergenes = res.data.allergenes;
        props.init(allergenes);
        notyf.success(res.data.message);
      });

      setLoad(false);
      props.handleCloseAdd(false);
    })();
  };

  return (
    <Modal
      className="modal add-allergie"
      show={props.showAdd}
      onHide={() => props.handleCloseAdd(false)}
    >
      <Modal.Header closeButton>
        <h1 className="modal--title">Ajouter un allergène</h1>
        <img
          onClick={() => props.handleCloseAdd(false)}
          className="modal--close"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>
      {loadMedia ? (
        <Media
          setLoadMedia={setLoadMedia}
          handleSelectedMedia={handleSelectedMedia}
        />
      ) : (
        <form onSubmit={handleAdd}>
          <Modal.Body className="fade-in">
            <div className="inputs">
              <label>Nom de l'alllergène</label>
              <input
                required
                name="name"
                value={allerg}
                onChange={(e) => setAllerg(e.target.value)}
              />
            </div>
            <p>Selectionnez un icon carré en PNG (max 100x100)</p>
            <div className="styles--body-upload">
              {/* <input
              type='file'
              id='upload--logo'
              onChange={handleUpload}
              name='logo'
            /> */}
              <label htmlFor="upload--logo">
                <img
                  alt="upload-file"
                  src={upload}
                  name="allergenes"
                  onClick={handleMedia}
                />
              </label>
              {image !== "" && (
                <img src={image.allergenes} alt="" style={{ width: 40 }} />
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button className="modal--btn-validate large" disabled={load}>
              {load ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Ajouter"
              )}
            </button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};

export default AddAllergene;
