import React from "react";
import { Switch, Route } from "react-router-dom";
import InformationStore from "./informations";
import InformationLegales from "./informationLegales";
// import PaiementStore from "./paiements";

const InfosStore = (props) => {
  return (
    <Switch>
      <Route
        exact
        path="/store/informations"
        component={() => <InformationStore store={props.store} />}
      />
      <Route
        exact
        path="/store/informations/informations-legales"
        component={() => <InformationLegales store={props.store} />}
      />
      {/* <Route exact path="/store/informations/paiement" component={() => <PaiementStore store={props.store} />} /> */}
    </Switch>
  );
};

export default InfosStore;
