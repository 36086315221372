import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Notyf } from 'notyf';

//components
import ChoixProduits from '../commun/ChoixProduits';
import Select from 'react-select';

const Etape1 = ({ promoDatas, update, retour, store }) => {

    const notyf = new Notyf({
        position: {
          x: "right",
          y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const typeProduit = [
        {value: 'moins', label: 'Le moins cher'},
        {value: 'plus', label: 'Le plus cher'},
    ];

    const { register, control, handleSubmit, setValue, watch } = useForm({defaultValues: promoDatas});
    const [showProduits, setShowProduits] = useState(false);
    const selectedProduits = watch("produitsIdentiqueOffert.produits");

    const onSubmit = (data) => {
        if( selectedProduits.length === 0 ){
            notyf.error("Veuillez sélectionner au moins un produit");
            return
        }
        if( data.produitsIdentiqueOffert.type instanceof Object ){
            data.produitsIdentiqueOffert.type = data.produitsIdentiqueOffert.type.value
        } 
        update({
            ...promoDatas, 
            produitsIdentiqueOffert: data.produitsIdentiqueOffert,
        });
    };

    const getSellectedProduits = (data) => {
        setValue('produitsIdentiqueOffert.produits', data);
        setShowProduits(false);
    }

    return (
        <div className="type5_etape1">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-group mb-5 type5_etape1_head">
                    <div>
                        <label htmlFor="nombre">Nombre de produit acheté = </label>
                        <div className="input-group">
                            <input type="number" id="nombre" name="nombre" {...register("produitsIdentiqueOffert.nombre", { required: true })} />
                            <div className="input-group-append">
                                <span className="input-group-text">Produits</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <button className="btn btn-light select-products" type="button" onClick={() => setShowProduits(!showProduits) }>selectionnez la catégorie ou les produits</button>

                    {selectedProduits && selectedProduits.length > 0 && (
                    <div className="selected_produits mb-4">
                        <input type="hidden" name="data" {...register("produitsIdentiqueOffert.produits")} />
                        <ul className="list-group">
                            { selectedProduits.map( (product, index) => 
                                <li className="d-flex" key={index}> 
                                <div>
                                    <img src={product.imageUrl} alt="" style={{width: 35}} />
                                </div>
                                <div>
                                    { product.name }
                                    {
                                        product.variant && 
                                        <span> [{product.variant.name}]</span>
                                    }
                                </div>
                                <div  className="remove-item">
                                    <button onClick={()=>{selectedProduits.splice(index, 1); getSellectedProduits(selectedProduits)}} type="button" className="btn btn-light">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </li>
                            ) }
                        </ul>
                    </div>
                )}
                </div>

                <div className="row">
                    <div className="col">
                        <label htmlFor="produitsIdentiqueOffert.type">Promotion sur le produit</label>
                        <Controller
                            name="produitsIdentiqueOffert.type"
                            control={control}
                            defaultValue={ typeProduit[0] }
                            render={({ field }) => (
                                <Select
                                    onChange={(e) => field.onChange(e.value)}
                                    className="basic-single" 
                                    classNamePrefix="select" 
                                    value={ typeProduit.find(type => type.value === field.value )} 
                                    options={typeProduit} 
                                />
                            )}
                        />
                    </div>
                </div> 

                <div className="form-group steps-controlers">
                    <div>
                        <button className="btn btn-dark" type="button" onClick={ retour }>Retour</button>
                    </div>
                    <div>
                        <button className="btn btn-warning" type="submit">Suivant</button>
                    </div>
                </div>
            </form>

            { showProduits && (
                <ChoixProduits 
                    store={ store } 
                    handleClose={ setShowProduits } 
                    show={ showProduits } 
                    submit={ getSellectedProduits }
                    default={ selectedProduits }
                />
            ) }

        </div>
    )
}

export default Etape1