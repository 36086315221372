import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "~/components/Loader";
import { Notyf } from "notyf";
import { GetFormattedDate } from '~/_helpers/helper';
import { updatePromo } from '~/api/promo';
import { dateToTimes } from '~/_helpers/helper';

//svg
import Radio from "~/components/Svgs/Super/addProduct/radio";


const PromoEdit = ({ promo, setEdit, setPromos, enseigne }) => {

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });
  
  const { register, handleSubmit, setValue } = useForm();
  const [load, setLoad] = useState(false);
  const [unlimited, setUnlimited] = useState(promo.unlimitedUses);

  const onSubmit = (data) => {

    let date = new Date();
    date.setDate(date.getDate() - 1);
    let { dateStart, dateEnd } = data ;

    if(  dateToTimes(dateEnd) < date.getTime() ) {
      notyf.error("Vous ne pouvez pas choisir une date antérieur");
      return
    } 
    
    if( dateToTimes(dateStart) > dateToTimes(dateEnd) ) {
      notyf.error("La date de départ doit être inférieure à la date de fin");
      return
    }  

    let newPromo = {
      ...promo, 
      ...data,
      unlimitedUses: unlimited,
    };

    setLoad(true);
    let enseigneId = enseigne._id;
    updatePromo(enseigneId, newPromo)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setPromos(res.data.promocodes);
        setEdit(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="promo--edit">
        
        <form className="inputs" onSubmit={handleSubmit(onSubmit)}>
          <div className="block-styled"> 
            <h1 className="block-styled-head text-center"><i className="fas fa-arrow-left" onClick={() => setEdit(false)}></i>  Code promo</h1> 
            <div className="block-styled-body">
              <div className="container">

                <div className="row">
                    <div className="col-sm-6">
                      <label>Nom de la promo</label>
                      <input type="text" name="name" defaultValue={promo.name} {...register("name", { required: true })} required />
                    </div>
                    <div className="col-sm-6">
                      <label>Code promo</label>
                      <input type="text" name="code" defaultValue={promo.code} {...register("code", { required: true })} required />
                    </div>
                  </div>

                  <div className="row" style={{alignItems:'flex-end'}}>
                    <div className="col">
                      <label>Remise de</label>
                      <input type="number" step="0.5" min="0" name="discountAmount" defaultValue={promo.discountAmount}  {...register("discountAmount", { required: true })} required />
                    </div>
                    <div className="col">
                      <label>&nbsp;</label>
                      <select name="method" defaultValue={promo.method} {...register("method", { required: true })} required >
                        <option value="fixe">euro €</option>
                        <option value="percent">pourcentage %</option>
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label>Promo à partir de </label>
                      <input type="number" step="0.01" min="0" name="startValue" defaultValue={promo.startValue}  {...register("startValue", { required: true })} required />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <label>Date début promo</label>
                      <input type="date" name="dateStart" defaultValue={GetFormattedDate(promo.dateStart)}  {...register("dateStart", { required: true })} required />
                    </div>
                    <div className="col-sm-6">
                      <label>Date fin promo</label>
                      <input type="date" name="dateEnd" defaultValue={GetFormattedDate(promo.dateEnd)}  {...register("dateEnd", { required: true })} required />
                    </div>
                  </div>

                  {
                    !unlimited &&
                    <div className="row">
                      <div className="col">
                        <label>Nombre d'utilisation</label>
                        <input type="number" step="1" min="0" name="usesPerCustomer" defaultValue={promo.usesPerCustomer}  {...register("usesPerCustomer")} />
                      </div>
                    </div>
                  }

                  <div className="row">
                    <div className="col">
                      <div className="d-flex">
                        <span>ou cocher pour utilisation illimité &nbsp; &nbsp;</span>
                        <i onClick={() => { setUnlimited(!unlimited); setValue("usesPerCustomer", ""); }} >
                          <Radio color={unlimited ? "#E08005" : "white"} />
                        </i>
                      </div>
                    </div>
                  </div>

              </div>

             <button>Valider</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
};

export default PromoEdit;
