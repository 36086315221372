import {
  LOGOUT_USER,
  AUTH_USER,
  AUTH_USER_FAILED,
  GET_USER_IN_S,
  CHANGE_ROLE_USER,
} from "~/constants/actionTypes";
import * as api from "~/api/index";

export const authUser = (email, password) => async (dispatch) => {
  try {
    const { data } = await api.authUser(email, password);
    if (data.isLogged) {
      const { token, isLogged } = data;
      const User = JSON.parse(window.atob(token.split(".")[1]));
      localStorage.setItem("token", token);
      localStorage.setItem("isLogged", isLogged);

      dispatch({ type: AUTH_USER, payload: User });
    } else {
      dispatch({ type: AUTH_USER_FAILED, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: AUTH_USER_FAILED, payload: error.message });
    console.log(error.message);
  }
};

export const logoutUser = () => (dispatch) => {
  try {
    localStorage.clear();
    dispatch({ type: LOGOUT_USER });
  } catch (error) {
    console.log(error.message);
  }
};

export const getUserInSession = () => (dispatch) => {
  try {
    if (
      localStorage.getItem("isLogged") !== "true" ||
      localStorage.getItem("token") === null
    ) {
      dispatch({ type: LOGOUT_USER });
    } else {
      const User = JSON.parse(
        window.atob(localStorage.getItem("token").split(".")[1])
      );
      dispatch({ type: GET_USER_IN_S, payload: User });
    }
  } catch (error) {
    console.log(error.message);
  }
};

export const changeUserRole = (role) => (dispatch) => {
  try {
    if (
      localStorage.getItem("isLogged") !== "true" ||
      localStorage.getItem("token") === null
    ) {
      dispatch({ type: LOGOUT_USER });
    } else {
      const User = JSON.parse(
        window.atob(localStorage.getItem("token").split(".")[1])
      );
      if (
        User.scope === "ROLE_SUPREMEADMIN" ||
        (User.scope === "ROLE_SUPERADMIN" && role !== "ROLE_SUPREMEADMIN")
      ) {
        dispatch({ type: CHANGE_ROLE_USER, payload: role });
      }
    }
  } catch (error) {
    console.log(error.message);
  }
};
