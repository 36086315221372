import React, { useLayoutEffect, useState } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
//import SidebarMobile from "./Sidebar/Mobile";
import SidebarLaptop from "./Sidebar/Laptop";
import { useMedia } from "use-media";
/* SUPREME */
import SupremeDashboard from "./SupremeInterface/Dashboard";
import SupremeAccess from "./SupremeInterface/Access";

/* SUPER */
import SuperDashboard from "./EnseigneInterface/Dashboard";
import Informations from "./EnseigneInterface/Informations";
import SuperAccess from "./EnseigneInterface/Access";
import Footer from "./Footer";
import Terminate from "./SupremeInterface/Terminate";
import Styles from "./EnseigneInterface/Styles";
import Loyalty from "./EnseigneInterface/Loyalty";
import Promo from "./EnseigneInterface/Promo";
import Media from "./EnseigneInterface/Media";
// Store
import Store from "./StoreInterface";
// Import Redux / actions
import { useSelector, useDispatch } from "react-redux";
import { changeUserRole } from "~/redux/actions/userAction";
// Helper
import {
  isPathInternePages,
  isEnseignepage,
  isBoutiquepage,
} from "~/_helpers/helper";
import Loader from "~/components/Loader";
import PartnerSms from "./SupremeInterface/PartnerSms";

const Online = (props) => {
  // Variable global
  const user = useSelector((state) => state.user);
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  // Varaiable local
  const [isLoading, setLoading] = useState(true);
  const [hide, setHide] = useState("close");
  const [hideL, setHideL] = useState("openL");
  const isMobile = useMedia({ maxWidth: 767 });

  //  Functions
  useLayoutEffect(() => {
    //fake security
    if (user.data === null) {
      history.push("/");
    } else {
      if (
        isEnseignepage(location.pathname) &&
        user.data.scope === "ROLE_SUPREMEADMIN"
      ) {
        dispatch(changeUserRole("ROLE_SUPERADMIN"));
      } else if (
        isBoutiquepage(location.pathname) &&
        (user.data.scope === "ROLE_SUPERADMIN" ||
          user.data.scope === "ROLE_SUPREMEADMIN")
      ) {
        dispatch(changeUserRole("ROLE_STORE"));
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.data]);

  //const handleOpen = () => { hide === "close" ? setHide("open") : setHide("close") };
  const handleOpenL = () => {
    hideL === "openL" ? setHideL("closeL") : setHideL("openL");
  };

  // Components
  //const sideBarreMobile = ( <SidebarMobile hide={hide} handleOpen={handleOpen} role={user.currentRole} /> )
  const sideBarrelaptop = (
    <SidebarLaptop
      hide={hideL}
      handleOpen={handleOpenL}
      role={user.currentRole}
      isMobile={isMobile}
      location={props.location}
    />
  );

  // Global Content
  const sidebar = sideBarrelaptop; //isMobile ? sideBarreMobile : sideBarrelaptop ;

  // Content Suprem
  const routSuprem = (
    <Switch>
      <Route exact path="/dashboard" component={SupremeDashboard} />
      <Route path="/dashboard/access" component={SupremeAccess} />
      <Route path="/dashboard/terminate" component={Terminate} />
      <Route path="/dashboard/partnerSms" component={PartnerSms} />
    </Switch>
  );

  // Content Enseigne
  const routSuper = (
    <Switch>
      <Route path="/enseigne/dashboard" component={SuperDashboard} />
      <Route path="/enseigne/informations" component={Informations} />
      <Route path="/enseigne/access" component={SuperAccess} />
      <Route path="/enseigne/loyalty" component={Loyalty} />
      <Route path="/enseigne/code-promo" component={Promo} />
      <Route path="/enseigne/media" component={Media} />
    </Switch>
  );

  // Content Boutique
  const routStore = (
    <Switch>
      <Route path="/store" component={Store} />
    </Switch>
  );

  const routInterne = (
    <Switch>
      <Route
        path="/enseigne/styles"
        component={() => <Styles hide={hide} setHide={setHide} />}
      />
    </Switch>
  );

  // Main content
  let mainContent = "";
  switch (user.currentRole) {
    case "ROLE_SUPREMEADMIN":
      mainContent = routSuprem;
      break;
    case "ROLE_SUPERADMIN":
      mainContent = routSuper;
      break;
    case "ROLE_STORE":
      mainContent = routStore;
      break;
    default:
      break;
  }

  mainContent = (
    <>
      {sidebar}

      <div
        className={
          hideL === "closeL" ? "online--right-close" : "online--right-open"
        }
      >
        {isPathInternePages(location.pathname) ? routInterne : mainContent}
      </div>
    </>
  );

  return (
    // Affichage selon le Role User
    <div className="online--content">
      {isLoading ? <Loader /> : mainContent}
      <Footer />
    </div>
  );
};

export default Online;
