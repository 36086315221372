import React from "react";

const OrdersIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr">
        <svg style={{marginLeft: -4, marginTop: -2}} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.606 9.64376C19.4492 8.26856 18.2854 7.22998 16.9013 7.22998H8.09874C6.71463 7.22998 5.55084 8.26856 5.39396 9.64375L4.09066 21.0688C4.02392 21.6539 4.48155 22.1667 5.07047 22.1667H19.9295C20.5184 22.1667 20.9761 21.6539 20.9093 21.0688L19.606 9.64376Z" stroke="#393838" strokeWidth="1.5"/>
            <path d="M14.8564 10.8139C15.0517 10.6187 15.3683 10.6187 15.5635 10.8139C15.7588 11.0092 15.7588 11.3258 15.5635 11.521L14.8564 10.8139ZM9.49927 11.521C9.304 11.3258 9.304 11.0092 9.49927 10.8139C9.69453 10.6187 10.0111 10.6187 10.2064 10.8139L9.49927 11.521ZM15.5635 11.521L15.3402 11.7443L14.6331 11.0372L14.8564 10.8139L15.5635 11.521ZM9.72254 11.7443L9.49927 11.521L10.2064 10.8139L10.4296 11.0372L9.72254 11.7443ZM15.3402 11.7443C13.789 13.2956 11.2738 13.2956 9.72254 11.7443L10.4296 11.0372C11.5904 12.198 13.4724 12.198 14.6331 11.0372L15.3402 11.7443Z" fill="#393838"/>
            <path d="M9.375 6.67537C9.375 2.79212 11.4583 2.0835 12.5 2.0835C13.5417 2.0835 15.625 2.79212 15.625 6.67537" stroke="#393838" strokeWidth="1.5"/>
        </svg>

    </span>
  );
};

export default OrdersIcon;
