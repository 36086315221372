import React from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "~/assets/svg/close.svg";

const DeleteStore = (props) => {
  return (
    <Modal
      className="modal"
      show={props.deleteStoreModal}
      onHide={props.handleCloseDeleteStoreModal}
    >
      <Modal.Header closebutton>
        <h1 className="modal--title">Confirmation</h1>
        <img
          onClick={props.handleCloseDeleteStoreModal}
          className="modal--close"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>
      <Modal.Body>
        Êtes-vous sûr de vouloir résilier cette boutique ?
      </Modal.Body>
      <Modal.Footer>
        <button
          className="modal--btn-close"
          onClick={props.handleCloseDeleteStoreModal}
        >
          Retour
        </button>
        <button
          className="modal--btn-validate"
          onClick={props.handleDeleteStore}
        >
          {props.deleteStoreLoad ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            "Oui"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteStore;
