import React from "react";
import { useMedia } from "use-media";
import { useHistory } from "react-router-dom";

const Index = ({ setHide }) => {
  const isTablet = useMedia({ minWidth: 968 });
  const history = useHistory();
  const activeMenu = history.location.pathname.split("/").pop();
  const handleRedirect = (location) => {
    history.push(`/enseigne/styles/${location}`);
    if (!isTablet) {
      setHide("close");
    }
  };
  return (
    <>
      <h5>STYLE</h5>
      <ul>
        <li
          className={activeMenu === "banner" ? "active" : ""}
          onClick={() => handleRedirect("banner")}
        >
          Bannière
        </li>
        <li
          className={activeMenu === "logo" ? "active" : ""}
          onClick={() => handleRedirect("logo")}
        >
          Logo
        </li>
        <li
          className={activeMenu === "products" ? "active" : ""}
          onClick={() => handleRedirect("products")}
        >
          Produits
        </li>
        <li
          className={activeMenu === "variants" ? "active" : ""}
          onClick={() => handleRedirect("variants")}
        >
          Choix
        </li>
        <li
          className={activeMenu === "colors" ? "active" : ""}
          onClick={() => handleRedirect("colors")}
        >
          Couleurs
        </li>

        <li
          className={activeMenu === "mobile" ? "active" : ""}
          onClick={() => handleRedirect("mobile")}
        >
          Personnalisé
        </li>
      </ul>
    </>
  );
};

export default Index;
