import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
import { createGroupe } from "~/api/menu";
//svg
import closeIcon from "~/assets/svg/close.svg";
import { useForm } from "react-hook-form";

//CSS
import "../_edit_item.scss";
import { groupTypes } from "~/constants/groupType";
import { useEffect } from "react";
import Ingredients from "./ingredients/Ingredients";

const AddGroupe = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const initGroupe = {
    name: "",
    titre: "",
    selections: [],
  };
  const initTymp = {
    idIngredient: -1,
    idProduct: -1,
    price: 0,
    typeIngredient: "ingredient",
  };

  const { register, control } = useForm({ initTymp });

  const [load, setLoad] = useState(false);
  const [groupe, setGroupe] = useState(initGroupe);
  const [temp, setTemp] = useState(initTymp);
  const [selections, setSelections] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [dataList, setDataList] = useState([]);
  const [sendToKitcken, setSendToKitcken] = useState(true);

  const handleAdd = (e) => {
    e.preventDefault();

    if (selections.length < 1) {
      notyf.error("Merci d'ajouter les ingredients !");
      return false;
    }

    let exists = props.groupes.find((item) => {
      return item.name.toLowerCase() === groupe.name.toLowerCase();
    });
    if (exists) {
      notyf.error("Cet groupe est déjà ajouté");
      return;
    }

    setLoad(true);

    (async function todo() {
      let menuActive = localStorage.getItem("menuActive");
      selections.map((item) => {
        if (item.idProduct === -1) {
          return delete item.idProduct;
        }
        if (item.idIngredient === -1) {
          return delete item.idIngredient;
        }
        return item;
      });

      let newGroupe = {
        ...groupe,
        selections,
        storeId: props.storeId,
        menuId: menuActive,
        sendToKitcken,
      };
      await createGroupe(newGroupe)
        .then((res) => {
          let groupes = res.data.groupes;
          props.init(groupes);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          notyf.error(err.message);
        });

      setLoad(false);
      props.handleCloseAdd(false);
    })();
  };

  const handleAddTemp = (e) => {
    e.preventDefault();

    if (
      !temp || temp.typeIngredient === "ingredient"
        ? temp.idIngredient < 0
        : temp.idProduct < 0
    ) {
      notyf.error("Merci de selectionner un ingredient avec leur prix !");
      return false;
    }

    setSelections([...selections, temp]);
    setTemp({ ...initTymp, typeIngredient: selectType });
  };

  // Delete selection
  const handleDeleteTemp = (id) => {
    let sls = selections.filter((row) =>
      row.typeIngredient === "ingredient"
        ? row.idIngredient !== id
        : row.idProduct !== id
    );
    setSelections(sls);
  };

  // Get ingredient name
  const getIngredientName = (id) => {
    let sls = selections.find(
      (row) => row.idIngredient === id || row.idProduct === id
    );

    const ing = [...props.products, ...props.ingredients].find(
      (item) => item._id === id
    );

    return `${ing?.name} ${
      sls.typeIngredient === "ingredient" ? "(I)" : "(P)"
    }`;
  };

  useEffect(() => {
    if (selectType === "ingredient") {
      setDataList(props.ingredients.filter((ingredient) => ingredient?.enable));
    } else {
      setDataList(props.products.filter((product) => product?.enable));
    }
  }, [selectType, props.ingredients, props.products]);

  return (
    <Modal
      className="modal modal-groupe"
      show={props.showAdd}
      onHide={() => props.handleCloseAdd(false)}
    >
      <Modal.Header closeButton>
        <h1 className="modal--title">Ajout Groupe ingredients </h1>
        <img
          onClick={() => props.handleCloseAdd(false)}
          className="modal--close"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>

      <form onSubmit={handleAdd}>
        <Modal.Body className="fade-in">
          <div className="inputs">
            <label>Nom du groupe </label>
            <input
              type="text"
              required
              name="name"
              value={groupe.name}
              onChange={(e) => setGroupe({ ...groupe, name: e.target.value })}
            />
          </div>
          <div className="inputs">
            <label>Titre sur le site </label>
            <input
              type="text"
              required
              name="titre"
              value={groupe.titre}
              onChange={(e) => setGroupe({ ...groupe, titre: e.target.value })}
            />
          </div>

          <div className="inputs d-flex align-items-center mt-3">
            <label>Groupe envoyé en cuisine</label>
            <div className="cmd-choix ml-4">
              <span>
                <span
                  className={sendToKitcken ? "option active" : "option"}
                  onClick={() => setSendToKitcken(true)}
                >
                  Oui
                </span>
                <span
                  className={!sendToKitcken ? "option active" : "option"}
                  onClick={() => setSendToKitcken(false)}
                >
                  Non
                </span>
              </span>
            </div>
          </div>
          <div className="mt-3">
            <h2 className="text-center mb-3">Selection d'ingredient</h2>
          </div>
          <div className="mb-4">
            <Ingredients
              handleAddTemp={handleAddTemp}
              temp={temp}
              setTemp={setTemp}
              handleDeleteTemp={handleDeleteTemp}
              dataList={dataList}
              selectType={selectType}
              setSelectType={setSelectType}
              groupTypes={groupTypes}
              selections={selections}
              getIngredientName={getIngredientName}
              setSelections={setSelections}
              {...{ control, register }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="one-button">
          <button className="modal--btn-validate" disabled={load}>
            {load ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Ajouter"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddGroupe;
