import React, { useEffect, useState } from "react";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Notyf } from "notyf";

// Utils
import { legalInfosType } from "~/constants/legalInfosType";
import { createLegalInfo, fetchLegalInfo } from "~/api/legalInfos";
import Loader from "~/components/Loader";

function InformationLegales(props) {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const store = props.store;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  const [title, setTitle] = useState({
    label: "Mention Légales",
    value: "mentions",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("type", title.value);
    formData.append("title", title.label);
    formData.append("body", value);

    try {
      const res = await createLegalInfo(store._id, formData);
      notyf.success(res.message);
    } catch (error) {
      notyf.success(error.message);
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    const getLegalInfo = async (storeId, type) => {
      setLoading(true);

      try {
        const res = await fetchLegalInfo(storeId, type);
        setTitle({
          label: res.response.title,
          value: res.response.type,
        });

        setValue(res.response.body);
        setLoading(false);
      } catch (err) {
        setValue("");
        setLoading(false);
        return null;
      }
    };

    getLegalInfo(store._id, title.value);
  }, [store, title.value]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container">
      <div className="page-informations-legales">
        <div className="row gx-5 justify-content-center">
          <div className="col-sm-10">
            <div className="block-styled">
              <h1 className="block-styled-head">Informations Légales</h1>

              <form className="block-styled-body carde" onSubmit={handleSubmit}>
                <div className="col-sm-12 mb-4">
                  <Select
                    options={legalInfosType.map((type) => ({
                      value: type.value,
                      label: type.label,
                    }))}
                    value={title}
                    onChange={(value) => setTitle(value)}
                    className="basic-single"
                    classNamePrefix="select"
                  />
                </div>
                <div className="col-sm-12 mb-4">
                  <ReactQuill
                    id="body"
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    placeholder="Description..."
                    className="quill-container"
                  />
                </div>

                <div className="col-sm-12 mt29">
                  <button
                    type="submit"
                    className="modal--btn-validate validateBtn"
                  >
                    Valider
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InformationLegales;
