import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
//import { uploadFile } from '~/api/upload';
import { updateSection, deleteSection } from "~/api/menu";
//svg
import closeIcon from "~/assets/svg/close.svg";
import RadioColor from "../../../../Svgs/Super/styles/radioColor";
//CSS
import "../_add_item.scss";
import { SketchPicker } from "react-color";

const EditSection = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  let obj = {
    item: { hex: props.section.color },
  };

  const [load, setLoad] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [sect, setSect] = useState(props.section);
  const [color, setColor] = useState(props.section.color);
  const [mulled, setMulled] = useState("");
  const [design, setDesign] = useState(obj);

  const handleEdit = (e) => {
    e.preventDefault();
    setLoad(true);

    (async function todo() {
      let newSection = {
        ...sect,
        name: sect.name,
        color: color,
      };
      await updateSection(newSection)
        .then((res) => {
          let sections = res.data.sections;
          props.init(sections);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          notyf.error(err.message);
        });

      setLoad(false);
      props.handleCloseEdit(false);
    })();
  };

  const handleDelete = async () => {
    setLoadDelete(true);
    await deleteSection(sect._id)
      .then((res) => {
        let sections = res.data.sections;
        props.init(sections);
        notyf.success(res.data.message);

        setLoadDelete(false);
        props.handleCloseEdit(false);
      })
      .catch((err) => {
        setLoadDelete(false);
        notyf.error(err.message);
      });
  };

  const handleChangeComplete = (color) => {
    setDesign({
      ...design,
      [mulled]: color,
    });
    setColor(color.hex);
  };

  return (
    <Modal
      className="modal edit-allergie"
      show={props.showEdit}
      onHide={() => props.handleCloseEdit(false)}
    >
      <Modal.Header closeButton>
        <h1 className="modal--title">Modifier {sect.name} </h1>
        <img
          onClick={() => props.handleCloseEdit(false)}
          className="modal--close"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>

      <form onSubmit={handleEdit}>
        <Modal.Body className="fade-in">
          <div className="inputs">
            <label>Nom de la section</label>
            <input
              required
              name="name"
              value={sect.name}
              onChange={(e) => setSect({ ...sect, name: e.target.value })}
            />
          </div>

          <div className="inputs">
            <label>Choisissez la couleur de la section</label>
            <div
              className="styles--body-colors"
              style={{ width: "100%", padding: "1rem" }}
            >
              <div
                className="styles--body-colors-left"
                onClick={() => setMulled("item")}
              >
                <span>
                  <RadioColor color={design.item.hex} />
                </span>
                <div className="styles--body-colors-btn">
                  <p className="styles--body-colors-btn-principal">
                    ( votre couleur )
                  </p>
                </div>
              </div>
              {mulled === "item" && (
                <>
                  <div className="picker-bg" onClick={() => setMulled("")}>
                    &nbsp;
                  </div>
                  <div className="styles--body-colors-picker">
                    <SketchPicker
                      color={design.item}
                      onChangeComplete={handleChangeComplete}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="modal--btn-validate">
            {load ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Modifier"
            )}
          </button>
          <button
            type="button"
            className="modal--btn-validate large"
            onClick={handleDelete}
          >
            {loadDelete ? (
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Supprimer"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditSection;
