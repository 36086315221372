import React from 'react';
import { useForm, Controller } from "react-hook-form";
import { generateCode } from '~/_helpers/helper';
import { Notyf } from "notyf";
//components
import Select from 'react-select';

const Etape1 = ({ promoDatas, update, retour }) => {

    const typeReduction = [
        {value: 'fixe', label: '€'},
        {value: 'percent', label: '%'},
    ];

    const notyf = new Notyf({
        position: { x: "center", y: "top", },
        duration: 3000,
        dismissible: true,
    });

    const { register, control, handleSubmit, setValue, watch } = useForm({defaultValues: promoDatas});
    const codePromo = watch("codePromo");

    const onSubmit = (data) => {
        if( data.reduction.method === 'fixe' ){
            if( parseFloat(data.reduction.valeur) >  data.startAmount){
                notyf.error("Le coût de la réduction ne doit pas être supérieur à la somme de déclenchement");
                return
            }
        }else{
            if( parseFloat(data.reduction.valeur) >  100){
                notyf.error("Le coût de la réduction ne doit pas dépasser 100%");
                return
            }
        }
        
        update({
            ...promoDatas, 
            codePromo: data.codePromo,
            startAmount: data.startAmount,
            reduction: data.reduction,
        });
    };

    const getCode = (e) => {
        let checked = e.target.checked;
        if( checked ){
            let code = "LO"+ generateCode(7) ;
            setValue('codePromo', code);
        }else{
            setValue('codePromo', '');
        }
    }

    return (
        <div className="type1_etape1">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="startAmount">Sommes d’achat pour declencher la reduction</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" id="startAmount" name="startAmount" {...register("startAmount", { required: true })} />
                        <div className="input-group-append">
                            <span className="input-group-text">€</span>
                        </div>
                    </div>
                </div>
                
                <div className="form-group">
                    <label htmlFor="reduction.valeur">Coût de la réduction</label>
                    <div className="input-group reduction-cost">
                        <input type="text" className="form-control" id="reduction.valeur" name="reduction.valeur" {...register("reduction.valeur", { required: true })} />
                        <div className="input-group-append">
                            <Controller
                                name="reduction.method"
                                control={control}
                                defaultValue={ typeReduction[0] }
                                render={({ field }) => (
                                    <Select
                                        onChange={(e) => field.onChange(e.value)}
                                        className="basic-single" 
                                        classNamePrefix="select" 
                                        value={ typeReduction.find(type => type.value === field.value )} 
                                        options={typeReduction} 
                                    />
                                )}
                            />
                        </div>
                        <div className="input-group-prepend">
                            <label className="input-group-text">de reduction</label>
                        </div>
                    </div>
                </div>

                <div className="custom-control custom-checkbox mb-3">
                    <input type="checkbox" checked={codePromo} className="custom-control-input" id="generate_code" onChange={ getCode } />
                    <label className="custom-control-label" htmlFor="generate_code">Générer un code promo</label>
                </div>

                { codePromo && (
                    <div className="form-group">
                        <label htmlFor="codePromo">Code promo</label>
                        <input type="text" className="form-control" id="codePromo" name="codePromo" { ...register("codePromo") } />
                    </div>
                ) } 
                <div className="form-group steps-controlers">
                    <div>
                        <button className="btn btn-dark" type="button" onClick={ retour }>Retour</button>
                    </div>
                    <div>
                        <button className="btn btn-warning" type="submit">Suivant</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Etape1
