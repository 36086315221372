import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

// components
import SidebarMenu from "./SidebarMenu";
import AllergenesStore from "./allergenes";
import Additionnel from "./additionel";
import EnviesStore from "./envies";
import IngredientsStore from "./ingredients";
import CategorieStore from "./categories";
import GroupesStore from "./groupes";
import ProduitsStore from "./produits";
import Menu from "./Menu";
// SCSS
import "./_listing.scss";
import CuisineStore from "./cuisine";

const MenuStore = (props) => {
  let location = useLocation();

  return (
    <div className="container-fluid page-menu-products">
      <div className="row">
        <div className="col-lg-10 col-md-9 col-sm-12 layout-content-switcher">
          <div className="container">
            <Switch>
              <Route
                exact
                path="/store/menu"
                component={() => <Menu store={props.store} />}
              />
              <Route
                exact
                path="/store/menu/allergenes"
                component={() => <AllergenesStore store={props.store} />}
              />
              <Route
                exact
                path="/store/menu/envies"
                component={() => <EnviesStore store={props.store} />}
              />
              <Route
                exact
                path="/store/menu/ingredients"
                component={() => <IngredientsStore store={props.store} />}
              />
              <Route
                exact
                path="/store/menu/groupes"
                component={() => <GroupesStore store={props.store} />}
              />
              <Route
                exact
                path="/store/menu/categories"
                component={() => <CategorieStore store={props.store} />}
              />
              <Route
                exact
                path="/store/menu/produits"
                component={() => <ProduitsStore store={props.store} />}
              />
              <Route
                exact
                path="/store/menu/additionnel"
                component={() => <Additionnel store={props.store} />}
              />
              <Route
                exact
                path="/store/menu/cuisine"
                component={() => <CuisineStore store={props.store} />}
              />
            </Switch>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-12 side-menu-container">
          {location.pathname !== "/store/menu" ? (
            <SidebarMenu show={true} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuStore;
