import React, { useState, useEffect } from 'react';
import { Notyf } from "notyf";
// Components
import Loader from "~/components/Loader";
import AddEnvie from './AddEnvie';
import EditEnvie from './EditEnvie';
import Confirm from '~/components/Commun/Confirm';
// Utils
import { getEnviesOfStore, deleteEnvie } from '~/api/menu';

//svg 
import Plus from "~/assets/svg/plus.svg";
import EditOrangeIcon from '~/components/Svgs/Shop/EditOrange';
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";

//Pagination
import TablePagination from '~/assets/table-pagination/'

const EnviesStore = (props) => {

    const notyf = new Notyf({
        position: {
          x: "center",
          y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const store = props.store ;
    const [load, setLoad] = useState(true);
    const [envies, setEnvies] = useState([]);
    const [toAdd, setToAdd] = useState(false);
    const [toEdit, setToEdit] = useState(false);
    const [toDelete, setToDelete] = useState(false);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        let storeId = store._id;
        let menuActive = localStorage.getItem("menuActive");
        getEnviesOfStore(storeId, menuActive)
            .then((res) => {
                setEnvies(res.data.wishes);
                setLoad(false);
            }).catch((err) => {
                setLoad(false);
                notyf.error(err.message);
            });
        // eslint-disable-next-line
    }, []);

    const handleDelete = () => {
        setLoad(true);
        setToDelete(false);
        deleteEnvie(selected._id)
            .then((res) => {
                let envies = res.data.wishes;
                setEnvies(envies);
                notyf.success(res.data.message);
                setLoad(false);
            }).catch((err) => {
                setLoad(false);
                notyf.error(err.message);
            });
    }


    /// Pagination 
    const tableColumns = [{
        Header: 'Nom',
        accessor: 'name'
    },{
        Header: 'Action',
        accessor: '_id', 
        className: "actions multiple-btns",
        Cell: ({ row })  => { 
            let envie = row.original
            return (
                <>
                    <button type="button" className="btn btn-light btn-edit"
                            onClick={ () => {setSelected(envie);setToEdit(true) } }
                    >
                        <EditOrangeIcon />
                    </button>
                    <button type="button" className="btn btn-danger btn-delete"
                            onClick={ () => { setSelected(envie);setToDelete(true) } }
                    ><TrashOutlineIcon /></button>
                </>
            )
        }
    }];


    return (
        <>
            {load ? (
                <Loader />
            ) : (
                <div> 

                    <div className="layout-head">
                        <div className="row">
                            <div className="col-6">
                            <h1>Envies</h1>
                            </div>
                            <div className="col">
                                <button type="button" className="modal--btn-validate" onClick={ () => setToAdd(true)  }>
                                    <img alt="plus" src={Plus} /> Ajouter un Ingredient
                                </button>
                            </div>
                        </div>
                    </div>

                    { envies.length ? (
                        <div className="layout-content">
                            <div className="block-styled container">
                                <div className="block-styled-body">
                                    <TablePagination columns={tableColumns} data={envies} />
                                </div>
                            </div> 
                        </div>
                    ) : (
                        <p className="mt-5 mb-5 text-center">Aucune données pour le moments...</p>
                    ) }

                    { toAdd && <AddEnvie storeId={store._id} envies={envies} showAdd={toAdd} handleCloseAdd={setToAdd} init={setEnvies} /> }
                    { toEdit && <EditEnvie envie={selected} showEdit={toEdit} handleCloseEdit={setToEdit} init={setEnvies} /> }
                    { toDelete && <Confirm show={toDelete} handleClose={setToDelete} 
                                            message="Êtes-vous sûr de vouloir supprimer l'envie ?" 
                                            handleConfirm={handleDelete} confirmeLoad={load} /> }
                </div>
            )}
        </>
    )
}

export default EnviesStore
