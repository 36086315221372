import React from "react";

const CancelIcon = (props) => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4587 1.53977L9.45991 0.541016L5.50033 4.5006L1.54074 0.541016L0.541992 1.53977L4.50158 5.49935L0.541992 9.45893L1.54074 10.4577L5.50033 6.4981L9.45991 10.4577L10.4587 9.45893L6.49908 5.49935L10.4587 1.53977Z" fill="#CB0909"/>
    </svg>
  );
};

export default CancelIcon;
