import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
import { createtEnvie } from '~/api/menu';
//svg
import closeIcon from "~/assets/svg/close.svg";
//CSS
import '../_add_item.scss';

const AddEnvie = (props) => {

    const notyf = new Notyf({
        position: {
          x: "center",
          y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const [load, setLoad] = useState(false);
    const [envie, setEnvie] = useState('');

    const handleAdd = (e) => {
        e.preventDefault();

        let exists = props.envies.find(item=>{return item.name.toLowerCase() === envie.toLowerCase()}) 
        if( exists ){
            notyf.error("Cet ingrédient est déjà ajouté");
            return
        }

        setLoad(true);

        (async function todo() {
            let menuActive = localStorage.getItem("menuActive");
            let newEnvie = {
                name: envie,
                storeId: props.storeId,
                menuId: menuActive
            }
            await createtEnvie(newEnvie)
                .then((res) => {
                    let envies = res.data.wishes;
                    props.init(envies);
                    notyf.success(res.data.message);
                }).catch((err) => {
                    notyf.error(err.message);
                });

            setLoad(false);
            props.handleCloseAdd(false);
            
        })(); 

    }

    return (
        <Modal className="modal add-envie" show={props.showAdd} onHide={ () => props.handleCloseAdd(false) }>

            <Modal.Header closeButton>
                <h1 className="modal--title">Ajout d'envie </h1>
                <img
                    onClick={ () => props.handleCloseAdd(false) }
                    className="modal--close"
                    alt="close-svg"
                    src={closeIcon} />
            </Modal.Header>

            <form onSubmit={ handleAdd }>
                <Modal.Body className="fade-in">
                    <div className="inputs">
                        <label>Nom de l'ingredient</label>
                        <input required name="name" value={envie} onChange={ (e) => setEnvie(e.target.value) } />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className="modal--btn-validate large" disabled={load}>
                        {load ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            ) : ( "Ajouter" )
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddEnvie
