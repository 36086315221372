import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Notyf } from "notyf";

import Select from "react-select";

// Utils
import { updateStoreConfig } from "~/api/stores";
// Components
import Loader from "~/components/Loader";
import { getCaisseWithStoreId } from "~/api/caisse";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
  duration: 3000,
  dismissible: true,
});

function Manager(props) {
  const { handleSubmit } = useForm();
  const store = props.store;
  const [load, setLoad] = useState(false);
  const [cashs, setCashs] = useState([]);

  const [cashSelected, setCashSelected] = useState({
    label: props.store.config.cashActive,
    value: props.store.config.cashActive,
  });

  const onSubmit = () => {
    setLoad(true);
    updateStoreConfig(store._id, "cashActive", cashSelected.value)
      .then((res) => {
        //setLoad(false);
        notyf.success(res.data.message);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  useEffect(() => {
    let storeId = localStorage.getItem("storeId");
    setLoad(true);
    getCaisseWithStoreId(storeId)
      .then((r) => {
        setCashs(r.cashs);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  }, []);

  return (
    <div className='container px-4'>
      {load ? (
        <Loader />
      ) : (
        <div className='page-informations block-styled'>
          <h1 className='block-styled-head'>Gerant</h1>
          <div className='block-styled-body carde'>
            <div className='row gx-5 justify-content-between'>
              <div className='col'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='inputs'>
                    <Select
                      options={cashs.map((cash) => ({
                        label: cash.idConnexion,
                        value: cash.idConnexion,
                      }))}
                      className='basic-single'
                      classNamePrefix='select'
                      value={cashSelected}
                      onChange={(value) => {
                        setCashSelected(value);
                      }}
                    />
                  </div>
                  <button className='modal--btn-validate' type='submit'>
                    Modifier
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Manager;
