import React, { useState } from "react";
import { Notyf } from "notyf";
import { Switch } from "@material-ui/core";

// Components
import Loader from "~/components/Loader";
import { updateStoreConfig } from "~/api/stores";
// Utils

const AcceptOnlineOrder = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const store = props.store;
  const { autoAccept } = store.config;

  const [canAccept, setCanAccept] = useState(autoAccept.paidOrders);
  const [canReject, setCanReject] = useState(autoAccept.unPaidOrders);
  const [acceptUnpaidOrder, setAcceptUnpaidOrder] = useState(
    autoAccept.borneOrderNotPaid
  );

  const updateConfig = async (e) => {
    e.preventDefault();

    const commandeConfig = {
      ...store.config.autoAccept,
      paidOrders: canAccept,
      unPaidOrders: canReject,
      borneOrderNotPaid: acceptUnpaidOrder,
    };

    updateStoreConfig(store._id, "autoAccept", commandeConfig)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setTimeout(function () {
          window.location.reload();
        }, 600);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <div className="container">
      {load ? (
        <Loader />
      ) : (
        <div className="row gx-5 justify-content-between page-type-commande">
          <div className="col">
            <div className="block-styled">
              <h1 className="text-center block-styled-head">
                Acceptation commande
              </h1>
              <div className="borne">
                <div className="row gx-5 btns-toggle justify-content-between">
                  <div className="col">
                    <div className="input mt-3">
                      <Switch
                        onChange={(e) => setCanAccept(!canAccept)}
                        checked={canAccept}
                        color="primary"
                        name="canAccept"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Accepter automatiquement les commandes payées
                    </div>
                    <div className="input mt-3">
                      <Switch
                        onChange={(e) => setCanReject(!canReject)}
                        checked={canReject}
                        color="primary"
                        name="canReject"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Accepter automatiquement les commandes impayées
                    </div>
                    <div className="input mt-3">
                      <Switch
                        onChange={(e) =>
                          setAcceptUnpaidOrder(!acceptUnpaidOrder)
                        }
                        checked={acceptUnpaidOrder}
                        color="primary"
                        name="acceptUnpaidOrder"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Accepter les commandes impayées
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center styles--top">
                <button type="button" onClick={updateConfig}>
                  Validez
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AcceptOnlineOrder;
