import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Notyf } from "notyf";

// Utils
import { updateStore } from "~/api/stores";
// Components
import Loader from "~/components/Loader";
// Css
import "./_informations.scss";

const InformationStore = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const { register, handleSubmit } = useForm();
  const store = props.store;
  const [load, setLoad] = useState(false);
  //const timezone = JSON.parse(store?.timezone);
  const country = store?.identity?.country;

  const onSubmit = (data) => {
    setLoad(true);
    let updStore = {
      ...store,
      ...data,
      identity: {
        ...data,
      },
      country: country,
      status: store?.status,
      //    timeZone: timezone
    };

    updateStore(updStore)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <div className="container px-4">
      {load ? (
        <Loader />
      ) : (
        <div className="page-informations block-styled">
          <h1 className="block-styled-head">
            Mettez à jour les détails de votre boutique{" "}
          </h1>
          <div className="block-styled-body carde">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="inputs">
                <div className="row">
                  <div className="col">
                    <label>Nom de la boutique</label>
                    <input
                      required
                      name="intitule"
                      defaultValue={store?.intitule}
                      {...register("intitule", { required: true })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label>Téléphone</label>
                    <input
                      required
                      name="phone"
                      defaultValue={store?.identity?.phone}
                      type="number"
                      {...register("phone", { required: true })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Nom du manager</label>
                    <input
                      required
                      name="managerLastname"
                      defaultValue={store?.manager?.lastname}
                      type="text"
                      {...register("managerLastname", { required: true })}
                    />
                  </div>
                  <div className="col">
                    <label>Prénom du manager</label>
                    <input
                      required
                      name="managerFirstname"
                      defaultValue={store?.manager?.firstname}
                      type="text"
                      {...register("managerFirstname", { required: true })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Numero et voie</label>
                    <input
                      required
                      name="street"
                      defaultValue={store?.identity?.street}
                      {...register("street", { required: true })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Code postal</label>
                    <input
                      required
                      name="zip"
                      defaultValue={store?.identity?.zip}
                      type="number"
                      {...register("zip", { required: true })}
                    />
                  </div>
                  <div className="col">
                    <label>Ville</label>
                    <input
                      required
                      name="city"
                      defaultValue={store?.identity?.city}
                      {...register("city", { required: true })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Latitude</label>
                    <input
                      required
                      name="lat"
                      defaultValue={store?.identity?.lat}
                      type="text"
                      {...register("lat", { required: true })}
                    />
                  </div>
                  <div className="col">
                    <label>Longitude</label>
                    <input
                      required
                      name="lng"
                      defaultValue={store?.identity?.lng}
                      {...register("lng", { required: true })}
                    />
                  </div>
                </div>
              </div>
              <button className="modal--btn-validate" type="submit">
                Modifier
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default InformationStore;
