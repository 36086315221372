import React from "react";

const Back = (props) => {
  return (
    <span className="sidebar--list-svg">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          fillRule="evenodd"
          d="M20.2913 9.23421H5.39577L12.2377 2.39228L10.4997 0.666504L0.708008 10.4582L10.4997 20.2498L12.2255 18.5241L5.39577 11.6821H20.2913V9.23421Z"
          fill="#E08005"
        />
      </svg>
    </span>
  );
};

export default Back;
