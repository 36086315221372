import React from "react";

const Bag = ({color}) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8544 9.61493C19.6831 8.11345 18.4125 6.97949 16.9013 6.97949H8.09874C6.58752 6.97949 5.31685 8.11345 5.14557 9.61493L3.84228 21.04C3.75861 21.7734 4.33226 22.4163 5.07047 22.4163H19.9295C20.6677 22.4163 21.2414 21.7734 21.1577 21.04L19.8544 9.61493Z"
        stroke={color}
      />
      <path
        d="M14.8574 10.8134C15.0526 10.6182 15.3692 10.6182 15.5645 10.8134C15.7598 11.0087 15.7598 11.3253 15.5645 11.5205L14.8574 10.8134ZM9.50024 11.5205C9.30498 11.3253 9.30498 11.0087 9.50024 10.8134C9.6955 10.6182 10.0121 10.6182 10.2073 10.8134L9.50024 11.5205ZM15.5645 11.5205L15.3412 11.7438L14.6341 11.0367L14.8574 10.8134L15.5645 11.5205ZM9.72351 11.7438L9.50024 11.5205L10.2073 10.8134L10.4306 11.0367L9.72351 11.7438ZM15.3412 11.7438C13.7899 13.2951 11.2748 13.2951 9.72351 11.7438L10.4306 11.0367C11.5914 12.1975 13.4734 12.1975 14.6341 11.0367L15.3412 11.7438Z"
      />
      <path
        d="M9.375 6.67489C9.375 2.79163 11.4583 2.08301 12.5 2.08301C13.5417 2.08301 15.625 2.79163 15.625 6.67489"
        stroke={color}
      />
    </svg>
  );
};

export default Bag;
