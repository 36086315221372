import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";
// Components
import Loader from "~/components/Loader";
import AddGroupe from "./AddGroupe";
import EditGroupe from "./EditGroupe";
import Confirm from "~/components/Commun/Confirm";
// Utils
import {
  getGroupesOfStore,
  deleteGroupe,
  getIngredientOfStore,
  getAllDatasOfStore,
} from "~/api/menu";

//svg
import Plus from "~/assets/svg/plus.svg";
import EditOrangeIcon from "~/components/Svgs/Shop/EditOrange";
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";

//Pagination
import TablePagination from "~/assets/table-pagination/";

const GroupesStore = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const store = props.store;
  const [load, setLoad] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [groupes, setGroupes] = useState([]);
  const [toAdd, setToAdd] = useState(false);
  const [toEdit, setToEdit] = useState(false);
  const [toDelete, setToDelete] = useState(false);
  const [selected, setSelected] = useState({});
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let storeId = store._id;
    let menuActive = localStorage.getItem("menuActive");

    getAllDatasOfStore(storeId, menuActive)
      .then((res) => {
        setProducts(res.data.produits);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });

    getGroupesOfStore(storeId, menuActive)
      .then((res) => {
        setGroupes(res.data.groupes);
      })
      .catch((err) => {
        console.log(err);
      });

    getIngredientOfStore(storeId, menuActive)
      .then((res) => {
        setIngredients(res.data.ingredients);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });

    // eslint-disable-next-line
  }, []);

  const handleDelete = () => {
    setLoad(true);
    setToDelete(false);
    deleteGroupe(selected._id)
      .then((res) => {
        let groupes = res.data.groupes;
        setGroupes(groupes);
        notyf.success(res.data.message);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  // Pagination
  const tableColumns = [
    {
      Header: "Nom",
      accessor: "name",
    },
    {
      Header: "Action",
      accessor: "_id",
      className: "actions multiple-btns",
      Cell: ({ row }) => {
        let groupe = row.original;
        return (
          <>
            <button
              type="button"
              className="btn btn-light btn-edit"
              onClick={() => {
                setSelected(groupe);
                setToEdit(true);
              }}
            >
              <EditOrangeIcon />
            </button>
            <button
              type="button"
              className="btn btn-danger btn-delete"
              onClick={() => {
                setSelected(groupe);
                setToDelete(true);
              }}
            >
              <TrashOutlineIcon />
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          <div className="layout-head">
            <div className="row">
              <div className="col-6">
                <h1>Groupes</h1>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="modal--btn-validate"
                  onClick={() => setToAdd(true)}
                >
                  <img alt="plus" src={Plus} /> Ajouter un groupe
                </button>
              </div>
            </div>
          </div>

          {groupes.length ? (
            <div className="layout-content">
              <div className="block-styled container">
                <div className="block-styled-body">
                  <TablePagination columns={tableColumns} data={groupes} />
                </div>
              </div>
            </div>
          ) : (
            <p className="mt-5 mb-5 text-center">
              Aucune données pour le moments...
            </p>
          )}

          {toAdd && (
            <AddGroupe
              storeId={store._id}
              groupes={groupes}
              showAdd={toAdd}
              handleCloseAdd={setToAdd}
              init={setGroupes}
              ingredients={ingredients}
              products={products}
            />
          )}
          {toEdit && (
            <EditGroupe
              groupe={selected}
              showEdit={toEdit}
              handleCloseEdit={setToEdit}
              init={setGroupes}
              ingredients={ingredients}
              products={products}
            />
          )}
          {toDelete && (
            <Confirm
              show={toDelete}
              handleClose={setToDelete}
              message="Êtes-vous sûr de vouloir supprimer le groupe ?"
              handleConfirm={handleDelete}
              confirmeLoad={load}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GroupesStore;
