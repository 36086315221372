/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from "react";
//import { useForm } from 'react-hook-form';
import Loader from "~/components/Loader";
//import { Notyf } from 'notyf';
import { getStoresOfEnseigne } from "~/api/stores";
import { getProduitsOfStore } from "~/api/produits";
//import { getIngredientOfStore } from '~/api/menu';
import { uploadFile } from "~/api/upload";
import { getMedias, deleteMedia, saveMedia } from "~/api/media";
import { BASE_MEDIA } from "~/assets/base";
import {
  //FileLibraryListItem,
  ReactMediaLibrary,
} from "react-media-library";
import uniqid from "uniqid";
import { getRealFileName } from "~/_helpers/helper";
const Media = (props) => {
  /* const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top',
    },
    //duration: 3000,
    dismissible: true,
  }); */
  //const [enseigne, setEnseigne] = useState();

  const [load, setLoad] = useState(false);
  const [display, setDisplay] = useState(true);
  const [fileLibraryList, setFileLibraryList] = useState([]);
  let enseigneId = localStorage.getItem("enseigneId");
  let libraryListButton = useRef(null);

  useEffect(() => {
    getStoresOfEnseigne(enseigneId).then(async (res) => {
      const storeIds = Array.from(res.data.stores).map((store) => store._id);
      for (let i = 0; i < storeIds.length; i++) {
        const { data } = await getProduitsOfStore(storeIds[i]);

        if (data.produits.length > 0) {
          const produits = data.produits
            .map((p) =>
              p.imageUrl
                ? {
                    _id: uniqid("media-"),
                    size: null,
                    fileName: getRealFileName(p.imageUrl),
                    createdAt: "",
                    thumbnailUrl: p.imageUrl,
                  }
                : null
            )
            .filter((img) => img);
          setFileLibraryList((currentList) => [...currentList, ...produits]);
          setLoad(false);
          
        }
        else{setLoad(false) }
      }
    });
  }, [enseigneId]);

  useEffect(() => {
    const tab = document.querySelector("#react-media-library-tabs-tab-library");
    if (tab) {
      tab.click();
      if (!libraryListButton.current) libraryListButton.current = tab;
    }
  });

  useEffect(() => {
    setLoad(true);
    getAll(enseigneId);
  }, [enseigneId]);

  async function getAll(enseigneId) {
    const { data } = await getMedias(enseigneId);
    let medias = [];
    for (let m = 0; m < data.medias.length; m++) {
      const media = {
        _id: uniqid("media-"),
        title: "",
        size: null,
        fileName: data.medias[m] ? getRealFileName(data.medias[m]) : "",
        type: "",
        createdAt: "",
        thumbnailUrl: `${data.medias[m]}`,
      };
      medias = [...medias, media];
    }
    setFileLibraryList((currentList) => [...medias, ...currentList]);
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async function uploadCallback(fileBase64, fileMeta) {
    let file = dataURLtoFile(fileBase64, fileMeta.fileName);
    let media = new FormData();
    media.append("file", file);
    const result = await uploadFile("medias", media);
    if (result.status === 200) {
      const fileName = result.data?.path?.split("/")[3];
      const data = {
        _id: uniqid("media-"),
        size: null,
        fileName: fileName ? getRealFileName(fileName) : "",
        createdAt: "",
        thumbnailUrl: `${BASE_MEDIA}${result.data?.path?.split("/")[3]}`,
      };

      await saveMedia(enseigneId, data.thumbnailUrl);
      setFileLibraryList((currentList) => {
        return [data, ...currentList];
      });
      libraryListButton.current.click();
      //Return true to display upload success in modal
      return true;
    } else {
      //Return false to display upload failed in modal
      return false;
    }
  }

  function selectCallback(item) {
    setDisplay(false);
    if (props.handleSelectedMedia)
      props.handleSelectedMedia(item?.thumbnailUrl);
  }

  async function deleteCallback(item) {
    const result = await deleteMedia(
      enseigneId,
      item.fileName,
      item.thumbnailUrl
    );

    if (result.status === 200) {
      setFileLibraryList((currentList) => [
        ...currentList.filter((crl) => crl?._id !== item?._id),
      ]);
    }
  }

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className='block-styled'>
        <div className='block-styled-head'>
          <label> Biblothèque de Medias</label>
        </div>
        <div className='block-styled-body carde'>
          <React.Fragment>
            <button
              onClick={() => setDisplay(true)}
              className='media_library_button'
            >
              Ouvrir
            </button>
            <ReactMediaLibrary
              show={display}
              onHide={() => {
                setDisplay(false);
                if (props.setLoadMedia) props.setLoadMedia(false);
              }}
              fileUploadCallback={uploadCallback}
              fileLibraryList={fileLibraryList}
              fileSelectCallback={selectCallback}
              fileDeleteCallback={deleteCallback}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
};

export default Media;
