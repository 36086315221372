import instanceAxios from "./axios.instance";
import { BASE_URI } from "~/assets/base";
import { serialiseStore } from "~/_helpers/helper";

export const getAllStores = async () => {
  const response = await instanceAxios.get(`${BASE_URI}stores/`);

  return response;
};

export const getStoresOfEnseigne = async (enseigneId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}stores/enseigne/${enseigneId}`
  );

  return response;
};

export const getStoreSmsInfo = async (storeId) => {
  const res = await instanceAxios.get(`${BASE_URI}store/${storeId}/smsinfo`);
  return res.data;
};

export const getStoreSmsInfoAdmin = async () => {
  const res = await instanceAxios.get(`${BASE_URI}store/sms/all`);
  return res.data;
};

export const addStoreCreditSms = async (storeId, data) => {
  const res = await instanceAxios.post(
    `${BASE_URI}store/${storeId}/addCredit`,
    data
  );
  return res.data;
};

/**
 *
 * @desc creation nouvelle boutique
 */
export const createStore = async (datas, enseigneId) => {
  const response = await instanceAxios.post(
    `${BASE_URI}enseigne/addstore/${enseigneId}`,
    {
      newStore: serialiseStore(datas),
      newUser: {
        email: datas.email,
        password: datas.password,
      },
    }
  );

  return response;
};

/**
 *
 *Validate Store Subscription
 */

export const validateSubscription = async (storeId) => {
  return await instanceAxios.put(
    `${BASE_URI}store/validate-subsription/${storeId}`,
    {}
  );
};

/**
 * Update Store
 */
export const updateStore = async (datas) => {
  //console.log('dtd', datas);
  const response = await instanceAxios.put(
    `${BASE_URI}store/update/${datas._id}`,
    { store: serialiseStore(datas) }
  );
  return response;
};

/**
 * Update Store
 */
export const updateStoreStatus = async (storeId, type) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/update/status/${storeId}`,
    { type }
  );

  return response;
};

/**
 * Update config of Store
 */
export const updateStoreConfig = async (storeId, configName, configValue) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/update/config/${storeId}`,
    {
      configName,
      configValue,
    }
  );

  return response;
};

/**
 * Get Store by User Id
 */
export const getStoreByUserId = async (userId) => {
  const response = await instanceAxios.get(`${BASE_URI}store/user/${userId}`);

  return response;
};

/**
 * Get Store by Id
 */
export const getStoreById = async (storeId) => {
  const response = await instanceAxios.get(`${BASE_URI}store/${storeId}`);

  return response;
};
/**
 * Update Email of store user
 */
export const updateStoreEmail = async (userId, newEmail) => {
  const response = await instanceAxios.put(
    `${BASE_URI}users/updateemail/${userId}`,
    { email: newEmail }
  );

  return response;
};

/**
 * Update acces of store
 */
export const updateStorePassword = async (userId, newPassword) => {
  const response = await instanceAxios.put(
    `${BASE_URI}users/updatepassword/${userId}`,
    { password: newPassword }
  );

  return response;
};

/**
 * switch accessibilite of store
 */

export const switchAccessibleStore = async (storeId) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/update/accessible/${storeId}`,
    {}
  );

  return response;
};

/**
 * Resililier Store
 */

export const resilierStore = async (storeId) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/resilier/${storeId}`,
    {}
  );

  return response;
};

/* additionnel menu */

export const addAditionnalMenu = async (storeId, produits) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/produits/additionel/${storeId}`,
    { produits: produits }
  );
  return response.data;
};
