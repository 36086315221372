import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";
import DeleteModal from "./DeleteModal";
import { getAllEnseignes, deleteEnseigne } from "~/api/enseigne";
import useMedia from "use-media";

//SVG
import Loader from "~/components/Loader";
import More from "~/assets/svg/more.svg";

//Pagination
import TablePagination from "~/assets/table-pagination/";

const Terminate = () => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "bottom",
    },
    duration: 3000,
    dismissible: true,
  });
  const isMobile = useMedia({ maxWidth: 768 });
  const [enseignes, setEnseignes] = useState([]);
  const [selected, setSelected] = useState("");
  const [showBtn, setShowBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    getAllEnseignes()
      .then((res) => {
        setLoad(false);
        setEnseignes(res.data.enseignes);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
  }, []);

  const handleTerminate = () => {
    setLoad(true);
    deleteEnseigne(selected)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setEnseignes(res.data.enseignes);
        setShowModal(false);
        setShowBtn(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  // Pagination
  const tableColumns = [
    {
      Header: "Id",
      className: "tdswitch",
      accessor: (d) => d._id.substring(0, 8),
      width: 100,
    },
    {
      Header: "Nom",
      accessor: (d) => d.identity.lastname,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Action",
      cssWidth: 166,
      accessor: (d) => d._id,
      Cell: ({ value, row }) => {
        let enseigne = row.original;
        return (
          <span
            className={`delete-btn-container ${
              showBtn && selected._id === enseigne._id ? "confirm-delete" : ""
            }`}
          >
            {isMobile && (
              <button
                className="btn"
                onClick={() => {
                  setSelected(enseigne);
                  setShowBtn(!showBtn);
                }}
              >
                <img alt="more" src={More} className="pointer" />
              </button>
            )}
            {(!isMobile || (showBtn && selected._id === enseigne._id)) && (
              <button
                onClick={() => {
                  setSelected(enseigne);
                  setShowModal(true);
                }}
                className="button terminate--table-content-btn terminate--table-content-btn-open"
              >
                Résilier
              </button>
            )}
          </span>
        );
      },
    },
  ];

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="terminate center_last_col">
        <div className="block-styled container">
          <div className="block-styled-body">
            <div className="terminate--inputWrapper">
              <h3>Toutes les enseignes</h3>
            </div>
            <div className="terminate--table">
              {showModal && (
                <DeleteModal
                  id={selected}
                  show={showModal}
                  load={load}
                  setShow={setShowModal}
                  handleTerminate={handleTerminate}
                />
              )}

              <TablePagination
                columns={tableColumns}
                data={enseignes}
                searchLabel={"Rechercher par id, email ou nom"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Terminate;
