import instanceAxios from "./axios.instance" ;
import { BASE_URI } from "~/assets/base";

export const getCustomerOfStore = async (storeId) => {
    const response = await instanceAxios.get(`${BASE_URI}customers/store/${storeId}`);

    return response;
}

export const createCustomer = async (datas) => {
    const response = await instanceAxios.post(`${BASE_URI}store/customer/create`,
      {
        newCustomer : datas
      }
    );

    return response;
}

/**
 * Update Customer
 */
export const updateCustomer = async (customer) => {
    const response = await instanceAxios.put(`${BASE_URI}customer/update/${customer._id}`,
      { customer }
    );

    return response;
}

/**
 * Switch statut Customer
 */
 export const switchStatutCustomer = async (customerId) => {
  const response = await instanceAxios.put(`${BASE_URI}customer/statut/${customerId}`,
    { }
  );

  return response;
}

/**
 * Maj points of Customer
 */
 export const updatePointsCustomer = async (customerId, points) => {
  const response = await instanceAxios.put(`${BASE_URI}customer/points/${customerId}`,
    { points }
  );

  return response;
}