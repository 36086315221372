const initProduit = {
  enable: true,
  storeId: "",
  name: "",
  description: "",
  imageUrl: "",
  idCategorie: "",
  visibilite: {
    canEmporter: true,
    canLivraison: true,
    canPlace: true,
  },
  horairePrecise: {
    enable: false,
    days: [],
    time: {
      start: "",
      end: "",
    },
  },
  removeIngredient: {
    enable: false,
    blacklist: [],
  },
  multiChoice: false,
  priceGlobal: 0,
  multiPrice: false,
  secondPrice: 0,
  variantes: [],
  supplement: [],
  envies: [],
  allergenes: [],
  sendToKitcken: true,
};

export default initProduit;
