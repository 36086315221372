import React from "react";

const radioColor = (props) => {
  return (
    <span>
      <svg
        width="30"
        height="30"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11Z"
          fill={props.color}
          stroke="#E08005"
          strokeWidth="0.5"
        />
      </svg>
    </span>
  );
};

export default radioColor;
