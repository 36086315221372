import React, { useState, useRef, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { List } from "react-movable";
import Select from "react-select";

//SVG
import dragable from "~/assets/svg/dragable.svg";
import Confirm from "~/components/Commun/Confirm";
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";
import EditOrangeIcon from "~/components/Svgs/Shop/EditOrange";
import ValidateIcon from "~/components/Svgs/Shop/Validate";

export default ({ control, register, groupes }) => {
  let { fields, remove, append, move } = useFieldArray({
    control,
    name: `supplement.groupes`,
  });
  const [groupesOptions, setGroupesOptions] = useState([]);
  const [selectedOption, setSelect] = useState(null);
  const groupeChoix = useRef(null);

  const [toDeleteGroupe, setToDeleteGroupe] = useState(null);
  const [toUpdateVariant, setToUpdateVariant] = useState([]);
  const variantDatas = useRef({});

  useEffect(() => {
    setGroupesOptions(groupes);
    // eslint-disable-next-line
  }, [groupes]);

  const appendGroup = () => {
    let check = groupesOptions.filter(
      (groupe) => groupe?.value === selectedOption?.value
    );
    if (check.length !== 1 || !groupeChoix.current.value) {
      return false;
    }

    append({
      idGroupe: selectedOption.value,
      nbr: groupeChoix.current.value,
    });
    setGroupesOptions(
      groupesOptions.filter((groupe) => groupe.value !== selectedOption.value)
    );

    setSelect(null);

    groupeChoix.current.value = null;
  };

  const handleChange = (selectedOption) => {
    setSelect(selectedOption);
  };

  const removeGroupe = (groupe, index) => {
    setGroupesOptions([
      ...groupesOptions,
      groupes.find((item) => item.value === groupe.idGroupe),
    ]);
    remove(index);
  };

  const getGroupeName = (id) => {
    let groupe = groupes.find((row) => {
      return row.value === id;
    });
    return groupe?.label;
  };
  const updateVariant = (index, id) => {
    if (toUpdateVariant.includes(index)) {
      setToUpdateVariant((currentState) =>
        currentState.filter((state) => state !== index)
      );
    } else {
      setToUpdateVariant((currentState) => [index]);
    }

    variantDatas.current =
      variantDatas.current.id !== id
        ? fields.find((field) => field.id === id)
        : {};
  };

  const handleChangeField = (e, id) => {
    let value = e.currentTarget.name.split(".")[
      e.currentTarget.name.split(".").length - 1
    ];
    variantDatas.current[value] = e.currentTarget.value;
  };

  const validateField = (index, id) => {
    updateVariant(index, id);
  };

  return (
    <>
      {fields.length > 0 && (
        <tr>
          <td colSpan="5" className="border-0 pl-0 pr-0 pt-0 pb-0">
            <List
              lockVertically
              values={fields}
              onChange={({ oldIndex, newIndex }) => {
                move(oldIndex, newIndex);
              }}
              renderList={({ children, props }) => (
                <table className="table table mb-0">
                  <thead className="table-dark">
                    <tr>
                      <th style={{ width: "100px" }}></th>
                      <th>Groupe</th>
                      <th>
                        <span>Nombre de choix (max)</span>
                      </th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody {...props}>{children}</tbody>
                </table>
              )}
              renderItem={({ value, index, props, isDragged, isSelected }) => {
                const row = (
                  <tr
                    {...props}
                    style={{
                      backgroundColor:
                        isDragged || isSelected ? "#ececec" : "#fafafa",
                    }}
                  >
                    <td style={{ width: "100px" }}>
                      <button
                        data-movable-handle
                        style={{
                          cursor: isDragged ? "grabbing" : "grab",
                        }}
                      >
                        <img alt="" src={dragable} />
                      </button>
                    </td>
                    <td>
                      {getGroupeName(value.idGroupe)}
                      <input
                        style={{
                          border: " 0.5px solid #DEDEDE",
                          outline: "none",
                          borderRadius: "8px",
                        }}
                        type="hidden"
                        {...register(`supplement.groupes.${index}.idGroupe`)}
                        defaultValue={value.idGroupe}
                      />
                    </td>
                    <td>
                      {!toUpdateVariant.includes(index) && value.nbr}
                      <input
                        style={{
                          border: " 0.5px solid #DEDEDE",
                          outline: "none",
                          borderRadius: "8px",
                        }}
                        type={toUpdateVariant.includes(index) ? "" : "hidden"}
                        {...register(`supplement.groupes.${index}.nbr`)}
                        defaultValue={value.nbr}
                        onChange={(e) => handleChangeField(e, value.id)}
                      />
                    </td>
                    <td style={{ width: "100px" }} align="center">
                      {!toUpdateVariant.includes(index) && (
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => updateVariant(index, value.id)}
                        >
                          <EditOrangeIcon />
                        </button>
                      )}
                    </td>
                    <td style={{ width: "100px" }} align="center">
                      {!toUpdateVariant.includes(index) ? (
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => {
                            setToDeleteGroupe({ value, index });
                          }}
                        >
                          <TrashOutlineIcon />
                        </button>
                      ) : (
                        <button
                          className="btn btn-light"
                          type="button"
                          onClick={() => {
                            validateField(index, value.id, fields);
                          }}
                        >
                          <ValidateIcon />
                        </button>
                      )}
                    </td>
                  </tr>
                );
                return isDragged ? (
                  <table
                    className="table dragged-table dragged-top"
                    style={{ ...props.style, borderSpacing: 0 }}
                  >
                    <tbody>{row}</tbody>
                  </table>
                ) : (
                  row
                );
              }}
            />
          </td>
        </tr>
      )}
      <tr>
        <td colSpan="3" className="pl-0 pr-0 pt-0 pb-0 border-0">
          <div className="add-groupe">
            <table className="bordered bgColor">
              <tr>
                <td style={{ width: 30 }}></td>
                <td>
                  <Select
                    onChange={handleChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedOption}
                    defaultValue={selectedOption}
                    options={groupesOptions}
                  />
                </td>
                <td>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="nombre de choix"
                    ref={groupeChoix}
                  />
                </td>
                <td style={{ width: 100 }} align="center">
                  <button
                    className="btn btn-warning btn-add"
                    type="button"
                    onClick={appendGroup}
                  >
                    {" "}
                    +{" "}
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>

      {toDeleteGroupe && (
        <Confirm
          show={toDeleteGroupe}
          handleClose={setToDeleteGroupe}
          message="Êtes-vous sûr de vouloir supprimer ce groupe ?"
          handleConfirm={() => {
            removeGroupe(toDeleteGroupe.value, toDeleteGroupe.index);
            setToDeleteGroupe(false);
          }}
          confirmeLoad={false}
        />
      )}
    </>
  );
};
