import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";
import { getEnseigneById, updateEnseigneEmail, updateEnseignePassword } from '~/api/enseigne';

//svgs
import mailIcon from "~/assets/svg/mail.svg";
import lockIcon from "~/assets/svg/lock.svg";
import eyeOpen from "~/assets/svg/eye-open.svg";
import eyeClose from "~/assets/svg/eye-close.svg";
import Loader from "~/components/Loader";



const Access = () => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });
  
  const inputs = {
    email: "",
    password: "",
    passwordConfirmation: "",
  };

  const [load, setLoad] = useState(false);
  const [input, setInput] = useState(inputs);
  const [showPass, setShowPass] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [enseigne, setEnseigne] = useState("");

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  //récup de l'id enseigne
  useEffect(() => {

    let enseigneId = localStorage.getItem("enseigneId");
    setLoad(true);
    getEnseigneById(enseigneId)
    .then((res) => {
      setEnseigne(res.data.enseigne);
      setInput({ ...inputs, "email": res.data.enseigne.email });
      setLoad(false);
    })
    .catch((err) => {
      setLoad(false);
      notyf.error(err.message);
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmitNewEmail = (e) => {
    e.preventDefault();
    setLoad(true);
    updateEnseigneEmail(enseigne._id, input.email)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();
    const { password, passwordConfirmation } = input;
    if (password !== passwordConfirmation) {
      notyf.error("Le mot de passe doit être identique");
    } else {
      setLoad(true);
      updateEnseignePassword(enseigne._id, password)
        .then((res) => {
          setLoad(false);
          notyf.success(res.data.message);
          setInput({...inputs, email :input.email });
        })
        .catch((err) => {
          setLoad(false);
          notyf.error(err.message);
        });
    }
  };
  return (
    <div className="access">
      {load ? (
        <Loader />
      ) : (
        <>
          <div className="block-styled container">
            <div className="block-styled-body">
              <div className="access--formsWrapper">
                <form className="access--form" onSubmit={handleSubmitNewEmail}>
                  <h3 className="access--title">Changer mon email de connexion</h3>
                  <label>Email</label>
                  <div>
                    <img alt="email-icon" src={mailIcon} />
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      value={input.email}
                      name="email"
                      autoComplete="off"
                      className="input-email"
                    />
                  </div>
                  <button type="submit">Modifier</button>
                </form>
                <form className="access--form" onSubmit={handleSubmitNewPassword}>
                  <h3 className="access--title">Changer mon mot de passe</h3>
                  <label>Mot de passe</label>
                  <div>
                    <img alt="password-icon" src={lockIcon} />
                    <input
                      type={showPass ? "text" : "password"}
                      className="input-libre"
                      required
                      autoComplete="off"
                      onChange={handleChange}
                      value={input.password}
                      name="password"
                    />
                    <img
                      onClick={() => setShowPass(!showPass)}
                      alt="eye-close-icon"
                      className="access--form-eyeIcon"
                      src={showPass ? eyeOpen : eyeClose}
                    />
                  </div>
                  <label>Confirmé mot de passe</label>
                  <div>
                    <img alt="password-icon" src={lockIcon} />
                    <input
                      type={showConfirm ? "text" : "password"}
                      required
                      onChange={handleChange}
                      value={input.passwordConfirmation}
                      name="passwordConfirmation"
                    />
                    <img
                      onClick={() => setShowConfirm(!showConfirm)}
                      alt="eye-close-icon"
                      className="access--form-eyeIcon"
                      src={showConfirm ? eyeOpen : eyeClose}
                    />
                  </div>
                  <button type="submit">Modifier</button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Access;
