import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./Login";
import PasswordForget from "./PasswordForget";
import ValidateStoreSubscription from "./validate-store-subscription";

const Offline = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/password-forget" component={PasswordForget} />
        <Route
          exact
          path="/validate-store-subscription/:storeId/enseigneId/:enseigneId"
          component={ValidateStoreSubscription}
        />
      </Switch>
    </Fragment>
  );
};

export default Offline;
