import React, { useEffect, useState } from "react";
import { Notyf } from "notyf";

// Components
import Loader from "~/components/Loader";
// Utils
import {
  createUserStoreAdmin,
  getUserStoreAdmin,
  updateUserStoreAdmin,
} from "~/api";
//svgs
import mailIcon from "~/assets/svg/mail.svg";
import lockIcon from "~/assets/svg/lock.svg";

const AccesCaisse = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [user, setUser] = useState(null);
  const [inputs, setInputs] = useState({ role: "ROLE_ADMIN" });

  const [load, setLoad] = useState(true);
  const store = props.store;

  useEffect(() => {
    getUserStoreAdmin(store._id)
      .then((res) => {
        setUser(res.data.user);
        setInputs({
          fullName: res.data.user.fullName,
          phone: res.data.user.phone,
          email: res.data.user.email,
          codePin: res.data.user.codePin,
        });
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
        notyf.error(err.message);
      });

    // eslint-disable-next-line
  }, []);

  // change value of forms
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  // submit form change email
  const handleSubmitNewEmail = (e) => {
    e.preventDefault();
    setLoad(true);

    (!user ? createUserStoreAdmin : updateUserStoreAdmin)(
      user ? user._id : store._id,
      inputs
    )
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setUser(res.data.user);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <div className='access'>
      {load ? (
        <Loader />
      ) : (
        <>
          <div className='block-styled container'>
            <div className='block-styled-body'>
              <div className='access--formsWrapper'>
                <form className='access--form' onSubmit={handleSubmitNewEmail}>
                  <h3 className='access--title'>Informations Admin caisse</h3>

                  <label>Nom complet</label>
                  <div>
                    <img alt='email-icon' src={mailIcon} />
                    <input
                      type='text'
                      required
                      onChange={handleChange}
                      value={inputs.fullName}
                      name='fullName'
                      autoComplete='off'
                      className='input-email'
                    />
                  </div>

                  <label>Email</label>
                  <div>
                    <img alt='email-icon' src={mailIcon} />
                    <input
                      type='text'
                      required
                      onChange={handleChange}
                      value={inputs.email}
                      name='email'
                      autoComplete='off'
                      className='input-email'
                    />
                  </div>

                  <label>Téléphone</label>
                  <div>
                    <img alt='email-icon' src={mailIcon} />
                    <input
                      type='text'
                      required
                      onChange={handleChange}
                      value={inputs.phone}
                      name='phone'
                      autoComplete='off'
                      className='input-email'
                    />
                  </div>

                  <label>Code Pin</label>
                  <div>
                    <img alt='email-icon' src={lockIcon} />
                    <input
                      type='text'
                      required
                      onChange={handleChange}
                      value={inputs.codePin}
                      name='codePin'
                      autoComplete='off'
                      className='input-email'
                    />
                  </div>

                  <button type='submit'>{!user ? "Creer" : "Modifier"}</button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccesCaisse;
