import React from "react";
import GroupesArray from "./GroupesArray";

export default function Supplements({ control, register, groupes }) {

  return (
        <table className="table supplement">
            <tbody>
                <GroupesArray groupes={groupes} {...{ control, register }} />
            </tbody>
        </table>
  );
}
