import React from "react";

const OrderTypeIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr">
        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.25 4C9.25 1.92893 10.9289 0.25 13 0.25C14.8142 0.25 16.3275 1.53832 16.675 3.25H20C20.4142 3.25 20.75 3.58579 20.75 4C20.75 4.41421 20.4142 4.75 20 4.75H16.675C16.3275 6.46168 14.8142 7.75 13 7.75C10.9289 7.75 9.25 6.07107 9.25 4ZM13 1.75C14.2426 1.75 15.25 2.75736 15.25 4C15.25 5.24264 14.2426 6.25 13 6.25C11.7574 6.25 10.75 5.24264 10.75 4C10.75 2.75736 11.7574 1.75 13 1.75ZM1 3.25C0.585786 3.25 0.25 3.58579 0.25 4C0.25 4.41421 0.585786 4.75 1 4.75H7C7.41421 4.75 7.75 4.41421 7.75 4C7.75 3.58579 7.41421 3.25 7 3.25H1ZM1 13.25C0.585786 13.25 0.25 13.5858 0.25 14C0.25 14.4142 0.585786 14.75 1 14.75H4.32501C4.67247 16.4617 6.18578 17.75 8 17.75C10.0711 17.75 11.75 16.0711 11.75 14C11.75 11.9289 10.0711 10.25 8 10.25C6.18578 10.25 4.67247 11.5383 4.32501 13.25H1ZM5.75 14C5.75 15.2426 6.75736 16.25 8 16.25C9.24264 16.25 10.25 15.2426 10.25 14C10.25 12.7574 9.24264 11.75 8 11.75C6.75736 11.75 5.75 12.7574 5.75 14ZM14 13.25C13.5858 13.25 13.25 13.5858 13.25 14C13.25 14.4142 13.5858 14.75 14 14.75H20C20.4142 14.75 20.75 14.4142 20.75 14C20.75 13.5858 20.4142 13.25 20 13.25H14Z" fill="#282828"/>
        </svg>
    </span>
  );
};

export default OrderTypeIcon;
