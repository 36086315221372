import React from "react";
import SepaElement from "./SepaElement";
import axios from "axios";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import { useEffect } from "react";

function Sepa() {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [products, setProducts] = React.useState(null);
  const [clientProducts] = React.useState([
    {
      price: "price_1LWHIqCUX4Iq5f7H2SZTpWA3",
    },
    {
      price: "price_1JS2YLCUX4Iq5f7He6yUypvi",
    },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!stripe || !elements) {
        return;
      }
      const sepaPayment = await stripe.createPaymentMethod({
        type: "sepa_debit",
        sepa_debit: elements.getElement(IbanElement),
        billing_details: {
          email,
          name,
        },
      });
      await axios.post(
        "https://api.eatlines.com/api/store/create-payment-intent",
        {
          payment_method: sepaPayment.paymentMethod.id,
          items: clientProducts,
          email,
        },
        clientProducts
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await axios.post(
        "https://api.eatlines.com/api/store/products",
        { products: clientProducts }
      );
      setProducts(res.data);
    })();
  }, [clientProducts]);

  useEffect(() => {
    if (products) {
      let _total = 0;
      for (const product of products) {
        _total += product.unit_amount;
      }
      setTotal(_total);
    }
  }, [products]);

  return (
    <div className="sr-root">
      <div className="sr-main">
        <form
          id="payment-form"
          className="sr-payment-form"
          onSubmit={handleSubmit}
        >
          <div className="sr-combo-inputs-row">
            <div className="col">
              <p
                style={{
                  fontSize: "20px",
                }}
              >
                Payer via un prélèvement SEPA
              </p>
            </div>
          </div>

          <div className="sr-combo-inputs-row">
            <div className="col">
              <label htmlFor="name">Nom associé au compte</label>
              <input
                id="name"
                name="name"
                placeholder="Jenny Rosen"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="col">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="jenny.rosen@example.com"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="sr-combo-inputs-row">
            <div className="col">
              <SepaElement />
            </div>
          </div>

          <div className="sr-combo-inputs-row">
            <div className="col">
              <button id="confirm-mandate" disabled={!stripe}>
                Payer
                <span> {(total / 100).toFixed(2)} € </span>
              </button>
            </div>
          </div>
        </form>

        <div className="col" id="mandate-acceptance">
          En fournissant votre IBAN et en confirmant ce paiement, vous autorisez
          (A) EATLINES SASU et Stripe, notre prestataire de services de
          paiement, et ou PPRO, son prestataire de services local, à envoyer des
          instructions à votre banque pour débiter votre compte, et (B) votre
          banque à débiter votre compte conformément à ces instructions. Vous
          bénéficiez d’un droit à remboursement par votre banque conformément
          aux conditions décrites dans la convention que vous avez passée avec
          elle. Toute demande de remboursement doit être présentée dans les 8
          semaines suivant la date de débit de votre compte.
        </div>
      </div>
    </div>
  );
}
export default Sepa;
