import React from "react";
import AddEnseigne from "./AddEnseigne.js";
import Plus from "~/assets/svg/plus.svg";
//import Loop from "~/assets/svg/loop.svg";

const Inputs = (props) => {
  return (
    <div className="supremeDash--inputsWrapper">
      <button className="supremeDash--addSuper-btn " onClick={props.handleShow}>
        <img alt="plus" src={Plus} /> <p>Ajouter une enseigne</p>
      </button>
      {/* <div className="supremeDash--input-wrapper ">
        <img alt="loop" src={Loop} />
        <input
          placeholder="Rechercher par id, email ou nom"
          onChange={(e) => props.handleChangeSearchBars(e)}
          value={props.input}
        />
      </div> */}
      <AddEnseigne
        show={props.show}
        handleClose={props.handleClose}
        handleAddEnseigne={props.handleAddEnseigne}
        addEnseigneInput={props.addEnseigneInput}
        handleChangeAddEnseigne={props.handleChangeAddEnseigne}
        load={props.load}
        handleChangeTimeZone={props.handleChangeTimeZone}
      />
    </div>
  );
};

export default Inputs;
