import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Notyf } from "notyf";

//components
import ChoixProduits from '../commun/ChoixProduits';
import Select from 'react-select';

const Etape1 = ({ promoDatas, update, retour, store }) => {

    const notyf = new Notyf({
        position: {
          x: "right",
          y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const typeProduit = [
        {value: 'moins', label: 'Le moins cher'},
        {value: 'plus', label: 'Le plus cher'},
    ];

    const { register, control, handleSubmit, setValue, watch } = useForm({defaultValues: promoDatas});
    const { fields, append, remove } = useFieldArray( { control, name: "produitsDifferentPromo.groupes" } );
    const [showProduits, setShowProduits] = useState(false);
    const selectedProduits = watch("produitsDifferentPromo.groupes");
    const nombreGroupes = watch("produitsDifferentPromo.nombre");
    const [curent, setCurent] = useState(0);

    useEffect(() => {

        let count = selectedProduits.length ;
        let max = parseInt(nombreGroupes);
        if (count <= max) {
            for (let index = count; index < (max+1); index++) {
                append({ produits: [] });
            }
        }else{
            for (let i = count; i > (max+1); i--) {
                remove(i-1);
            }
        }
        
        // eslint-disable-next-line
    }, [nombreGroupes]);

    const onSubmit = (data) => { 
        if( selectedProduits[0].produits.length === 0 ){
            notyf.error("Veuillez sélectionner au moins un produit");
            return
        }
        if( data.produitsDifferentPromo.type instanceof Object ){
            data.produitsDifferentPromo.type = data.produitsDifferentPromo.type.value
        } 
        update({
            ...promoDatas, 
            produitsDifferentPromo: data.produitsDifferentPromo,
        });
    };

    const getSellectedProduits = (data) => {
        setValue(`produitsDifferentPromo.groupes.${curent}.produits`, data);
        setShowProduits(false);
    }

    const removeProductFromGroupe = (indexGroupe, indexProduct) => {
        let products = selectedProduits[indexGroupe].produits;
        products.splice(indexProduct, 1);   
        setValue(`produitsDifferentPromo.groupes.${indexGroupe}.produits`, products);
        setValue(`produitsDifferentPromo.nombre`, nombreGroupes);
    } 

    //
    if(fields.length > 0){
        fields.map( (item, index) => typeProduit.push({value: `groupe_${index}`, label: `Groupe ${index+1}`}) )
    }

    return (
        <div className="type7_etape1">
            <form onSubmit={handleSubmit(onSubmit)}>
                
                <div className="form-group mb-5 type7_etape1_head">
                    <div>
                        <label htmlFor="nombre">Nombre de produit acheté = </label>
                        <div className="input-group">
                            <input type="number" min="1" className="form-control" id="nombre" name="nombre" {...register("produitsDifferentPromo.nombre", { required: true })} />
                            <div className="input-group-append">
                                <span className="input-group-text">Produits</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-5">
                    {fields.map((item, index) => {
                        return (
                            <div className="selected_produits mb-3" key={index}>
                                <input type="hidden" defaultValue={`${item.produits}`} {...register(`produitsDifferentPromo.groupes.${index}.produits`)} />
                                <center className="mb-1">
                                    <strong>Sélectionnez le {index+1} choix</strong>
                                </center>
                                {
                                    Array.isArray(selectedProduits[index]?.produits) &&
                                    <ul className="list-group">
                                        { selectedProduits[index]?.produits?.map( (product, key) => 
                                            <li className="d-flex" key={key}> 
                                            <div>
                                                <img src={product.imageUrl} alt="" style={{width: 35}} />
                                            </div>
                                            <div>
                                                { product.name }
                                                {
                                                    product.variant && 
                                                    <span> [{product.variant.name}]</span>
                                                }
                                            </div>
                                            <div  className="remove-item">
                                                <button onClick={()=>{ removeProductFromGroupe(index, key) }} type="button" className="btn btn-light">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </li>
                                        ) }
                                    </ul>
                                } 
                                <button className="btn btn-light select-products" type="button" onClick={() => {setCurent(index); setShowProduits(!showProduits)} }>Groupe {index+1}</button>
                            </div>
                        );
                    })}
                </div>
                

                <div className="row mb-5">
                    <div className="col">
                        <label htmlFor="produitsDifferentPromo.type">Promotion sur le produit</label>
                        <Controller
                            name="produitsDifferentPromo.type"
                            control={control}
                            defaultValue={ typeProduit[0] }
                            render={({ field }) => (
                                <Select
                                    onChange={(e) => field.onChange(e.value)}
                                    className="basic-single" 
                                    classNamePrefix="select" 
                                    value={ typeProduit.find(type => type.value === field.value )} 
                                    options={typeProduit} 
                                />
                            )}
                        />
                    </div>
                </div> 

                <div className="form-group mb-5 type5_etape1_footer">
                    <div>
                    <label htmlFor="remise">Remise pour l'article en promo :</label>
                        <div className="input-group">
                            <input type="number" min="1" className="form-control" id="remise" name="remise" {...register("produitsDifferentPromo.remise", { required: true })} />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group steps-controlers">
                    <div>
                        <button className="btn btn-dark" type="button" onClick={ retour }>Retour</button>
                    </div>
                    <div>
                        <button className="btn btn-warning" type="submit">Suivant</button>
                    </div>
                </div>
                
            </form>

            { showProduits && (
                <ChoixProduits 
                    store={ store } 
                    handleClose={ setShowProduits } 
                    show={ showProduits } 
                    submit={ getSellectedProduits }
                    default={ selectedProduits.length ? selectedProduits[curent].produits : [] }
                />
            ) }

        </div>
    )
}

export default Etape1