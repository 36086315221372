import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { Switch, Route, useHistory } from "react-router-dom";
// Components
import InfosStore from "./informations";
import AccesStore from "./acces";
import CommandeStore from "./commandes";
import TypeCommandeStore from "./typeCommande";
import ServiceStore from "./services";
import PromotionStore from "./promotions";
import CodePromoStore from "./codesPromo";
import HoraireStore from "./horaires";
import MenuStore from "./menu";
import ClientStore from "./clients";
import BorneStore from "./bornes";
import Manager from "./manager";
import Loader from "~/components/Loader";
// Utils
import { Notyf } from "notyf";
import { useSelector } from "react-redux";
import { getStoreByUserId, getStoreById } from "~/api/stores";
import { getTerminalByStoreId } from "~/api/terminal";

const Store = (props) => {
  let storeId = queryString.parse(props.location.search).storeid;
  //  messages
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "bottom",
    },
    duration: 6000,
    dismissible: true,
  });

  //state globaux
  const user = useSelector((state) => state.user.data.user);
  const [store, setStore] = useState({});
  const [load, setLoad] = useState(true);
  const [terminal, setTerminal] = useState(null);
  let history = useHistory();

  useEffect(() => {
    let storeId = queryString.parse(props.location.search).storeid;
    if (storeId) {
      localStorage.setItem("storeId", storeId);
    } else if (localStorage.getItem("storeId") !== null) {
      storeId = localStorage.getItem("storeId");
    }

    if (user.enseigneId) {
      localStorage.setItem("enseigneId", user.enseigneId);
    }

    // enseigne => get enseigne by USER ID
    (async function fetch() {
      if (!storeId) {
        await getStoreByUserId(user.id).then((res) => {
          if (res.data.exist === false) {
            history.push("/");
            return;
          } else {
            storeId = res.data.store._id;
            localStorage.setItem("storeId", storeId);
            //localStorage.setItem("enseigneId", res.data.store.enseigneId);
            //history.push("/store/commandes/mes-commandes");
            history.push("/store/commandes/archives-fiscales");
          }
        });
      }

      // get Store
      getStoreById(storeId)
        .then((res) => {
          setStore(res.data.store);
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
          notyf.error(err.message);
        });
    })();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (storeId) {
        try {
          const terminal = await getTerminalByStoreId(storeId);
          setTerminal(terminal);
        } catch (err) {
          setTerminal(null);
        }
      }
    })();
  }, [storeId]);

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <Switch>
          <Route
            path="/store/menu"
            component={() => <MenuStore store={store} />}
          />
          <Route
            path="/store/informations"
            component={() => <InfosStore store={store} />}
          />
          <Route
            path="/store/acces"
            component={() => <AccesStore store={store} />}
          />
          <Route
            path="/store/commandes"
            component={() => <CommandeStore store={store} />}
          />
          <Route
            path="/store/type-commande"
            component={() => <TypeCommandeStore store={store} />}
          />
          <Route
            path="/store/services"
            component={() => <ServiceStore store={store} />}
          />
          <Route
            path="/store/promotions"
            component={() => <PromotionStore store={store} />}
          />
          <Route
            path="/store/codes-promo"
            component={() => <CodePromoStore store={store} />}
          />
          <Route
            path="/store/horaires"
            component={() => <HoraireStore store={store} />}
          />
          <Route
            path="/store/manager"
            component={() => <Manager store={store} />}
          />
          <Route
            path="/store/clients"
            component={() => <ClientStore store={store} />}
          />
          {terminal && (
            <Route
              path="/store/bornes"
              component={() => (
                <BorneStore
                  store={store}
                  terminal={terminal}
                  setTerminal={setTerminal}
                />
              )}
            />
          )}
        </Switch>
      )}
    </>
  );
};

export default Store;
