import axios from 'axios';

const instanceAxios = axios.create({
    validateStatus: function (status) {
        return status >= 200 && status <= 503;
    }
});

instanceAxios.interceptors.request.use(config=>{
    let token = localStorage.getItem("token");
    let isUpdateEnseigne = false;
    
    // pour cibler la base de donné global lors de l'update de count stores
    if ( config.url.indexOf("enseigne/update/") > 0 ) {
        isUpdateEnseigne = true;
    }
    if ( token != null ) {
        let enseignId = localStorage.getItem("enseigneId");
        if ( (enseignId != null) && !isUpdateEnseigne ) {
            token = token + '-refenseign-' + enseignId ;
        }
        config.headers.Authorization = `Bearer ${token}`; 
    }

    return config;
}, err=>{  
    return Promise.reject(err);
})

instanceAxios.interceptors.response.use((response) => {
    if( response.status >= 200 && response.status < 300) return Promise.resolve(response) 
    else return Promise.reject(response.data);
  }, (error) => {  
    return Promise.reject(error);
  });

export default instanceAxios;