import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Notyf } from "notyf";
import Select from "react-select";

// Utils
import { getEnseigneById } from "~/api/enseigne";
import { createPromo } from "~/api/promo";
import { dateToTimes } from "~/_helpers/helper";

// components
import PromoSingle from "./PromoSingle";
import PromoEdit from "./PromoEdit";
import Loader from "~/components/Loader";

//svg
//import plus from "~/assets/svg/plus-black.svg";
import Radio from "~/components/Svgs/Super/addProduct/radio";
import Loop from "~/assets/svg/loop.svg";
import Close from "~/assets/svg/close-x.svg";

//CSS
import "./_code_promo.scss";

const simpleTextSearch = require("simple-text-search");

const CodePromoStore = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const store = props.store;
  const [enseigne, setEnseigne] = useState();
  const [load, setLoad] = useState(false);
  const [promos, setPromos] = useState([]);
  const [add, setAdd] = useState(false);
  const [unlimited, setUnlimited] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState(null);

  // filters
  const [stateSelect, setStateSelect] = useState({
    value: "all",
    label: "Tous",
  });
  const [searchInput, setSearchInput] = useState("");

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    setLoad(true);
    let enseigneId = store.enseigneId;
    getEnseigneById(enseigneId)
      .then((res) => {
        console.log("res", res);
        //
        setEnseigne(res.data.enseigne);
        setFiltrePromo(res.data.enseigne.promocodes);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const setFiltrePromo = (promos) => {
    let promoArray = promos.filter(
      (promo) => promo.storeId === "0" || promo.storeId === store._id
    );
    setPromos(promoArray);
  };

  //ajouter une promo
  const onSubmit = (data) => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    let { dateStart, dateEnd } = data;

    if (
      dateToTimes(dateStart) < date.getTime() ||
      dateToTimes(dateEnd) < date.getTime()
    ) {
      notyf.error("Vous ne pouvez pas choisir une date antérieur");
      return;
    }

    if (dateToTimes(dateStart) > dateToTimes(dateEnd)) {
      notyf.error("La date de départ doit être inférieure à la date de fin");
      return;
    }

    const promo = {
      ...data,
      unlimitedUses: unlimited,
      useType: "private",
      enable: true,
      storeId: store._id,
    };
    setLoad(true);
    createPromo(enseigne._id, promo)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setPromos(res.data.promocodes);
        setAdd(!add);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  /// filters
  const isExpired = (promo) => {
    return new Date(new Date(promo.dateEnd).setHours(23, 59, 59)) < new Date();
  };
  const filtredPromos = promos.filter((promo) => {
    let status = false;
    switch (stateSelect.value) {
      case "all":
        status = true;
        break;
      case "activated":
        status = promo.enable && !isExpired(promo);
        break;
      case "deactivated":
        status = !promo.enable;
        break;
      case "expired":
        status = isExpired(promo);
        break;
      default:
        break;
    }

    if (!promo) return null;

    let get = simpleTextSearch([promo.name, promo.code]);
    return get(searchInput).length > 0 && status;
  });

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="block-styled codesPromos">
        {!add && !edit && (
          <div className="block-styled-head">
            <div className="filters">
              <div className="row">
                <div className="col-sm-8">
                  <label>Filtre par état</label>
                  <Select
                    options={[
                      { value: "all", label: "Tous" },
                      { value: "activated", label: "Activée" },
                      { value: "deactivated", label: "désactivée" },
                      { value: "expired", label: "Expirée" },
                    ]}
                    value={stateSelect}
                    onChange={(value) => setStateSelect(value)}
                    defaultValue={stateSelect}
                    className="basic-single"
                    classNamePrefix="select"
                  />
                </div>
                <div className="col-sm-4">
                  <div className="input-wrapper">
                    <img alt="loop-icon" src={Loop} />
                    <input
                      type="text"
                      onChange={(e) =>
                        setSearchInput(e.target.value.toLowerCase())
                      }
                      value={searchInput}
                      placeholder="Rechercher..."
                    />
                    <img
                      onClick={() => setSearchInput("")}
                      alt="removeText"
                      src={Close}
                      className="d-none d-md-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="block-styled-body carde">
          <div className="promo container">
            {add ? (
              <form className="inputs" onSubmit={handleSubmit(onSubmit)}>
                <div className="block-styled">
                  <h1 className="block-styled-head text-center">
                    <i
                      className="fas fa-arrow-left"
                      onClick={() => setAdd(false)}
                    ></i>{" "}
                    Code promo
                  </h1>
                  <div className="block-styled-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-6">
                          <label>Nom de la promo</label>
                          <input
                            type="text"
                            name="name"
                            {...register("name", { required: true })}
                            required
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>Code promo</label>
                          <input
                            type="text"
                            name="code"
                            {...register("code", { required: true })}
                            required
                          />
                        </div>
                      </div>

                      <div className="row" style={{ alignItems: "flex-end" }}>
                        <div className="col">
                          <label>Remise de</label>
                          <input
                            type="number"
                            step="0.5"
                            min="0"
                            name="discountAmount"
                            {...register("discountAmount", { required: true })}
                            required
                          />
                        </div>
                        <div className="col">
                          <label>&nbsp;</label>
                          <select
                            name="method"
                            {...register("method", { required: true })}
                            required
                          >
                            <option value="fixe">euro €</option>
                            <option value="percent">pourcentage %</option>
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label>Promo à partir de </label>
                            <div className="input-group mb-3">
                              <input
                                className="form-control"
                                type="number"
                                step="1"
                                min="0"
                                name="startValue"
                                {...register("startValue", { required: true })}
                                required
                              />
                              <div className="input-group-append">
                                <span className="input-group-text devise_lab">
                                  €
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label>Date début promo</label>
                          <input
                            type="date"
                            name="dateStart"
                            {...register("dateStart", { required: true })}
                            required
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>Date fin promo</label>
                          <input
                            type="date"
                            name="dateEnd"
                            {...register("dateEnd", { required: true })}
                            required
                          />
                        </div>
                      </div>

                      {!unlimited && (
                        <div className="row">
                          <div className="col">
                            <label>Nombre d'utilisation</label>
                            <input
                              type="number"
                              step="1"
                              min="0"
                              name="usesPerCustomer"
                              {...register("usesPerCustomer", {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col">
                          <div className="d-flex">
                            <span className="colored_orange">
                              ou cocher pour utilisation illimité &nbsp; &nbsp;
                            </span>
                            <i
                              onClick={() => {
                                setUnlimited(!unlimited);
                                setValue("usesPerCustomer", "");
                              }}
                            >
                              <Radio color={unlimited ? "#E08005" : "white"} />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button>Valider</button>
                  </div>
                </div>
              </form>
            ) : (
              <>
                {!edit && (
                  <>
                    {" "}
                    <div className="promo--all">
                      {filtredPromos.map((promo, id) => (
                        <PromoSingle
                          key={id}
                          promo={promo}
                          setEdit={setEdit}
                          setSelected={setSelected}
                          setPromos={setPromos}
                          enseigne={enseigne}
                          store={store}
                          handleSetLoad={setLoad}
                        />
                      ))}
                      <div
                        className="promo--single promo--add-btn"
                        onClick={() => setAdd(!add)}
                      >
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM25.9999 22V14H21.9999V22H13.9999V26H21.9999V34H25.9999V26H33.9999V22H25.9999ZM7.99989 24C7.99989 32.82 15.1799 40 23.9999 40C32.8199 40 39.9999 32.82 39.9999 24C39.9999 15.18 32.8199 7.99997 23.9999 7.99997C15.1799 7.99997 7.99989 15.18 7.99989 24Z"
                            fill="#393838"
                          />
                        </svg>
                        AJOUTER UN CODE PROMO
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {edit && (
              <PromoEdit
                promo={selected}
                setEdit={setEdit}
                setPromos={setPromos}
                enseigne={enseigne}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default CodePromoStore;
