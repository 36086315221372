import React from "react";

const Trash = (props) => {
  return (
    <span className="sidebar--list-svg mr">
      <svg
        width="15"
        height="19"
        viewBox="0 0 15 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.854 2.53333H13.5082V3.05H1.4915V2.53333H4.14567H4.43562L4.64065 2.32831L5.39395 1.575H9.60572L10.359 2.32831L10.5641 2.53333H10.854ZM3.6665 17.425C2.99894 17.425 2.44984 16.8759 2.44984 16.2083V5.40833H12.5498V16.2083C12.5498 16.8759 12.0007 17.425 11.3332 17.425H3.6665Z"
          stroke={props.color}
          strokeWidth="1.4"
        />
      </svg>
    </span>
  );
};

export default Trash;
