export const PRIVATE_PATH =  `assets/images` ;

export const typePromotion = {
    1: "Livraison offerte",
    2: "Réduction d’une somme sur panier final",
    3: "Produit offert par commande",
    4: "Addition de produit = un prix",
    5: "1, 2, 3 produits achetés = 1 identique offert",
    6: "1, 2, 3.. produits achetés = 1 produit offert",
    7: "1, 2, 3... produits achetés = 1 produit en promo",
}