import React, { useEffect, useState } from "react";
import { Notyf } from "notyf";
import { Switch } from "@material-ui/core";

// Components
import Loader from "~/components/Loader";
// Utils
import { getUserById } from "~/api";
import {
  updateStoreEmail,
  updateStorePassword,
  switchAccessibleStore,
} from "~/api/stores";
//svgs
import mailIcon from "~/assets/svg/mail.svg";
import lockIcon from "~/assets/svg/lock.svg";
import eyeOpen from "~/assets/svg/eye-open.svg";
import eyeClose from "~/assets/svg/eye-close.svg";

const AccesStore = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [user, setUser] = useState({});
  const [inputs, setInputs] = useState({});
  const [showPass, setShowPass] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [load, setLoad] = useState(true);
  const store = props.store;

  useEffect(() => {
    getUserById(store.managerUserId)
      .then((res) => {
        setUser(res.data.user);
        setInputs({
          email: res.data.user.email,
          password: "",
          passwordConfirmation: "",
          accessible: store.accessible,
        });
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });

    // eslint-disable-next-line
  }, []);

  // change value of forms
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  // submit form change email
  const handleSubmitNewEmail = (e) => {
    e.preventDefault();
    setLoad(true);

    updateStoreEmail(user._id, inputs.email)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  // submit change password
  const handleSubmitNewPassword = (e) => {
    e.preventDefault();

    const { password, passwordConfirmation } = inputs;
    if (password !== passwordConfirmation) {
      notyf.error("Le mot de passe doit être identique");
    } else {
      setLoad(true);
      updateStorePassword(user._id, password)
        .then((res) => {
          setLoad(false);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          setLoad(false);
          notyf.error(err.message);
        });
    }
  };

  // Switch store accessibilite
  const changeAccessible = () => {
    setLoad(true);
    switchAccessibleStore(store._id)
      .then((res) => {
        setLoad(false);
        setInputs({
          ...inputs,
          accessible: !inputs.accessible,
        });
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <div className='access'>
      {load ? (
        <Loader />
      ) : (
        <>
          <div className='block-styled container'>
            <div className='block-styled-body'>
              <div className='access--formsWrapper'>
                <form className='access--form' onSubmit={handleSubmitNewEmail}>
                  <h3 className='access--title'>
                    Changer mon email de connexion
                  </h3>
                  <label>Email</label>
                  <div>
                    <img alt='email-icon' src={mailIcon} />
                    <input
                      type='text'
                      required
                      onChange={handleChange}
                      value={inputs.email}
                      name='email'
                      autoComplete='off'
                      className='input-email'
                    />
                  </div>
                  <button type='submit'>Modifier</button>
                </form>

                <form
                  className='access--form'
                  onSubmit={handleSubmitNewPassword}
                >
                  <h3 className='access--title'>Changer mon mot de passe</h3>
                  <label>Mot de passe</label>
                  <div>
                    <img alt='password-icon' src={lockIcon} />
                    <input
                      type={showPass ? "text" : "password"}
                      required
                      autoComplete='off'
                      onChange={handleChange}
                      value={inputs.password}
                      name='password'
                      className='input-libre'
                    />
                    <img
                      onClick={() => setShowPass(!showPass)}
                      alt='eye-close-icon'
                      className='access--form-eyeIcon'
                      src={showPass ? eyeOpen : eyeClose}
                    />
                  </div>
                  <label>Confirmé mot de passe</label>
                  <div>
                    <img alt='password-icon' src={lockIcon} />
                    <input
                      type={showConfirm ? "text" : "password"}
                      required
                      onChange={handleChange}
                      value={inputs.passwordConfirmation}
                      name='passwordConfirmation'
                      className='input-libre'
                    />
                    <img
                      onClick={() => setShowConfirm(!showConfirm)}
                      alt='eye-close-icon'
                      className='access--form-eyeIcon'
                      src={showConfirm ? eyeOpen : eyeClose}
                    />
                  </div>
                  <button type='submit'>Modifier</button>
                </form>
              </div>
              <div className='access--formsWrapper align-items-center'>
                <span style={{ marginTop: -6 }}>
                  <Switch
                    onChange={changeAccessible}
                    checked={inputs.accessible}
                    color='primary'
                    name='accessible'
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </span>
                <span>
                  Donnez vous le droit à l’administrateur de l’enseigne de
                  rentrer dans la boutique
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccesStore;
