import React from 'react';

const Media = (props) => {
  return (
    <span className='sidebar--list-svg'>
      <svg
        {...props}
        width='20'
        height='20'
        viewBox='0 0 19 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18 4.6v12.8a.6.6 0 01-.6.6H4.6a.6.6 0 01-.6-.6V4.6a.6.6 0 01.6-.6h12.8a.6.6 0 01.6.6v0z'
          stroke='#000'
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 1H1.6a.6.6 0 00-.6.6V15M4 13.8L9.444 12 18 15M13.5 10a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'
          stroke='#000'
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
};

export default Media;
