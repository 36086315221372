import React from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "~/assets/svg/close.svg";

const EditStore = (props) => {
  return (
    <Modal
      className='modal addenseigne'
      show={props.showEditStore}
      onHide={props.handleCloseEditStore}
    >
      <form onSubmit={props.handleEditStore}>
        <Modal.Header closeButton>
          <h1 className='modal--title'>Modifier la boutique</h1>
          <img
            onClick={props.handleCloseEditStore}
            className='modal--close'
            alt='close-svg'
            src={closeIcon}
          />
        </Modal.Header>
        <Modal.Body className='fade-in'>
          <div className='inputs'>
            <label>Nom de la boutique</label>
            <input
              required
              name='intitule'
              value={props.selectedInputEditStore.intitule}
              onChange={props.handleChangeSelectedEditStore}
            />
            <label>Numero et voie</label>
            <input
              required
              name='street'
              value={props.selectedInputEditStore.street}
              onChange={props.handleChangeSelectedEditStore}
            />

            <div className='row'>
              <div className='col-sm-6'>
                <label>Code postal</label>
                <input
                  required
                  name='zip'
                  value={props.selectedInputEditStore.zip}
                  onChange={props.handleChangeSelectedEditStore}
                  type='number'
                  step='0.01'
                  min='0'
                />
              </div>
              <div className='col-sm-6'>
                <label>Ville</label>
                <input
                  required
                  name='city'
                  value={props.selectedInputEditStore.city}
                  onChange={props.handleChangeSelectedEditStore}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label>Latitude</label>
                <input
                  required
                  name='lat'
                  value={props.selectedInputEditStore.lat}
                  onChange={props.handleChangeSelectedEditStore}
                />
              </div>
              <div className='col'>
                <label>Longitude</label>
                <input
                  required
                  name='lng'
                  value={props.selectedInputEditStore.lng}
                  onChange={props.handleChangeSelectedEditStore}
                />
              </div>
            </div>

            <label>Téléphone</label>
            <input
              required
              name='phone'
              value={props.selectedInputEditStore.phone}
              onChange={props.handleChangeSelectedEditStore}
              type='number'
            />

            <label>Nom du manager</label>
            <input
              required
              name='managerLastname'
              value={props.selectedInputEditStore.managerLastname}
              onChange={props.handleChangeSelectedEditStore}
              type='text'
            />
            <label>Prénom du manager</label>
            <input
              required
              name='managerFirstname'
              value={props.selectedInputEditStore.managerFirstname}
              onChange={props.handleChangeSelectedEditStore}
              type='text'
            />

            <div className='row'>
              <div className='col'>
                <label>Condition de vente</label>
                <select
                  name='vad'
                  value={props.selectedInputEditStore.vad}
                  onChange={props.handleChangeSelectedEditStore}
                >
                  <option value={true}>Avec vad</option>
                  <option value={false}>Sans vad</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='modal--btn-close'
            type='button'
            onClick={props.handleCloseEditStore}
          >
            Retour
          </button>
          <button className='modal--btn-validate' type='submit'>
            {props.editLoad ? (
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              "Modifier"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditStore;
