import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import TranchesArray from "./tranchesArray";
//SVG
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";

const ZonesArray = ({ control, register, getValues, setValue }) => {

    const { fields, append, remove } = useFieldArray({ control, name: "zones" });

    useEffect(() => {
        
        if (fields.length < 1 ) {
            append({ 
                enable: true,
                name: "Zone 1", 
                nbKmMax: null,
                type: "km"
            });
        }else{
            setValue('zones', fields ) ;
        }

        // eslint-disable-next-line
    }, []);


    const appendZone = () => {
        append({ 
            enable: true,
            name: `Zone ${fields.length+1}`, 
            nbKmMax: null,
            type: "km"
        });
    }

    /*
    const handleChangeType = (index, value) => {
        let zones = getValues('zones');
        zones[index].type = value ;
        setValue('zones', zones ) ;
    }*/

    const handleChangeTab = (index) => {
        let zones = getValues('zones');
        zones[index].enable = !zones[index].enable ;
        setValue('zones', zones ) ;
    }

    return (
        <>
           {fields.map((area, index)=>{
                return(
                    <div key={index} className={`area ${area.enable ? 'active' : '' }`}> 
                        <div className="area-title" onClick={ ()=> handleChangeTab(index) } >
                            <input type="hidden" {...register(`zones.${index}.name`)} defaultValue={area.name} />
                            {area.name}
                            <i className="fa fa-chevron-down"></i>
                        </div>

                        <div className="area-content">
                            <button className="btn btn-danger btn-small btn-block rounded-pill" type="button"  onClick={() => remove(index)}>
                                <TrashOutlineIcon /> Supprimer cette zone
                            </button>
                            {/* <div className="area-tabs d-flex">
                                <div>
                                    <button 
                                        onClick={ ()=> handleChangeType(index, 'km') } 
                                        type="button" 
                                        className={`btn ${area.type === 'km' ? 'active' : ''}`}>
                                            Par km
                                    </button>
                                </div>
                                <div>
                                    <button 
                                        onClick={ ()=> handleChangeType(index, 'trace') } 
                                        type="button" 
                                        className={`btn ${area.type === 'trace' ? 'active' : ''}`}>
                                            Tracé
                                    </button>
                                </div>
                            </div> */}
                            <div className="slices">

                                <div className="slice">
                                    <div className="d-flex">
                                        <div>
                                            <label>Nombre de km max :</label>
                                        </div>
                                        <div>
                                            <input type="number" {...register(`zones.${index}.nbKmMax`)} step="0.01" defaultValue={area.nbKmMax} className="form-control" />
                                        </div>
                                    </div> 
                                </div>

                                <TranchesArray nestIndex={index} {...{ control, register }} />
                                
                            </div>
                        </div>
                        
                    </div>
                )
            })} 

            <button onClick={ appendZone } type="button" className="btn add-area">Ajouter une zone de livraison ?</button> 
        </>
    )
}

export default ZonesArray
