import * as React from "react";

const Downlaord = (props) => (
  <a href={props.link} download style={{ marginRight: "42px" }}>
    <svg
      width={20}
      height={20}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 17.292h20V20H0v-2.708ZM13.68 0H6.32v8.277h-4.6l8.28 7.71 8.28-7.71h-4.6V0Z'
        fill='#000'
      />
    </svg>
  </a>
);

export default Downlaord;
