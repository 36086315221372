import React, { useState } from "react";
import { NavLink } from "react-router-dom";

//SHOP ICONS
import GestionIcon from "~/components/Svgs/Super/Gestion";
import MenuIcon from "~/components/Svgs/Shop/Menu";
import InfosIcon from "~/components/Svgs/Shop/Infos";
import AccessIcon from "~/components/Svgs/Shop/Access";
import Style from "~/components/Svgs/Super/Style";
import BurgerIcon from "~/components/Svgs/Shop/Burger";

const SidebarMenu = (props) => {
  // Render
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="toggle-products-menu d-md-none">
        <button
          className="btn"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <BurgerIcon />
        </button>
      </div>
      <div
        className={`side-menu d-none d-md-block ${showMenu ? "active" : ""}`}
      >
        <div>
          <NavLink
            exact
            className="sidebarL--list-navLink"
            activeClassName="selected"
            to="/store/menu/categories"
          >
            <li className="sidebarL--list_item">
              <span className="sidebarL--list-dash">
                {" "}
                {props.show && "Categories"}{" "}
              </span>
              <GestionIcon />
            </li>
          </NavLink>
          <NavLink
            exact
            className="sidebarL--list-navLink"
            activeClassName="selected"
            to="/store/menu/produits"
          >
            <li className="sidebarL--list_item">
              <span className="sidebarL--list-dash">
                {" "}
                {props.show && "Produits"}{" "}
              </span>
              <MenuIcon />
            </li>
          </NavLink>
          <NavLink
            exact
            className="sidebarL--list-navLink"
            activeClassName="selected"
            to="/store/menu/ingredients"
          >
            <li className="sidebarL--list_item">
              <span className="sidebarL--list-dash">
                {" "}
                {props.show && "Ingredients"}{" "}
              </span>
              <InfosIcon />
            </li>
          </NavLink>
          <NavLink
            exact
            className="sidebarL--list-navLink"
            activeClassName="selected"
            to="/store/menu/envies"
          >
            <li className="sidebarL--list_item">
              <span className="sidebarL--list-dash">
                {" "}
                {props.show && "Envies"}{" "}
              </span>
              <AccessIcon />
            </li>
          </NavLink>
          <NavLink
            exact
            className="sidebarL--list-navLink"
            activeClassName="selected"
            to="/store/menu/allergenes"
          >
            <li className="sidebarL--list_item">
              <span className="sidebarL--list-dash">
                {" "}
                {props.show && "Allergenes"}{" "}
              </span>
              <Style />
            </li>
          </NavLink>
          <NavLink
            exact
            className="sidebarL--list-navLink"
            activeClassName="selected"
            to="/store/menu/groupes"
          >
            <li className="sidebarL--list_item">
              <span className="sidebarL--list-dash">
                {" "}
                {props.show && "Groupes"}{" "}
              </span>
              <GestionIcon />
            </li>
          </NavLink>
          <NavLink
            exact
            className="sidebarL--list-navLink"
            activeClassName="selected"
            to="/store/menu/additionnel"
          >
            <li className="sidebarL--list_item">
              <span className="sidebarL--list-dash">
                {props.show && "Additionnel"}
              </span>
              <Style />
            </li>
          </NavLink>
          <NavLink
            exact
            className="sidebarL--list-navLink"
            activeClassName="selected"
            to="/store/menu/cuisine"
          >
            <li className="sidebarL--list_item">
              <span className="sidebarL--list-dash">
                {props.show && "Cuisine"}
              </span>
              <Style />
            </li>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default SidebarMenu;
