import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
import { createSection } from "~/api/menu";
//svg
import closeIcon from "~/assets/svg/close.svg";
import RadioColor from "../../../../Svgs/Super/styles/radioColor";
//CSS
import "../_add_item.scss";
import { SketchPicker } from "react-color";

const AddSection = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  let obj = {
    item: { hex: "" },
  };

  const [load, setLoad] = useState(false);
  const [sect, setSect] = useState("");
  const [color, setColor] = useState("#ffffff");
  const [mulled, setMulled] = useState("");
  const [design, setDesign] = useState(obj);

  const handleAdd = (e) => {
    e.preventDefault();

    let exists = props.sections.find((item) => {
      return item.name.toLowerCase() === sect.toLowerCase();
    });
    if (exists) {
      notyf.error("Cette section est déjà ajoutée");
      return;
    }
    setLoad(true);

    (async function todo() {
      let menuActive = localStorage.getItem("menuActive");

      let newSection = {
        name: sect,
        color: color,
        storeId: props.storeId,
        menuId: menuActive,
      };
      await createSection(newSection).then((res) => {
        let sections = res.data.sections;
        props.init(sections);
        notyf.success(res.data.message);
      });

      setLoad(false);
      props.handleCloseAdd(false);
    })();
  };

  const handleChangeComplete = (color) => {
    setDesign({
      ...design,
      [mulled]: color,
    });
    setColor(color.hex);
  };

  return (
    <Modal
      className="modal add-allergie"
      show={props.showAdd}
      onHide={() => props.handleCloseAdd(false)}
    >
      <Modal.Header closeButton>
        <h1 className="modal--title">Ajouter une section</h1>
        <img
          onClick={() => props.handleCloseAdd(false)}
          className="modal--close"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>
      <form onSubmit={handleAdd}>
        <Modal.Body className="fade-in">
          <div className="inputs">
            <label>Nom de la section</label>
            <input
              required
              name="name"
              value={sect}
              onChange={(e) => setSect(e.target.value)}
            />
          </div>

          <div className="inputs">
            <label>Choisissez la couleur de la section</label>
            <div
              className="styles--body-colors"
              style={{ width: "100%", padding: "1rem" }}
            >
              <div
                className="styles--body-colors-left"
                onClick={() => setMulled("item")}
              >
                <span>
                  <RadioColor color={design.item.hex} />
                </span>
                <div className="styles--body-colors-btn">
                  <p className="styles--body-colors-btn-principal">
                    ( votre couleur )
                  </p>
                </div>
              </div>
              {mulled === "item" && (
                <>
                  <div className="picker-bg" onClick={() => setMulled("")}>
                    &nbsp;
                  </div>
                  <div className="styles--body-colors-picker">
                    <SketchPicker
                      color={design.item}
                      onChangeComplete={handleChangeComplete}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="modal--btn-validate large" disabled={load}>
            {load ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Ajouter"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddSection;
