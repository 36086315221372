import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
//import { uploadFile } from '~/api/upload';
import { updateIngredient } from "~/api/menu";
//svg
import closeIcon from "~/assets/svg/close.svg";
import upload from "~/assets/svg/upload-lg.svg";
import { useLoadMedia } from "~/hooks/loadMedia";
//CSS
import "../_add_item.scss";

const EditIngredient = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const [ingredient, setIngredient] = useState(props.ingredient);
  const [sendToKitcken, setSendToKitcken] = useState(ingredient?.sendToKitcken);
  const [loadImage, setLoadImage] = useState();
  const [image, setImage] = useState("");
  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    //uppdateInfo,
    //setUpdateInfo,
    setLoadMedia,
    Media,
  } = useLoadMedia({
    ingredient: {
      boolean: loadImage,
      action: setLoadImage,
      name: "ingredient",
      updateValue: setImage,
    },
  });

  const handleEdit = (e) => {
    e.preventDefault();
    setLoad(true);

    (async function todo() {
      let iconName = "";

      iconName = image?.ingredient;

      let newAllergene = {
        ...ingredient,
        name: ingredient.name,
        imageUrl: iconName !== "" ? iconName : ingredient.imageUrl,
        sendToKitcken,
      };
      await updateIngredient(newAllergene)
        .then((res) => {
          let ingredients = res.data.ingredients;
          props.init(ingredients);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          notyf.error(err.message);
        });

      setLoad(false);
      props.handleCloseEdit(false);
    })();
  };

  return (
    <>
      <Modal
        className="modal edit-ingredient"
        show={props.showEdit}
        onHide={() => props.handleCloseEdit(false)}
      >
        <Modal.Header closeButton>
          <h1 className="modal--title">Modifier {ingredient.name} </h1>
          <img
            onClick={() => props.handleCloseEdit(false)}
            className="modal--close"
            alt="close-svg"
            src={closeIcon}
          />
        </Modal.Header>

        {loadMedia ? (
          <Media
            setLoadMedia={setLoadMedia}
            handleSelectedMedia={handleSelectedMedia}
          />
        ) : (
          <form onSubmit={handleEdit}>
            <Modal.Body className="fade-in">
              <div className="inputs">
                <label>Nom de l'ingédient</label>
                <input
                  required
                  name="name"
                  value={ingredient.name}
                  onChange={(e) =>
                    setIngredient({ ...ingredient, name: e.target.value })
                  }
                />
              </div>
              {ingredient.imageUrl && !image.ingredient ? (
                <center>
                  <img
                    alt=""
                    src={ingredient.imageUrl}
                    style={{ width: 40, marginBottom: 30 }}
                  />
                </center>
              ) : (
                <center>
                  <img
                    alt=""
                    src={image?.ingredient}
                    style={{ width: 40, marginBottom: 30 }}
                  />
                </center>
              )}
              <p>Selectionnez un icon carré en PNG (max 100x100)</p>
              <div className="styles--body-upload">
                <label htmlFor="upload--logo">
                  <img
                    alt="upload-file"
                    src={upload}
                    name="ingredient"
                    onClick={handleMedia}
                  />
                </label>
              </div>
              <div className="styles--body-upload">
                <div className="row align-items-center choice-toggle">
                  <div className="col-6 text-left">
                    <p>Ingredient envoyé en cuisine</p>
                  </div>
                  <div className="col-6 text-right">
                    <div className="cmd-choix">
                      <span>
                        <span
                          className={sendToKitcken ? "option active" : "option"}
                          onClick={() => setSendToKitcken(true)}
                        >
                          Oui
                        </span>
                        <span
                          className={
                            !sendToKitcken ? "option active" : "option"
                          }
                          onClick={() => setSendToKitcken(false)}
                        >
                          Non
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button type="submit" className="modal--btn-validate large">
                {load ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Modifier"
                )}
              </button>
            </Modal.Footer>
          </form>
        )}
      </Modal>
    </>
  );
};

export default EditIngredient;
