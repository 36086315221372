import React, { useState } from "react";
import { Notyf } from "notyf";
import { Switch } from "@material-ui/core";

// Components
import Loader from "~/components/Loader";
// Utils
import { updateTerminal } from "~/api/terminal";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
  duration: 3000,
  dismissible: true,
});

const LivraisonCommande = (props) => {
  const [load, setLoad] = useState(false);
  const { terminal, store } = props;

  const [commande, setCommande] = useState(terminal.config.commande);

  const updateConfig = async (e) => {
    setLoad(true);

    const config = {
      ...terminal.config,
      commande,
    };

    try {
      const res = await updateTerminal(store._id, {
        config,
      });
      if (res.status === 200) {
        notyf.success("Success");
        setLoad(false);
        props.setTerminal({ ...terminal, config });
      }
    } catch (error) {
      setLoad(false);
      notyf.error("Erreur");
    }
  };

  return (
    <div className='container'>
      {load ? (
        <Loader />
      ) : (
        <div className='row gx-5 justify-content-between  page-type-commande'>
          <div className='col'>
            <div className='block-styled'>
              <h1 className='text-center block-styled-head'>
                Mode de commande
              </h1>
              <div className=' borne'>
                <div className='row gx-5 btns-toggle justify-content-between'>
                  <div className='col'>
                    <div className='input'>
                      <Switch
                        onChange={(e) =>
                          setCommande({
                            ...commande,
                            place: !commande.place,
                          })
                        }
                        checked={commande.place}
                        color='primary'
                        name='canCB'
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Sur place
                    </div>
                    <div className='input'>
                      <Switch
                        onChange={(e) =>
                          setCommande({
                            ...commande,
                            emporter: !commande.emporter,
                          })
                        }
                        checked={commande.emporter}
                        color='primary'
                        name='canES'
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Emporter
                    </div>
                  </div>
                </div>

                <div className='styles--top mt-3'>
                  <button type='button' onClick={updateConfig}>
                    Validez
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LivraisonCommande;
