import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Switch } from "@material-ui/core";

//svgs
import Plus from "~/assets/svg/plus.svg";
import Send from "~/assets/svg/send.svg";
import {
  createCaisse,
  getCaisseWithStoreId,
  updateCaisseStatus,
} from "~/api/caisse";
import Loader from "~/components/Loader";

const Caisses = (props) => {
  const { selectedStoreId } = props;

  const [loading, setLoading] = useState(true);
  const [storeCaisses, setStoreCaisses] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);

  const handleCreateCaisse = async () => {
    try {
      setCreateLoading(true);
      const data = await createCaisse(selectedStoreId);
      setStoreCaisses((stores) => [...stores, data]);
      setCreateLoading(false);
    } catch (err) {
      setCreateLoading(false);
    }
  };

  const handleChangeStatus = async (terminal) => {
    try {
      setLoading(true);
      const enable = !terminal.enable;
      await updateCaisseStatus(terminal._id, enable);
      setStoreCaisses((stores) =>
        stores.map((x) =>
          x._id === terminal._id ? { ...x, enable: enable } : x
        )
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getCaisses = async () => {
      try {
        setLoading(true);
        const res = await getCaisseWithStoreId(selectedStoreId);
        setStoreCaisses(res.cashs);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    if (selectedStoreId) getCaisses();
  }, [selectedStoreId]);

  return (
    <Modal
      className="modal terminal"
      show={props.caisseStoreModal}
      onHide={props.handleCloseCaisseStoreModal}
    >
      <Modal.Header closeButton>
        <h1 className="modal--title">Caisses</h1>
      </Modal.Header>

      <Modal.Body className="fade-in">
        <div class="terminal-btn--container">
          <button
            className="superdash--addStore-btn"
            // onClick={handleShowAddStore}
            onClick={handleCreateCaisse}
            disabled={createLoading}
          >
            {createLoading ? (
              <p>Chargement...</p>
            ) : (
              <>
                <img alt="plus" src={Plus} />
                <p>Ajouter une caisse</p>{" "}
              </>
            )}
          </button>
        </div>
        <div class="terminals">
          {loading && <Loader />}
          {!loading &&
            storeCaisses.map((caisse) => {
              return (
                <div
                  key={caisse._id}
                  class="d-flex terminals--container terminals--item"
                >
                  <div className="d-flex terminals--item">
                    <Switch
                      onChange={() => handleChangeStatus(caisse)}
                      checked={caisse.enable}
                      color={caisse.enable ? "primary" : "warning"}
                      name="changePaid"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <p>{caisse.name}</p>
                  </div>
                  <div className="d-flex terminals--item">
                    <p>Envoi mail</p>
                    <img src={Send} alt="send" />
                  </div>
                </div>
              );
            })}
        </div>

        <div class="terminal-btn--container">
          <button
            className="superdash--addStore-btn terminal-btn--container--finish "
            onClick={props.handleCloseCaisseStoreModal}
          >
            {/* <img alt='plus' src={Plus} /> */}
            <p>Terminer</p>{" "}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Caisses;
