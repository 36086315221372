import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Switch } from "@material-ui/core";

//svgs
import Plus from "~/assets/svg/plus.svg";
import Send from "~/assets/svg/send.svg";
import {
  createTerminal,
  getTerminalWithStoreId,
  updateTerminalStatus,
} from "~/api/terminal";
import Loader from "~/components/Loader";

const Terminals = (props) => {
  const { selectedStoreId } = props;

  const [loading, setLoading] = useState(true);
  const [storeTerminals, setStoreTerminals] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);

  const handleCreateTerminal = async () => {
    try {
      setCreateLoading(true);
      const data = await createTerminal(selectedStoreId);
      setStoreTerminals((stores) => [...stores, data]);
      setCreateLoading(false);
    } catch (err) {
      setCreateLoading(false);
    }
  };

  const handleChangeStatus = async (terminal) => {
    try {
      setLoading(true);
      const enable = !terminal.enable;
      await updateTerminalStatus(terminal._id, enable);
      setStoreTerminals((stores) =>
        stores.map((x) =>
          x._id === terminal._id ? { ...x, enable: enable } : x
        )
      );
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getTerminals = async () => {
      try {
        setLoading(true);
        const res = await getTerminalWithStoreId(selectedStoreId);
        setStoreTerminals(res.terminals);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    if (selectedStoreId) getTerminals();
  }, [selectedStoreId]);

  return (
    <Modal
      className='modal terminal'
      show={props.terminalStoreModal}
      onHide={props.handleCloseTerminalStoreModal}
    >
      <Modal.Header closeButton>
        <h1 className='modal--title'>Bornes</h1>
      </Modal.Header>

      <Modal.Body className='fade-in'>
        <div class='terminal-btn--container'>
          <button
            className='superdash--addStore-btn'
            // onClick={handleShowAddStore}
            onClick={handleCreateTerminal}
            disabled={createLoading}
          >
            {createLoading ? (
              <p>Chargement...</p>
            ) : (
              <>
                <img alt='plus' src={Plus} />
                <p>Ajouter une borne</p>{" "}
              </>
            )}
          </button>
        </div>
        <div class='terminals'>
          {loading && <Loader />}
          {!loading &&
            storeTerminals.map((terminal) => {
              return (
                <div
                  key={terminal._id}
                  class='d-flex terminals--container terminals--item'
                >
                  <div className='d-flex terminals--item'>
                    <Switch
                      onChange={() => handleChangeStatus(terminal)}
                      checked={terminal.enable}
                      color={terminal.enable ? "primary" : "warning"}
                      name='changePaid'
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <p>{terminal.name}</p>
                  </div>
                  <div className='d-flex terminals--item'>
                    <p>Envoi mail</p>
                    <img src={Send} alt='send' />
                  </div>
                </div>
              );
            })}
        </div>

        <div class='terminal-btn--container'>
          <button
            className='superdash--addStore-btn terminal-btn--container--finish '
            onClick={props.handleCloseTerminalStoreModal}
          >
            {/* <img alt='plus' src={Plus} /> */}
            <p>Terminer</p>{" "}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Terminals;
