import React from "react";

const Style = (props) => {
  return (
    <span className="sidebar--list-svg mr">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3765 2.25C14.1815 2.25 13.994 2.325 13.844 2.4675L7.12402 9.1875L9.18652 11.25L15.9065 4.53C16.199 4.2375 16.199 3.765 15.9065 3.4725L14.9015 2.4675C14.7515 2.3175 14.564 2.25 14.3765 2.25ZM5.62402 12C6.03652 12 6.37402 12.3375 6.37402 12.75C6.37402 13.575 5.69902 14.25 4.87402 14.25C4.74652 14.25 4.62652 14.235 4.49902 14.2125C4.73152 13.8 4.87402 13.305 4.87402 12.75C4.87402 12.3375 5.21152 12 5.62402 12ZM3.37402 12.75C3.37402 11.505 4.37902 10.5 5.62402 10.5C6.86902 10.5 7.87402 11.505 7.87402 12.75C7.87402 14.4075 6.53152 15.75 4.87402 15.75C3.74152 15.75 2.56402 15.165 1.87402 14.25C2.50402 14.25 3.37402 13.7325 3.37402 12.75Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

export default Style;
