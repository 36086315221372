import React, { useEffect, useState } from 'react'
//import Select from 'react-select'
// Utils
import { getReservationsOfStore, deleteReservation } from '~/api/reservations';
import { GetFormattedDate, dateToTimes } from '~/_helpers/helper';
//Components
import DetailReservation from './Details'
import Loader from "~/components/Loader"; 
//SVG 
import eye from "~/assets/svg/eye.svg";
//CSS
import './_reservations.scss' 

//Pagination
import TablePagination from '~/assets/table-pagination/'

const Reservations = (props) => {

    //Stats
    const store = props.store 
    const [load, setLoad] = useState(true); 
    const [reservations, setReservations] = useState([]); 
    const [active, setActive] = useState({});
    const [dates, setDates] = useState({start: '', end: ''});

    /// detail
    const [showDetail, setShowDetail] = useState(false);  

    useEffect(() => { 
        let storeId = store._id;
        getReservationsOfStore(storeId)
            .then((res) => {
                setReservations(res.data.reservations);
                setLoad(false);
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
            });
            
        // eslint-disable-next-line
    }, []);

    const reservationsFiltered = reservations.filter((reservation) => { 

        if( dates.start !== '' || dates.end !== '' ){
            let currentDate = GetFormattedDate(reservation.dateLivraison)

            let check = () => {
                let check1 = true;
                let check2 = true;
                if(dates.start !== '' ){
                    check1 =  dateToTimes(currentDate) >= dateToTimes(dates.start) ;
                }
                if(dates.end !== '' ){
                    check2 = dateToTimes(currentDate) <= dateToTimes(dates.end) ;
                }
                return check1 && check2;
            }
            return check()
        }
        return true
    });

    const handleDelete = (id) => {
        setShowDetail(false);
        setLoad(true);
        deleteReservation(id)
            .then((res) => {
                setReservations(res.data.reservations);
                setLoad(false);
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
            });
    }


    /// Pagination 
    const tableColumns = [{
        Header: 'Commande',
        accessor: d => '#'+ d.incrementId +' '+ d.customer?.firstName
    },{
        Header: 'Date/Heure',
        accessor: d => GetFormattedDate(d.dateLivraison) +' '+ d.heureLivraison
    },{
        Header: 'Personnes',
        accessor: 'nbrPersonnes'
    },{
        Header: '', 
        accessor: '_id',
        Cell: ({ value, row })  => {
            return(
                <button  
                    onClick={ ()=>{setActive(row.original); setShowDetail(true)} }
                    className="btn btn-light btn-details">
                    <img src={eye} alt="" />
                </button>
            )
        }
    }];

    if (load) {
        return <Loader />;
    } else {
        return ( 
            <>
                <div className="container page-reservations">
                    <div className="block-styled container">
                        <div className="block-styled-body">

                            <div className="filters">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label>Date debut </label> 
                                        <input type="date" className="form-control" onChange={(e)=>{setDates({...dates, start: e.target.value})}} />
                                    </div>
                                    <div className="col-sm-3">
                                        <label>Date fin </label>
                                        <input type="date" className="form-control" onChange={(e)=>{setDates({...dates, end: e.target.value})}} />
                                    </div> 
                                </div>
                            </div> 
                            
                            <div className="reservations-list">
                                <TablePagination columns={tableColumns} data={reservationsFiltered} /> 
                            </div>  
                        </div>
                    </div>
                </div>                       
                { 
                    showDetail && 
                    <DetailReservation handleClose={(setShowDetail)} reservation={ active } delete={ handleDelete } /> 
                }
            </>
        )
    }
}

export default Reservations
