import React from 'react';

// Componenets
import PromoType1 from './type1';
import PromoType2 from './type2';
import PromoType3 from './type3';
import PromoType4 from './type4';
import PromoType5 from './type5';
import PromoType6 from './type6';
import PromoType7 from './type7';

const AdvancesData = ({advanceDatas, title, back, promotion, store}) => {

    const initData = (datas) => {
        advanceDatas(datas);
    }

    let showType = "";
    switch (promotion.type) {
        case 1:
            showType = <PromoType1 init={initData} title={title} handleBack={back} promo={promotion} />
            break;
        case 2:
            showType = <PromoType2 init={initData} title={title} handleBack={back} promo={promotion} />
            break;
        case 3:
            showType = <PromoType3 init={initData} title={title} handleBack={back} promo={promotion} store={store} />
            break;
        case 4:
            showType = <PromoType4 init={initData} title={title} handleBack={back} promo={promotion} store={store} />
            break;
        case 5:
            showType = <PromoType5 init={initData} title={title} handleBack={back} promo={promotion} store={store} />
            break;
        case 6:
            showType = <PromoType6 init={initData} title={title} handleBack={back} promo={promotion} store={store} />
            break;
        case 7:
            showType = <PromoType7 init={initData} title={title} handleBack={back} promo={promotion} store={store} />
            break;
        default:
            break;
    }

    return (
        <div>
            {showType}
        </div>
    )
}

export default AdvancesData
