import React from "react";

const Infos = (props) => {
  return (
    <span className="sidebar--list-svg mr">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.81154 4.11155C4.81154 2.34953 6.23842 0.922656 8.00043 0.922656C9.76245 0.922656 11.1893 2.34953 11.1893 4.11155C11.1893 5.87356 9.76245 7.30043 8.00043 7.30043C6.23842 7.30043 4.81154 5.87356 4.81154 4.11155ZM0.922656 12.8615C0.922656 12.4721 1.11218 12.0792 1.54765 11.6773C1.98788 11.271 2.63116 10.9045 3.39842 10.5971C4.93407 9.98193 6.79002 9.67266 8.00043 9.67266C9.21084 9.67266 11.0668 9.98193 12.6025 10.5971C13.3697 10.9045 14.013 11.271 14.4532 11.6773C14.8887 12.0792 15.0782 12.4721 15.0782 12.8615V15.0782H0.922656V12.8615Z" stroke="#393838" strokeWidth="1.4"/>
      </svg> 
    </span>
  );
};

export default Infos;
