import { BASE_URI } from "~/assets/base";
import instanceAxios from "./axios.instance";

export const getArchivesWithStoreId = async (
  storeId,
  { cashRegister, type }
) => {
  const res = await instanceAxios.get(
    `${BASE_URI}store/archived/${storeId}?cashRegister=${cashRegister}&type=${type}`
  );
  return res.data;
};
