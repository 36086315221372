import React, { useState } from 'react';

// Components
import Etape1 from './Etape1';
import PromoMoment from '../commun/PromoMoment';

const PromoType7 = ({init, title, handleBack, promo, store}) => {

    const [etape, setEtape] = useState(1);
    const [promoDatas, setPromoDatas] = useState(promo);
    const doneStep = 2 ;
    
    const handleRetour = () => {
        if (etape === 1) {
            handleBack();
        }else{
            setEtape(etape-1);
        }
    }

    const submit = (data) => {
        if( etape < doneStep ) {
            setPromoDatas(data);
            setEtape(etape+1);
        }else{
            init(data) ;
        }
    }

    return (
        <div>
            <h2 className="mb-5">{ title }</h2>

            { etape === 1 && (
                <Etape1 promoDatas={promoDatas} update={submit} retour={handleRetour} store={store} />
            )}

            { etape === 2 && (
                <PromoMoment promoDatas={promoDatas} update={submit} retour={handleRetour} last={true} />
            )}

        </div>
    )
}

export default PromoType7