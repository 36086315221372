import React from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import L from "leaflet";

import { useForm } from "react-hook-form";

// Components
import { Switch } from "@material-ui/core";
import ZonesArray from "./zones/zonesArray";

//CSS
import "./_gmaps.scss";
import "leaflet/dist/leaflet.css";

const LivraisonGmap = (props) => {
  //Stats
  const livraison = props.livraison;
  const defaultValues = livraison;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ defaultValues });

  const mapOptions = {
    center: [props.store.identity.lat, props.store.identity.lng],
    marker: {
      icon: L.icon({
        iconUrl: require("~/assets/svg/marker.svg"),
        iconSize: [40, 60],
      }),
    },
    circle: {
      colors: [
        "#E08005",
        "#0577E0",
        "#ff053a",
        "#0de4e1",
        "#ff7b05",
        "#13a31a",
        "#000",
        "#fff",
        "#fff",
      ],
    },
  };

  const onSubmit = (data) => {
    props.submit(data);
  };

  const onStatusChanged = (newStatus) => {
    props.submit({
      ...livraison,
      enable: newStatus,
    });
  };

  const onSelectType = (type) => {
    props.submit({
      ...livraison,
      type: type,
    });
  };

  return (
    <div className='page-gmaps'>
      <div className='row'>
        <div className='col-md-9 gmaps-container'>
          <MapContainer
            center={mapOptions.center}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='asdasd'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Marker position={mapOptions.center} icon={mapOptions.marker.icon}>
              <Popup>text</Popup>
              {/* <Popup>
                           <h2>{props.store.intitule}</h2>
                           <p>{props.store.identity.street}, {props.store.identity.zip} {props.store.identity.city} - {props.store.identity.country}</p>
                        </Popup> */}
            </Marker>
            {livraison.type === "zone" &&
              livraison.zones.map((zone, index) => {
                if (zone.type === "km") {
                  return (
                    <Circle
                      center={mapOptions.center}
                      color={mapOptions.circle.colors[index]}
                      radius={zone.nbKmMax * 1000}
                    ></Circle>
                  );
                } else {
                  return "";
                }
              })}
          </MapContainer>
        </div>
        <div className='col-md-3 right-side'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-3'>
              <button
                type='button'
                className='btn btn-light'
                onClick={() => {
                  props.handleShowMap(false);
                }}
              >
                <i className='fa fa-arrow-left'></i> Retour aux parametres
              </button>
            </div>

            <div className='cmd-choix deliveryType d-flex'>
              <span>
                <span
                  className={
                    livraison.type === "zone" ? "option active" : "option"
                  }
                  onClick={() => onSelectType("zone")}
                >
                  Zone
                </span>
                <span
                  className={
                    livraison.type === "trajet" ? "option active" : "option"
                  }
                  onClick={() => onSelectType("trajet")}
                >
                  Trajet
                </span>
              </span>
            </div>

            <div className='delivery-switcher'>
              <div> Livraison </div>
              <div>
                <Switch
                  onChange={(e) => {
                    onStatusChanged(!livraison.enable);
                  }}
                  checked={livraison.enable}
                  color='primary'
                  name='delivery'
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </div>

            <div class='alert alert-danger' role='alert'>
              Le traçage des zones ne se fais que sur ordinateur.
              <br />
              Vous pouvez parametrer par km
            </div>

            <div className='areas'>
              <ZonesArray
                {...{ control, register, getValues, setValue, errors }}
              />
            </div>
            <button className='btn btn-success' type='submit'>
              Enregistrer les modifications
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LivraisonGmap;
