import React, { useState, useEffect } from 'react';
// Components
import AddAllergene from './AddAllergene';
import EditAllergene from './EditAllergene';
import Loader from "~/components/Loader";
// Utils
import { getAllergeneOfStore } from '~/api/menu';

//svg 
import Plus from "~/assets/svg/plus.svg";
import EditOrangeIcon from '~/components/Svgs/Shop/EditOrange';

//Pagination
import TablePagination from '~/assets/table-pagination/'

const AllergenesStore = (props) => {

    const store = props.store ;
    const [load, setLoad] = useState(true);
    const [toAdd, setToAdd] = useState(false);
    const [toEdit, setToEdit] = useState(false);
    const [allergenes, setAllergenes] = useState([]);
    const [selected, setSelected] = useState({})
    
    useEffect(() => {
        let storeId = store._id;
        let menuActive = localStorage.getItem("menuActive");
        getAllergeneOfStore(storeId, menuActive)
            .then((res) => {
                setAllergenes(res.data.allergenes);
                setLoad(false);
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
            });
        // eslint-disable-next-line
    }, []);


    // Pagination 
    const tableColumns = [{
        Header: 'Nom',
        accessor: 'name',
        Cell: ({ row })  => {
            let allergene = row.original
            return (
                <>
                    { allergene.imageUrl && <img alt="" src={ allergene.imageUrl } style={{width:31,marginRight:10}}/> }
                    { allergene.name }
                </>
            )
        }
    },{
        Header: 'Action',
        accessor: '_id', 
        className: "actions multiple-btns",
        Cell: ({ row })  => { 
            let allergene = row.original
            return (
                <>
                    {
                        <button disabled={allergene.storeId === "0000"} type="button" className="btn btn-light btn-edit" 
                                onClick={ () => {setSelected(allergene);setToEdit(true) } }
                        ><EditOrangeIcon /></button>
                    }
                </>
            )
        }
    }];

    return (
        <>
            {load ? (
                <Loader />
            ) : (
                <div> 
                    <div className="layout-head">
                        <div className="row">
                            <div className="col-6">
                            <h1>Allergènes</h1>
                            </div>
                            <div className="col">
                                <button type="button" className="modal--btn-validate" onClick={ () => setToAdd(true)  }>
                                    <img alt="plus" src={Plus} /> Ajouter une allergène
                                </button>
                            </div>
                        </div>
                    </div>

                    { allergenes.length && (  
                        <div className="layout-content">
                            <div className="block-styled container">
                                <div className="block-styled-body"> 
                                    <TablePagination columns={tableColumns} data={allergenes} />
                                </div>
                            </div> 
                        </div>
                    )}
                    

                    { toAdd && <AddAllergene storeId={store._id} allergenes={allergenes} showAdd={toAdd} handleCloseAdd={setToAdd} init={setAllergenes} /> }
                    { toEdit && <EditAllergene allergene={selected} showEdit={toEdit} handleCloseEdit={setToEdit} init={setAllergenes} /> }
                </div>
            )}
        </>
    )
}

export default AllergenesStore
