import React, { useState } from 'react';
//import { Notyf } from 'notyf';
import { useForm } from 'react-hook-form';
import { useLoadMedia } from '~/hooks/loadMedia';

//svg
import upload from '~/assets/svg/upload-lg.svg';

const BaseData = ({ baseDatas, title, back, promotion }) => {
  /* const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top',
    },
    duration: 3000,
    dismissible: true,
  }); */

  const { register, handleSubmit } = useForm({ defaultValues: promotion });
  const [image, setImage] = useState(promotion.pathImage);
  // const [file, setFile] = useState(null);
  const [loadImage, setLoadImage] = useState();
  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    //uppdateInfo,
    //setUpdateInfo,
    setLoadMedia,
    Media,
  } = useLoadMedia({
    promotion: {
      boolean: loadImage,
      action: setLoadImage,
      name: 'promotion',
      updateValue: setImage,
    },
  });

  const onSubmit = (data) => {
    //console.log('data', image?.promotion);
    baseDatas({ ...data, pathImage: image?.promotion });
  };

  /* const handleUpload = (e) => {
    let target = e.target;
    let imageFile = target.files[0];
    let file = new FormData();
    file.append('file', imageFile);

    var dim = { width: 900, height: 600 };

    if (!imageFile) {
      notyf.error('Veuillez sélectionner une image.');
    } else if (!imageFile.name.match(/\.(jpg|jpeg|png|svg|gif)$/)) {
      notyf.error('Veuillez sélectionner une image valide. ');
    } else if (target.files[0].size > 2097152) {
      notyf.error("La taille de l'image doit être inférieure à 2 Mo !");
    } else {
      let _URL = window.URL;
      let img = new Image();
      img.onload = function () {
        setFile(file);
        setImage(URL.createObjectURL(file.get('file')));
      };
      img.src = _URL.createObjectURL(imageFile);
    }
  }; */

  return (
    <div className='base-data-promotion'>
      <>
        {loadMedia ? (
          <Media
            setLoadMedia={setLoadMedia}
            handleSelectedMedia={handleSelectedMedia}
          />
        ) : (
          <>
            <h2 className='mb-5'>{title}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-group'>
                <label htmlFor='name'>Nom de la promotion</label>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  name='name'
                  {...register('name', { required: true })}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='description'>Description</label>
                <textarea
                  className='form-control'
                  id='description'
                  name='description'
                  {...register('description', { required: false })}
                  rows='3'
                ></textarea>
              </div>
              <div className='form-group '>
                <center>Sélectionné une image de la promo (max 900x600)</center>
                <div className='styles--body-upload'>
                  {/* <input
                    type='file'
                    id='upload--logo'
                    onChange={handleUpload}
                    name='logo'
                  /> */}
                  <label htmlFor='upload--logo'>
                    <img
                      alt='upload-file'
                      src={upload}
                      name='promotion'
                      onClick={handleMedia}
                    />
                  </label>
                  {image !== '' && (
                    <img src={image?.promotion} alt='' style={{ width: 100 }} />
                  )}
                </div>
              </div>

              <div className='form-group steps-controlers'>
                <div>
                  <button className='btn btn-dark' type='button' onClick={back}>
                    Retour
                  </button>
                </div>
                <div>
                  <button className='btn btn-warning' type='submit'>
                    Suivant
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </>
    </div>
  );
};

export default BaseData;
