import React, { useState, useEffect } from "react";
import { List, arrayMove } from "react-movable";
import { Notyf } from "notyf";

//Owl carousel
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";

// Components
import Produit from "./Produit";
import Loader from "~/components/Loader";
import Confirm from "~/components/Commun/Confirm";
import { Switch } from "@material-ui/core";
// Utils
import {
  deleteProduit,
  initOrderProduits,
  updateProduit,
} from "~/api/produits";
import { getAllDatasOfStore } from "~/api/menu";
import { showPrice } from "~/_helpers/helper";
//svg
import dragable from "~/assets/svg/dragable.svg";
import Plus from "~/assets/svg/plus.svg";
import EditOrangeIcon from "~/components/Svgs/Shop/EditOrange";
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";
// import 'react-owl-carousel2'; //Allows for server-side rendering.

const ProduitsStore = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const store = props.store;

  const [load, setLoad] = useState(true);
  const [produits, setProduits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [envies, setEnvies] = useState([]);
  const [allergenes, setAllergenes] = useState([]);
  const [sections, setSections] = useState([]);
  const [groupes, setGroupes] = useState([]);
  const [toAdd, setToAdd] = useState(false);
  const [toEdit, setToEdit] = useState(false);
  const [toDelete, setToDelete] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    let storeId = store._id;
    let menuActive = localStorage.getItem("menuActive");

    getAllDatasOfStore(storeId, menuActive)
      .then((res) => {
        setCategories(res.data.categories);
        setIngredients(res.data.ingredients);
        setEnvies(res.data.wishes);
        setAllergenes(res.data.allergenes);
        setSections(res.data.sections);
        setProduits(res.data.produits);
        setGroupes(res.data.groupes);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleDelete = () => {
    setLoad(true);
    setToDelete(false);
    deleteProduit(selected._id)
      .then((res) => {
        setProduits((produits) =>
          produits.filter((x) => x._id !== selected._id)
        );
        notyf.success(res.data.message);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const switchStatut = async (id) => {
    let produit = produits.find((p) => p._id === id);

    const data = { ...produit, enable: !produit.enable };

    updateProduit(data)
      .then((res) => {
        setProduits((produits) =>
          produits.map((x) => (x._id === id ? data : x))
        );
        notyf.success(res.data.message);
      })
      .catch((err) => {
        notyf.error(err.message);
      });
  };

  const updateOrder = (oldIndex, newIndex) => {
    setLoad(true);
    const move = newIndex > oldIndex ? "down" : "up";

    oldIndex = produits[oldIndex].position;
    newIndex = produits[newIndex].position;

    initOrderProduits(store._id, move, oldIndex, newIndex)
      .then((res) => {
        console.log(res.data.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoad(false);
      })
      .catch((err) => {
        notyf.error(err.message);
        setLoad(false);
      });
  };

  /// Filter products by category
  const filtredProduits = produits.filter((produit) => {
    return selectedCategory
      ? selectedCategory._id === produit.idCategorie
      : true;
  });

  /// Owl Carousel
  const options = {
    items: 6,
    autoWidth: true,
    margin: 22,
    nav: true,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>',
    ],
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
        autoWidth: false,
      },
      // breakpoint from 480 up
      480: {
        items: 4,
      },
      // breakpoint from 768 up
      768: {
        items: 6,
      },
    },
  };

  const events = {};

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <>
          {!toAdd && !toEdit ? (
            <div>
              <div className="cats-list">
                {categories.length > 0 && (
                  <OwlCarousel options={options} events={events}>
                    {categories.map((cat, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            setSelectedCategory(
                              selectedCategory === cat ? null : cat
                            );
                          }}
                          className={`cat-item ${
                            selectedCategory && selectedCategory._id === cat._id
                              ? "selected"
                              : ""
                          }`}
                        >
                          {cat.name}
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )}
              </div>
              <div className="layout-head ng-bg">
                <div className="row">
                  <div className="col text-center">
                    <button
                      type="button"
                      className="modal--btn-validate"
                      onClick={() => setToAdd(true)}
                    >
                      <img alt="plus" src={Plus} /> Ajouter un produit
                    </button>
                  </div>
                </div>
              </div>

              {filtredProduits.length ? (
                <div className="layout-content">
                  <List
                    lockVertically
                    values={filtredProduits}
                    onChange={({ oldIndex, newIndex }) => {
                      setProduits(
                        arrayMove(filtredProduits, oldIndex, newIndex)
                      );
                      updateOrder(oldIndex, newIndex);
                    }}
                    renderList={({ children, props }) => (
                      <div className="block-styled">
                        <div className="block-styled-body no-padding">
                          <table className="table list-product">
                            <thead className="table-dark">
                              <tr>
                                <th scope="col">Position</th>
                                <th scope="col">
                                  <span className="d-none d-md-block">
                                    Statut
                                  </span>
                                </th>
                                <th scope="col">Nom</th>
                                <th scope="col">Prix</th>
                                <th scope="col" className="actions">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody {...props}>{children}</tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    renderItem={({ value, props, isDragged, isSelected }) => {
                      const row = (
                        <tr
                          {...props}
                          style={{
                            backgroundColor:
                              isDragged || isSelected ? "#ececec" : "#ffffff",
                          }}
                        >
                          <td>
                            <button
                              data-movable-handle
                              style={{
                                cursor: isDragged ? "grabbing" : "grab",
                              }}
                            >
                              <img alt="" src={dragable} />
                            </button>
                          </td>
                          <td>
                            <Switch
                              onChange={() => switchStatut(value._id)}
                              checked={value.enable}
                              color="primary"
                              name="canCB"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="img-product">
                                <img
                                  src={value.imageUrl}
                                  alt=""
                                  className="mr-2"
                                  style={{ height: 35 }}
                                />
                              </div>
                              <div className="product-name">{value.name}</div>
                            </div>
                          </td>
                          <td>
                            {(() => {
                              if (value.multiChoice) {
                                let prices = value.variantes.sort((a, b) => {
                                  return (
                                    parseFloat(a.price) - parseFloat(b.price)
                                  );
                                });
                                return showPrice(prices[0].price);
                              }

                              return showPrice(value.priceGlobal);
                            })()}{" "}
                            <br />
                            <small>
                              {value.tva ? `tva ${value.tva} %` : ""}
                            </small>
                          </td>
                          <td className="actions multiple-btns">
                            <button
                              type="button"
                              className="btn btn-light btn-edit"
                              onClick={() => {
                                setSelected(value);
                                setToEdit(true);
                              }}
                            >
                              <EditOrangeIcon />
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-delete"
                              onClick={() => {
                                setSelected(value);
                                setToDelete(true);
                              }}
                            >
                              <TrashOutlineIcon />
                            </button>
                          </td>
                        </tr>
                      );
                      return isDragged ? (
                        <table
                          className="table dragged-table"
                          style={{ ...props.style, borderSpacing: 0 }}
                        >
                          <tbody>{row}</tbody>
                        </table>
                      ) : (
                        row
                      );
                    }}
                  />
                </div>
              ) : (
                <p className="no-results">Aucune données pour le moments...</p>
              )}

              {toDelete && (
                <Confirm
                  show={toDelete}
                  handleClose={setToDelete}
                  message="Êtes-vous sûr de vouloir supprimer le produit ?"
                  handleConfirm={handleDelete}
                  confirmeLoad={load}
                />
              )}
            </div>
          ) : (
            <>
              {toAdd && (
                <Produit
                  action="add"
                  produit={null}
                  storeId={store._id}
                  handleCloseAdd={setToAdd}
                  init={setProduits}
                  categories={categories}
                  category={selectedCategory}
                  produits={produits}
                  envies={envies}
                  allergenes={allergenes}
                  sections={sections}
                  ingredients={ingredients}
                  groupes={groupes}
                />
              )}
              {toEdit && (
                <Produit
                  action="update"
                  produit={selected}
                  storeId={store._id}
                  handleCloseAdd={setToEdit}
                  init={setProduits}
                  categories={categories}
                  produits={produits}
                  envies={envies}
                  allergenes={allergenes}
                  sections={sections}
                  ingredients={ingredients}
                  groupes={groupes}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProduitsStore;
