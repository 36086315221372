import instanceAxios from "./axios.instance";
import { BASE_URI } from "~/assets/base";

/**
 * function login
 */
export const authUser = async (email, password) => {
  const response = await instanceAxios.post(`${BASE_URI}users/auth`, {
    email: email,
    password: password,
  });

  return response;
};

/**
 * Get user of store
 */
export const getUserById = async (userId) => {
  const response = await instanceAxios.get(`${BASE_URI}users/${userId}`);

  return response;
};

export const getUserStoreAdmin = async (storeId) => {
  const res = await instanceAxios.get(`${BASE_URI}store/userStore/${storeId}`);
  return res;
};

export const createUserStoreAdmin = async (storeId, data) => {
  const res = await instanceAxios.post(
    `${BASE_URI}store/userStore/add/${storeId}`,
    data
  );
  return res;
};

export const updateUserStoreAdmin = async (storeId, data) => {
  const res = await instanceAxios.put(
    `${BASE_URI}store/userStore/update/${storeId}`,
    data
  );
  return res;
};
