import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";

import axios from "axios";
import { BASE_URI } from "~/assets/base";
// Import Redux
import { useSelector } from 'react-redux';

//svgs
import mailIcon from "~/assets/svg/mail.svg";
import lockIcon from "~/assets/svg/lock.svg";
import eyeOpen from "~/assets/svg/eye-open.svg";
import eyeClose from "~/assets/svg/eye-close.svg";
import Loader from "~/components/Loader";

const Access = (props) => {

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "bottom",
    },
    duration: 3000,
    dismissible: true,
  });

  const userState = useSelector(state => state.user.data);
  const inputs = {
    email: "",
    password: "",
    passwordConfirmation: "",
  };
  const [input, setInput] = useState(inputs);
  const [load, setLoad] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    
    if (userState.user.id !== undefined) {
      axios
        .get(`${BASE_URI}users/${userState.user.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          setInput({ ...input, "email": res.data.user.email });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);
  
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmitNewEmail = (e) => {
    e.preventDefault();
    setLoad(true);
    const { email } = input;
    axios
      .put(
        `${BASE_URI}users/updateemail/${userState.user.id}`,
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          validateStatus: function (status) {
              return status >= 200 && status <= 503;
          }
        }
      )
      .then((res) => { 
        if( res.status >= 200 && res.status < 300){
          setLoad(false);
          notyf.success(res.data.message);
        }
        else throw(res.data) 
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();

    const { password, passwordConfirmation } = input;
    if (password !== passwordConfirmation) {
      notyf.error("Le mot de passe doit être identique");
    } else {
      setLoad(true);
      axios
        .put(
          `${BASE_URI}users/updatepassword/${userState.user.id}`,
          {
            password,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            validateStatus: function (status) {
                return status >= 200 && status <= 503;
            }
          }
        )
        .then((res) => {
          if( res.status >= 200 && res.status < 300){
            setLoad(false);
            notyf.success(res.data.message);
            setInput(inputs);
          }
          else throw(res.data)
        })
        .catch((err) => {
          setLoad(false); 
          notyf.error(err.message);
        });
    }
  };

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="access">
        <div className="block-styled container"> 
          <div className="block-styled-body"> 
            <div className="access--formsWrapper">
              <form className="access--form" onSubmit={handleSubmitNewEmail}>
                <h3 className="access--title">Changer mon email de connexion</h3>
                <label>Email</label>
                <div>
                  <img alt="email-icon" src={mailIcon} />
                  <input
                    type="text"
                    required
                    onChange={handleChange}
                    value={input.email}
                    name="email"
                    autoComplete="off"
                    className="input-email"
                  />
                </div>
                <button type="submit">Modifier</button>
              </form>
              <form className="access--form" onSubmit={handleSubmitNewPassword}>
                <h3 className="access--title">Changer mon mot de passe</h3>
                <label>Mot de passe</label>
                <div>
                  <img alt="password-icon" src={lockIcon} />
                  <input
                    type={showPass ? "text" : "password"}
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    value={input.password}
                    name="password"
                    className="input-libre"
                  />
                  <img
                    onClick={() => setShowPass(!showPass)}
                    alt="eye-close-icon"
                    className="access--form-eyeIcon"
                    src={showPass ? eyeOpen : eyeClose}
                  />
                </div>
                <label>Confirmé mot de passe</label>
                <div>
                  <img alt="password-icon" src={lockIcon} />
                  <input
                    type={showConfirm ? "text" : "password"}
                    required
                    onChange={handleChange}
                    value={input.passwordConfirmation}
                    name="passwordConfirmation"
                    className="input-libre"
                  />
                  <img
                    onClick={() => setShowConfirm(!showConfirm)}
                    alt="eye-close-icon"
                    className="access--form-eyeIcon"
                    src={showConfirm ? eyeOpen : eyeClose}
                  />
                </div>
                <button type="submit">Modifier</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Access;
