import React from "react";

const MenuIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.75 5.75H6.25V0.5H7.75V5.75C7.75 7.4075 6.4075 8.75 4.75 8.75V15.5H3.25V8.75C1.5925 8.75 0.25 7.4075 0.25 5.75V0.5H1.75V5.75H3.25V0.5H4.75V5.75ZM10 9.5V3.5C10 2.18 11.68 0.5 13.75 0.5V15.5H12.25V9.5H10Z" fill="#393838"/>
        </svg>
    </span>
  );
};

export default MenuIcon;
