import React from "react";

//SVG
import Loop from "~/assets/svg/loop.svg";
import Close from "~/assets/svg/close-x.svg";

const Pagination = (props) => {
  const {
    pageIndex,
    pageCount,
    canPreviousPage,
    previousPage,
    gotoPage,
    canNextPage,
    nextPage,
    pageSize,
    setPageSize,
    searchForm,
    searchLabel,
    searchInput,
    handelSearchInput,
    className,
    withPaginate = true,
  } = props;

  const pages = (c, m) => {
    if (m === 1) return [1];
    var current = c,
      delta = 3,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);
    for (let i = c - delta; i <= c + delta; i++) {
      if (i >= left && i < right && i < m && i > 1) {
        range.push(i);
      }
    }
    range.push(m);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };
  //console.log(pageIndex, pages(pageIndex, pageCount))
  return (
    <div className={`row mt-4 p20  mb-4 ${className}`}>
      <div className={`col-sm-${withPaginate ? "4" : "10"} filters`}>
        {searchForm && (
          <div className='input-wrapper'>
            <img alt='loop-icon' src={Loop} />
            <input
              type='text'
              onChange={(e) => handelSearchInput(e.target.value)}
              value={searchInput}
              placeholder={searchLabel || "Rechercher..."}
            />
            <img
              onClick={() => handelSearchInput("")}
              alt='removeText'
              src={Close}
              className='d-none d-md-block'
            />
          </div>
        )}
      </div>
      {withPaginate && (
        <div className='col-sm-4 p20'>
          <ul className='pagination'>
            <li className='previous-page'>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <i className='fa fa-chevron-left'></i>
              </button>
            </li>

            {pages(pageIndex + 1, pageCount).map((page, i) => {
              return (
                <li
                  key={i}
                  className={
                    Number.isInteger(page) && pageIndex + 1 === page
                      ? "active"
                      : ""
                  }
                >
                  {Number.isInteger(page) ? (
                    <button onClick={() => gotoPage(i)}> {page} </button>
                  ) : (
                    "..."
                  )}
                </li>
              );
            })}

            <li className='next-page'>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                <i className='fa fa-chevron-right'></i>
              </button>
            </li>
          </ul>
        </div>
      )}
      {withPaginate && (
        <div className='col-sm-4'>
          <select
            className='form-control'
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Par {pageSize}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};
export default Pagination;
