import instanceAxios from "./axios.instance";
import { BASE_URI } from "~/assets/base";

/**
 * Partie Menu
 */

export const getAllMenuOfStore = async (storeId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}store/menu/listing/${storeId}`
  );
  return response;
};

export const createMenu = async (datas, duplique) => {
  const response = await instanceAxios.post(`${BASE_URI}store/menu/create`, {
    newMenu: datas,
    duplique,
  });
  return response;
};

export const getAllDatasOfStore = async (storeId, menuId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}store/menu/datasstore/${storeId}/${menuId}`
  );
  return response;
};
export const deleteMenu = async (id) => {
  const response = await instanceAxios.delete(
    `${BASE_URI}store/menu/delete/${id}`
  );
  return response;
};
export const changeMenuStatus = async (menu) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/menu/updateStatus`,
    { menu }
  );

  return response;
};
export const updateMenu = async (menu) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/menu/update/${menu._id}`,
    { menu }
  );
  return response;
};

/**
 * Partie Allergene
 */

export const getAllergeneOfStore = async (storeId, menuId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}store/allergene/${storeId}/${menuId}`
  );
  return response;
};
export const createAllergene = async (datas) => {
  const response = await instanceAxios.post(
    `${BASE_URI}store/allergene/create`,
    { newAllergene: datas }
  );
  return response;
};
export const updateAllergene = async (allergene) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/allergene/update/${allergene._id}`,
    { allergene }
  );
  return response;
};
export const deleteAllergene = async (id) => {
  const response = await instanceAxios.delete(
    `${BASE_URI}store/allergene/delete/${id}`
  );
  return response;
};

/**
 * Partie Envies
 */

export const getEnviesOfStore = async (storeId, menuId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}store/envies/${storeId}/${menuId}`
  );
  return response;
};

export const createtEnvie = async (datas) => {
  const response = await instanceAxios.post(`${BASE_URI}store/envie/create`, {
    newEnvie: datas,
  });
  return response;
};

export const updateEnvie = async (envie) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/envie/update/${envie._id}`,
    { envie }
  );
  return response;
};

export const deleteEnvie = async (id) => {
  const response = await instanceAxios.delete(
    `${BASE_URI}store/envie/delete/${id}`
  );
  return response;
};

/**
 * Partie Ingredients
 */

export const getIngredientOfStore = async (storeId, menuId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}store/ingredients/${storeId}/${menuId}`
  );
  return response;
};
export const createIngredient = async (datas) => {
  const response = await instanceAxios.post(
    `${BASE_URI}store/ingredient/create`,
    {
      newIngredient: datas,
    }
  );
  return response;
};
export const updateIngredient = async (ingredient) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/ingredient/update/${ingredient._id}`,
    { ingredient }
  );
  return response;
};
export const deleteIngredient = async (id) => {
  const response = await instanceAxios.delete(
    `${BASE_URI}store/ingredient/delete/${id}`
  );
  return response;
};

/**
 * Partie Groupes
 */

export const getGroupesOfStore = async (storeId, menuId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}store/groupes/${storeId}/${menuId}`
  );
  return response;
};
export const createGroupe = async (datas) => {
  const response = await instanceAxios.post(`${BASE_URI}store/groupe/create`, {
    newGroupe: datas,
  });
  return response;
};
export const updateGroupe = async (groupe) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/groupe/update/${groupe._id}`,
    { groupe }
  );
  return response;
};
export const deleteGroupe = async (id) => {
  const response = await instanceAxios.delete(
    `${BASE_URI}store/groupe/delete/${id}`
  );
  return response;
};

/**
 * Partie Catégories
 */

export const getCategoriesOfStore = async (storeId, menuId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}store/categories/${storeId}/${menuId}`
  );
  return response;
};
export const createCategorie = async (datas) => {
  const response = await instanceAxios.post(
    `${BASE_URI}store/categorie/create`,
    {
      newCategorie: datas,
    }
  );
  return response;
};
export const updateCategorie = async (categorie) => {
  const { _id, ...data } = categorie;
  const response = await instanceAxios.put(
    `${BASE_URI}store/categorie/update/${categorie._id}`,
    { categorie: data }
  );
  return response;
};
export const deleteCategorie = async (id) => {
  const response = await instanceAxios.delete(
    `${BASE_URI}store/categorie/delete/${id}`
  );
  return response;
};
export const initOrderCategories = async (
  storeId,
  move,
  oldIndex,
  newIndex
) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/categories/position/${storeId}`,
    { move, oldIndex, newIndex }
  );
  return response;
};

/**
 * Partie Section
 */

export const getSectionOfStore = async (storeId, menuId) => {
  const response = await instanceAxios.get(
    `${BASE_URI}store/sections/${storeId}/${menuId}`
  );
  return response;
};

export const createSection = async (datas) => {
  const response = await instanceAxios.post(`${BASE_URI}store/section/create`, {
    newSection: datas,
  });
  return response;
};

export const updateSection = async (section) => {
  const response = await instanceAxios.put(
    `${BASE_URI}store/section/update/${section._id}`,
    { section }
  );
  return response;
};

export const deleteSection = async (id) => {
  const response = await instanceAxios.delete(
    `${BASE_URI}store/section/delete/${id}`
  );
  return response;
};
