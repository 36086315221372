import React, { useState, useEffect } from 'react';
import { Notyf } from 'notyf';
// Api
import { getEnseigneById } from '~/api/enseigne';
import { createPromo, updatePromo, deletePromo } from '~/api/promotion';
import { uploadFile } from '~/api/upload';

// components
import ListesPromotions from './listes';
import Loader from '~/components/Loader';
import SinglePromotion from './single';

//CSS
import './_promotions.scss';

const PromotionStore = (props) => {
  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top',
    },
    duration: 3000,
    dismissible: true,
  });

  // Stats
  const store = props.store;
  const [enseigne, setEnseigne] = useState();
  const [promotions, setPromotions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    let enseigneId = store.enseigneId;
    getEnseigneById(enseigneId)
      .then((res) => {
        setEnseigne(res.data.enseigne);
        setFiltrePromo(res.data.enseigne.promotions);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });

    // eslint-disable-next-line
  }, []);

  //ajouter une promo
  const onSubmit = (data) => {
    setLoad(true);
    (async function todo() {
      let promoImage = '';
      if (typeof data.pathImage === 'object' && data.pathImage !== null) {
        if (data.pathImage.constructor.name === 'FormData') {
          await uploadFile('promotions', data.pathImage)
            .then((res) => {
              promoImage = res.data.filename;
            })
            .catch((err) => {
              notyf.error(err.message);
            });
        } else {
          promoImage = data.pathImage;
        }
      }

      const promo = {
        ...data,
        pathImage: promoImage ? promoImage : data.pathImage,
        storeId: store._id,
      };

      if (promo._id !== null) {
        // Update
        updatePromo(enseigne._id, promo)
          .then((res) => {
            setLoad(false);
            setFiltrePromo(res.data.promotions);
            setEdit(false);
            notyf.success(res.data.message);
          })
          .catch((err) => {
            setLoad(false);
            notyf.error(err.message);
          });
      } else {
        // Create
        delete promo._id;
        createPromo(enseigne._id, promo)
          .then((res) => {
            setLoad(false);
            setFiltrePromo(res.data.promotions);
            setAdd(false);
            notyf.success(res.data.message);
          })
          .catch((err) => {
            setLoad(false);
            notyf.error(err.message);
          });
      }
    })();
  };

  const handleChangeStatus = (promoId) => {
    setLoad(true);
    let promo = promotions.find((promo) => promo._id === promoId);
    let newPromo = {
      ...promo,
      enable: !promo.enable,
    };
    let enseigneId = enseigne._id;
    updatePromo(enseigneId, newPromo)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setFiltrePromo(res.data.promotions);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const handleDelete = (promoId) => {
    let enseigneId = enseigne._id;
    setLoad(true);
    deletePromo(enseigneId, promoId)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setFiltrePromo(res.data.promotions);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const setFiltrePromo = (promos) => {
    let promoArray = promos.filter(
      (promo) => promo.storeId === '0' || promo.storeId === store._id
    );
    setPromotions(promoArray);
  };

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className='page-promotions container'>
        <>
          {add && (
            <div className=''>
              <SinglePromotion
                action='create'
                current={null}
                store={store}
                show={setAdd}
                step={1}
                submit={onSubmit}
              />
            </div>
          )}

          {edit && (
            <div className=''>
              <SinglePromotion
                action='edit'
                current={selected}
                store={store}
                show={setEdit}
                step={2}
                submit={onSubmit}
              />
            </div>
          )}

          {!add && !edit && (
            <div className='promo--all'>
              <ListesPromotions
                listes={promotions}
                setEdit={setEdit}
                setSelected={setSelected}
                handleChangeStatus={handleChangeStatus}
                handleDelete={handleDelete}
                store={store}
              />
              <div
                className='promo--single promo--add-btn'
                onClick={() => setAdd(!add)}
              >
                <svg
                  width='48'
                  height='48'
                  viewBox='0 0 48 48'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM25.9999 22V14H21.9999V22H13.9999V26H21.9999V34H25.9999V26H33.9999V22H25.9999ZM7.99989 24C7.99989 32.82 15.1799 40 23.9999 40C32.8199 40 39.9999 32.82 39.9999 24C39.9999 15.18 32.8199 7.99997 23.9999 7.99997C15.1799 7.99997 7.99989 15.18 7.99989 24Z'
                    fill='#393838'
                  />
                </svg>
                AJOUTER UNE PROMO
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
};

export default PromotionStore;
