import React, { useState, useEffect } from 'react';
import { Notyf } from 'notyf';
import Loader from '~/components/Loader';
//import { uploadFile } from '~/api/upload';
import { updateEnseigneStyles } from '~/api/enseigne';
import { getRealFileName } from '~/_helpers/helper';
//svg
import Radio from '~/components/Svgs/Super/addProduct/radio';
import upload from '~/assets/svg/upload-lg.svg';
import { useLoadMedia } from '~/hooks/loadMedia';

const Banner = ({ enseigne }) => {
  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top',
    },
    duration: 5000,
    dismissible: true,
  });

  let infos = {
    isBanner: true,
    picture: '',
  };

  const [input, setInput] = useState(infos);
  const [load, setLoad] = useState(false);

  const [loadPicture, setLoadPicture] = useState(false);
  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    uppdateInfo,
    setUpdateInfo,
    Media,
    setLoadMedia,
  } = useLoadMedia({
    picture: {
      boolean: loadPicture,
      action: setLoadPicture,
      name: 'picture',
      updateValue: setInput,
    },
  });

  useEffect(() => {
    setInput({
      ...input,
      isBanner: enseigne.styles.banniere.enable,
    });
    // eslint-disable-next-line
  }, []);

  /* const handleUpload = (e) => {
    let imageFile = e.target.files[0];
    if (!imageFile) {
      notyf.error('Veuillez sélectionner une image.');
    } else if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      notyf.error('Veuillez sélectionner une image valide. ');
    } else if (e.target.files[0].size > 2097152) {
      notyf.error("La taille de l'image doit être inférieure à 2 Mo !");
    } else {
      let _URL = window.URL;
      let img = new Image();
      img.onload = function () {
        setInput({ ...input, picture: imageFile });
      };
      img.src = _URL.createObjectURL(imageFile);
    }
  }; */

  const handleValidate = (e) => {
    e.preventDefault();
    setLoad(true);
    setUpdateInfo(false);
    let styles = enseigne.styles;

    if (input.isBanner) {
      if (input.picture !== '') {
        /*  let formData = new FormData();
        formData.append('file', input.picture);
        uploadFile('banners', formData).then((res) => {
         
        }); */
        styles.banniere.enable = true;
        styles.banniere.path = input.picture;
        updateEnseigneStyles(enseigne._id, styles).then((res) => {
          notyf.success(res.data.message);
          setLoad(false);
        });
      } else {
        notyf.error('Vous devez choisir une image');
      }
    } else {
      setLoad(true);
      styles.banniere.enable = false;
      updateEnseigneStyles(enseigne._id, styles)
        .then((res) => {
          notyf.success(res.data.message);
          setLoad(false);
        })
        .catch((err) => {
          notyf.error(err.message);
        });
    }

    return false;
  };

  return (
    <>
      {loadMedia ? (
        <Media
          setLoadMedia={setLoadMedia}
          handleSelectedMedia={handleSelectedMedia}
        />
      ) : (
        <div className='styles--banner'>
          <form onSubmit={handleValidate}>
            <div className='styles--top'>
              <h1>Bannière</h1>

              <hr />
            </div>

            {load ? (
              <Loader />
            ) : (
              <div className='styles--body'>
                <div>
                  <p>
                    Souhaitez-vous{' '}
                    {enseigne.styles.banniere.enable ? 'toujours' : ''} une
                    bannière en page d'accueil ?
                  </p>

                  <div className='styles--body-radio'>
                    <div>
                      <div>
                        <label>Oui</label>
                        <span
                          onClick={() => setInput({ ...input, isBanner: true })}
                        >
                          <Radio color={input.isBanner ? '#E08005' : 'white'} />
                        </span>
                      </div>
                      <div>
                        <label>Non</label>
                        <span
                          onClick={() =>
                            setInput({ ...input, isBanner: false })
                          }
                        >
                          <Radio
                            color={!input.isBanner ? '#E08005' : 'white'}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  {input.isBanner && (
                    <>
                      {enseigne.styles.banniere.path && (
                        <img
                          className='styles--body-banner'
                          alt='current--banner'
                          src={
                            input.picture === ''
                              ? enseigne.styles.banniere.path
                              : input.picture
                          }
                        />
                      )}
                      {enseigne.styles.banniere.path && (
                        <p>
                          Vous pouvez modifier l'image en chargant une nouvelle
                        </p>
                      )}
                      <span>
                        (Selectionnez une bannière de taille 750px x 420px et
                        inférieure à 2 Mo max)
                      </span>
                      <div className='styles--body-upload'>
                        {/*  <input
                      type='file'
                      id='upload--baner'
                      onChange={handleUpload}
                    /> */}
                        <label htmlFor='upload--baner'>
                          {' '}
                          <img
                            alt='upload-file'
                            src={upload}
                            onClick={handleMedia}
                            name='picture'
                          />{' '}
                        </label>

                        {input.picture !== '' && (
                          <span>{getRealFileName(input.picture)}</span>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className='styles--top'>
              {uppdateInfo && (
                <div className='update-msg-container'>
                  <span>
                    Il ya une nouvelle mise à jour <br /> appuyez sur valider
                    pour appliquer
                  </span>
                </div>
              )}
              <button>Valider</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Banner;
