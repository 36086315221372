import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
import { updateCategorie } from "~/api/menu";
//svg
import closeIcon from "~/assets/svg/close.svg";
import upload from "~/assets/svg/upload-lg.svg";
//CSS
import "../_add_item.scss";
import { useLoadMedia } from "~/hooks/loadMedia";

const EditCategorie = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const [categorie, setCategorie] = useState(props.categorie);
  const [image, setImage] = useState("");
  const [bannersImage, setBannersImage] = useState("");
  const [loadImage, setLoadImage] = useState();
  const [loadBannerImage, setLoadBannerImage] = useState();

  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    //uppdateInfo,
    //setUpdateInfo,
    setLoadMedia,
    Media,
  } = useLoadMedia({
    categories: {
      boolean: loadImage,
      action: setLoadImage,
      name: "categories",
      updateValue: setImage,
    },
    banners: {
      boolean: loadBannerImage,
      action: setLoadBannerImage,
      name: "banners",
      updateValue: setBannersImage,
    },
  });

  const handleEdit = (e) => {
    e.preventDefault();
    setLoad(true);

    (async function todo() {
      let iconName = "";
      let bannerName = "";

      iconName = image?.categories;
      bannerName = bannersImage?.banners;

      await updateCategorie({
        ...categorie,
        iconUrl: iconName,
        bannerUrl: bannerName,
      })
        .then((res) => {
          let categories = res.data.categories;
          props.init(categories);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          notyf.error(err.message);
        });

      setLoad(false);
      props.handleCloseEdit(false);
    })();
  };

  return (
    <Modal
      className="modal edit-category"
      show={props.showEdit}
      onHide={() => props.handleCloseEdit(false)}
    >
      <Modal.Header closeButton>
        <h1 className="modal--title">Modifier {categorie.name} </h1>
        <img
          onClick={() => props.handleCloseEdit(false)}
          className="modal--close"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>

      {loadMedia ? (
        <Media
          setLoadMedia={setLoadMedia}
          handleSelectedMedia={handleSelectedMedia}
        />
      ) : (
        <form onSubmit={handleEdit}>
          <Modal.Body className="fade-in">
            <div className="inputs">
              <label>Nom de la catégorie</label>
              <input
                required
                name="name"
                value={categorie?.name}
                onChange={(e) =>
                  setCategorie({ ...categorie, name: e.target.value })
                }
              />
            </div>
            <p>Selectionnez un icon</p>
            <div className="styles--body-upload">
              <label htmlFor="upload--logo">
                <img
                  alt="upload-file"
                  src={upload}
                  name="categories"
                  onClick={handleMedia}
                />
              </label>
              {image !== "" && (
                <img src={image.categories} alt="" style={{ width: 40 }} />
              )}
            </div>
            <p>Selectionnez une bannière</p>
            <div className="styles--body-upload">
              <label htmlFor="upload--logo">
                <img
                  alt="upload-file"
                  src={upload}
                  name="banners"
                  onClick={handleMedia}
                />
              </label>
              {bannersImage !== "" && (
                <img src={bannersImage.banners} alt="" style={{ width: 40 }} />
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="submit" className="modal--btn-validate large">
              {load ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Modifier"
              )}
            </button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};

export default EditCategorie;
