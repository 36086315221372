import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
//import { uploadFile } from '~/api/upload';
import { createIngredient } from "~/api/menu";
//svg
import closeIcon from "~/assets/svg/close.svg";
import upload from "~/assets/svg/upload-lg.svg";
//CSS
import "../_add_item.scss";
import { useLoadMedia } from "~/hooks/loadMedia";

const AddIngredient = (props) => {
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const [ingredient, setIngredient] = useState("");
  const [image, setImage] = useState("");
  // const [icon, setIcon] = useState({});
  const [loadImage, setLoadImage] = useState();
  const [sendToKitcken, setSendToKitcken] = useState(true);

  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    //uppdateInfo,
    //setUpdateInfo,
    setLoadMedia,
    Media,
  } = useLoadMedia({
    ingredient: {
      boolean: loadImage,
      action: setLoadImage,
      name: "ingredient",
      updateValue: setImage,
    },
  });

  const handleUpload = (e) => {
    let target = e.target;
    let imageFile = e.target.files[0];
    let file = new FormData();
    file.append("file", imageFile);

    //var dim = { width: 100, height: 100 };

    if (!imageFile) {
      notyf.error("Veuillez sélectionner une image.");
    } else if (!imageFile.name.match(/\.(jpg|jpeg|png|svg|gif)$/)) {
      notyf.error("Veuillez sélectionner une image valide. ");
    } else if (target.files[0].size > 2097152) {
      notyf.error("La taille de l'image doit être inférieure à 2 Mo !");
    } else {
      let _URL = window.URL;
      let img = new Image();
      img.onload = function () {
        //setIcon(file);
        setImage(URL.createObjectURL(file.get("file")));
      };
      img.src = _URL.createObjectURL(imageFile);
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();

    let exists = props.ingredients.find((item) => {
      return item.name.toLowerCase() === ingredient.toLowerCase();
    });
    if (exists) {
      notyf.error("Cet ingrédient est déjà ajouté");
      return;
    }

    setLoad(true);

    (async function todo() {
      let iconName = "";
      iconName = image?.ingredient;
      let menuActive = localStorage.getItem("menuActive");
      let newIngredient = {
        name: ingredient,
        imageUrl: iconName,
        storeId: props.storeId,
        menuId: menuActive,
        sendToKitcken,
      };
      await createIngredient(newIngredient)
        .then((res) => {
          let ingredients = res.data.ingredients;
          props.init(ingredients);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          notyf.error(err.message);
        });

      setLoad(false);
      props.handleCloseAdd(false);
    })();
  };

  return (
    <>
      <Modal
        className="modal add-ingredient"
        show={props.showAdd}
        onHide={() => props.handleCloseAdd(false)}
      >
        <Modal.Header closeButton>
          <h1 className="modal--title">Ajouter un ingrédient</h1>
          <img
            onClick={() => props.handleCloseAdd(false)}
            className="modal--close"
            alt="close-svg"
            src={closeIcon}
          />
        </Modal.Header>
        {loadMedia ? (
          <Media
            setLoadMedia={setLoadMedia}
            handleSelectedMedia={handleSelectedMedia}
          />
        ) : (
          <form onSubmit={handleAdd}>
            <Modal.Body className="fade-in">
              <div className="inputs">
                <label>Nom de l'ingrédient</label>
                <input
                  required
                  name="name"
                  value={ingredient}
                  onChange={(e) => setIngredient(e.target.value)}
                />
              </div>
              <p>Selectionnez un icon carré en PNG (max 100x100)</p>
              <div className="styles--body-upload">
                <input
                  type="file"
                  id="upload--logo"
                  onChange={handleUpload}
                  name="logo"
                />
                <label htmlFor="upload--logo">
                  <img
                    alt="upload-file"
                    src={upload}
                    name="ingredient"
                    onClick={handleMedia}
                  />
                </label>
                {image !== "" && (
                  <img src={image.ingredient} alt="" style={{ width: 40 }} />
                )}
              </div>
              <div className="styles--body-upload">
                <div className="row align-items-center choice-toggle">
                  <div className="col-6 text-left">
                    <p>Ingredient envoyé en cuisine</p>
                  </div>
                  <div className="col-6 text-right">
                    <div className="cmd-choix">
                      <span>
                        <span
                          className={sendToKitcken ? "option active" : "option"}
                          onClick={() => setSendToKitcken(true)}
                        >
                          Oui
                        </span>
                        <span
                          className={
                            !sendToKitcken ? "option active" : "option"
                          }
                          onClick={() => setSendToKitcken(false)}
                        >
                          Non
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="modal--btn-validate large" disabled={load}>
                {load ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Ajouter"
                )}
              </button>
            </Modal.Footer>
          </form>
        )}
      </Modal>
    </>
  );
};

export default AddIngredient;
