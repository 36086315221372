import React, { useEffect, useState, useCallback } from "react";

const Stats = ({ enseignes }) => {
  const [numOfStores, setNumOfStores] = useState(0);

  const getStores = useCallback(() => {
      let count = 0;
      enseignes.map( e => count = count + e.countStores );
      setNumOfStores(count);
    },[enseignes]
  );

  useEffect(() => {
    getStores();
  }, [getStores]);

  return (
    <div className="supremeDash--stats">
      <h2>Toutes les Enseignes</h2>
      <div className="supremeDash--stats-right">
        <div className="supremeDash--stats-wrapper">
          <h5>Nombre d'enseigne&nbsp;: </h5>
          <p className="supremeDash--stats-p">{enseignes.length}</p>
        </div>
        <div className="supremeDash--stats-wrapper">
          <h5>Nombre de boutique&nbsp;:</h5>
          <p className="supremeDash--stats-p">{numOfStores}</p>
        </div>
      </div>
    </div>
  );
};

export default Stats;
