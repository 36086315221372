import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { showPrice } from "~/_helpers/helper";
import { getStats } from "~/api/orders";

//Components
import Loader from "~/components/Loader";

//CSS
import "./_statistics.scss";

const Statistics = (props) => {
  const [load, setLoad] = useState(false);
  const store = props.store;
  const [dateStart, setDateStart] = useState(
    moment().subtract(30, "days").format("yyyy-MM-DD")
  );
  const [dateEnd, setDateEnd] = useState(moment().format("yyyy-MM-DD"));
  const [stats, setStats] = useState({});

  const getOrderStats = useCallback(() => {
    setLoad(true);
    getStats({
      storeId: store._id,
      start: dateStart,
      end: dateEnd,
    })
      .then((res) => {
        setLoad(false);
        setStats(res.data.stats);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [dateStart, dateEnd, store._id]);

  useEffect(() => {
    getOrderStats();
  }, [getOrderStats]);

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="page-statistics container">
        <div className="row">
          <div className="col-sm-8 left-side">
            <div className="block-styled mb-4">
              <div className="block-styled-body carde row">
                <div className="col">
                  <label className="inlabel">Date début</label>
                  <input
                    type="date"
                    className="form-control"
                    value={dateStart}
                    onChange={(e) => setDateStart(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label className="inlabel">Date fin</label>
                  <input
                    type="date"
                    className="form-control"
                    value={dateEnd}
                    onChange={(e) => setDateEnd(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="block-styled  mb-4">
                  <div className="block-styled-head">Chiffres d’affaires</div>
                  <div className="block-styled-body big-number">
                    {showPrice(stats?.sales)}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="block-styled  mb-4">
                  <div className="block-styled-head">Commandes / jour</div>
                  <div className="block-styled-body big-number">
                    {stats?.ordersPerDay}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="block-styled  mb-4">
                  <div className="block-styled-head">Panier moyen</div>
                  <div className="block-styled-body big-number">
                    {showPrice(stats?.averageOrder)}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="block-styled  mb-4">
                  <div className="block-styled-head">Nombre de Réservation</div>
                  <div className="block-styled-body big-number">
                    {stats?.reservations}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4  right-side">
            <div className="block-styled">
              <div className="block-styled-head">Nombre de Commande</div>
              <div className="block-styled-body">
                <ul>
                  <li className="d-flex">
                    <span>Sur place</span>
                    <span>{stats?.orders?.place}</span>
                  </li>
                  <li className="d-flex">
                    <span>A emporter</span>
                    <span>{stats?.orders?.emporter}</span>
                  </li>
                  <li className="d-flex">
                    <span>En livraison</span>
                    <span>{stats?.orders?.livraison}</span>
                  </li>
                </ul>
              </div>
              {stats?.products?.length > 0 && (
                <>
                  <div className="block-styled-head">Meilleurs vente</div>
                  <div className="block-styled-body">
                    <ul>
                      {stats?.products
                        ?.sort((a, b) => b.qty - a.qty)
                        .map((product) => (
                          <li className="d-flex">
                            <span>{product.name}</span>
                            <span>{product.qty}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Statistics;
