import React from "react";
import GroupesArray from "./GroupesArray";

export default function Ingredients({
  handleAddTemp,
  temp,
  setTemp,
  getIngredientName,
  handleDeleteTemp,
  dataList,
  selectType,
  setSelectType,
  groupTypes,
  control,
  register,
  selections,
  setSelections,
}) {
  return (
    <table className="table supplement">
      <tbody>
        <GroupesArray
          handleAddTemp={handleAddTemp}
          temp={temp}
          setTemp={setTemp}
          handleDeleteTemp={handleDeleteTemp}
          dataList={dataList}
          selectType={selectType}
          setSelectType={setSelectType}
          groupTypes={groupTypes}
          groupes={selections}
          getIngredientName={getIngredientName}
          setSelections={setSelections}
          {...{ control, register }}
        />
      </tbody>
    </table>
  );
}
