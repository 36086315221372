import React from "react";

const Gestion = (props) => {
  return (
    <span className="sidebar--list-svg mr">
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.83301 7.66634H7.66634V3.83301H3.83301V7.66634ZM9.58301 19.1663H13.4163V15.333H9.58301V19.1663ZM7.66634 19.1663H3.83301V15.333H7.66634V19.1663ZM3.83301 13.4163H7.66634V9.58301H3.83301V13.4163ZM13.4163 13.4163H9.58301V9.58301H13.4163V13.4163ZM15.333 3.83301V7.66634H19.1663V3.83301H15.333ZM13.4163 7.66634H9.58301V3.83301H13.4163V7.66634ZM15.333 13.4163H19.1663V9.58301H15.333V13.4163ZM19.1663 19.1663H15.333V15.333H19.1663V19.1663Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};

export default Gestion;
