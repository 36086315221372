import { BASE_URI } from "~/assets/base";
import instanceAxios from "./axios.instance";

export const getCaisseWithStoreId = async (storeId) => {
  const res = await instanceAxios.get(`${BASE_URI}cashs/${storeId}`);
  return res.data;
};

export const createCaisse = async (storeId) => {
  const res = await instanceAxios.post(`${BASE_URI}cash/add`, { storeId });
  return res.data;
};

export const updateCaisseStatus = async (terminalId, status) => {
  const res = await instanceAxios.put(
    `${BASE_URI}terminal/status/${terminalId}`,
    {
      enable: status,
    }
  );
  return res.data;
};

export const updateCaisse = async (storeId, data) => {
  const res = await instanceAxios.put(`${BASE_URI}cash/${storeId}`, data);
  return res;
};

export const getCaisseByStoreId = async (storeId) => {
  const res = await instanceAxios.get(`${BASE_URI}cash/${storeId}`);
  return res.data;
};
