import React, { useState } from 'react';
// components
import Loader from '~/components/Loader';
import ListProduit from './Select';

//CSS

const Additionnel = (props) => {
  // Stats
  const store = props.store;
  const [load] = useState(false);
  if (load) {
    return <Loader />;
  } else {
    return (
      <div className='page-promotions container'>
        <div className=''>
          <div className='block-styled-body carde'>
            <div className='block-styled single-promotion'>
              <div className='promotion-box'>
                <div>
                  <div>
                    <ListProduit store={store} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Additionnel;
