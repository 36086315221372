import React, { useState } from 'react';
import { Modal } from "react-bootstrap"; 
import { GetFormattedDate } from '~/_helpers/helper';
// Components
import ConfirmModal from "~/components/Reusable/confirm";
//svg
import closeIcon from "~/assets/svg/close.svg";
//CSS
import './_reservations.scss';

const DetailReservation = (props) => { 

    // Stats

    const reservation = props.reservation;
    const [showModal, setShowModal] = useState(false);

    const handleDelete = () => {
        props.delete(reservation._id);
    }

    return (
        <Modal className="modal detail-reservation" show={true}  onHide={ () => props.handleClose(false) }>

            <Modal.Header closeButton>
                <h1 className="modal--title">RESERVATION</h1>
                <img
                    onClick={ () => props.handleClose(false) }
                    className="modal--close"
                    alt="close-svg"
                    src={closeIcon} />
            </Modal.Header>

            <Modal.Body className="fade-in">
                

                <div className="order-infos">
                    <div className="d-flex">
                        <div>
                            <h2>Date</h2>
                            <p>{ GetFormattedDate(reservation.dateLivraison) } </p>
                        </div>
                        <div>
                            <h2>Heure</h2>
                            <p>{ reservation.heureLivraison }</p>
                        </div>
                        <div>
                            <h2>Personnes</h2>
                            <p>{ reservation.nbrPersonnes }</p>
                        </div>
                    </div> 
                    <div>
                        <h2>Email</h2>
                        <p>{ reservation.customer.user.email }</p>
                    </div>

                    <div>
                        <h2>Téléphone</h2>
                        <p>{ reservation.customer.phone }</p>
                    </div>

                    <div className="d-flex">
                        <div>
                            <h2>Nom </h2>
                            <p>{ reservation.customer.firstName}</p>
                        </div>
                        <div>
                            <h2>Prénom</h2>
                            <p>{ reservation.customer.lastName }</p>
                        </div> 
                    </div>

                     
                </div>

                {showModal && (
                    <ConfirmModal
                        show={showModal}
                        setShow={setShowModal}
                        message="Êtes-vous sûr de vouloir supprimer cette réservation ?"
                        btnValide="Validez"
                        handleValide={handleDelete}
                    />
                )}

                
            </Modal.Body>
            <Modal.Footer className="one-button"> 
                <button className="modal--btn-validate large" type="button" onClick={ () => setShowModal(true) } >Annuler et supprimer</button>
            </Modal.Footer> 
        </Modal>
    )
}

export default DetailReservation
