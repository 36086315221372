import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Notyf } from 'notyf';
// Utils
//import { uploadFile } from '~/api/upload';
import { updateAllergene, deleteAllergene } from '~/api/menu';
//svg
import closeIcon from '~/assets/svg/close.svg';
import upload from '~/assets/svg/upload-lg.svg';
import { useLoadMedia } from '~/hooks/loadMedia';
//CSS
import '../_add_item.scss';
const EditAllergene = (props) => {
  const notyf = new Notyf({
    position: {
      x: 'center',
      y: 'top',
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const [allerg, setAllerg] = useState(props.allergene);
  // const [icon, setIcon] = useState({});
  const [image, setImage] = useState('');

  const [loadImage, setLoadImage] = useState();
  const {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    //uppdateInfo,
    //setUpdateInfo,
    setLoadMedia,
    Media,
  } = useLoadMedia({
    allergenes: {
      boolean: loadImage,
      action: setLoadImage,
      name: 'allergenes',
      updateValue: setImage,
    },
  });

  /*  const handleUpload = (e) => {
    let target = e.target;
    let imageFile = target.files[0];
    let file = new FormData();
    file.append('file', imageFile);

    var dim = { width: 100, height: 100 };
    
        if (target.name !== "logo") {
          dim = { width: 70, height: 70 };
        }

    if (!imageFile) {
      notyf.error('Veuillez sélectionner une image.');
    } else if (!imageFile.name.match(/\.(jpg|jpeg|png|svg|gif)$/)) {
      notyf.error('Veuillez sélectionner une image valide. ');
    } else if (target.files[0].size > 2097152) {
      notyf.error("La taille de l'image doit être inférieure à 2 Mo !");
    } else {
      let _URL = window.URL;
      let img = new Image();
      img.onload = function () {
        setIcon(file);
        setAllerg({
          ...allerg,
          imageUrl: URL.createObjectURL(file.get('file')),
        });
      };
      img.src = _URL.createObjectURL(imageFile);
    }
  }; */

  const handleEdit = (e) => {
    e.preventDefault();
    setLoad(true);

    (async function todo() {
      let iconName = '';
      /*  if (icon.constructor.name === 'FormData') {
        await uploadFile('icons', icon).then((res) => {
          iconName = res.data.filename;
        });
      } */

      iconName = image?.allergenes;

      let newAllergene = {
        ...allerg,
        name: allerg.name,
        imageUrl: iconName !== '' ? iconName : allerg.imageUrl,
      };
      await updateAllergene(newAllergene)
        .then((res) => {
          let allergenes = res.data.allergenes;
          props.init(allergenes);
          notyf.success(res.data.message);
        })
        .catch((err) => {
          notyf.error(err.message);
        });

      setLoad(false);
      props.handleCloseEdit(false);
    })();
  };

  const handleDelete = () => {
    setLoad(true);
    deleteAllergene(allerg._id)
      .then((res) => {
        let allergenes = res.data.allergenes;
        props.init(allergenes);
        notyf.success(res.data.message);

        setLoad(false);
        props.handleCloseEdit(false);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <Modal
      className='modal edit-allergie'
      show={props.showEdit}
      onHide={() => props.handleCloseEdit(false)}
    >
      <Modal.Header closeButton>
        <h1 className='modal--title'>Modifier {allerg.name} </h1>
        <img
          onClick={() => props.handleCloseEdit(false)}
          className='modal--close'
          alt='close-svg'
          src={closeIcon}
        />
      </Modal.Header>
      {loadMedia ? (
        <Media setLoadMedia={setLoadMedia} handleSelectedMedia={handleSelectedMedia} />
      ) : (
        <form onSubmit={handleEdit}>
          <Modal.Body className='fade-in'>
            <div className='inputs'>
              <label>Nom de l'alllergène</label>
              <input
                required
                name='name'
                value={allerg.name}
                onChange={(e) => setAllerg({ ...allerg, name: e.target.value })}
              />
            </div>
            {allerg.imageUrl && !image.allergenes ? (
              <center>
                <img
                  alt=''
                  src={allerg.imageUrl}
                  style={{ width: 40, marginBottom: 30 }}
                />
              </center>
            ) : (
              <center>
                <img
                  alt=''
                  src={image?.allergenes}
                  style={{ width: 40, marginBottom: 30 }}
                />
              </center>
            )}
            <p>Selectionnez un icon carré en PNG (max 100x100)</p>
            <d className='styles--body-upload'>
              {/* <input
              type='file'
              id='upload--logo'
              onChange={handleUpload}
              name='logo'
            /> */}
              iv
              <label htmlFor='upload--logo'>
                <img
                  alt='upload-file'
                  src={upload}
                  name='allergenes'
                  onClick={handleMedia}
                />
              </label>
            </d>
          </Modal.Body>
          <Modal.Footer>
            <button type='submit' className='modal--btn-validate'>
              {load ? (
                <div className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Modifier'
              )}
            </button>
            <button
              type='button'
              className='modal--btn-validate large'
              onClick={handleDelete}
            >
              {load ? (
                <div className='spinner-border text-danger' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                'Supprimer'
              )}
            </button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};

export default EditAllergene;
