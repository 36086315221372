import instanceAxios from "./axios.instance" ;
import { BASE_URI } from "~/assets/base";

export const createPromo = async (enseigneId, promo) => {
    const response = await instanceAxios.put(`${BASE_URI}enseigne/promotion/add/${enseigneId}`,
        { promo }
    );

    return response;
}

export const updatePromo = async (enseigneId, promo) => {
    const response = await instanceAxios.put(`${BASE_URI}enseigne/promotion/update/${enseigneId}`,
        { newPromo : promo }
    );

    return response;
}

export const deletePromo = async (enseigneId, promoId) => {
    const response = await instanceAxios.put(`${BASE_URI}enseigne/promotion/delete/${enseigneId}`,
        { promoId }
    );

    return response;
}