import React, { useState, useEffect, Fragment } from "react";
//import { useMedia } from "use-media";
import { Switch, Route } from "react-router-dom";
import Banner from "./Banner";
import Colors from "./Colors";
import Logo from "./Logo";
import Products from "./Products";
import Variants from "./Variants";
import Index from "./Index/index";
import Loader from "~/components/Loader";
import { getEnseigneById } from "~/api/enseigne";

//SVG
import BurgerIcon from "~/components/Svgs/Shop/Burger";
import Perso from "./Perso";

const Styles = ({ hide, setHide }) => {
  const [enseigne, setEnseigne] = useState(null);
  const [load, setLoad] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  //const isTablet = useMedia({ minWidth: 768 });

  useEffect(() => {
    let enseigneId = localStorage.getItem("enseigneId");

    getEnseigneById(enseigneId)
      .then((res) => {
        setEnseigne(res.data.enseigne);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
  }, []);
  return (
    <div className='styles'>
      <div className='toggle-products-menu d-md-none'>
        <button
          className='btn'
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <BurgerIcon />
        </button>
      </div>

      <div className={`styles--nav ${showMenu ? "active" : ""}`}>
        <Index setHide={setHide} />
      </div>

      <div className='styles--pages'>
        {load ? (
          <Loader />
        ) : (
          <Fragment>
            {hide === "close" && (
              <Switch>
                <Route
                  path='/enseigne/styles/banner'
                  component={() => <Banner enseigne={enseigne} />}
                />
                <Route
                  path='/enseigne/styles/logo'
                  component={() => <Logo enseigne={enseigne} />}
                />
                <Route
                  path='/enseigne/styles/products'
                  component={() => <Products enseigne={enseigne} />}
                />
                <Route
                  path='/enseigne/styles/variants'
                  component={() => <Variants enseigne={enseigne} />}
                />
                <Route
                  path='/enseigne/styles/colors'
                  component={() => <Colors enseigne={enseigne} />}
                />
                <Route
                  path='/enseigne/styles/mobile'
                  component={() => <Perso enseigne={enseigne} />}
                />
              </Switch>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Styles;
