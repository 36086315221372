import React, { useState, useEffect } from "react";
// Components
import AddSection from "./AddSection";
import EditSection from "./EditSection";
import Loader from "~/components/Loader";
// Utils
import { getSectionOfStore } from "~/api/menu";

//svg
import Plus from "~/assets/svg/plus.svg";
import EditOrangeIcon from "~/components/Svgs/Shop/EditOrange";

//Pagination
import TablePagination from "~/assets/table-pagination/";

const CuisineStore = (props) => {
  const store = props.store;
  const [load, setLoad] = useState(true);
  const [toAdd, setToAdd] = useState(false);
  const [toEdit, setToEdit] = useState(false);
  const [sections, setSections] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    let menuActive = localStorage.getItem("menuActive");

    (async () => {
      await getSectionOfStore(store._id, menuActive)
        .then((res) => {
          setSections(res.data.sections);
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
          console.log(err);
        });
    })();
  }, [store._id]);

  // Pagination
  const tableColumns = [
    {
      Header: "Nom",
      accessor: "name",
      Cell: ({ row }) => {
        let section = row.original;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {section.color && (
              <div
                style={{
                  width: 25,
                  marginRight: 10,
                  height: 25,
                  backgroundColor: section.color,
                  borderRadius: "50%",
                }}
              ></div>
            )}
            {section.name}
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: "_id",
      className: "actions multiple-btns",
      Cell: ({ row }) => {
        let section = row.original;
        return (
          <>
            {
              <button
                disabled={section.storeId === "0000"}
                type="button"
                className="btn btn-light btn-edit"
                onClick={() => {
                  setSelected(section);
                  setToEdit(true);
                }}
              >
                <EditOrangeIcon />
              </button>
            }
          </>
        );
      },
    },
  ];

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          <div className="layout-head">
            <div className="row">
              <div className="col-6">
                <h1>Section</h1>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="modal--btn-validate"
                  onClick={() => setToAdd(true)}
                >
                  <img alt="plus" src={Plus} /> Ajouter une section
                </button>
              </div>
            </div>
          </div>

          {sections.length && (
            <div className="layout-content">
              <div className="block-styled container">
                <div className="block-styled-body">
                  <TablePagination columns={tableColumns} data={sections} />
                </div>
              </div>
            </div>
          )}

          {toAdd && (
            <AddSection
              storeId={store._id}
              sections={sections}
              showAdd={toAdd}
              handleCloseAdd={setToAdd}
              init={setSections}
            />
          )}
          {toEdit && (
            <EditSection
              section={selected}
              showEdit={toEdit}
              handleCloseEdit={setToEdit}
              init={setSections}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CuisineStore;
