import instanceAxios from "./axios.instance" ;
import { BASE_URI } from "~/assets/base";


/**
 * 
 * @desc creation une enseigne/enseigne
 */
export const createEnseigne = async ({email, password, intitule, address, zipCode, city, country, timeZone, urlWebSite, gender, firstname, lastname, phone, offer}) => {
    const response = await instanceAxios.post(`${BASE_URI}users/enseigne/create`, 
      {
        email, password, intitule, address, zipCode, city, country, timeZone, urlWebSite, gender, firstname, lastname, phone, offer,
      }
    );

    return response;
}

export const getAllEnseignes = async () => {
    const response = await instanceAxios.get(`${BASE_URI}enseignes`);

    return response;
}

export const changeEnseigneStatus = async (id) => {
  const response = await instanceAxios.put(`${BASE_URI}enseigne/updateStatus`,
    { id }
  );

  return response;
}

export const updateEnseigne = async (enseigne) => {
  const response = await instanceAxios.put(`${BASE_URI}enseigne/update/${enseigne._id}`,
    { enseigne }
  );

  return response;
}

export const updateEnseigneEmail = async (enseigneId, newEmail) => {
  const response = await instanceAxios.put(`${BASE_URI}enseigne/updateemail/${enseigneId}`,
    { email : newEmail }
  );

  return response;
}

export const updateEnseigneStyles = async (enseigneId, styles) => {
  const response = await instanceAxios.put(`${BASE_URI}enseigne/styles/${enseigneId}`,
    { styles }
  );

  return response;
}

export const updateEnseignePassword = async (enseigneId, newPassword) => {
  const response = await instanceAxios.put(`${BASE_URI}enseigne/updatepassword/${enseigneId}`,
    { password : newPassword }
  );

  return response;
}

export const getEnseigneById = async (enseigneId) => {
    const response = await instanceAxios.get(`${BASE_URI}enseigne/${enseigneId}`);

    return response;
}

export const getEnseigneByUserId = async (userId) => {
  const response = await instanceAxios.get(`${BASE_URI}enseigne/user/${userId}`);

  return response;
}


export const deleteEnseigne = async (enseigne) => {
  const response = await instanceAxios.delete(`${BASE_URI}enseigne/delete/${enseigne._id}`);

  return response;
}

