import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Notyf } from "notyf";
// Utils
import { updateEnvie } from '~/api/menu';
//svg
import closeIcon from "~/assets/svg/close.svg";
//CSS
import '../_add_item.scss';

const EditEnvie = (props) => {

    const notyf = new Notyf({
        position: {
          x: "center",
          y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const [load, setLoad] = useState(false);
    const [envie, setEnvie] = useState(props.envie);

    const handleEdit = (e) => {
        e.preventDefault(); 

        setLoad(true);

        (async function todo() {

            await updateEnvie(envie)
                .then((res) => {
                    let envies = res.data.wishes;
                    props.init(envies);
                    notyf.success(res.data.message);
                }).catch((err) => {
                    notyf.error(err.message);
                });

            setLoad(false);
            props.handleCloseEdit(false);
            
        })(); 

    }

    return (
        <Modal
            className="modal edit-envie"
            show={props.showEdit}
            onHide={ () => props.handleCloseEdit(false) }>

            <Modal.Header closeButton>
                <h1 className="modal--title">Modifier { envie.name } </h1>
                <img
                    onClick={ () => props.handleCloseEdit(false) }
                    className="modal--close"
                    alt="close-svg"
                    src={closeIcon} />
            </Modal.Header>

            <form onSubmit={ handleEdit }>
                <Modal.Body className="fade-in">
                    <div className="inputs">
                        <label>Nom de l'envie</label>
                        <input
                            required
                            name="name"
                            value={envie.name}
                            onChange={ (e) => setEnvie({...envie, name: e.target.value}) } />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button type="submit" className="modal--btn-validate large" disabled={load}>
                        {load ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            ) : ( "Modifier" )
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default EditEnvie
