import React, { useEffect, Fragment } from "react";
import { useFieldArray } from "react-hook-form";
//SVG
import TrashOutlineIcon from "~/components/Svgs/Shop/TrashOutline";

const TranchesArray = ({ nestIndex, control, register }) => {

    const { fields, remove, append } = useFieldArray({ control, name: `zones[${nestIndex}].slices` });

    useEffect(() => {
        
        if (fields.length < 1 ) {
            append({ 
                minAmout: null, 
                deliveryCost: null 
            });
        }

        // eslint-disable-next-line
    }, []);

    const appendTranche = () => {
        append({ 
            minAmout: null, 
            deliveryCost: null 
        });
    }
    
    return (
        <>
            {fields.map((_slice, k)=>{
                return(
                    <Fragment key={k}>
                        <div className="slice">
                            <div className="d-flex">
                                <div>
                                    <label>Montant minimum :</label>
                                </div>
                                <div>
                                    <input 
                                        {...register(`zones[${nestIndex}].slices[${k}].minAmout`, { required: true })}
                                        type="number" step="0.01" defaultValue={_slice.minAmout} className="form-control"  
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>
                                    <label>Frais de livraison :</label>
                                </div>
                                <div>
                                    <input 
                                        {...register(`zones[${nestIndex}].slices[${k}].deliveryCost`, { required: true })}
                                        type="number" step="0.01" defaultValue={_slice.deliveryCost} className="form-control" 
                                    />
                                </div>
                            </div>
                            <button className="btn btn-danger" type="button"  onClick={ () => remove(k) }>
                                <TrashOutlineIcon />
                            </button>
                        </div>
                    </ Fragment>
                )
            })}

            <center>
                <button onClick={ appendTranche }  type="button" className="btn add-slice">Ajouter une tranche ?</button>
            </center>
            
        </>
    )
}

export default TranchesArray
