import instanceAxios from "./axios.instance" ;
import { BASE_URI } from "~/assets/base";
/**
 * 
 * @desc upload file
 */
 export const uploadFile = async (folder, formData) => {
    const response = await instanceAxios.post(`${BASE_URI}upload/${folder}`,
        formData
    );
    return response;
}
