import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { Notyf } from 'notyf';
import { Switch } from '@material-ui/core';

// Components
import Loader from '~/components/Loader';
// Utils
import { updateStoreConfig } from '~/api/stores';

const PlaceCommande = (props) => {
  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top',
    },
    duration: 3000,
    dismissible: true,
  });

  const [load, setLoad] = useState(false);
  const store = props.store;
  const [place, setPlace] = useState(store.config.commande.place);

  const updateConfig = (e) => {
    e.preventDefault();
    setLoad(true);

    let commandeConfig = {
      ...store.config.commande,
      place,
    };

    updateStoreConfig(store._id, 'commande', commandeConfig)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setTimeout(function () {
          window.location.reload();
        }, 600);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const downloadQR = (e) => {
    const { id } = e.target;
    const canvas = document.getElementById(`${id}`);
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qr_code_store.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className='container'>
      {load ? (
        <Loader />
      ) : (
        <div className='row gx-5 justify-content-between page-type-commande'>
          <div className='col'>
            <div className='block-styled'>
              <h1 className='text-center block-styled-head'>Vente sur place</h1>
              <div className='block-styled-body carde'>
                <center className='text-orange'>
                  Necessite l’impression du qr code
                </center>
                <div className='cmd-choix d-flex'>
                  <div>
                    <strong>Offrez-vous la commande sur place ? </strong>
                  </div>
                  <div>
                    <span>
                      <span
                        className={place.enable ? 'option active' : 'option'}
                        onClick={() => setPlace({ ...place, enable: true })}
                      >
                        Oui
                      </span>
                      <span
                        className={!place.enable ? 'option active' : 'option'}
                        onClick={() => setPlace({ ...place, enable: false })}
                      >
                        Non
                      </span>
                    </span>
                  </div>
                </div>

                {place.enable && (
                  <>
                    <div className='row gx-5 btns-toggle justify-content-between'>
                      <div className='col'>
                        <strong>
                          Quels moyens de paiement pour la vente à place ?
                        </strong>
                        <div className='input mt-3'>
                          <Switch
                            onChange={(e) =>
                              setPlace({
                                ...place,
                                paiement: {
                                  ...place.paiement,
                                  canCB: !place.paiement.canCB,
                                },
                              })
                            }
                            checked={place.paiement.canCB}
                            color='primary'
                            name='canCB'
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          Paiement en ligne par CB
                        </div>
                        <div className='input'>
                          <Switch
                            onChange={(e) =>
                              setPlace({
                                ...place,
                                paiement: {
                                  ...place.paiement,
                                  canES: !place.paiement.canES,
                                },
                              })
                            }
                            checked={place.paiement.canES}
                            color='primary'
                            name='canES'
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          Paiement en espece
                        </div>
                        <div className='input'>
                          <Switch
                            onChange={(e) =>
                              setPlace({
                                ...place,
                                paiement: {
                                  ...place.paiement,
                                  canCR: !place.paiement.canCR,
                                },
                              })
                            }
                            checked={place.paiement.canCR}
                            color='primary'
                            name='canCR'
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          Titres restaurant dématérialisés
                        </div>
                      </div>
                    </div>
                    <div className='text-center mt-5'>
                      <span>
                        <QRCode
                          id='qr_code_store'
                          value={`${store.urlWebSite}/index/${store._id}`}
                          size={290}
                          level={'H'}
                          includeMargin={true}
                        />
                        <button
                          id='qr_code_store'
                          type='button'
                          className='btn btn-dark btn-lg'
                          onClick={downloadQR}
                        >
                          Télecharger le QR CODE Boutique
                        </button>
                      </span>
                      <span>
                        <QRCode
                          id='qr_code_table'
                          value={`${store.urlWebSite}/index/${store._id}/table`}
                          size={290}
                          level={'H'}
                          includeMargin={true}
                        />
                        <button
                          id='qr_code_table'
                          type='button'
                          className='btn btn-dark btn-lg'
                          onClick={downloadQR}
                        >
                          Télecharger le QR CODE Table
                        </button>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='text-center'>
              <button
                type='button'
                className='modal--btn-validate mt-3'
                onClick={updateConfig}
              >
                Validez
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaceCommande;
