import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";
import { updateEnseigneStyles } from '~/api/enseigne';

//imgs et svg
import choice1 from "~/assets/images/styles/variants-choice1.png";
import choice2 from "~/assets/images/styles/variants-choice2.png";
import Radio from "../../../../Svgs/Super/addProduct/radio";
import Loader from "~/components/Loader";

const Variants = ({ enseigne }) => {

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [choice, setChoice] = useState(1);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setChoice(enseigne.styles.layoutVariants);
    // eslint-disable-next-line
  }, []);

  const handleValidate = () => {
    setLoad(true);
    let styles = enseigne.styles;
    styles.layoutVariants = choice;

    updateEnseigneStyles(enseigne._id, styles)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <div className="styles--variants">
      <div className="styles--top">
        <h1>Affichage produit</h1>
        <hr />
      </div>
      {load ? (
        <Loader />
      ) : (
        <div className="styles--body">
          <div> 
            <p>Choissisez la mise en place de vos variants et suppléments</p>
            <div className="styles--block">

              <div className="styles--body-radio mb-0">
                    <div>
                      <label>En ligne</label>
                      <span onClick={() => setChoice('ligne')}>
                        <Radio color={choice === 'ligne' ? "#e08005" : "white"} />
                      </span>
                    </div>
              
                    <span>Conseiller avec ou sans photo pour chaque ingrédient </span>
                    <img className={choice === 'ligne' ? "styles--body-selected" : null} alt="choice1" src={choice1} onClick={() => setChoice('ligne')} />
              </div>


              <div className="styles--body-radio mb-0">
                <div>
                  <label>En colonne</label>
                  <span onClick={() => setChoice('colonne')}>
                    <Radio color={choice === 'colonne' ? "#e08005" : "white"} />
                  </span>
                </div>
            
                <span>Conseiller avec ou sans photo pour chaque ingrédient </span>
                <img className={choice === 'colonne' ? "styles--body-selected" : null} alt="choice2" src={choice2} onClick={() => setChoice('colonne')} />  
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="styles--top">
        <button onClick={handleValidate}>Valider</button>
      </div>

    </div>
  );
};

export default Variants;
