import React from "react";

const AccessIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr">
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 11.25H16.5V8.25H18V3.75H9.99C9.1275 1.935 7.29 0.75 5.25 0.75C2.355 0.75 0 3.105 0 6C0 8.895 2.355 11.25 5.25 11.25C7.29 11.25 9.135 10.065 9.99 8.25H12V11.25ZM15 9.75H13.5V6.75H8.955L8.7825 7.2525C8.2575 8.745 6.8325 9.75 5.25 9.75C3.18 9.75 1.5 8.07 1.5 6C1.5 3.93 3.18 2.25 5.25 2.25C6.8325 2.25 8.2575 3.255 8.7825 4.7475L8.955 5.25H16.5V6.75H15V9.75ZM5.25 8.25C4.0125 8.25 3 7.2375 3 6C3 4.7625 4.0125 3.75 5.25 3.75C6.4875 3.75 7.5 4.7625 7.5 6C7.5 7.2375 6.4875 8.25 5.25 8.25ZM4.5 6C4.5 5.5875 4.8375 5.25 5.25 5.25C5.6625 5.25 6 5.5875 6 6C6 6.4125 5.6625 6.75 5.25 6.75C4.8375 6.75 4.5 6.4125 4.5 6Z" fill="#393838"/>
        </svg> 
    </span>
  );
};

export default AccessIcon;
