import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Nepting from "./nepting";
import PayGreen from "./paygreen";
import Sms from "./sms";

const ServiceStore = (props) => {
  return (
    <Switch>
      <Route exact path="/store/services">
        <Redirect to="/store/services/paygreen" />
      </Route>
      <Route
        exact
        path="/store/services/paygreen"
        component={() => <PayGreen store={props.store} />}
      />
      <Route
        exact
        path="/store/services/sms"
        component={() => <Sms store={props.store} />}
      />
      <Route
        exact
        path="/store/services/nepting"
        component={() => <Nepting store={props.store} />}
      />
    </Switch>
  );
};

export default ServiceStore;
