import React from "react";

const ClientsIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr">
        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="9.5" cy="6.04167" rx="5.5" ry="5.04167" stroke="#393838" strokeWidth="1.5"/>
            <path d="M1 19.3333C1 19.3333 3.75 16.5833 9.25 16.5833C14.75 16.5833 17.5 19.3333 17.5 19.3333" stroke="#393838" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    </span>
  );
};

export default ClientsIcon;
