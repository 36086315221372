import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Notyf } from "notyf";

// Utils
import { updateStoreConfig } from "~/api/stores";
// Components
import Loader from "~/components/Loader";
// Css
import "../../informations/_informations.scss";
import { useSelector } from "react-redux";

const Sms = (props) => {
  const user = useSelector((state) => state.user);
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const { register, handleSubmit } = useForm();
  const store = props.store;
  const [load, setLoad] = useState(false);

  const onSubmit = (data) => {
    setLoad(true);
    let updateServices = {
      ...store.config.services,
      sms: data,
    };

    updateStoreConfig(store._id, "services", updateServices)
      .then((res) => {
        //setLoad(false);
        notyf.success(res.data.message);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <div className='container px-4'>
      {load ? (
        <Loader />
      ) : (
        <div className='page-informations block-styled'>
          <h1 className='block-styled-head'>Configuration Sms </h1>
          <div className='block-styled-body carde'>
            <div className='row gx-5 justify-content-between'>
              <div className='col'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='inputs'>
                    <label>Clé privée </label>
                    <input
                      required
                      name='cle'
                      defaultValue={store.config.services?.sms?.cle}
                      type='text'
                      {...register("cle", { required: true })}
                    />

                    <label>Identifiant Sms</label>
                    <input
                      required
                      name='identifiant'
                      defaultValue={store.config.services?.sms?.identifiant}
                      type='text'
                      {...register("identifiant", { required: true })}
                    />
                  </div>
                  <button
                    disabled={
                      user.data.scope !== "ROLE_SUPREMEADMIN" &&
                      user.data.scope !== "ROLE_SUPERADMIN"
                    }
                    className='modal--btn-validate'
                    type='submit'
                  >
                    Modifier
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sms;
