import instanceAxios from "./axios.instance" ;
import { BASE_URI } from "~/assets/base";


export const getReservationsOfStore = async (storeId) => {
    const response = await instanceAxios.get(`${BASE_URI}store/reservations/${storeId}`);
    return response;
}
export const createReservation = async (datas) => {
    const response = await instanceAxios.post(`${BASE_URI}store/reservation/create`,
        {
            newReservation : datas
        }
    );
    return response;
}
export const updateReservation = async (reservation) => {
    const response = await instanceAxios.put(`${BASE_URI}store/reservation/update/${reservation._id}`,
        { reservation }
    )
    return response;
}
export const deleteReservation = async (id) => {
    const response = await instanceAxios.delete(`${BASE_URI}store/reservation/delete/${id}`);
    return response;
}