import { LOGOUT_USER, AUTH_USER_FAILED, AUTH_USER, GET_USER_IN_S, CHANGE_ROLE_USER } from '~/constants/actionTypes';

const initialUser = {
    data: null,
    currentRole: null,
    inLoad: true,
    error: false,
    errorMessage:''
}
export const userReducer = (user = initialUser, action) => {
    switch (action.type) {
        case AUTH_USER:
            return {data: action.payload, currentRole: action.payload.scope, inLoad:false, error: false, errorMessage:''};
        case GET_USER_IN_S:
            return {data: action.payload, currentRole: action.payload.scope, inLoad:false, error: false, errorMessage:''};
        case CHANGE_ROLE_USER:
            return {...user, currentRole: action.payload};
        case AUTH_USER_FAILED:
            return {data: null, inLoad:false, error: true, errorMessage:action.payload};
        case LOGOUT_USER:
            return {...initialUser, inLoad:false};
        default:
            return user;
    }
}