import React, { useState, useEffect } from "react";
import Stats from "./widgets/Stats.js";
import Inputs from "./Inputs/Inputs.js";
import EnseignesList from "./EnseigneList/List";
import { Notyf } from "notyf";
import Loader from "~/components/Loader";
// Import Redux / actions
import { useDispatch } from "react-redux";
import { changeUserRole } from "~/redux/actions/userAction";
import {
  createEnseigne,
  getAllEnseignes,
  changeEnseigneStatus,
} from "~/api/enseigne";

const Gestion = (props) => {
  //  messages
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "bottom",
    },
    duration: 4000,
    dismissible: true,
  });

  let enseigneInfos = {
    intitule: "",
    email: "",
    address: "",
    zipCode: "",
    city: "",
    country: "",
    timeZone: {
      value: "Europe/Brussels",
      label: "(GMT+2:00) Brussels, Copenhagen, Madrid, Paris",
    },
    urlWebSite: "",
    gender: "homme",
    firstname: "",
    lastname: "",
    phone: "",
    offer: "abonnement",
    password: "",
  };

  const [enseignes, setEnseignes] = useState([]);
  const [input, setInput] = useState("");
  const [addEnseigneInput, setAddEnseigneInput] = useState(enseigneInfos);
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Functions

  //récupération des enseignes
  useEffect(() => {
    getAllEnseignes().then((res) => {
      setLoad(false);
      setEnseignes(res.data.enseignes);
    });
  }, []);

  //input searchbar
  const handleChangeSearchBars = (e) => {
    setInput(e.target.value.toLowerCase());
  };

  const handleChangeAddEnseigne = (e) => {
    setAddEnseigneInput({
      ...addEnseigneInput,
      [e.target.name]: e.target.value.toLowerCase(),
    });
  };

  //searchbars

  const filteredEnseignes = enseignes.filter((admins) => {
    return (
      admins.identity.lastname
        .toLowerCase()
        .indexOf(input.toLocaleLowerCase()) !== -1 ||
      admins._id.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1 ||
      admins.email.toLowerCase().indexOf(input.toLocaleLowerCase()) !== -1
    );
  });

  //ajout d'une enseigne
  const handleAddEnseigne = async (e) => {
    e.preventDefault();
    setLoad(true);

    await createEnseigne(addEnseigneInput)
      .then((res) => {
        setLoad(false);
        setShow(false);
        notyf.success(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  //changement de status de l'enseigne
  const handleEditEnseigneStatus = async (id) => {
    setLoad(true);
    await changeEnseigneStatus(id)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
        setEnseignes(res.data.enseignes);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  //Entrer dans  l'interface d'un market
  const handleRedirectToEnseigne = (id) => {
    dispatch(changeUserRole("ROLE_SUPERADMIN"));
    props.history.push(`/enseigne/dashboard/?enseigneid=${id}`);
  };

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="block-styled container">
        <div className="block-styled-body">
          <div className="supremeDash">
            <Stats enseignes={enseignes} />
            <Inputs
              handleAddEnseigne={handleAddEnseigne}
              handleChangeSearchBars={handleChangeSearchBars}
              handleChangeAddEnseigne={handleChangeAddEnseigne}
              addEnseigneInput={addEnseigneInput}
              input={input}
              show={show}
              setShow={setShow}
              handleClose={handleClose}
              handleShow={handleShow}
              load={load}
            />
            <EnseignesList
              load={load}
              filteredEnseignes={filteredEnseignes}
              handleRedirectToEnseigne={handleRedirectToEnseigne}
              handleEditEnseigneStatus={handleEditEnseigneStatus}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default Gestion;
