import React from "react";

const TimeTableIcon = (props) => {
  return (
    <span className="sidebar--list-svg mr">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5353 9.99983C18.5353 14.714 14.7137 18.5355 9.99958 18.5355C5.28544 18.5355 1.46387 14.714 1.46387 9.99983C1.46387 5.28568 5.28544 1.46411 9.99958 1.46411C14.7137 1.46411 18.5353 5.28568 18.5353 9.99983Z" stroke="#393838" strokeWidth="1.5"/>
            <path d="M10.0004 5.35693C10.3949 5.35693 10.7147 5.67673 10.7147 6.07122V9.64229L12.8576 9.64265C13.2521 9.64265 13.5718 9.96244 13.5718 10.3569C13.5718 10.7514 13.2521 11.0712 12.8576 11.0712L10.0094 11.0712C10.0064 11.0712 10.0034 11.0712 10.0004 11.0712C9.60593 11.0712 9.28613 10.7514 9.28613 10.3569V6.07122C9.28613 5.67673 9.60593 5.35693 10.0004 5.35693Z" fill="#393838" stroke="#393838" strokeWidth="0.5"/>
        </svg> 
    </span>
  );
};

export default TimeTableIcon;
