import { BASE_URI } from "~/assets/base";
import instanceAxios from "./axios.instance";

export const fetchLegalInfo = async (storeId, type) => {
  const res = await instanceAxios
    .get(`${BASE_URI}legalInfo/${storeId}?type=${type}`)
    .catch((err) => {
      return null;
    });
  return res.data;
};

export const createLegalInfo = async (storeId, postData) => {
  const res = await instanceAxios
    .post(`${BASE_URI}legalInfo/add/${storeId}`, postData)
    .catch((err) => {
      return null;
    });

  return res.data;
};
