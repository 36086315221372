import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";
import { getAllEnseignes } from "~/api/enseigne";
import Select from "react-select";

//SVG
import Loader from "~/components/Loader";
import Plus from "~/assets/svg/plus.svg";

//Pagination
import TablePagination from "~/assets/table-pagination/";
import AddCreditSmsModal from "./AddCreditSmsModal";
import {
  getStoreSmsInfo,
  getStoresOfEnseigne,
  getStoreSmsInfoAdmin,
  addStoreCreditSms,
} from "~/api/stores";

const notyf = new Notyf({
  position: {
    x: "center",
    y: "bottom",
  },
  duration: 3000,
  dismissible: true,
});

const PartnerSms = () => {
  const [enseignes, setEnseignes] = useState([]);
  const [stores, setStores] = useState([]);
  const [enseigneSelected, setEnseigneSelected] = useState(null);
  const [loadStore, setLoadStore] = useState(false);
  const [allSms, setAllSms] = useState(0);

  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    getAllEnseignes()
      .then((res) => {
        setLoad(false);
        setEnseignes(res.data.enseignes);
        const enseigne = res.data.enseignes[0];
        setEnseigneSelected({
          label: enseigne.intitule,
          value: enseigne._id,
        });
        getStoreSmsInfoAdmin().then((r) => {
          setAllSms(r.credits.creditSms);
        });
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (enseigneSelected) {
      setLoadStore(true);
      localStorage.setItem("enseigneId", enseigneSelected.value);
      getStoresOfEnseigne(enseigneSelected.value)
        .then(async (res) => {
          setStores(res.data.stores);
          setLoad(false);
          setLoadStore(false);
          const stores = await Promise.all(
            res.data.stores.map(async (x) => {
              try {
                const smsInfo = await getStoreSmsInfo(x._id);
                return {
                  ...x,
                  quantity: smsInfo.credits.creditSms,
                };
              } catch (err) {
                return { ...x, quantity: 0 };
              }
            })
          );
          setStores(stores);
        })
        .catch((err) => {
          setLoadStore(false);
          notyf.error(err.message);
        });
    }
  }, [enseigneSelected]);

  const onSelected = (store) => {
    setSelected(store);
    setShowModal(true);
  };

  const handleAddCredit = async (data) => {
    await addStoreCreditSms(selected._id, data);
    setAllSms((all) => all - data.sms);
    setStores((stores) =>
      stores.map((x) =>
        x._id === selected._id
          ? { ...x, quantity: x.quantity + parseInt(data.sms) }
          : x
      )
    );
  };

  // Pagination
  const tableColumns = [
    {
      Header: "Noms des boutiques",
      className: "tdswitch",
      accessor: (d) => d.intitule,
      cssWidth: 500,
    },
    {
      Header: "Email",
      accessor: (d) => d.managerUserId.email,
    },
    {
      Header: "Quantité",
      accessor: "quantity",
      cssWidth: 100,
    },
    {
      Header: " ",
      cssWidth: 166,
      accessor: (d) => d._id,
      Cell: ({ value, row }) => {
        return (
          <button
            onClick={() => onSelected(row.original)}
            className="addCreditBtn"
          >
            <img src={Plus} alt="more" />
          </button>
        );
      },
    },
  ];

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="terminate center_last_col">
        <div className="block-styled container">
          <div className="block-styled-body">
            <div className="terminate--inputWrapper">
              <h3>
                Quantité restante: <span className="qte">{allSms} SMS</span>
              </h3>
            </div>
            <div className="terminate--inputWrapper">
              <Select
                options={enseignes.map((x) => ({
                  label: x.intitule,
                  value: x._id,
                }))}
                className="basic-single"
                classNamePrefix="select"
                value={enseigneSelected}
                onChange={(value) => setEnseigneSelected(value)}
              />
            </div>
            {loadStore && (
              <div style={{ position: "relative" }}>
                {" "}
                <Loader />
              </div>
            )}
            {!loadStore && (
              <div className="terminate--table">
                <TablePagination
                  columns={tableColumns}
                  data={stores}
                  searchLabel={"Rechercher une boutique de l'enseigne"}
                  withPaginate={false}
                />
              </div>
            )}
          </div>
        </div>
        <AddCreditSmsModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          storeSelected={selected}
          handleAddCredit={handleAddCredit}
          notyf={notyf}
        />
      </div>
    );
  }
};

export default PartnerSms;
