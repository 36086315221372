import axios from "axios";

export const verifiedStuartAddress = async (v, config) => {
  const address = `${v.street} ${v.zip} ${v.city}`;

  const tokeRes = await axios.post(
    `
  https://api.sandbox.stuart.com/oauth/token`,
    {
      client_id: config.identifiant,
      client_secret: config.cle,
      scope: "api",
      grant_type: "client_credentials",
    }
  );

  const res = await axios.get(
    `https://api.sandbox.stuart.com/v2/addresses/validate?type=picking&address=${address}`,
    {
      headers: {
        authorization: `Bearer ${tokeRes.data.access_token}`,
      },
    }
  );

  return res.data.success;
};
