import React, { useState } from "react";
import { Notyf } from "notyf";
import { forgetPassword, resetPassword } from "~/api/user";

//Loader
import Loader from "~/components/Loader";

// SVG
import mailIcon from "~/assets/svg/mail.svg";
import fondOrange from "~/assets/svg/fond-orange.svg";

const PasswordForget = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [load, setLoad] = useState(false);
  const [mailSent, setMailSent] = useState(false);

  const handleForgetPassword = (e) => {
    e.preventDefault();
    setLoad(true);
    forgetPassword(email)
      .then((res) => {
        setLoad(false);
        notyf.success(res.message);
        setMailSent(true);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      code: code,
      password: password,
    };
    if (data.password !== data.confirmPassword) {
      return notyf.error("Les mots de passe ne sont pas identiques");
    }

    resetPassword(data)
      .then((res) => {
        setLoad(false);
        notyf.success(res.message);
        props.history.push("/");
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className="connexion">
        <div className="connexion--top">
          <h1 className="connexion--title">MOT DE PASSE OUBLIE</h1>
          {!mailSent && (
            <form className="connexion--form">
              <label className="connexion--label">Adresse Email</label>
              <div className="connexion--inputWrapper">
                <img alt="mail-icon" src={mailIcon} />
                <input
                  className="connexion--input"
                  onChange={(e) => {
                    setEmail(e.target.value.toLowerCase());
                  }}
                  value={email}
                  name="email"
                  type="text"
                  required
                />
              </div>
              <p
                onClick={() => props.history.push("/")}
                className="connexion--passwordForget"
              >
                Revenir à la connexion ?
              </p>
              <div
                onClick={handleForgetPassword}
                className="connexion--btnWrapper"
              >
                ENVOYER
              </div>
            </form>
          )}
          {mailSent && (
            <form className="connexion--form" onSubmit={handleResetPassword}>
              <label className="connexion--label">Code de vérification</label>
              <div className="connexion--inputWrapper">
                <input
                  className="connexion--input"
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  value={code}
                  name="code"
                  type="text"
                  required
                />
              </div>
              <label className="connexion--label">Nouveau mot de passe</label>
              <div className="connexion--inputWrapper">
                <input
                  className="connexion--input input-libre"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  name="password"
                  type="password"
                  required
                />
              </div>
              <label className="connexion--label">
                Confirmation de mot de passe
              </label>
              <div className="connexion--inputWrapper">
                <input
                  className="connexion--input"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  value={confirmPassword}
                  name="confirmPassword"
                  type="password"
                  required
                />
              </div>
              <p
                onClick={() => setMailSent(false)}
                className="connexion--passwordForget"
              >
                Renvoyer le code
              </p>
              <button type="submit" className="connexion--btnWrapper">
                Réinitialiser
              </button>
            </form>
          )}
        </div>
        <img
          className="connexion--separator"
          alt="mail-icon"
          src={fondOrange}
        />
        <div className="connexion--bottom">
          <p>By eatlines</p>
        </div>
      </div>
    );
  }
};

export default PasswordForget;
