import React from "react";

function Terminal() {
  return (
    <span className='sidebar--list-svg mr'>
      <svg
        width='16'
        height='25'
        viewBox='0 0 16 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.0404 16.4997H0.957031V3.79134C0.957031 3.115 1.11083 2.5356 1.32195 2.15174C1.54892 1.73908 1.73869 1.70801 1.76536 1.70801H14.232C14.2587 1.70801 14.4485 1.73908 14.6754 2.15174C14.8866 2.5356 15.0404 3.115 15.0404 3.79134V16.4997Z'
          stroke='#393838'
          stroke-width='1.5'
        />
        <path
          d='M14.232 23.2917H1.76536C1.73869 23.2917 1.54892 23.2606 1.32195 22.8479C1.11083 22.4641 0.957031 21.8847 0.957031 21.2083V17H15.0404V21.2083C15.0404 21.8847 14.8866 22.4641 14.6754 22.8479C14.4485 23.2606 14.2587 23.2917 14.232 23.2917Z'
          stroke='#393838'
          stroke-width='1.5'
        />
        <path
          d='M9.16797 19.083H13.843V20.4997H9.16797V19.083Z'
          fill='#393838'
        />
        <path
          d='M2.15625 19.083H6.83125V20.4997H2.15625V19.083Z'
          fill='#393838'
        />
        <path
          d='M5.66172 19.791C5.66172 20.5702 5.15526 21.2077 4.49297 21.2077C3.83068 21.2077 3.32422 20.5702 3.32422 19.791H5.66172Z'
          fill='#393838'
        />
      </svg>
    </span>
  );
}

export default Terminal;
