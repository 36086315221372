import React, { useState, useEffect } from "react"; 
import { Link } from "react-router-dom";

//API
import { validateSubscription }  from '~/api/stores';

// components
import Loader from "../Loader";

//SVG
import fondOrange from "~/assets/svg/fond-orange.svg";


const ValidateStoreSubscription = (props) => { 

    const [load, setLoad] = useState(true)
    const [success, setSuccess] = useState(false) 

    useEffect(() => {
        let storeId = props.match.params.storeId
        let enseigneId = props.match.params.enseigneId
        localStorage.setItem("token", `${storeId}-refenseign-${enseigneId}`);
        validateSubscription(storeId).then(()=>{

            setLoad(false)
            setSuccess(true)
        }).catch(err=>{
            setLoad(false)
        })
        // eslint-disable-next-line 
    },[])

    if(load) return(<Loader />)
    if( success ){
        return(
            <div className="connexion validate-store-subscription">
            <div className="connexion--top">
              <h1 className="connexion--title">FÉLICITATIONS</h1>
                <center>
                <p>Votre inscription a été effectué avec succès</p>
                <p>Vous pouvez maintenant accéder à votre compte<br /> pour configurer votre boutique</p>
                </center>
              <Link to="/" style={{textDecoration: 'none'}}><button className="connexion--btnWrapper">CONNEXION</button></Link>
            </div>
            <img
              className="connexion--separator"
              alt="mail-icon"
              src={fondOrange}
            />
            <div className="connexion--bottom">
              <p>By eatlines</p>
            </div>
          </div>
        )
    }else{
        // window.location.href="/"
        return ''
    }
}

export default ValidateStoreSubscription