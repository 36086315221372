import React from "react";
import { Modal } from "react-bootstrap";

import closeIcon from "~/assets/svg/close.svg";

const ConfirmModal = (props) => {
  return (
    <Modal className="modal" show={props.show} onHide={() => props.setShow()}>
      <Modal.Header>
        <img
          onClick={() => props.setShow()}
          className="modal--close cursor"
          alt="close-svg"
          src={closeIcon}
        />
      </Modal.Header>
      <Modal.Body>
        <p className="modal--warning-p">
            {props.message}
        </p>
        <div className="modal--warning-btnWrapper">
          <button
            className="modal--warning-btnValidate"
            onClick={() => props.handleValide()}
            >{props.btnValide}</button>
          <button
            className="modal--warning-btnClose"
            onClick={() => props.setShow()}
          >{props.btncancel ? props.btncancel : "Annuler" }</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;