const initialPromotion = {
    _id: null,
    type: 1,
    enable : true,
    storeId: null,
    name: null,
    description: null,
    pathImage: null,
    startAmount: 0,
    codePromo: null,
    typeCommande: [],
    reduction: {
      method: 'fixe',
      valeur: '0',
    },
    produitsOffert: {
      nombre: 0,
      produits: []
    },
    produitsAddition: {
      price: "0",
      produits: []
    },
    produitsIdentiqueOffert: {
      nombre: 0,
      produits: [],
      type: null,
    },
    produitsDifferentOffert: {
      nombre: 1,
      groupes:[{
        produits:[]
      }],
      type: "moins",
    },
    produitsDifferentPromo: {
      nombre: 1,
      groupes:[{
        produits:[]
      }],
      type: "moins",
      remise: 50
    },
    moments:{
      custom: false,
      plageDate: {
        enable: false,
        start: null,
        end: null,
      },
      days: [],
      time: {
        start: '',
        end: ''
      }
    }
}

export default initialPromotion;