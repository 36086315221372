import React from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "~/assets/svg/close.svg";

const AddStore = (props) => {
  return (
    <Modal
      className='modal addenseigne'
      show={props.showAddStore}
      onHide={props.handleCloseAddStore}
    >
      <Modal.Header closeButton>
        <h1 className='modal--title'>Ajouter une boutique</h1>
        <img
          onClick={props.handleCloseAddStore}
          className='modal--close'
          alt='close-svg'
          src={closeIcon}
        />
      </Modal.Header>
      <form onSubmit={props.handleAddStore}>
        <Modal.Body className='fade-in'>
          <div className='inputs'>
            <label>Nom de la boutique</label>
            <input
              required
              name='intitule'
              value={props.addStoreInput.intitule}
              onChange={props.handleChangeAddStore}
            />
            <label>Numero et voie</label>
            <input
              required
              name='street'
              value={props.addStoreInput.street}
              onChange={props.handleChangeAddStore}
            />
            <label>Code postal</label>
            <input
              required
              name='zip'
              type='number'
              step='0.01'
              min='0'
              value={props.addStoreInput.zip}
              onChange={props.handleChangeAddStore}
            />

            <label>Ville</label>
            <input
              required
              name='city'
              value={props.addStoreInput.city}
              onChange={props.handleChangeAddStore}
            />

            <div className='row'>
              <div className='col'>
                <label>Latitude</label>
                <input
                  required
                  name='lat'
                  value={props.addStoreInput.lat}
                  onChange={props.handleChangeAddStore}
                />
              </div>
              <div className='col'>
                <label>Longitude</label>
                <input
                  required
                  name='lng'
                  value={props.addStoreInput.lng}
                  onChange={props.handleChangeAddStore}
                />
              </div>
            </div>
            <label>Téléphone</label>
            <input
              name='phone'
              type='number'
              value={props.addStoreInput.phone}
              onChange={props.handleChangeAddStore}
            />

            <label>Nom du manager</label>
            <input
              name='managerLastname'
              type='text'
              value={props.addStoreInput.managerLastname}
              onChange={props.handleChangeAddStore}
            />
            <label>Prénom du manager</label>
            <input
              name='managerFirstname'
              type='text'
              value={props.addStoreInput.managerFirstname}
              onChange={props.handleChangeAddStore}
            />
            <label>Email</label>
            <input
              name='email'
              type='text'
              value={props.addStoreInput.email}
              onChange={props.handleChangeAddStore}
              className='input-email'
            />

            <label>Mot de passe</label>
            <input
              className='input-libre'
              required
              name='password'
              onChange={props.handleChangeAddStore}
              value={props.addStoreInput.password}
              type='password'
            />

            <div className='row'>
              <div className='col'>
                <label>Condition de vente</label>
                <select
                  name='vad'
                  value={props.addStoreInput.vad}
                  onChange={props.handleChangeAddStore}
                >
                  <option value={true}>Avec vad</option>
                  <option value={false}>Sans vad</option>
                </select>
              </div>
            </div>

            <div className='custom-control custom-radio mt-4 mb-3'>
              <input
                type='checkbox'
                className='custom-control-input'
                id='sendMail'
                name='sendMail'
                checked={props.addStoreInput.sendMail === true}
                onChange={props.handleSendMail}
              />
              <label
                className='custom-control-label simle_text'
                htmlFor='sendMail'
              >
                Envoyez le lien d'abonnement au gérant de la boutique pour
                valider son inscription
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props.addStoreInput.sendMail && (
            <center className='mb-4'>
              <p className='simle_text'>
                Cette demande va envoyez le lien d'abonnement au gérant de la
                boutique pour valider son inscription
              </p>
            </center>
          )}
          <button
            className='modal--btn-close'
            type='button'
            onClick={props.handleCloseAddStore}
          >
            Retour
          </button>
          <button className='modal--btn-validate'>
            {props.load ? (
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              "Ajouter"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddStore;
