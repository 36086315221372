import React, { useState } from 'react';

const ChoixType = ({handleType, defaultType}) => {

    const [curentType, setCurentType] = useState(defaultType) ;

    const doHandleType = (step) => {
        handleType(step) ;
        setCurentType(step);
    }


    return (
        <div>
            <h2 className="mb-5">Séléctionner la promotion</h2>

            <div className="list_choix">
                <ul>
                    <li>
                        <div className="custom-control custom-radio mb-3">
                            <input type="radio" className="custom-control-input" id="livraison_offerte" name="type_promo" checked={curentType===1} onChange={ () => doHandleType(1) } />
                            <label className="custom-control-label" htmlFor="livraison_offerte">Livraison offerte</label>
                        </div>
                    </li>
                    <li>
                        <div className="custom-control custom-radio mb-3">
                            <input type="radio" className="custom-control-input" id="reduction_panier" name="type_promo" checked={curentType===2} onChange={ () => doHandleType(2) } />
                            <label className="custom-control-label" htmlFor="reduction_panier">Réduction d’une somme sur panier final</label>
                        </div>
                    </li>
                    <li>
                        <div className="custom-control custom-radio mb-3">
                            <input type="radio" className="custom-control-input" id="produit_offert" name="type_promo" checked={curentType===3} onChange={ () => doHandleType(3) } />
                            <label className="custom-control-label" htmlFor="produit_offert">Produit offert par commande</label>
                        </div>
                    </li>
                    <li>
                        <div className="custom-control custom-radio mb-3">
                            <input type="radio" className="custom-control-input" id="addition_produit" name="type_promo" checked={curentType===4} onChange={ () => doHandleType(4) } />
                            <label className="custom-control-label" htmlFor="addition_produit">Addition de produit  = un prix</label>
                        </div>
                    </li>
                    <li>
                        <div className="custom-control custom-radio mb-3">
                            <input type="radio" className="custom-control-input" id="mlt_identique_offert" name="type_promo" checked={curentType===5} onChange={ () => doHandleType(5) } />
                            <label className="custom-control-label" htmlFor="mlt_identique_offert">1, 2, 3 produits achetés = 1 identique offert</label>
                        </div>
                    </li>
                    <li>
                        <div className="custom-control custom-radio mb-3">
                            <input type="radio" className="custom-control-input" id="mlt_produit_offert" name="type_promo" checked={curentType===6} onChange={ () => doHandleType(6) } />
                            <label className="custom-control-label" htmlFor="mlt_produit_offert">1, 2, 3.. produits achetés = 1 produit offert</label>
                        </div>
                    </li>
                    <li>
                        <div className="custom-control custom-radio mb-5">
                            <input type="radio" className="custom-control-input" id="mlt_produit_promo" name="type_promo" checked={curentType===7} onChange={ () => doHandleType(7) } />
                            <label className="custom-control-label" htmlFor="mlt_produit_promo">1, 2, 3... produits achetés = 1 produit en promo</label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ChoixType
