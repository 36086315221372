import React from "react";

const EditIcon = (props) => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4804 0.919167L11.9429 2.28417C12.1867 2.51167 12.1867 2.87917 11.9429 3.10667L10.7992 4.17417L8.45543 1.98667L9.59918 0.919167C9.71793 0.808333 9.87418 0.75 10.0367 0.75C10.1992 0.75 10.3554 0.8025 10.4804 0.919167ZM0.874023 9.06251V11.25H3.21777L10.1303 4.79835L7.78652 2.61085L0.874023 9.06251ZM2.6992 10.0833H2.1242V9.54663L7.7867 4.26163L8.3617 4.7983L2.6992 10.0833Z" fill="#393838"/>
    </svg>
  );
};

export default EditIcon;
