import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";
import Loader from "~/components/Loader";
import { getEnseigneById, updateEnseigne } from "~/api/enseigne";
import { getAllCurrency } from "~/api/currency";

const Informations = () => {
  //messages
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const [enseigne, setEnseigne] = useState();
  const [input, setInput] = useState();
  const [load, setLoad] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    let enseigneId = localStorage.getItem("enseigneId");
    setLoad(true);
    getAllCurrency()
      .then((res) => {
        const currencies = res.data.currencies;
        setCurrencies(currencies);
        getEnseigneById(enseigneId)
          .then((res) => {
            let theEnseigne = res.data.enseigne;
            setInput({
              intitule: theEnseigne.intitule,
              urlWebSite: theEnseigne.urlWebSite,
              lastname: theEnseigne.identity.lastname,
              firstname: theEnseigne.identity.firstname,
              gender: theEnseigne.identity.gender,
              email: theEnseigne.email,
              phone: theEnseigne.identity.phone,
              currency: theEnseigne.currency,
              offer: theEnseigne.newOffer
                ? theEnseigne.newOffer
                : theEnseigne.offer,
              newOffer: theEnseigne.newOffer,
              urlFacebook: theEnseigne.reseauSocial
                ? theEnseigne.reseauSocial.facebook
                : "",
              urlSnapchat: theEnseigne.reseauSocial
                ? theEnseigne.reseauSocial.snapchat
                : "",
              urlInstagram: theEnseigne.reseauSocial
                ? theEnseigne.reseauSocial.instagram
                : "",
              urlAppStore: theEnseigne?.appLinks?.ios
                ? theEnseigne.appLinks.ios
                : "",
              urlPlayStore: theEnseigne?.appLinks?.android
                ? theEnseigne.appLinks.ios
                : "",
            });
            setEnseigne(theEnseigne);
            setLoad(false);
          })
          .catch((err) => {
            setLoad(false);
          });
      })
      .catch((err) => {
        setLoad(false);
      });

    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: name === "currency" ? value : value.toLowerCase(),
    });
  };

  const handleEditInfos = (e) => {
    e.preventDefault();
    setLoad(true);
    const upEnseigne = {
      ...enseigne,
      identity: {
        gender: input.gender,
        firstname: input.firstname,
        lastname: input.lastname,
        phone: input.phone,
      },
      reseauSocial: {
        facebook: input.urlFacebook,
        snapchat: input.urlSnapchat,
        instagram: input.urlInstagram,
      },
      appLinks: {
        android: input.urlPlayStore,
        ios: input.urlAppStore,
      },
      intitule: input.intitule,
      urlWebSite: input.urlWebSite,
      offer: input.offer !== enseigne.offer ? enseigne.offer : input.offer,
      newOffer: input.offer !== enseigne.offer ? input.offer : null,
      currency: input.currency,
    };
    updateEnseigne(upEnseigne)
      .then((res) => {
        setLoad(false);
        notyf.success(res.data.message);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };
  if (enseigne) {
    return (
      <div className="informations">
        <div className="block-styled">
          <h1 className="block-styled-head">Informations générales</h1>
          <div className="block-styled-body">
            {load ? (
              <Loader />
            ) : (
              <form onSubmit={handleEditInfos} className="informations--form">
                <div className="inputs">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="informations--flex">
                        <div>
                          <label>Nom du restaurant</label>
                          <input
                            type="text"
                            required
                            onChange={handleChange}
                            value={input.intitule}
                            name="intitule"
                          />
                        </div>
                      </div>

                      <div className="informations--flex">
                        <div>
                          <label>Nom de responsable</label>
                          <input
                            type="text"
                            required
                            onChange={handleChange}
                            value={input.lastname}
                            name="lastname"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Prénom de responsable</label>
                          <input
                            type="text"
                            required
                            onChange={handleChange}
                            value={input.firstname}
                            name="firstname"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Genre</label>
                          <select
                            required
                            onChange={handleChange}
                            value={input.gender}
                            name="gender"
                          >
                            <option value={"homme"}>Homme</option>
                            <option value={"femme"}>Femme</option>
                          </select>
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Téléphone</label>
                          <input
                            required
                            onChange={handleChange}
                            type="tel"
                            value={input.phone}
                            name="phone"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Devise</label>
                          <select
                            required
                            onChange={handleChange}
                            value={input.currency}
                            name="currency"
                          >
                            {currencies.map((current, key) => (
                              <option key={key} value={current.value}>
                                {current.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="informations--flex">
                        <div>
                          <label>Lien du site</label>
                          <input
                            type="text"
                            required
                            onChange={handleChange}
                            value={input.urlWebSite}
                            name="urlWebSite"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Lien Facebook</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            value={input.urlFacebook}
                            name="urlFacebook"
                            placeholder="https://"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Lien Snapchat</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            value={input.urlSnapchat}
                            name="urlSnapchat"
                            placeholder="https://"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Lien Instagram</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            value={input.urlInstagram}
                            name="urlInstagram"
                            placeholder="https://"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Lien Play store</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            value={input.urlPlayStore}
                            name="urlPlayStore"
                            placeholder="https://"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Lien App store</label>
                          <input
                            type="text"
                            onChange={handleChange}
                            value={input.urlAppStore}
                            name="urlAppStore"
                            placeholder="https://"
                          />
                        </div>
                      </div>
                      <div className="informations--flex">
                        <div>
                          <label>Formules</label>
                          <select
                            value={input.offer}
                            name="offer"
                            onChange={handleChange}
                          >
                            <option value={"abonnement"}>
                              Abonnement mensuel de 59€
                            </option>
                            <option value={"commission"}>
                              Commission de 5% par panier
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {enseigne.newOffer && (
                  <>
                    <div style={{ height: 20 }}></div>
                    <center className="change-formula-warning">
                      Pour un changement de formule, modifier la <br />
                      formule pour qu’une demande nous sois faite
                    </center>
                  </>
                )}

                <button type="submit">Modifier</button>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default Informations;
