import React, { useState } from 'react';
//icons and svgs
import { Switch } from "@material-ui/core";
import More from "~/assets/svg/more.svg";
import Edit from "~/assets/images/fi-rs-pencil.png";
import Trash from "~/assets/svg/trash-black.svg";
import { typePromotion } from '~/constants/global';

const Promotion = ({promo, setEdit, setSelected, handleChangeStatus, handleDelete, store}) => {

    const [showDelete, setShowDelete] = useState(false);
    const [show, setShow] = useState(false);

    const getDateFromString = (string)=>{
        let dateArray = string.split('/')
        return new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]} 23:59:59`)
    }

    const isExpired = (promo)=>{
        if( promo.moments.plageDate.enable === false ) return false;
        else{
            let dateEnd = getDateFromString( promo.moments.plageDate.end ||  promo.moments.plageDate.start );
            return new Date() > dateEnd
        }
    }
    
    return ( 
        <div className="promo--single" onMouseLeave={() => setShow(false)}>
            <div className="promo--single-top">
                {promo.storeId === store._id && (
                    <>
                        <Switch
                            onChange={() => !isExpired(promo) && handleChangeStatus(promo._id)}
                            checked={promo.enable && !isExpired(promo)}
                            color="primary"
                            name="changeStatus"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    </>
                )}

                {
                    isExpired(promo) && 
                    <div>
                        <span className="promo--single-expired">Expiré</span>
                    </div>
                }
    
                {promo.storeId === store._id
                    ?
                        <button onClick={() => setShow(!show)} className="btn-transparent">
                            <img className="pointer" alt="more" src={More} />
                        </button>
                    : <span className="text-danger">Non modifiable</span>
                }
            </div>
            <div className={
                    show
                    ? "promo--single-more"
                    : "promo--single-more promo--single-more-close"
                }
            >
                <div onClick={() => { setEdit(true); setSelected(promo); }} >
                    <p>Modifier</p>
                    <img alt="edit" src={Edit} />
                </div>
                <hr />
                {showDelete ? (
                    <div className="promo--single-btns">
                        <button className="promo--single-btn-back" onClick={() => setShowDelete(false)} > Retour </button>
                        <button className="promo--single-btn-validate" onClick={() => handleDelete(promo._id)} > Valider </button>
                    </div>
                ) : (
                    <div onClick={() => setShowDelete(true)}>
                        <p>Supprimer</p>
                        <img alt="trash" src={Trash} />
                    </div>
                )}
            </div>
    
            <div className="mb-2">
                <p className="promo-name">
                    {promo.name}
                </p>
            </div>
            <div className="mb-2">
                <p  className="promo-type">
                    <span>Promo :</span>
                    { typePromotion[promo.type] }
                </p>    
            </div>
            { 
                promo.moments.plageDate.enable &&
                <div className="mb-2">
                    <p  className="promo-date">
                        {
                            promo.moments.plageDate.end 
                            ?
                                <span><strong>Date départ :</strong> { promo.moments.plageDate.start }<br /></span>
                            :
                                <span><strong>Date fin :</strong> { promo.moments.plageDate.start }<br /></span>
                        }
                        {
                            promo.moments.plageDate.end &&
                            <span><strong>Date fin :</strong>  { promo.moments.plageDate.end }</span>
                        } 
                    </p>    
                </div>
            } 
        </div>
    )
}

export default Promotion
