import React, { useState } from "react";
import { Notyf } from "notyf";
import { Switch } from "@material-ui/core";

// Components
import Loader from "~/components/Loader";
// Utils
import { updateTerminal } from "~/api/terminal";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
  duration: 3000,
  dismissible: true,
});

const LivraisonCommande = (props) => {
  const [load, setLoad] = useState(false);

  const store = props.store;
  const terminal = props.terminal;

  const [recoveryMode, setRecoveryMode] = useState(
    terminal.config.recoveryMode
  );

  const [thankMessage, setThankMessage] = useState(
    terminal.config.thankMessage
  );

  const updateConfig = async (e) => {
    setLoad(true);

    const config = {
      ...terminal.config,
      recoveryMode,
      thankMessage,
    };

    try {
      const res = await updateTerminal(store._id, {
        config,
      });
      if (res.status === 200) {
        notyf.success("Success");
        setLoad(false);
        props.setTerminal({ ...terminal, config });
      }
    } catch (error) {
      setLoad(false);
      notyf.error("Erreur");
    }
  };

  return (
    <div className="container">
      {load ? (
        <Loader />
      ) : (
        <div className="row gx-5 justify-content-between  page-type-commande">
          <div className="col">
            <div className="block-styled">
              <h1 className="text-center block-styled-head">
                Mode de récuperation
              </h1>
              <div className=" borne">
                <p className="text-center ">
                  Choisissez le mode de récuperation
                </p>
                <div className="row gx-5 btns-toggle justify-content-between">
                  <div className="col">
                    <div className="input">
                      <Switch
                        onChange={(e) => setRecoveryMode("pager")}
                        checked={recoveryMode === "pager"}
                        color="primary"
                        name="canCB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Bippeur
                    </div>
                    <div className="input">
                      <Switch
                        onChange={(e) => setRecoveryMode("table")}
                        checked={recoveryMode === "table"}
                        color="primary"
                        name="canES"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Support table
                    </div>
                    <div className="input">
                      <Switch
                        onChange={(e) => setRecoveryMode("tv")}
                        checked={recoveryMode === "tv"}
                        color="primary"
                        name="canCR"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Tv client
                    </div>
                    <div className="input thank-text">
                      <label htmlFor="thank-message">
                        Message de remerciement
                      </label>
                      <input
                        value={thankMessage}
                        name="thankMessage"
                        onChange={(e) => setThankMessage(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="styles--top">
                  <button type="button" onClick={updateConfig}>
                    Validez
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LivraisonCommande;
