import React, { useState, useEffect } from "react";
import { Notyf } from "notyf";
import { updatePromo, deletePromo } from '~/api/promo';

//icons and svgs
import { Switch } from "@material-ui/core";
import More from "~/assets/svg/more.svg";
import Edit from "~/assets/images/fi-rs-pencil.png";
import Trash from "~/assets/svg/trash-black.svg";

let date = new Date();

const Promo = ({ promo, setEdit, setSelected, setPromos, enseigne, store, handleSetLoad }) => {

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });
  
  const [starts, setStarts] = useState("");
  const [ends, setEnds] = useState("");
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false); 
  const currentDate = date.getTime();

  
  useEffect(() => {
    let isMounted = true;
    if ( isMounted ){
      let dateStart = new Date(promo.dateStart);
      let dateEnd = new Date(promo.dateEnd);
      setStarts(
        dateStart.toLocaleString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
      );
      setEnds(
        dateEnd.toLocaleString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
      );
    }
    return () => isMounted = false;
    // eslint-disable-next-line 
  }, [promo]);

  const handleChangeStatus = () => {
    if( new Date(promo.dateEnd).setHours(23,59,59) < currentDate ) return
    handleSetLoad(true);
    let newPromo = {
      ...promo, 
      enable: !promo.enable 
    };
    let enseigneId = enseigne._id;
    updatePromo(enseigneId, newPromo)
      .then((res) => {
        handleSetLoad(false);
        notyf.success(res.data.message);
        setPromos(res.data.promocodes);
        setEdit(false);
      })
      .catch((err) => {
        handleSetLoad(false);
        notyf.error(err.message);
      });
  };

  const handleDelete = (promoId) => {
    let enseigneId = enseigne._id;
    handleSetLoad(true);
    deletePromo(enseigneId, promoId)
      .then((res) => {
        handleSetLoad(false);
        notyf.success(res.data.message);
        setShowDelete(false);
        setShow(false);
        setPromos(res.data.promocodes);
      })
      .catch((err) => {
        handleSetLoad(false);
        notyf.error(err.message);
      });
  };

  const isExpired = ()=>{
    return new Date(new Date(promo.dateEnd).setHours(23,59,59)) < currentDate
  } 
  
  return (
    <div className="promo--single" onMouseLeave={() => setShow(false)}>
      <div className="promo--single-top">
        
        {
          promo.storeId === store._id && (
            <>
              <Switch
                onChange={() => handleChangeStatus()}
                checked={promo.enable && !isExpired()}
                color="primary"
                name="changeStatus"
                inputProps={{ "aria-label": "primary checkbox" }}
              />  
            </>
          )
        }

        {
          isExpired() && 
          <div>
            <span className="promo--single-expired">Expiré</span>
          </div>
        } 

        {
          promo.storeId === store._id
          ? 
          <button onClick={() => setShow(!show)} className="btn-transparent">
            <img
              className="pointer"
              alt="more"
              src={More}
            />
          </button>
          : <span className="text-danger">Non modifiable</span>
        }
        
      </div>
      <div
        className={
          show
            ? "promo--single-more"
            : "promo--single-more promo--single-more-close"
        }
      >
        <div
          onClick={() => {
            setEdit(true);
            setSelected(promo);
          }}
        >
          <p>Modifier</p>
          <img
            alt="edit"
            src={Edit} 
          />
        </div>
        <hr />
        {showDelete ? (
          <div className="promo--single-btns">
            <button
              className="promo--single-btn-back"
              onClick={() => setShowDelete(false)}
            >
              Retour
            </button>
            <button
              className="promo--single-btn-validate"
              onClick={() => handleDelete(promo._id)}
            >
              Valider
            </button>
          </div>
        ) : (
          <div onClick={() => setShowDelete(true)}>
            <p>Supprimer</p>
            <img
              alt="trash"
              src={Trash} 
            />
          </div>
        )}
      </div>

      <div className="mb-2">
        <span>Titre promo: <strong>{promo.name}</strong></span>
      </div>
      <div className="mb-2">
        <span>Code promo: <strong>{promo.code}</strong></span>
      </div>
      <div className="mb-2">
        <span>Date: <strong>{starts} au {ends}</strong></span>
      </div>
      <div className="mb-2">
        <span>Remise: <strong>{promo.discountAmount} {promo.method === "fixe" ? "€" : "%"}</strong></span>
      </div>
      <div>
        <span>A partir de: <strong>{promo.startValue}€</strong></span>
      </div>
    
    </div>
  ); 
};

export default Promo;
