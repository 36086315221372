import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Components
import Store from "./store/index";
import Caisse from "./caisse/index";

const AccessStore = (props) => {
  return (
    <Switch>
      <Route exact path='/store/acces'>
        <Redirect to='/store/acces/store' />
      </Route>
      <Route
        exact
        path='/store/acces/store'
        component={() => <Store store={props.store} />}
      />
      <Route
        exact
        path='/store/acces/caisse'
        component={() => <Caisse store={props.store} />}
      />
    </Switch>
  );
};

export default AccessStore;
