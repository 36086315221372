import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Notyf } from "notyf";

// components
import Loader from "~/components/Loader";
import ConfirmModal from "~/components/Reusable/confirm";
import Select from "react-select";

// Utils
import {
  getAllMenuOfStore,
  createMenu,
  deleteMenu,
  changeMenuStatus,
  updateMenu,
} from "~/api/menu";

//svg
import { Switch } from "@material-ui/core";
import More from "~/assets/svg/more.svg";
import Edit from "~/assets/images/fi-rs-pencil.png";
import Trash from "~/assets/svg/trash-black.svg";

//CSS
import "../codesPromo/_code_promo.scss";

const Menu = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const store = props.store;
  const [load, setLoad] = useState(false);
  const [duplique, setDuplique] = useState(false);
  const [menusduplique, setMenusduplique] = useState([]);
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [produits, setProduits] = useState([]);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState(null);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const { register, handleSubmit, setValue } = useForm();
  let history = useHistory();

  useEffect(() => {
    setLoad(true);
    getAllMenuOfStore(store._id)
      .then(async (res) => {
        const menuArray = await Promise.all(
          res.data.menus.map(async (menu) => {
            if (menu.storeId === store._id) {
              let catArray = await res.data.categories.filter(
                (c) => c.menuId === menu._id
              );
              let prodArray = await res.data.produits.filter(
                (p) => p.menuId === menu._id
              );

              let up = {
                ...menu,
                countCat: catArray.length,
                countProd: prodArray.length,
              };
              return up;
            }
          })
        );
        setMenus(menuArray.filter((m) => m !== undefined));

        const menuArrayDuplique = await Promise.all(
          res.data.menus.map(async (menu) => {
            return { value: menu._id, label: menu.name };
          })
        );
        setMenusduplique(menuArrayDuplique);

        setCategories(res.data.categories);
        setProduits(res.data.produits);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    setLoad(true);
    if (edit) {
      let upMenu = {
        ...selected,
        name: data.name,
      };
      updateMenu(upMenu)
        .then(async (res) => {
          const menuArray = await Promise.all(
            res.data.menus.map(async (menu) => {
              let catArray = await categories.filter(
                (c) => c.menuId === menu._id
              );
              let prodArray = await produits.filter(
                (p) => p.menuId === menu._id
              );

              let up = {
                ...menu,
                countCat: catArray.length,
                countProd: prodArray.length,
              };
              return up;
            })
          );
          setMenus(menuArray);
          notyf.success(res.data.message);
          initInput();
          setLoad(false);
        })
        .catch((err) => {
          notyf.error(err.message);
          setLoad(false);
        });
    } else {
      let newMenu = {
        name: data.name,
        storeId: store._id,
      };

      createMenu(newMenu, data.duplique)
        .then(async (res) => {
          const menuArray = await Promise.all(
            res.data.menus.map(async (menu) => {
              let catArray = await categories.filter(
                (c) => c.menuId === menu._id
              );
              let prodArray = await produits.filter(
                (p) => p.menuId === menu._id
              );

              let up = {
                ...menu,
                countCat: catArray.length,
                countProd: prodArray.length,
              };
              return up;
            })
          );
          setMenus(menuArray);
          notyf.success(res.data.message);
          if (duplique) {
            setTimeout(function () {
              window.location.reload();
            }, 600);
          } else {
            initInput();
            setLoad(false);
          }
        })
        .catch((err) => {
          notyf.error(err.message);
          setLoad(false);
        });
    }
  };

  const handleChangeStatus = (menu) => {
    if (menu.enable) {
      notyf.error("Désolé, faut avoir obligatoirement un menu active");
      return false;
    } else {
      menu.enable = true;
      changeMenuStatus(menu)
        .then((res) => {
          setMenus((menus) =>
            menus.map((m) =>
              m._id === menu._id ? menu : { ...m, enable: false }
            )
          );
          notyf.success(res.data.message);
          setLoad(false);
        })
        .catch((err) => {
          notyf.error(err.message);
          setLoad(false);
        });
    }
  };

  const handleDelete = (menuId) => {
    setLoad(true);
    deleteMenu(menuId)
      .then((res) => {
        setMenus(res.data.menus);
        notyf.success(res.data.message);
        setTimeout(function () {
          window.location.reload();
        }, 600);
      })
      .catch((err) => {
        notyf.error(err.message);
        setLoad(false);
      });
    setShowDelete(false);
  };

  const editMenu = (menu) => {
    setEdit(true);
    setSelected(menu);
    setValue("name", menu.name);
  };

  const initInput = () => {
    setValue("name", "");
    setValue("duplique", "");
    setAdd(false);
    setEdit(false);
    setShow(false);
    setDuplique(false);
  };

  const showMenu = (menu) => {
    localStorage.setItem("menuActive", menu._id);
    history.push("/store/menu/produits");
  };

  const showDuplique = (e) => {
    let checked = e.target.checked;
    if (checked) {
      setDuplique(true);
    } else {
      setDuplique(false);
      setValue("duplique", "");
    }
  };

  if (load) {
    return <Loader />;
  } else {
    return (
      <div className='block-styled codesPromos'>
        <div className='block-styled-body carde'>
          <div className='promo container'>
            {add || edit ? (
              <form className='inputs' onSubmit={handleSubmit(onSubmit)}>
                <div className='block-styled'>
                  <h1 className='block-styled-head text-center'>
                    <i
                      className='fas fa-arrow-left'
                      onClick={() => initInput()}
                    ></i>{" "}
                    Menu
                  </h1>
                  <div className='block-styled-body'>
                    <div className='container'>
                      {add && (
                        <>
                          <div className='row'>
                            <div className='col'>
                              <div className='custom-control custom-checkbox mb-3'>
                                <input
                                  type='checkbox'
                                  className='custom-control-input'
                                  id='dupliquer'
                                  onChange={showDuplique}
                                />
                                <label
                                  className='custom-control-label'
                                  htmlFor='dupliquer'
                                >
                                  Dupliquer un menu existant ?
                                </label>
                              </div>
                            </div>
                          </div>

                          {duplique && (
                            <div className='row'>
                              <div className='col'>
                                <Select
                                  options={menusduplique}
                                  onChange={(value) =>
                                    setValue("duplique", value.value)
                                  }
                                  className='basic-single'
                                  classNamePrefix='select'
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div className='row'>
                        <div className='col'>
                          <label>Nom du menu</label>
                          <input
                            type='text'
                            name='name'
                            {...register("name", { required: true })}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <button>Valider</button>
                  </div>
                </div>
              </form>
            ) : (
              <>
                {!edit && (
                  <>
                    {" "}
                    <div className='promo--all'>
                      {menus.map((menu, id) => (
                        <div
                          key={id}
                          className='promo--single'
                          onMouseLeave={() => setShow(false)}
                        >
                          <div className='promo--single-top'>
                            <Switch
                              onChange={() => handleChangeStatus(menu)}
                              checked={menu.enable}
                              color='primary'
                              name='changeStatus'
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            <div>
                              <span className='label'>
                                <strong>Informations du menu</strong>
                              </span>
                            </div>

                            <button
                              onClick={() => {
                                setShow(!show);
                                setSelected(menu);
                              }}
                              className='btn-transparent'
                            >
                              <img className='pointer' alt='more' src={More} />
                            </button>
                          </div>
                          <div
                            className={
                              show && selected._id === menu._id
                                ? "promo--single-more"
                                : "promo--single-more promo--single-more-close"
                            }
                          >
                            <div onClick={() => editMenu(menu)}>
                              <p>Modifier</p>
                              <img alt='edit' src={Edit} />
                            </div>
                            <hr />

                            {showDelete ? (
                              <ConfirmModal
                                show={showDelete}
                                setShow={setShowDelete}
                                message='Attention cette action supprime tous le contenu de ce menu (produits, catégories... etc), Êtes-vous sûr de vouloir supprimer ce menu ?'
                                btnValide='Validez'
                                handleValide={() => handleDelete(selected._id)}
                              />
                            ) : (
                              <div onClick={() => setShowDelete(true)}>
                                <p>Supprimer</p>
                                <img alt='trash' src={Trash} />
                              </div>
                            )}
                          </div>

                          <div className='mb-2'>
                            <span>
                              Nom du menu: <strong>{menu.name}</strong>
                            </span>
                          </div>

                          <div className='mt-3 mb-3 text-center'>
                            <div>
                              <span className='label'>
                                <strong>Composition du menu</strong>
                              </span>
                            </div>
                          </div>

                          <div className='mb-2'>
                            <span>
                              Catégories: <strong>{menu.countCat}</strong>
                            </span>
                          </div>
                          <div className='mb-2'>
                            <span>
                              produits: <strong>{menu.countProd}</strong>
                            </span>
                          </div>

                          <div className='text-center'>
                            <button
                              type='button'
                              className='modal--btn-validate mt-3'
                              onClick={() => showMenu(menu)}
                            >
                              Voir le menu
                            </button>
                          </div>
                        </div>
                      ))}

                      <div
                        className='promo--single promo--add-btn'
                        onClick={() => setAdd(!add)}
                      >
                        <svg
                          width='48'
                          height='48'
                          viewBox='0 0 48 48'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM25.9999 22V14H21.9999V22H13.9999V26H21.9999V34H25.9999V26H33.9999V22H25.9999ZM7.99989 24C7.99989 32.82 15.1799 40 23.9999 40C32.8199 40 39.9999 32.82 39.9999 24C39.9999 15.18 32.8199 7.99997 23.9999 7.99997C15.1799 7.99997 7.99989 15.18 7.99989 24Z'
                            fill='#393838'
                          />
                        </svg>
                        AJOUTER UN MENU
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Menu;
