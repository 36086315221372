import { useState } from "react";
import Media from "../components/Online/EnseigneInterface/Media";
export function useLoadMedia(input) {
  const [loadMedia, setLoadMedia] = useState(false);
  const [uppdateInfo, setUpdateInfo] = useState(false);
  const handleMedia = (e) => {
    const { name } = e.target;
    setLoadMedia(true);
    switch (name) {
      case input[name].name:
        input[name].action(true);
        break;
      default:
        break;
    }
  };
  const handleSelectedMedia = (mediaPath) => {
    Object.keys(input).forEach((object) => {
      const obj = { ...input[object] };
      if (obj.boolean) {
        const objectName = obj.name;
        const newValue = {};
        newValue[objectName] = mediaPath;
        if (obj.updateImage) {
          obj.updateImage(mediaPath);
        } else obj.updateValue((current) => ({ ...current, ...newValue }));
        obj.action(false);
      }
    });
    setLoadMedia(false);
    setUpdateInfo(true);
  };
  return {
    loadMedia,
    handleMedia,
    handleSelectedMedia,
    uppdateInfo,
    setUpdateInfo,
    Media,
    setLoadMedia,
  };
}
