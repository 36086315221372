import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Notyf } from "notyf";

// Utils
import { updateStoreConfig } from "~/api/stores";
// Components
import Loader from "~/components/Loader";
// Css
import "../../informations/_informations.scss";

const Nepting = (props) => {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 3000,
    dismissible: true,
  });

  const { register, handleSubmit } = useForm();
  const store = props.store;
  const [load, setLoad] = useState(false);

  const onSubmit = (data) => {
    setLoad(true);
    let updateServices = {
      ...store.config.services,
      nepting: data,
    };

    updateStoreConfig(store._id, "services", updateServices)
      .then((res) => {
        notyf.success(res.data.message);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setLoad(false);
        notyf.error(err.message);
      });
  };

  return (
    <div className="container px-4">
      {load ? (
        <Loader />
      ) : (
        <div className="page-informations block-styled">
          <h1 className="block-styled-head">Configuration Nepting </h1>
          <div className="block-styled-body carde">
            <div className="row gx-5 justify-content-between">
              <div className="col">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="inputs">
                    <label>Identifiant Marchand</label>
                    <input
                      required
                      name="identifiant"
                      defaultValue={store.config.services?.nepting?.identifiant}
                      type="text"
                      {...register("identifiant", { required: true })}
                    />
                  </div>
                  <button className="modal--btn-validate" type="submit">
                    Modifier
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nepting;
