import React, { useState } from 'react';
import { Notyf } from "notyf";
import { Switch } from '@material-ui/core';

// Components
import Loader from "~/components/Loader";
// Utils
import { updateStoreConfig } from '~/api/stores' ;


const EmporterCommande = (props) => {

    const notyf = new Notyf({
        position: {
            x: "right",
            y: "top",
        },
        duration: 3000,
        dismissible: true,
    });

    const [load, setLoad] = useState(false);
    const store = props.store ;
    const [emporter, setEmporter] = useState(store.config.commande.emporter);


    const updateConfig = (e) => {
        e.preventDefault();
        setLoad(true);

        let commandeConfig = {
            ...store.config.commande,
            emporter
        }

        updateStoreConfig(store._id, "commande", commandeConfig)
            .then((res) => {
                setLoad(false);
                notyf.success(res.data.message);
                setTimeout(function(){ window.location.reload(); }, 600);
            })
            .catch((err) => {
                setLoad(false);
                notyf.error(err.message);
            });

    }


    return (
        <div className="container">
            { load 
            ? ( <Loader /> ) 
            : (
                <div className="row gx-5 justify-content-between page-type-commande">
                    <div className="col">
                        <div className="block-styled">
                            <h1 className="text-center block-styled-head">Vente a emporter</h1>
                            <div className="block-styled-body carde">
                                <div className="cmd-choix d-flex">
                                    <div>
                                        <strong>
                                            Offrez-vous la vente à emporter ?
                                        </strong>
                                    </div>
                                    <div>
                                        <span>
                                            <span 
                                                className={emporter.enable ? "option active" : "option"}
                                                onClick={() => setEmporter({...emporter, enable: true})}
                                            >Oui</span>
                                            <span 
                                                className={!emporter.enable ? "option active" : "option"}
                                                onClick={() => setEmporter({...emporter, enable: false})}
                                            >Non</span>
                                        </span>
                                    </div>
                                </div>

                                { emporter.enable && (
                                    <>
                                        <div className="cmd-choix  d-flex">
                                            <div>
                                                <strong>Permettez vous les pré commande en dehors des horaires ?</strong>
                                            </div>
                                            <div>
                                                <span>
                                                    <span 
                                                        className={emporter.enablePreCommande ? "option active" : "option"}
                                                        onClick={() => setEmporter({...emporter, enablePreCommande: true})}
                                                    >Oui</span>
                                                    <span 
                                                        className={!emporter.enablePreCommande ? "option active" : "option"}
                                                        onClick={() => setEmporter({...emporter, enablePreCommande: false})}
                                                    >Non</span>
                                                </span>
                                            </div>
                                        </div>

                                        { emporter.enablePreCommande && ( 
                                            <>
                                                <div className="cmd-choix">
                                                    <div className="input-group mb-3 d-flex align-items-center">
                                                        <span className="mr-3">Les commandes doivent être faites :</span>
                                                        <input 
                                                            type="number" className="form-control oneNumber" 
                                                            value={emporter.preCommandeMin}
                                                            step="15"
                                                            min="15"
                                                            max="1380"
                                                            onChange={ (e) => setEmporter({...emporter, preCommandeMin: e.target.value.toLowerCase()}) } />
                                                        <span className="input-group-text">min</span>
                                                        <span className="ml-3">en avance</span>
                                                    </div>
                                                    </div>

                                                    <div className="cmd-choix">
                                                    <div className="input-group mb-3 d-flex align-items-center">
                                                        <span className="mr-3">Les commandes ne peuvent etre faites&nbsp;:</span>
                                                        <input 
                                                            type="number" className="form-control oneNumber" 
                                                            min="0"
                                                            max="7"
                                                            value={emporter.preCommandeDay} 
                                                            onChange={ (e) => setEmporter({...emporter, preCommandeDay: e.target.value.toLowerCase()}) } />
                                                        <span className="input-group-text">jours</span>
                                                        <span className="ml-3">en avance</span>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        

                                        <div className="row gx-5 btns-toggle justify-content-between">
                                            <div className="col">
                                                <strong>Quels moyens de paiement pour la vente à emporter ?</strong>
                                                <div className="input">
                                                    <Switch 
                                                        onChange={(e) => setEmporter({...emporter, paiement: {...emporter.paiement, canCB: !emporter.paiement.canCB} }) } 
                                                        checked={emporter.paiement.canCB} 
                                                        color="primary" name="canCB" inputProps={{ "aria-label": "primary checkbox" }} />
                                                    Paiement en ligne par CB
                                                </div>
                                                <div className="input">
                                                    <Switch 
                                                        onChange={(e) => setEmporter({...emporter, paiement: {...emporter.paiement, canES: !emporter.paiement.canES} }) }
                                                        checked={emporter.paiement.canES} 
                                                        color="primary" name="canES" inputProps={{ "aria-label": "primary checkbox" }} />
                                                    Paiement en espece
                                                </div>
                                                <div className="input">
                                                    <Switch 
                                                        onChange={(e) => setEmporter({...emporter, paiement: {...emporter.paiement, canCR: !emporter.paiement.canCR} }) }
                                                        checked={emporter.paiement.canCR} 
                                                        color="primary" name="canCR" inputProps={{ "aria-label": "primary checkbox" }} />
                                                    Titres restaurant dématérialisés
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                
                                <center>
                                    <button type="button" className="modal--btn-validate mt-3" onClick={ updateConfig }>Validez</button>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default EmporterCommande